import { ArrowRightTableIcon, InfoModalCloseIcon } from "assets/icons/icons";
import styles from "./ChangeLanguage.module.scss";
import i18n from "localizations/config";
import { useAppDispatch, useAppSelector } from "hooks";
import { closeModal } from "App/actions";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { updateLanguageBegin } from "pages/Profile/actions";
import {
  clearFilters,
  fetchProductBookmarksBegin,
  fetchProductDetailsBegin,
  fetchProductsBegin,
  getPurchasingFiltersBegin,
} from "pages/Purchasing/actions";
import { fetchCountriesBegin } from "pages/Authentication/actions";
import { getTodaysQuestionBegin } from "pages/Gamification/actions";
import StorageService from "services/storage.service";
import { useSearchParams } from "react-router-dom";

interface Props {}

interface ChangeLanguageInterface {
  order: number;
  name: string;
  onClick: () => void;
}

const ChangeLanguage: React.FC<Props> = (props) => {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { productDetails } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const streamParam = searchParams.get("stream");
  const langParam = searchParams.get("lang");

  const handleLanguageSet = (lang: string) => {
    if (isLoggedIn) {
      dispatch(updateLanguageBegin(lang.toLowerCase()));
    }
    i18n.changeLanguage(lang.toLowerCase());
    localStorage.setItem("lang", lang);

    if (productDetails) {
      dispatch(fetchProductDetailsBegin(productDetails?.id, lang));
    }

    if (langParam) {
      searchParams.delete("lang");
      setSearchParams(searchParams);
    }

    dispatch(fetchProductsBegin(streamParam, 1, "", [{}], lang));
    dispatch(getPurchasingFiltersBegin(lang));
    dispatch(getTodaysQuestionBegin(lang));
    dispatch(clearFilters());
    StorageService.deleteCookie("priceSlider");
    StorageService.setKeyValue("clear_filters", true);

    if (isLoggedIn) {
      dispatch(fetchProductBookmarksBegin(lang));
      setTimeout(() => {
        dispatch(fetchCountriesBegin("withAuth"));
      }, 2000);
    } else {
      setTimeout(() => {
        dispatch(fetchCountriesBegin("withoutAuth"));
      }, 2000);
    }
    dispatch(closeModal());
  };

  const languages: ChangeLanguageInterface[] = [
    {
      order: 1,
      name: "English",
      onClick: () => handleLanguageSet("en"),
    },
    {
      order: 2,
      name: "Deutsch",
      onClick: () => handleLanguageSet("de"),
    },
    {
      order: 3,
      name: "Français",
      onClick: () => handleLanguageSet("fr"),
    },
    {
      order: 4,
      name: "Italiano",
      onClick: () => handleLanguageSet("it"),
    },
    // {
    //   order: 5,
    //   name: "Espagnol",
    //   onClick: () => handleLanguageSet("es"),
    // },
  ];

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.header} onClick={() => dispatch(closeModal())}>
        <InfoModalCloseIcon />
      </div>
      <div>
        {languages.map((item) => {
          return (
            <div
              key={item.order}
              className={styles.wrap}
              style={{
                borderBottom: `2px solid ${getDefaultColour(defaultColour)}`,
              }}
              onClick={item.onClick}
            >
              <div className={styles.language}>
                <div className={styles.languageOrder}>
                  <span>{item.order}</span>
                </div>
                <div className={styles.languageName}>
                  <span>{item.name}</span>
                </div>
              </div>
              <div>
                <ArrowRightTableIcon color={getDefaultColour(defaultColour)} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChangeLanguage;
