import { useTranslation } from "react-i18next";
import styles from "./SetStrongPassword.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import PasswordInput from "components/PasswordInput/PasswordInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import {
  getDefaultColour,
  hasEightCharacters,
  hasLowercaseLetter,
  hasNumbers,
  hasSymbols,
  hasUppercaseLetter,
} from "utils/utils";
import { GlobalIState } from "types/RootState";
import {
  changeAuthSecurityBegin,
  clearAction,
  getClientBegin,
} from "pages/Profile/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  openToaster,
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import { CHANGE_AUTH_SECURITY_SUCCESS } from "pages/Profile/constants";
import { useNavigate } from "react-router-dom";

export default function SetStrongPassword() {
  const {
    action: { isSuccess, isLoading, actionName },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [state, setState] = useState({
    newPassword: "",
  });
  const disabled =
    !hasUppercaseLetter(state.newPassword) ||
    !hasLowercaseLetter(state.newPassword) ||
    !hasNumbers(state.newPassword) ||
    !hasSymbols(state.newPassword) ||
    !hasEightCharacters(state.newPassword);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = () => {
    dispatch(changeAuthSecurityBegin(state.newPassword));
  };

  useEffect(() => {
    if (actionName === CHANGE_AUTH_SECURITY_SUCCESS) {
      dispatch(
        openToaster("SUCCESS", translations("success.moved_to_strong_password"))
      );
      dispatch(getClientBegin());
      if (isMobile) navigate("/profile");
      else dispatch(setMiddlepartItem(null));
    }
    return () => {
      dispatch(clearAction());
    };
  }, [isSuccess]);

  return (
    <div className={styles.changePasswordContent}>
      <h1>{translations("label.set_strong_password")}</h1>
      <div className={styles.fields}>
        <PasswordInput
          name="newPassword"
          className="secondInput"
          placeholder={translations("label.password")}
          value={state.newPassword}
          onChange={(e) => handleChange(e)}
        />

        <div className={styles.hintLabel}>
          <span>
            {translations("label.this_password_will_be_used_for_future")}
          </span>
        </div>

        <div className={styles.hints}>
          <span
            style={{
              color: !hasUppercaseLetter(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint2")}
          </span>
          <span
            style={{
              color: !hasLowercaseLetter(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint3")}
          </span>
          <span
            style={{
              color: !hasNumbers(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint4")}
          </span>
          <span
            style={{
              color: !hasSymbols(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint5")}
          </span>
          <span
            style={{
              color: !hasEightCharacters(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint6")}
          </span>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            disabled={disabled}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
