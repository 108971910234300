import { combineReducers } from "redux";
import globalReducer from "App/globalReducer";
import modalReducer from "App/modalReducer";
import authenticationReducer from "pages/Authentication/reducer";
import ProfilePageReducer from "pages/Profile/reducer";
import InvestmentsReducer from "pages/Investments/reducer";
import PurchasingReducer from "pages/Purchasing/reducer";
import GamificationReducer from "pages/Gamification/reducer";
import PersonalLedgerReducer from "pages/PersonalLedger/reducer";

export default combineReducers({
  globalReducer,
  modalReducer,
  authenticationReducer,
  ProfilePageReducer,
  InvestmentsReducer,
  PurchasingReducer,
  GamificationReducer,
  PersonalLedgerReducer,
});
