import { useTranslation } from "react-i18next";
import styles from "./Transactions.module.scss";
import { formatPrice, getCurrencyStr, getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";

import {
  fetchTransactionDetailsBegin,
  fetchUserTransactionsBegin,
} from "pages/Investments/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useNavigate, useSearchParams } from "react-router-dom";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface Props {
  type?: string;
}

export default function Transactions(props: Props) {
  const { type } = props;
  const { leftPartShownItem, middlePartShownItem } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    transactions,
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [shownTransactionsLength, setShownTransactionsLength] = useState(2);
  const isMobile = useCheckMobileScreen();

  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");

  const handleLoadMore = () => {
    setShownTransactionsLength(transactions.length);
  };

  const handleOpenTransactionDetails = (id) => {
    dispatch(fetchTransactionDetailsBegin(id));
    if (isMobile) {
      navigate(`/wallet/transactions/details?id=${id}`);
    } else {
      if (leftPartShownItem.includes("2")) {
        dispatch(
          setLeftPartItem(
            leftPartShownItem === "1" ? `2/drinking` : `2/${leftPartShownItem}`
          )
        );
      }

      dispatch(setMiddlepartItem(<Transactions type={type} />));
      dispatch(setRightPartItem("2"));
    }
  };

  useEffect(() => {
    if (!transactions) {
      dispatch(fetchUserTransactionsBegin(typeParam ? typeParam : type));
    }
  }, [transactions, , type, typeParam]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.transactionsContainer}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        {type === "gaming" ? (
          <div className={styles.noItemsBox}>
            <span>{translations("label.no_transactions_history")}</span>
          </div>
        ) : (
          <>
            {transactions?.length === 0 ? (
              <div className={styles.noItemsBox}>
                <span>{translations("label.no_transactions_history")}</span>
              </div>
            ) : (
              (transactions?.length === 1
                ? transactions
                : transactions?.slice(0, shownTransactionsLength)
              )?.map((day) => (
                <div key={day.date}>
                  <div className={styles.date}>
                    <div className={styles.line}>
                      <hr />
                    </div>
                    <div className={styles.dateValue}>{day.date}</div>
                    <div className={styles.line}>
                      <hr />
                    </div>
                  </div>

                  {day?.transactions?.map((item, index) => (
                    <div key={item.id} className={styles.transaction}>
                      <div>
                        <div>{dayjs.utc(item.created_at).format("HH:mm")}</div>
                        <div
                          className={styles.title}
                          onClick={() => handleOpenTransactionDetails(item.id)}
                        >
                          {[4, 5].includes(item?.transactionType?.id)
                            ? day?.transactions[index]?.currency
                            : item?.transactionType?.name}
                        </div>
                      </div>
                      <div className={styles.rightPartInfo}>
                        <div>{item.status}</div>
                        <div
                          className={styles.price}
                          onClick={() => handleOpenTransactionDetails(item.id)}
                        >
                          {/* 4 Medals, 5 Booster */}
                          {![4, 5].includes(item?.transactionType?.id) ? (
                            <span>
                              {leftPartShownItem === "1" ||
                              leftPartShownItem === "drinking"
                                ? "CHF "
                                : getCurrencyStr(item?.currency)}{" "}
                            </span>
                          ) : null}
                          <span>
                            {item?.transactionType?.id === 5
                              ? Number(item?.amount)?.toFixed(0)
                              : formatPrice(
                                  item?.transactionType?.id === 4
                                    ? item.amount
                                    : Number(
                                        item?.discounted_amount
                                          ? item?.discounted_amount
                                          : item?.amount
                                      ).toFixed(2)
                                )}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))
            )}

            {transactions?.length > 2 && shownTransactionsLength === 2 ? (
              <div
                className={styles.loadMoreButton}
                onClick={() => handleLoadMore()}
              >
                {translations("button.more")}
              </div>
            ) : null}
          </>
        )}
      </div>
    </LoaderWrapper>
  );
}
