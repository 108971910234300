import { useTranslation } from "react-i18next";
import styles from "./PhoneNumberUpdated.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import ButtonComponent from "components/Button/Button.component";

import { RoundedSuccessIcon } from "assets/icons/icons";
import { useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import PersonalDetails from "pages/Profile/PersonalDetails/PersonalDetails.page";
import { getClientBegin } from "pages/Profile/actions";

export default function PhoneNumberUpdated() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    dispatch(getClientBegin());
    if (isMobile) {
      dispatch(setLeftPartItem(<PersonalDetails />));
    } else {
      dispatch(setLeftPartItem("1"));
      dispatch(setMiddlepartItem(<PersonalDetails />));
      dispatch(setRightPartItem(null));
    }
  };

  return (
    <div className={styles.phoneNumberUpdatedWrapper}>
      <div className={styles.successStep}>
        <div>
          <h1>{translations("label.phone_number_change_success")}</h1>
          <h2>{translations("label.phone_number_change.hint")}</h2>
        </div>

        <div className={styles.icon}>
          <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
        </div>
        <div className={styles.button}>
          <ButtonComponent
            label={translations("button.close")}
            class={"btn btn-primary"}
            handleClick={() => handleClose()}
          />
        </div>
      </div>
    </div>
  );
}
