import React from "react";
import styles from "./LevelsTable.module.scss";
import { useTranslation } from "react-i18next";

const LevelsTable: React.FC = () => {
  const { t: translations } = useTranslation();
  return (
    <div className={styles.tableContainer}>
      <table className={styles.list}>
        <thead>
          <tr>
            <th>{translations("label.levels")}</th>
            <th>{translations("label.purchases_table")}</th>
            <th>{translations("label.investments")}</th>
            <th>{translations("label.referrals")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label={translations("label.levels")}>
              {translations("label.purple")}
            </td>
            <td></td>
            <td></td>
            <td></td>
            {/* <td colSpan={2} className={styles.startingLevel} data-label="">
              - {translations("label.starting_level")} -
            </td> */}
          </tr>
          <tr>
            <td data-label={translations("label.levels")}>
              {translations("label.silver")}
            </td>
            <td data-label={translations("label.purchases_table")}>2'500</td>
            <td data-label={translations("label.investments")}>2'500</td>
            <td data-label={translations("label.referrals")}>3</td>
          </tr>
          <tr>
            <td data-label={translations("label.levels")}>
              {translations("label.gold")}
            </td>
            <td data-label={translations("label.purchases_table")}>10'000</td>
            <td data-label={translations("label.investments")}>10'000</td>
            <td data-label={translations("label.referrals")}>10</td>
          </tr>
          <tr>
            <td data-label={translations("label.levels")}>
              {translations("label.black")}
            </td>
            <td data-label={translations("label.purchases_table")}>50'000</td>
            <td data-label={translations("label.investments")}>50'000</td>
            <td data-label={translations("label.referrals")}>30</td>
          </tr>
          <tr>
            <td data-label={translations("label.levels")}>
              {translations("label.platinum")}
            </td>
            <td data-label={translations("label.purchases_table")}>200'000</td>
            <td data-label={translations("label.investments")}>200'000</td>
            <td data-label={translations("label.referrals")}>125</td>
          </tr>
          <tr>
            <td data-label={translations("label.levels")}>
              {translations("label.ultimate")}
            </td>
            <td data-label={translations("label.purchases_table")}>500'000</td>
            <td data-label={translations("label.investments")}>500'000</td>
            <td data-label={translations("label.referrals")}>250</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default LevelsTable;
