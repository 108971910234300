import React, { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import styles from "./LogoAndQuoteWrapper.module.scss";
import VinfinityLogo from "assets/images/VinfinityLogo.png";

import { useAppDispatch, useAppSelector } from "hooks";

import { closeToaster } from "App/actions";

import { GlobalIState } from "types/RootState";
import { getDefaultBackground } from "utils/utils";
import ModalComponent from "components/Modal/Modal.component";
import StorageService from "services/storage.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LogoAndQuoteWrapper: React.FC<any> = () => {
  const { toaster } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { defaultBackground } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const [searchParams] = useSearchParams();
  const invitedBy = searchParams.get("invited_by")?.replaceAll("-", " ");
  const expiredAcceptedEventInvite = StorageService.getDataByKey(
    "expiredAcceptedEventInvite"
  );

  const dispatch = useAppDispatch();

  const { t: translations } = useTranslation();

  const handlecloseToaster = () => {
    dispatch(closeToaster());
  };

  // useEffect(() => {
  //   if (isToasterOpen) {
  //     setTimeout(function () {
  //       dispatch(closeToaster());
  //     }, 4000);
  //   }
  // }, [isToasterOpen]);

  return (
    <>
      <div
        className={styles.mainWrapper}
        style={{
          backgroundImage: `url(${getDefaultBackground(defaultBackground)})`,
        }}
      >
        <div className={styles.appHeader}>
          <div className={styles.logo}>
            <img src={VinfinityLogo} alt="Vinfinity Logo" />
          </div>
          <div className={styles.title}>
            {!expiredAcceptedEventInvite ? (
              <h1>
                {translations("label.invited_friend_registration_title") +
                  invitedBy}
              </h1>
            ) : null}
          </div>
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
      <ToastContainer />
      {/* <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isToasterOpen}
        key={"bottom" + "center"}
        message={toasterMessage}
        onClose={handlecloseToaster}
      /> */}
      <ModalComponent />
    </>
  );
};
export default LogoAndQuoteWrapper;
