import { useTranslation } from "react-i18next";
import styles from "./Concierge.module.scss";

export default function Concierge() {
  const { t: translations } = useTranslation();
  return (
    <div className={styles.container}>
      <h1>{translations("label.concierge")}</h1>
    </div>
  );
}
