import { useTranslation } from "react-i18next";
import styles from "./ResetPasswordSuccess.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import ButtonComponent from "components/Button/Button.component";
import { RoundedSuccessIcon } from "assets/icons/icons";

import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { setLeftPartItem, setRightPartItem } from "App/actions";

export default function ResetPasswordSuccess() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.successStep}>
        <div>
          <h1>{translations("label.password_reset_success")}</h1>
          <h2>{translations("label.password_reset_success_hint")}</h2>
        </div>

        <div className={styles.icon}>
          <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
        </div>
        <div className={styles.button}>
          <ButtonComponent
            label={translations("button.close")}
            class={"btn btn-primary"}
            handleClick={() => handleClose()}
          />
        </div>
      </div>
    </div>
  );
}
