import { useTranslation } from "react-i18next";
import styles from "./ChangePasscode.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import PasscodeInput from "components/PasscodeInput/PasscodeInput.component";
import { useEffect, useState } from "react";
import { HidePasswordIcon, ShowPasswordIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import {
  openToaster,
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import StorageService from "services/storage.service";
import { changePasswordBegin, clearAction } from "pages/Profile/actions";
import { CHANGE_PASSWORD_SUCCESS } from "../constants";
import ChpConfirmEmail from "./ChpConfirmEmail/ChpConfirmEmail.page";
import SetStrongPassword from "../SetStrongPassword/SetStrongPassword.page";

export default function ChangePasscode() {
  const {
    action: { isSuccess, isLoading, actionName },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [oldPasscode, setOldPasscode] = useState("");
  const [newPasscode, setNewPasscode] = useState("");
  const [showOldPasscode, setShowOldPasscode] = useState(true);
  const [showNewPasscode, setShowNewPasscode] = useState(true);

  const handleSave = () => {
    if (oldPasscode?.length !== 4 || newPasscode?.length !== 4) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_passcode_field"))
      );
      return;
    }

    dispatch(
      changePasswordBegin({
        old_password: oldPasscode,
        password: newPasscode,
      })
    );
  };

  useEffect(() => {
    if (actionName === CHANGE_PASSWORD_SUCCESS) {
      if (isMobile) {
        navigate("/change-passcode/confirm");
      } else {
        dispatch(setMiddlepartItem(<ChpConfirmEmail />));
      }
      dispatch(setRightPartItem(null));
    }
  }, [actionName]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>{translations("label.passcode")}</h1>

        <div className={styles.passcodeContainer}>
          <h3>{translations("label.current_passcode")}</h3>
          <div
            className={styles.showPasscodeLabel}
            onClick={() => setShowOldPasscode(!showOldPasscode)}
          >
            <span>
              {translations(
                showOldPasscode ? "label.hide_passcode" : "label.show_passcode"
              )}
            </span>
            <div>
              {showOldPasscode ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>
          <PasscodeInput
            length={4}
            showPasscode={showOldPasscode}
            onChange={setOldPasscode}
          />

          <h3>{translations("label.new_passcode")}</h3>
          <div
            className={styles.showPasscodeLabel}
            onClick={() => setShowNewPasscode(!showNewPasscode)}
          >
            <span>
              {translations(
                showNewPasscode ? "label.hide_passcode" : "label.show_passcode"
              )}
            </span>
            <div>
              {showNewPasscode ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>
          <PasscodeInput
            length={4}
            showPasscode={showNewPasscode}
            onChange={setNewPasscode}
          />

          <div className={styles.hint}>
            <span>{translations("label.passcode_info")}</span>
          </div>
        </div>

        <div className={styles.saveButton}>
          <ButtonComponent
            label={translations("button.save")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />

          <div
            className={styles.changeAuthSecurityLabel}
            onClick={() => {
              if (isMobile) navigate("/set-strong-password");
              else dispatch(setMiddlepartItem(<SetStrongPassword />));
            }}
          >
            <span>
              {translations("label.remove_and_change_to_strong_password?")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
