import React from "react";
import styles from "./LoaderBounce.module.scss";
interface LoaderBounceProps {
  color?: string;
}

const LoaderBounce: React.FC<LoaderBounceProps> = (props) => {
  const { color } = props;
  let classnames = `${styles.loading} ${styles.bounce}`;
  return (
    <div
      className={classnames}
      style={{ backgroundColor: color ? color : "#fff" }}
    ></div>
  );
};

export default LoaderBounce;
