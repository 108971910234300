import { useTranslation } from "react-i18next";
import styles from "./VerifyEmailSuccess.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import ButtonComponent from "components/Button/Button.component";
import { RoundedSuccessIcon } from "assets/icons/icons";

import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { setLeftPartItem, setRightPartItem } from "App/actions";
import { useEffect } from "react";
import { verifyEmailBegin } from "../actions";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { VERIFY_EMAIL_SUCCESS } from "../constants";

export default function VerifyEmailSuccess() {
  const { client, defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
    isLoggedIn,
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();
  const emailTokenParam = searchParams.get("email-token");

  const handleClose = () => {
    navigate(isLoggedIn ? "/" : "/login");
  };

  useEffect(() => {
    if (emailTokenParam) {
      dispatch(verifyEmailBegin(emailTokenParam));
    }
  }, [emailTokenParam]);

  useEffect(() => {
    if (actionName === VERIFY_EMAIL_SUCCESS) {
      searchParams.delete("email-token");
      setSearchParams(searchParams);
    }
  }, [actionName]);

  return (
    <div className={styles.pageWrapper}>
      <LoaderWrapper isLoading={isLoading}>
        <div className={styles.successStep}>
          <div>
            <h1>{translations("label.email_verified")}</h1>
            <h2>{translations("label.email_verified_success")}</h2>
          </div>

          <div className={styles.icon}>
            <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
          </div>
          <div className={styles.button}>
            <ButtonComponent
              label={translations("button.close")}
              class={"btn btn-primary"}
              handleClick={() => handleClose()}
            />
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
}
