import { useTranslation } from "react-i18next";
import styles from "./MembershipTable.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import {
  getMedalsTableBegin,
  getReferralsTableBegin,
} from "pages/Gamification/actions";

import { GlobalIState } from "types/RootState";
import { formatPrice, getInitialsFromName } from "utils/utils";
import { useNavigate } from "react-router-dom";

interface Props {
  type: string;
}

const MembershipTable = (props: Props) => {
  const { type } = props;
  const {
    referralsLeagueTable,
    currentClientReferralsRanking,
    medalsLeagueTable,
    currentClientMedalsRanking,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const { client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();

  useEffect(() => {
    if (type === "referrals" && !referralsLeagueTable) {
      dispatch(getReferralsTableBegin());
    }

    if (type === "medals" && !medalsLeagueTable) {
      dispatch(getMedalsTableBegin());
    }
  }, [type, referralsLeagueTable]);

  return (
    <div className={styles.container}>
      <h3>
        {translations(
          type === "referrals"
            ? "label.referrals_league_table"
            : "label.medals_league_table"
        )}
      </h3>
      <table>
        <thead>
          <tr>
            <td className={styles.fixedWidth}>Nr.</td>
            <td className={styles.wideColumn}>{translations("label.user")}</td>
            <td className={styles.fixedWidth}>
              {translations(
                type === "referrals" ? "label.referrals" : "label.medals"
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          {(type === "referrals"
            ? referralsLeagueTable
            : medalsLeagueTable
          )?.map((row, index) => {
            const initials = getInitialsFromName(
              row.firstname + " " + row.lastname
            );

            return (
              <tr
                key={index}
                style={{
                  backgroundColor: row.id === client?.id && "#ff6333",
                  color: row.id === client?.id ? "#fff" : "#000",
                }}
              >
                <td className={styles.fixedWidth}>{index + 1}.</td>
                <td className={styles.wideColumn}>
                  {initials?.length === 2
                    ? getInitialsFromName(
                        row.firstname + " " + row.lastname
                      )[0] +
                      "." +
                      getInitialsFromName(
                        row.firstname + " " + row.lastname
                      )[1] +
                      "."
                    : initials + "."}
                </td>
                <td className={styles.fixedWidth}>
                  {type === "referrals"
                    ? row.referrals
                    : formatPrice(
                        Number(row.medals_balance).toFixed(0).toString()
                      )}
                </td>
              </tr>
            );
          })}
          {type === "referrals" && currentClientReferralsRanking ? (
            <tr className={styles.currentClientRow}>
              <td className={styles.fixedWidth}>
                {currentClientReferralsRanking.rank}.
              </td>
              <td className={styles.wideColumn}>{translations("label.you")}</td>
              <td className={styles.fixedWidth}>
                {currentClientReferralsRanking.referrals || 0}
              </td>
            </tr>
          ) : type === "medals" && currentClientMedalsRanking ? (
            <tr className={styles.currentClientRow}>
              <td className={styles.fixedWidth}>
                {currentClientMedalsRanking.rank}.
              </td>
              <td className={styles.wideColumn}>{translations("label.you")}</td>
              <td className={styles.fixedWidth}>
                {formatPrice(
                  currentClientMedalsRanking.medals_balance.toString()
                ) || 0}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>
      {type === "referrals" ? (
        <div
          className={styles.footerButton}
          onClick={() => navigate("/invite-referral")}
        >
          <span>{translations("button.make_new_referrals")}</span>
        </div>
      ) : null}
      {type === "medals" ? (
        <div className={styles.footerButton} onClick={() => navigate("/games")}>
          <span>{translations("label.play_new_game")}</span>
        </div>
      ) : null}
    </div>
  );
};

export default MembershipTable;
