import {
  CANCEL_EVENT_REGISTRATION_BEGIN,
  CANCEL_EVENT_REGISTRATION_FAILED,
  CANCEL_EVENT_REGISTRATION_SUCCESS,
  CLEAR_ACTION,
  CLEAR_EVENTS_FILTER,
  CLEAR_SCROLL_TO_TOP,
  CLOSE_MODAL,
  CLOSE_TOASTER,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_FAILED,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_SUCCESS,
  GET_EVENTS_BEGIN,
  GET_EVENTS_FAILED,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAILS_BEGIN,
  GET_EVENT_DETAILS_FAILED,
  GET_EVENT_DETAILS_SUCCESS,
  HIDE_MODAL_HEADER,
  LEFT_PART_SHOWN_ITEM,
  MIDDLE_PART_SHOWN_ITEM,
  OPEN_MODAL,
  OPEN_TOASTER,
  REGISTER_FRIENDS_ON_EVENT_BEGIN,
  REGISTER_FRIENDS_ON_EVENT_FAILED,
  REGISTER_FRIENDS_ON_EVENT_SUCCESS,
  REGISTER_ON_EVENT_BEGIN,
  REGISTER_ON_EVENT_FAILED,
  REGISTER_ON_EVENT_SUCCESS,
  REMOVE_FRIEND_FROM_EVENT_BEGIN,
  REMOVE_FRIEND_FROM_EVENT_FAILED,
  REMOVE_FRIEND_FROM_EVENT_SUCCESS,
  RIGHT_PART_SHOWN_ITEM,
  SCROLL_TO_TOP,
  SET_EVENTS_FILTER,
  UPDATE_INVITED_FRIEND_BEGIN,
  UPDATE_INVITED_FRIEND_FAILED,
  UPDATE_INVITED_FRIEND_SUCCESS,
} from "./constants";

export const clearAction = () => ({
  type: CLEAR_ACTION,
});

export const openModal = ({ content, modalStyle = {}, type = "xl" }) => {
  return { type: OPEN_MODAL, payload: { content, type, modalStyle } };
};

export const closeModal = () => {
  return { type: CLOSE_MODAL };
};

export const hideModalHeader = () => {
  return { type: HIDE_MODAL_HEADER };
};

export const openToaster = (type?: string, message?: string) => {
  return { type: OPEN_TOASTER, payload: { type, message } };
};

export const closeToaster = () => {
  return { type: CLOSE_TOASTER };
};

export const setLeftPartItem = (payload) => {
  return {
    type: LEFT_PART_SHOWN_ITEM,
    payload,
  };
};

export const setMiddlepartItem = (payload) => {
  return {
    type: MIDDLE_PART_SHOWN_ITEM,
    payload,
  };
};

export const setRightPartItem = (payload) => {
  return {
    type: RIGHT_PART_SHOWN_ITEM,
    payload,
  };
};

export const getEventsBegin = () => ({
  type: GET_EVENTS_BEGIN,
});

export const getEventsSuccess = (data) => ({
  type: GET_EVENTS_SUCCESS,
  payload: data,
});

export const getEventsFailed = (error) => ({
  type: GET_EVENTS_FAILED,
  payload: error,
});

export const getEventsDetailsBegin = (data) => ({
  type: GET_EVENT_DETAILS_BEGIN,
  payload: data,
});

export const getEventsDetailsSuccess = (data) => ({
  type: GET_EVENT_DETAILS_SUCCESS,
  payload: data,
});

export const getEventsDetailsFailed = (error) => ({
  type: GET_EVENT_DETAILS_FAILED,
  payload: error,
});

export const registerOnEventBegin = (data) => ({
  type: REGISTER_ON_EVENT_BEGIN,
  payload: data,
});

export const registerOnEventSuccess = (data) => ({
  type: REGISTER_ON_EVENT_SUCCESS,
  payload: data,
});

export const registerOnEventFailed = (error) => ({
  type: REGISTER_ON_EVENT_FAILED,
  payload: error,
});

export const cancelEventRegistrationBegin = (data) => ({
  type: CANCEL_EVENT_REGISTRATION_BEGIN,
  payload: data,
});

export const cancelEventRegistrationFailed = (data) => ({
  type: CANCEL_EVENT_REGISTRATION_FAILED,
  payload: data,
});

export const cancelEventRegistrationSuccess = (error) => ({
  type: CANCEL_EVENT_REGISTRATION_SUCCESS,
  payload: error,
});

export const registerFriendsOnEventBegin = (data) => ({
  type: REGISTER_FRIENDS_ON_EVENT_BEGIN,
  payload: data,
});

export const registerFriendsOnEventSuccess = (data) => ({
  type: REGISTER_FRIENDS_ON_EVENT_SUCCESS,
  payload: data,
});

export const registerFriendsOnEventFailed = (error) => ({
  type: REGISTER_FRIENDS_ON_EVENT_FAILED,
  payload: error,
});

export const removeFriendFromEventBegin = (data) => ({
  type: REMOVE_FRIEND_FROM_EVENT_BEGIN,
  payload: data,
});

export const removeFriendFromEventSuccess = (data) => ({
  type: REMOVE_FRIEND_FROM_EVENT_SUCCESS,
  payload: data,
});

export const removeFriendFromEventFailed = (error) => ({
  type: REMOVE_FRIEND_FROM_EVENT_FAILED,
  payload: error,
});

export const updateInvitedFriendBegin = (data) => ({
  type: UPDATE_INVITED_FRIEND_BEGIN,
  payload: data,
});

export const updateInvitedFriendSuccess = (data) => ({
  type: UPDATE_INVITED_FRIEND_SUCCESS,
  payload: data,
});

export const updateInvitedFriendFailed = (error) => ({
  type: UPDATE_INVITED_FRIEND_FAILED,
  payload: error,
});

export const setEventsFilter = (payload) => {
  return {
    type: SET_EVENTS_FILTER,
    payload,
  };
};

export const clearEventsFilter = () => {
  return { type: CLEAR_EVENTS_FILTER };
};

export const scrollToTop = () => {
  return { type: SCROLL_TO_TOP };
};

export const clearScrollTop = () => {
  return { type: CLEAR_SCROLL_TO_TOP };
};

export const getCelebrityTakeoverTransactionsBegin = (id) => ({
  type: GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN,
  payload: id,
});

export const getCelebrityTakeoverTransactionsSuccess = (data) => ({
  type: GET_CELEBRITY_TAKEOVER_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getCelebrityTakeoverTransactionsFailed = (error) => ({
  type: GET_CELEBRITY_TAKEOVER_TRANSACTIONS_FAILED,
  payload: error,
});
