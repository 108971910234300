import Cookies from "js-cookie";

const setKeyValue = (key, value) => {
  let dataToStore = JSON.stringify(value);
  Cookies.set(key, dataToStore, { expires: 180 });
};

const getDataByKey = (key) => {
  const cookies = Cookies.get(key);
  let data = null;
  if (cookies) {
    data = JSON.parse(cookies);
  }
  return data;
};

const removeCookie = (key) => {
  const cookies = Cookies.get(key);
  cookies.remove("key");
};

const deleteCookie = (key: string) => {
  Cookies.remove(key);
};

const StorageService = {
  setKeyValue,
  getDataByKey,
  removeCookie,
  deleteCookie,
};

export default StorageService;
