import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Impressum.module.scss";

function Impressum() {
  const {
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        dangerouslySetInnerHTML={{
          __html: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html data-editor-version="2" class="sg-campaigns" xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1">
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=Edge">
      <!--<![endif]-->
      <!--[if (gte mso 9)|(IE)]>
      <xml>
        <o:OfficeDocumentSettings>
          <o:AllowPNG/>
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
      <![endif]-->
      <!--[if (gte mso 9)|(IE)]>
  <style type="text/css">
    body {width: 600px;margin: 0 auto;}
    table {border-collapse: collapse;}
    table, td {mso-table-lspace: 0pt;mso-table-rspace: 0pt;}
    img {-ms-interpolation-mode: bicubic;}
  </style>
<![endif]-->
      <style type="text/css">
    body, p, div {
      font-family: arial,helvetica,sans-serif;
      font-size: 14px;
    }
    body {
      color: #000000;
    }
    body a {
      color: #1188E6;
      text-decoration: none;
    }
    p { margin: 0; padding: 0; }
    table.wrapper {
      width:100% !important;
      table-layout: fixed;
      -webkit-font-smoothing: antialiased;
      -webkit-text-size-adjust: 100%;
      -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }
    img.max-width {
      max-width: 100% !important;
    }
    .column.of-2 {
      width: 50%;
    }
    .column.of-3 {
      width: 33.333%;
    }
    .column.of-4 {
      width: 25%;
    }
    ul ul ul ul  {
      list-style-type: disc !important;
    }
    ol ol {
      list-style-type: lower-roman !important;
    }
    ol ol ol {
      list-style-type: lower-latin !important;
    }
    ol ol ol ol {
      list-style-type: decimal !important;
    }
    @media screen and (max-width:480px) {
      .preheader .rightColumnContent,
      .footer .rightColumnContent {
        text-align: left !important;
      }
      .preheader .rightColumnContent div,
      .preheader .rightColumnContent span,
      .footer .rightColumnContent div,
      .footer .rightColumnContent span {
        text-align: left !important;
      }
      .preheader .rightColumnContent,
      .preheader .leftColumnContent {
        font-size: 80% !important;
        padding: 5px 0;
      }
      table.wrapper-mobile {
        width: 100% !important;
        table-layout: fixed;
      }
      img.max-width {
        height: auto !important;
        max-width: 100% !important;
      }
      a.bulletproof-button {
        display: block !important;
        width: auto !important;
        font-size: 80%;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .columns {
        width: 100% !important;
      }
      .column {
        display: block !important;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
      .social-icon-column {
        display: inline-block !important;
      }
    }
  </style>
      <!--user entered Head Start--><!--End Head user entered-->
    </head>
    <body>
      <center class="wrapper" data-link-color="#1188E6" data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#FFFFFF;">
        <div class="webkit">
          <table cellpadding="0" cellspacing="0" border="0" width="100%" class="wrapper" bgcolor="#FFFFFF">
            <tr>
              <td valign="top" bgcolor="#FFFFFF" width="100%">
                <table width="100%" role="content-container" class="outer" align="center" cellpadding="0" cellspacing="0" border="0">
                  <tr>
                    <td width="100%">
                      <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tr>
                          <td>
                            <!--[if mso]>
    <center>
    <table><tr><td width="600">
  <![endif]-->
                                    <table width="100%" cellpadding="0" cellspacing="0" border="0" style="width:100%; max-width:600px;" align="center">
                                      <tr>
                                        <td role="modules-container" style="padding:0px 0px 0px 0px; color:#000000; text-align:left;" bgcolor="#FFFFFF" width="100%" align="left"><table class="module preheader preheader-hide" role="module" data-type="preheader" border="0" cellpadding="0" cellspacing="0" width="100%" style="display: none !important; mso-hide: all; visibility: hidden; opacity: 0; color: transparent; height: 0; width: 0;">
    <tr>
      <td role="module-content">
        <p></p>
      </td>
    </tr>
  </table><table class="module" role="module" data-type="text" border="0" cellpadding="0" cellspacing="0" width="100%" style="table-layout: fixed;" data-muid="b3fdf692-5bc8-48c4-a7b1-511155467c52" data-mc-module-version="2019-10-22">
    <tbody>
      <tr>
        <td style="padding:18px 0px 18px 0px; line-height:22px; text-align:inherit;" height="100%" valign="top" bgcolor="" role="module-content"><div><h1 style="text-align: start"><span style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 18pt; margin-left: 0cm; caret-color: rgb(0, 0, 0); color: black; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; line-height: 16pt; font-size: 24px; font-family: helvetica, sans-serif"><strong>IMPRESSUM VINFINITY</strong></span></h1>
<h2 style="text-align: start"><br></h2>
<h2 style="text-align: start"><span style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 18pt; margin-left: 0cm; caret-color: rgb(0, 0, 0); color: black; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; line-height: 16pt; font-size: 18px; font-family: helvetica, sans-serif"><strong>Vinfinity Schweiz</strong></span></h2>
<h4 style="text-align: start"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-family: helvetica, sans-serif; font-size: 14px">Vinfinity Schweiz GmbH, Rigiweg 21A, 6343 Holzhäusern</span><span style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 20.25pt; margin-left: 0cm; caret-color: rgb(0, 0, 0); color: black; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: -0.35pt; text-align: start; text-indent: 0px; text-transform: none; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-family: helvetica, sans-serif; font-size: 14px">, Switzerland</span></h4>
<h3 style="text-align: start"><br></h3>
<h3 style="text-align: start"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 16px">Contact</span></h3>
<div style="font-family: inherit; text-align: inherit"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 14px">Email: contact@vinfinity.ch</span></div>
<div style="font-family: inherit; text-align: inherit"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 14px">Managing Director: Dino Wintsch</span></div>
<h4 style="text-align: inherit"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 14px">Registernummer: CHE-268.424.199</span></h4>
<div style="font-family: inherit; text-align: start"><br></div>
<h2 style="text-align: inherit"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 18px">Cooperation Partner</span></h2>
<h4 style="text-align: inherit"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 14px">Gerstl Weinselektionen AG, Sandäckerstrasse 10 CH-8957 Spreitenbach, Switzerland</span></h4>
<h4 style="text-align: inherit"><span style="caret-color: rgb(0, 0, 0); color: #000000; font-style: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 14px"><em>Weinpassion seit 1981.</em></span></h4>
<div style="font-family: inherit; text-align: inherit; margin-left: 0px"><br></div>
<div style="font-family: inherit; text-align: inherit; margin-left: 0px"><span style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-style: inherit; font-variant-caps: inherit; font-weight: 400; font-stretch: inherit; line-height: inherit; font-family: inherit; font-size-adjust: inherit; font-kerning: inherit; font-variant-alternates: inherit; font-variant-ligatures: inherit; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-variant-position: inherit; font-feature-settings: inherit; font-optical-sizing: inherit; font-variation-settings: inherit; vertical-align: baseline; border-top-width: 0px; border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px; border-top-style: none; border-right-style: none; border-bottom-style: none; border-left-style: none; border-top-color: currentcolor; border-right-color: currentcolor; border-bottom-color: currentcolor; border-left-color: currentcolor; border-image-source: none; border-image-slice: 100%; border-image-width: 1; border-image-outset: 0; border-image-repeat: stretch; color: #000000; letter-spacing: normal; text-transform: none; margin-inline-start: 0px; margin-inline-end: 0px; margin-block-start: 1em; margin-block-end: 1em; caret-color: rgb(0, 0, 0); text-align: start; text-indent: 0px; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none; font-size: 16px">Contact</span><span style="font-size: 16px">&nbsp;</span></div>
<div style="font-family: inherit; text-align: inherit; margin-left: 0px"><span style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-style: inherit; font-variant-caps: inherit; font-weight: inherit; font-stretch: inherit; line-height: inherit; font-family: inherit; font-size-adjust: inherit; font-kerning: inherit; font-variant-alternates: inherit; font-variant-ligatures: inherit; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-variant-position: inherit; font-feature-settings: inherit; font-optical-sizing: inherit; font-variation-settings: inherit; font-size: 14px; vertical-align: baseline; border-top-width: 0px; border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px; border-top-style: none; border-right-style: none; border-bottom-style: none; border-left-style: none; border-top-color: currentcolor; border-right-color: currentcolor; border-bottom-color: currentcolor; border-left-color: currentcolor; border-image-source: none; border-image-slice: 100%; border-image-width: 1; border-image-outset: 0; border-image-repeat: stretch; caret-color: rgb(0, 0, 0); color: #000000; letter-spacing: normal; text-align: start; text-indent: 0px; text-transform: none; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none">Email: info@gerstl.ch</span></div>
<div style="font-family: inherit; text-align: inherit; margin-left: 0px"><br></div>
<div style="font-family: inherit; text-align: inherit; margin-left: 0px"><span style="box-sizing: border-box; padding-top: 0px; padding-right: 0px; padding-bottom: 0px; padding-left: 0px; margin-top: 0px; margin-right: 0px; margin-bottom: 0px; margin-left: 0px; font-style: inherit; font-variant-caps: inherit; font-weight: 400; font-stretch: inherit; line-height: inherit; font-family: inherit; font-size-adjust: inherit; font-kerning: inherit; font-variant-alternates: inherit; font-variant-ligatures: inherit; font-variant-numeric: inherit; font-variant-east-asian: inherit; font-variant-position: inherit; font-feature-settings: inherit; font-optical-sizing: inherit; font-variation-settings: inherit; font-size: 14px; vertical-align: baseline; border-top-width: 0px; border-right-width: 0px; border-bottom-width: 0px; border-left-width: 0px; border-top-style: none; border-right-style: none; border-bottom-style: none; border-left-style: none; border-top-color: currentcolor; border-right-color: currentcolor; border-bottom-color: currentcolor; border-left-color: currentcolor; border-image-source: none; border-image-slice: 100%; border-image-width: 1; border-image-outset: 0; border-image-repeat: stretch; color: #000000; text-transform: none; letter-spacing: normal; margin-inline-start: 0px; margin-inline-end: 0px; margin-block-start: 1.33em; margin-block-end: 1.33em; caret-color: rgb(0, 0, 0); text-align: start; text-indent: 0px; white-space-collapse: collapse; text-wrap-mode: wrap; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-line: none">Registernummer: CHE-106.274.915</span></div><div></div></div></td>
      </tr>
    </tbody>
  </table></td>
                                      </tr>
                                    </table>
                                    <!--[if mso]>
                                  </td>
                                </tr>
                              </table>
                            </center>
                            <![endif]-->
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </center>
    </body>
  </html>`,
        }}
        className={styles.wrapper}
      ></div>
    </LoaderWrapper>
  );
}

export default Impressum;
