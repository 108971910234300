import React, { useState } from "react";
import styles from "./PasswordInput.module.scss";
import {
  HidePasswordIcon,
  InfoIcon,
  ShowPasswordIcon,
} from "assets/icons/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import { useTranslation } from "react-i18next";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

interface Props {
  value: string;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  onKeyDown?: (e) => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.ChangeEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}
const PasswordInput: React.FC<Props> = ({ ...props }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("label.text");
      return;
    }
    setPasswordType("password");
  };

  const handlePasswordInfoPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.passwordInfo}>
            <div>
              <span>{translations("label.password.info")}</span>
            </div>
            <div className={styles.hints}>
              <span>{translations("label.password.hints")}</span>
            </div>
          </div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  return (
    <div className={styles.password}>
      {props.label ? (
        <div className={styles.inputLabel}>
          <div>
            <label htmlFor={props.name}>{props.label}</label>
          </div>
          {props.name !== "password" ? null : (
            <div
              className={styles.infoIcon}
              onClick={() => handlePasswordInfoPopup()}
            >
              <InfoIcon color={getDefaultColour(defaultColour)} />
            </div>
          )}
        </div>
      ) : null}
      <input
        id={props.name}
        type={passwordType}
        name={props?.name ? props?.name : "password"}
        className={props.className ? props.className : styles.passwordInput}
        style={
          props.className === "secondInput"
            ? ({
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties)
            : {
                borderLeft:
                  props?.value?.length >= 1
                    ? "3px solid transparent"
                    : `3px solid ${getDefaultColour(defaultColour)}`,
                color: getDefaultColour(defaultColour),
              }
        }
        {...props}
      />
      <button className={styles.icon} onClick={togglePassword}>
        {passwordType === "password" ? (
          <ShowPasswordIcon color={getDefaultColour(defaultColour)} />
        ) : (
          <HidePasswordIcon color={getDefaultColour(defaultColour)} />
        )}
      </button>
    </div>
  );
};
export default PasswordInput;
