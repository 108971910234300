import { LockerIcon } from "assets/icons/icons";
import styles from "./EnterTwoFactor.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getDefaultColour, handleOnlyNumbersKeydown } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { logInBegin } from "pages/Authentication/actions";
import StorageService from "services/storage.service";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";
import { useNavigate } from "react-router-dom";

interface Props {}

function EnterTwoFactor() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const data = StorageService.getDataByKey("loginTwoFactorData");
  const [twoFactorValue, setTwoFactorValue] = useState("");

  const handleSubmit = () => {
    dispatch(
      logInBegin(
        data.user,
        data.password,
        data.country_phone,
        data.phone,
        data.google_auth_code,
        data.login_method,
        twoFactorValue
      )
    );
  };

  useEffect(() => {
    if (actionName === LOG_IN_SUCCESS) {
      StorageService.deleteCookie("loginTwoFactorData");
      navigate("/");
    }
  }, [actionName]);

  return (
    <div
      className={styles.mainWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <LockerIcon />
        <h2>{translations("label.two_factor_authentication")}</h2>
        <span>{translations("label.enter_two_factor_code_login")}</span>
      </div>

      <div className={styles.twoFactorInput}>
        <input
          type="text"
          value={twoFactorValue}
          onKeyDown={handleOnlyNumbersKeydown}
          placeholder={translations("label.enter_your_code_here")}
          onChange={(e) => setTwoFactorValue(e.target.value)}
        />
      </div>

      <div className={styles.button}>
        <ButtonComponent
          label={translations("button.submit")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}

export default EnterTwoFactor;
