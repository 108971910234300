import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import styles from "./ConfirmEmail.module.scss";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import { sendVerifyEmail } from "pages/Authentication/saga";
import { sendVerifyEmailBegin } from "pages/Authentication/actions";

function ConfirmEmail() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();

  const handleClose = () => {
    if (isMobile) {
      navigate("/profile");
    }
    dispatch(setLeftPartItem("1"));
    dispatch(setMiddlepartItem(null));
    dispatch(setRightPartItem(null));
  };
  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h1>{translations("label.confirm_email")}</h1>

      <div className={styles.card}>
        <span>{translations("label.confirm_email.subtitle")}</span>

        <div
          className={styles.resendEmailBtn}
          onClick={() => dispatch(sendVerifyEmailBegin())}
        >
          {" "}
          <span>{translations("label.resend_email")}?</span>
        </div>
      </div>
      <ButtonComponent
        label={translations("button.close")}
        class={"btn btn-primary"}
        handleClick={() => handleClose()}
      />
    </div>
  );
}

export default ConfirmEmail;
