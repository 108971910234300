import { useEffect } from "react";
import styles from "./AllWineCollection.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchWineDetailsBegin,
  fetchWinePerformanceBegin,
  fetchWinesBegin,
} from "../actions";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "App/actions";
import { BackButtonIcon } from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import BottleShadow from "assets/images/wine-collection-shadow.png";

interface Props {
  setShowHighlightedBox?: React.Dispatch<React.SetStateAction<string>>;
}
function AllWineCollection(props: Props) {
  const { setShowHighlightedBox } = props;
  const {
    action: { isLoading },
    wines,
    wineDetails,
    winePerformance,
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();

  const handleFetchWineDetails = (id: number) => {
    if (isMobile) {
      navigate(`/investments/wine-collection/details?id=${id}`);
    } else {
      dispatch(fetchWinePerformanceBegin(id));
      dispatch(fetchWineDetailsBegin(id));
      // setShowHighlightedBox("wine-details");
      // dispatch(scrollToTop());
    }
  };

  useEffect(() => {
    dispatch(fetchWinesBegin());
  }, []);

  useEffect(() => {
    if (!isMobile && wineDetails && winePerformance) {
      setShowHighlightedBox("wine-details");
      dispatch(scrollToTop());
    }
  }, [dispatch, wineDetails, winePerformance]);

  return (
    <>
      <div className={styles.header}>
        {!isMobile ? (
          <div className="pointer" onClick={() => setShowHighlightedBox("")}>
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
        ) : null}
        <h2>{translations("label.the_wine_collection")}</h2>
      </div>
      <div className={styles.winesWrapper}>
        {wines?.map((item) => {
          return (
            <div
              className={styles.wineItem}
              key={item?.id}
              onClick={() => handleFetchWineDetails(item?.id)}
            >
              <div className={styles.imagePart}>
                <img src={item?.main_image} />
              </div>
              <img
                src={BottleShadow}
                className={styles.bottleShadow}
                alt="Shadow"
              />
              <div>
                <div style={{ minHeight: 42 }}>
                  <h3>
                    {item?.name?.length > 20
                      ? item?.name?.slice(0, 20) + "..."
                      : item?.name}
                  </h3>
                </div>
                <div>
                  <span>{item?.year}</span>
                </div>
                <div>
                  <span>
                    {item?.quantity} x {item?.unit_size}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default AllWineCollection;
