import { useTranslation } from "react-i18next";

import styles from "./AddPhone.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useEffect, useState } from "react";
import { GlobalIState } from "types/RootState";
import ButtonComponent from "components/Button/Button.component";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import SelectComponent from "components/Select/Select.component";
import { getCountriesSelectOptions, getDefaultColour } from "utils/utils";
import { addPhoneBegin } from "pages/Profile/actions";
import { openToaster, setLeftPartItem, setRightPartItem } from "App/actions";
import StorageService from "services/storage.service";
import PersonalDetails from "../PersonalDetails.page";
import ConfirmPhone from "./ConfirmPhone/ConfirmPhone.page";

interface Props {}

export default function AddPhone(props: Props) {
  const {
    client,
    action: { isLoading },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );

  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const addPhoneSuccessCookie = StorageService.getDataByKey("addPhoneSuccess");
  const countriesFiltered = countries?.filter((item) => item?.is_visible);

  const handleSave = () => {
    if (phone.match(/^[0-9]+$/) != null) {
      dispatch(
        addPhoneBegin({ country_phone: "+" + countryCode, phone: phone })
      );
    } else
      dispatch(
        openToaster("ERROR", translations("error.phone_number.only_digits"))
      );
  };

  useEffect(() => {
    if (addPhoneSuccessCookie) {
      if (isMobile) {
        dispatch(setLeftPartItem(<ConfirmPhone />));
      } else dispatch(setRightPartItem(<ConfirmPhone />));

      StorageService.deleteCookie("addPhoneSuccess");
    }
  }, [addPhoneSuccessCookie]);

  return (
    <div className={styles.addPhoneWrapper}>
      <div>
        <h1>{translations("label.phone_number")}</h1>
        <div className={styles.fields}>
          <div
            className={styles.countryInput}
            style={
              {
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties
            }
            data-chosen-value={countryCode !== "" ? "+" + countryCode : ""}
          >
            <SelectComponent
              name={"countryCode"}
              className="secondSelectStyle"
              label={translations("label.country")}
              value={countryCode}
              options={getCountriesSelectOptions(countriesFiltered)}
              onChange={(e) =>
                setCountryCode(e.target.value.replace(/\D/g, ""))
              }
            />
            <span
              className={styles.label}
              style={
                countryCode.length >= 2
                  ? { display: "none", pointerEvents: "none" }
                  : { pointerEvents: "none" }
              }
            >
              {translations("label.country")}
            </span>
          </div>
          <CustomInputComponent
            type="tel"
            name="phone"
            className="secondInput"
            placeholder={translations("label.mobile_number")}
            value={phone}
            minLength={7}
            maxLength={12}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleSave()}
        />
      </div>
    </div>
  );
}
