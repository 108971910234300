import {
  ChangePhoneNumberInterface,
  ConfirmChangePhoneNumberInterface,
  ResetPasswordInterface,
  UpdateUserInfoCartInterface,
} from "types/pages/profile.page.type";
import {
  AGREE_TO_PRIVACY_POLICY,
  AGREE_TO_TERMS_AND_CONDITIONS,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CLEAR_ACTION,
  CLEAR_CLIENT,
  DECLINE_PRIVACY_POLICY,
  GET_CLIENT_BEGIN,
  GET_CLIENT_FAILED,
  GET_CLIENT_SUCCESS,
  REQUEST_ACCOUNT_CLOSURE,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  UPDATE_ADDRESS_DETAILS_BEGIN,
  UPDATE_ADDRESS_DETAILS_FAILED,
  UPDATE_ADDRESS_DETAILS_SUCCESS,
  UPDATE_LANGUAGE_BEGIN,
  UPDATE_LANGUAGE_FAILED,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN,
  UPDATE_NOTIFICATION_IDENTIFICATION_FAILED,
  UPDATE_NOTIFICATION_IDENTIFICATION_SUCCESS,
  UPDATE_APPLICATION_VIEW_BEGIN,
  UPDATE_APPLICATION_VIEW_FAILED,
  UPDATE_APPLICATION_VIEW_SUCCESS,
  UPDATE_PERSONAL_DETAILS_BEGIN,
  UPDATE_PERSONAL_DETAILS_FAILED,
  UPDATE_PERSONAL_DETAILS_SUCCESS,
  UPDATE_PROFILE_PICTURE_BEGIN,
  UPDATE_PROFILE_PICTURE_FAILED,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_TERMS_AND_CONDITIONS,
  GET_PRIVACY_POLICY,
  ADD_PHONE_BEGIN,
  ADD_PHONE_SUCCESS,
  ADD_PHONE_FAILED,
  ADD_EMAIL_BEGIN,
  ADD_EMAIL_SUCCESS,
  ADD_EMAIL_FAILED,
  SET_DEFAULT_COLOR,
  RESET_DEFAULT_COLOR,
  GET_PENDING_DELIVERY_BALANCE_BEGIN,
  GET_PENDING_DELIVERY_BALANCE_SUCCESS,
  GET_PENDING_DELIVERY_BALANCE_FAILED,
  CONFIRM_PASSWORD_CHANGE_BEGIN,
  CONFIRM_PASSWORD_CHANGE_SUCCESS,
  CONFIRM_PASSWORD_CHANGE_FAILED,
  CHANGE_EMAIL_BEGIN,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILED,
  CONFIRM_CHANGE_EMAIL_BEGIN,
  CONFIRM_CHANGE_EMAIL_SUCCESS,
  CONFIRM_CHANGE_EMAIL_FAILED,
  CONFIRM_ADD_EMAIL_BEGIN,
  CONFIRM_ADD_EMAIL_SUCCESS,
  CONFIRM_ADD_EMAIL_FAILED,
  CHANGE_PHONE_NUMBER_BEGIN,
  CHANGE_PHONE_NUMBER_SUCCESS,
  CHANGE_PHONE_NUMBER_FAILED,
  CONFIRM_CHANGE_PHONE_NUMBER_BEGIN,
  CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS,
  CONFIRM_CHANGE_PHONE_NUMBER_FAILED,
  CONFIRM_ADD_PHONE_BEGIN,
  CONFIRM_ADD_PHONE_SUCCESS,
  CONFIRM_ADD_PHONE_FAILED,
  GET_REFERRAL_CODE_BEGIN,
  GET_REFERRAL_CODE_FAILED,
  GET_REFERRAL_CODE_SUCCESS,
  GET_USER_REFERRALS_BEGIN,
  GET_USER_REFERRALS_SUCCESS,
  GET_USER_REFERRALS_FAILED,
  CHECK_IF_HAS_SAVED_CARD_BEGIN,
  CHECK_IF_HAS_SAVED_CARD_SUCCESS,
  CHECK_IF_HAS_SAVED_CARD_FAILED,
  GENERATE_TWO_FACTOR_AUTH_BEGIN,
  GENERATE_TWO_FACTOR_AUTH_SUCCESS,
  GENERATE_TWO_FACTOR_AUTH_FAILED,
  VERIFY_TWO_FACTOR_AUTH_BEGIN,
  VERIFY_TWO_FACTOR_AUTH_SUCCESS,
  VERIFY_TWO_FACTOR_AUTH_FAILED,
  DEACTIVATE_TWO_FACTOR_AUTH_BEGIN,
  DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS,
  DEACTIVATE_TWO_FACTOR_AUTH_FAILED,
  GET_WALLET_TOTAL_AMOUNT_BEGIN,
  GET_WALLET_TOTAL_AMOUNT_SUCCESS,
  GET_WALLET_TOTAL_AMOUNT_FAILED,
  UPDATE_USER_INFO_BEGIN,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILED,
  CHANGE_AUTH_SECURITY_BEGIN,
  CHANGE_AUTH_SECURITY_SUCCESS,
  CHANGE_AUTH_SECURITY_FAILED,
  GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN,
  GET_S3_UPLOAD_REFERRAL_IMAGE_SUCCESS,
  GET_S3_UPLOAD_REFERRAL_IMAGE_FAILED,
  CLEAR_S3_LINK,
} from "./constants";

export const clearAction = () => ({
  type: CLEAR_ACTION,
});

export const changePasswordBegin = (payload) => ({
  type: CHANGE_PASSWORD_BEGIN,
  payload,
});

export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload: data,
});

export const changePasswordFailed = (error) => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: error,
});

export const resetPasswordBegin = (payload: ResetPasswordInterface) => ({
  type: RESET_PASSWORD_BEGIN,
  payload,
});

export const resetPasswordSuccess = (data) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailed = (error) => ({
  type: RESET_PASSWORD_FAILED,
  payload: error,
});

export const getClientBegin = () => ({
  type: GET_CLIENT_BEGIN,
});

export const getClientSuccess = (data) => ({
  type: GET_CLIENT_SUCCESS,
  payload: data,
});

export const getClientFailed = (error) => ({
  type: GET_CLIENT_FAILED,
  payload: error,
});

export const getTermsAndConditionsBegin = () => ({
  type: GET_TERMS_AND_CONDITIONS,
});
export const getTermsAndConditionsSuccess = (payload) => ({
  type: GET_TERMS_AND_CONDITIONS_SUCCESS,
  payload: payload,
});

export const getPrivacyPolicyBegin = () => ({
  type: GET_PRIVACY_POLICY,
});

export const getPrivacyPolicySuccess = (payload) => ({
  type: GET_PRIVACY_POLICY_SUCCESS,
  payload: payload,
});

export const termsAndConditionsAgreement = () => ({
  type: AGREE_TO_TERMS_AND_CONDITIONS,
});

export const privacyPolicyAgreement = () => ({
  type: AGREE_TO_PRIVACY_POLICY,
});

export const declinePrivacyPolicy = () => ({
  type: DECLINE_PRIVACY_POLICY,
});

export const requestAccountClosure = () => ({
  type: REQUEST_ACCOUNT_CLOSURE,
});

export const updateNotificationIdentBegin = (payload) => ({
  type: UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN,
  payload,
});

export const updateNotificationIdentSuccess = (data) => ({
  type: UPDATE_NOTIFICATION_IDENTIFICATION_SUCCESS,
  payload: data,
});

export const updateNotificationIdentFailed = (error) => ({
  type: UPDATE_NOTIFICATION_IDENTIFICATION_FAILED,
  payload: error,
});

export const updateLanguageBegin = (payload) => ({
  type: UPDATE_LANGUAGE_BEGIN,
  payload,
});

export const updateLanguageSuccess = (data) => ({
  type: UPDATE_LANGUAGE_SUCCESS,
  payload: data,
});

export const updateLanguageFailed = (error) => ({
  type: UPDATE_LANGUAGE_FAILED,
  payload: error,
});

export const clearClient = () => ({
  type: CLEAR_CLIENT,
});

export const updateProfilePictureBegin = (payload) => ({
  type: UPDATE_PROFILE_PICTURE_BEGIN,
  payload,
});

export const updateProfilePictureSuccess = (data) => ({
  type: UPDATE_PROFILE_PICTURE_SUCCESS,
  payload: data,
});

export const updateProfilePictureFailed = (error) => ({
  type: UPDATE_PROFILE_PICTURE_FAILED,
  payload: error,
});

export const updatePersonalDetailsBegin = (data) => ({
  type: UPDATE_PERSONAL_DETAILS_BEGIN,
  payload: data,
});

export const updatePersonalDetailsSuccess = (data) => ({
  type: UPDATE_PERSONAL_DETAILS_SUCCESS,
  payload: data,
});

export const updatePersonalDetailsFailed = (error) => ({
  type: UPDATE_PERSONAL_DETAILS_FAILED,
  payload: error,
});

export const updateAddressDetailsBegin = (data) => ({
  type: UPDATE_ADDRESS_DETAILS_BEGIN,
  payload: data,
});

export const updateAddressDetailsSuccess = (data) => ({
  type: UPDATE_ADDRESS_DETAILS_SUCCESS,
  payload: data,
});

export const updateAddressDetailsFailed = (error) => ({
  type: UPDATE_ADDRESS_DETAILS_FAILED,
  payload: error,
});

export const updateApplicationViewBegin = (data: {
  color: string;
  background_id: number | string;
}) => ({
  type: UPDATE_APPLICATION_VIEW_BEGIN,
  payload: data,
});

export const updateApplicationViewSuccess = (data) => ({
  type: UPDATE_APPLICATION_VIEW_SUCCESS,
  payload: data,
});

export const updateApplicationViewFailed = (error) => ({
  type: UPDATE_APPLICATION_VIEW_FAILED,
  payload: error,
});

export const addPhoneBegin = (data) => ({
  type: ADD_PHONE_BEGIN,
  payload: data,
});

export const addPhoneSuccess = (data) => ({
  type: ADD_PHONE_SUCCESS,
  payload: data,
});

export const addPhoneFailed = (error) => ({
  type: ADD_PHONE_FAILED,
  payload: error,
});

export const confirmAddPhoneBegin = (data) => ({
  type: CONFIRM_ADD_PHONE_BEGIN,
  payload: data,
});

export const confirmAddPhoneSuccess = (data) => ({
  type: CONFIRM_ADD_PHONE_SUCCESS,
  payload: data,
});

export const confirmAddPhoneFailed = (error) => ({
  type: CONFIRM_ADD_PHONE_FAILED,
  payload: error,
});

export const addEmailBegin = (data) => ({
  type: ADD_EMAIL_BEGIN,
  payload: data,
});

export const addEmailSuccess = (data) => ({
  type: ADD_EMAIL_SUCCESS,
  payload: data,
});

export const addEmailFailed = (error) => ({
  type: ADD_EMAIL_FAILED,
  payload: error,
});

export const confirmAddEmailBegin = () => ({
  type: CONFIRM_ADD_EMAIL_BEGIN,
});

export const confirmAddEmailSuccess = (data) => ({
  type: CONFIRM_ADD_EMAIL_SUCCESS,
  payload: data,
});

export const confirmAddEmailFailed = (error) => ({
  type: CONFIRM_ADD_EMAIL_FAILED,
  payload: error,
});

export const setDefaultColor = (data) => ({
  type: SET_DEFAULT_COLOR,
  payload: data,
});

export const resetDefaultColor = (data) => ({
  type: RESET_DEFAULT_COLOR,
  payload: data,
});

export const getPendingDeliveryBalanceBegin = () => ({
  type: GET_PENDING_DELIVERY_BALANCE_BEGIN,
});

export const getPendingDeliveryBalanceSuccess = (data) => ({
  type: GET_PENDING_DELIVERY_BALANCE_SUCCESS,
  payload: data,
});

export const getPendingDeliveryBalanceFailed = (error) => ({
  type: GET_PENDING_DELIVERY_BALANCE_FAILED,
  payload: error,
});

export const getWalletTotalAmountBegin = () => ({
  type: GET_WALLET_TOTAL_AMOUNT_BEGIN,
});

export const getWalletTotalAmountSuccess = (data) => ({
  type: GET_WALLET_TOTAL_AMOUNT_SUCCESS,
  payload: data,
});

export const getWalletTotalAmountFailed = (error) => ({
  type: GET_WALLET_TOTAL_AMOUNT_FAILED,
  payload: error,
});

export const confirmPasswordChangeBegin = () => ({
  type: CONFIRM_PASSWORD_CHANGE_BEGIN,
});

export const confirmPasswordChangeSuccess = (data) => ({
  type: CONFIRM_PASSWORD_CHANGE_SUCCESS,
  payload: data,
});

export const confirmPasswordChangeFailed = (error) => ({
  type: CONFIRM_PASSWORD_CHANGE_FAILED,
  payload: error,
});

export const changeEmailBegin = (data: {
  old_email: string;
  email: string;
}) => ({
  type: CHANGE_EMAIL_BEGIN,
  payload: data,
});

export const changeEmailSuccess = (data) => ({
  type: CHANGE_EMAIL_SUCCESS,
  payload: data,
});

export const changeEmailFailed = (error) => ({
  type: CHANGE_EMAIL_FAILED,
  payload: error,
});

export const confirmChangeEmailBegin = (data: {
  token: string;
  email: string;
}) => ({
  type: CONFIRM_CHANGE_EMAIL_BEGIN,
  payload: data,
});

export const confirmChangeEmailSuccess = (data) => ({
  type: CONFIRM_CHANGE_EMAIL_SUCCESS,
  payload: data,
});

export const confirmChangeEmailFailed = (error) => ({
  type: CONFIRM_CHANGE_EMAIL_FAILED,
  payload: error,
});

export const changePhoneNumberBegin = (data: ChangePhoneNumberInterface) => ({
  type: CHANGE_PHONE_NUMBER_BEGIN,
  payload: data,
});

export const changePhoneNumberSuccess = (data) => ({
  type: CHANGE_PHONE_NUMBER_SUCCESS,
  payload: data,
});

export const changePhoneNumberFailed = (error) => ({
  type: CHANGE_PHONE_NUMBER_FAILED,
  payload: error,
});

export const confirmChangePhoneNumberBegin = (
  data: ConfirmChangePhoneNumberInterface
) => ({
  type: CONFIRM_CHANGE_PHONE_NUMBER_BEGIN,
  payload: data,
});

export const confirmChangePhoneNumberSuccess = (data) => ({
  type: CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS,
  payload: data,
});

export const confirmChangePhoneNumberFailed = (error) => ({
  type: CONFIRM_CHANGE_PHONE_NUMBER_FAILED,
  payload: error,
});

export const getReferralCodeBegin = () => ({
  type: GET_REFERRAL_CODE_BEGIN,
});

export const getReferralCodeSuccess = (data) => ({
  type: GET_REFERRAL_CODE_SUCCESS,
  payload: data,
});

export const getReferralCodeFailed = (error) => ({
  type: GET_REFERRAL_CODE_FAILED,
  payload: error,
});

export const getUserReferralsBegin = (recent: boolean) => ({
  type: GET_USER_REFERRALS_BEGIN,
  payload: recent,
});

export const getUserReferralsSuccess = (data) => ({
  type: GET_USER_REFERRALS_SUCCESS,
  payload: data,
});

export const getUserReferralsFailed = (error) => ({
  type: GET_USER_REFERRALS_FAILED,
  payload: error,
});

export const checkIfHasSavedCardBegin = () => ({
  type: CHECK_IF_HAS_SAVED_CARD_BEGIN,
});

export const checkIfHasSavedCardSuccess = (data) => ({
  type: CHECK_IF_HAS_SAVED_CARD_SUCCESS,
  payload: data,
});

export const checkIfHasSavedCardFailed = (error) => ({
  type: CHECK_IF_HAS_SAVED_CARD_FAILED,
  payload: error,
});

export const generateTwoFactorAuthBegin = () => ({
  type: GENERATE_TWO_FACTOR_AUTH_BEGIN,
});

export const generateTwoFactorAuthSuccess = (data) => ({
  type: GENERATE_TWO_FACTOR_AUTH_SUCCESS,
  payload: data,
});

export const generateTwoFactorAuthFailed = (error) => ({
  type: GENERATE_TWO_FACTOR_AUTH_FAILED,
  payload: error,
});

export const verifyTwoFactorAuthBegin = (code: string) => ({
  type: VERIFY_TWO_FACTOR_AUTH_BEGIN,
  payload: code,
});

export const verifyTwoFactorAuthSuccess = (data) => ({
  type: VERIFY_TWO_FACTOR_AUTH_SUCCESS,
  payload: data,
});

export const verifyTwoFactorAuthFailed = (error) => ({
  type: VERIFY_TWO_FACTOR_AUTH_FAILED,
  payload: error,
});

export const deactivateTwoFactorAuthBegin = () => ({
  type: DEACTIVATE_TWO_FACTOR_AUTH_BEGIN,
});

export const deactivateTwoFactorAuthSuccess = (data) => ({
  type: DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS,
  payload: data,
});

export const deactivateTwoFactorAuthFailed = (error) => ({
  type: DEACTIVATE_TWO_FACTOR_AUTH_FAILED,
  payload: error,
});

export const updateUserInfoBegin = (data: UpdateUserInfoCartInterface) => ({
  type: UPDATE_USER_INFO_BEGIN,
  payload: data,
});

export const updateUserInfoSuccess = (data) => ({
  type: UPDATE_USER_INFO_SUCCESS,
  payload: data,
});

export const updateUserInfoFailed = (error) => ({
  type: UPDATE_USER_INFO_FAILED,
  payload: error,
});

export const changeAuthSecurityBegin = (password: string) => ({
  type: CHANGE_AUTH_SECURITY_BEGIN,
  payload: password,
});

export const changeAuthSecuritySuccess = (data) => ({
  type: CHANGE_AUTH_SECURITY_SUCCESS,
  payload: data,
});

export const changeAuthSecurityFailed = (error) => ({
  type: CHANGE_AUTH_SECURITY_FAILED,
  payload: error,
});

export const getS3UploadReferralImageBegin = () => ({
  type: GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN,
});

export const getS3UploadReferralImageSuccess = (data) => ({
  type: GET_S3_UPLOAD_REFERRAL_IMAGE_SUCCESS,
  payload: data,
});

export const getS3UploadReferralImageFailed = (error) => ({
  type: GET_S3_UPLOAD_REFERRAL_IMAGE_FAILED,
  payload: error,
});

export const clearS3Link = (error) => ({
  type: CLEAR_S3_LINK,
  payload: error,
});
