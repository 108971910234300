import React, { useEffect, useState } from "react";
import styles from "./RoseGlowLanding.module.scss";

import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import { GlobalIState } from "types/RootState";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";

import VinfinityWhiteLogo from "assets/images/VinfinityWhiteLogo.png";
import RoseGlowCan from "assets/images/RoseGlowCan.png";
import RoseGlowShadow from "assets/images/rose-glow-shadow.png";
import RG6Pack from "assets/images/rg-6pack.png";
import RG12Pack from "assets/images/rg-12pack.png";
import RG24Pack from "assets/images/rg-24pack.png";
import EnAutumnSale from "assets/images/en33autumn-discount.png";
import DeAutumnSale from "assets/images/de33autumn-discount.png";
import EsAutumnSale from "assets/images/es33autumn-discount.png";
import FrAutumnSale from "assets/images/fr33autumn-discount.png";
import ItAutumnSale from "assets/images/it33autumn-discount.png";
import { StarSign } from "assets/icons/icons";
import properties from "properties";
import { openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";

import ChangeLanguageModal from "components/ChangeLanguage/ChangeLanguage.component";

export const env = properties.ENV;

const RoseGlowLanding: React.FC = () => {
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const { client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const [quantity, setQuantity] = useState(6);

  const getLang = () => {
    return localStorage.getItem("lang");
  };

  const handleLanguageChangeModal = () => {
    dispatch(
      openModal({
        content: <ChangeLanguageModal />,
        type: modalTypes.mdNoBg,
      })
    );
  };

  const handleOrderNow = (quantity) => {
    // 9236 - DEV Rose glow id
    // 729 - PROD Rose glow id
    let id = env === "production" ? 729 : 9236;
    setQuantity(quantity);
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLang()));
  };

  const getAutumnSaleImage = () => {
    switch (getLang()) {
      case "en":
        return EnAutumnSale;
        break;
      case "de":
        return DeAutumnSale;
        break;
      case "es":
        return EsAutumnSale;
        break;
      case "fr":
        return FrAutumnSale;
        break;
      case "it":
        return ItAutumnSale;
        break;
      default:
        return EnAutumnSale;
        break;
    }
  };

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems([{ ...productDetails, quantity: quantity }][0]));
      setShouldAddToCart(false);
      navigate("/cart?type=guest&order=rg");
    }
  }, [shouldAddToCart, actionName]);

  return (
    <div className={styles.container}>
      <div className={styles.landing}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <img src={VinfinityWhiteLogo} />
          </div>
          <div
            className={styles.headerBtn}
            onClick={() => handleLanguageChangeModal()}
          >
            <span>{getLang()?.toUpperCase()}</span>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.leftSection}>
            <span>ROSÉ GLOW</span>
            {!isMobile ? (
              <ButtonComponent
                label={translations("label.order_now")}
                class={"btn btn-primary"}
                handleClick={() => handleOrderNow(6)}
              />
            ) : null}
          </div>
          <div className={styles.rightSection}>
            <img
              src={RoseGlowCan}
              className={styles.roseGlowCan}
              alt="Rose Glow"
            />
            <img
              src={RoseGlowShadow}
              className={styles.bottleShadow}
              alt="Shadow"
            />
            <img
              src={getAutumnSaleImage()}
              className={styles.autumnSaleBadge}
              alt="Autumn sale"
            />

            {isMobile ? (
              <ButtonComponent
                label={translations("label.order_now")}
                class={"btn btn-primary"}
                handleClick={() => handleOrderNow(6)}
              />
            ) : null}
          </div>
        </div>

        {!isMobile ? (
          <div className={styles.movingText}>
            <article>
              <span
                className={styles.leftMoving}
                data-text={translations("marquee.rose-glow")}
              >
                {translations("marquee.rose-glow")}
              </span>
            </article>
          </div>
        ) : null}
      </div>

      <div className={styles.shopContent}>
        <div className={styles.top}>
          <span>{translations("rose_glow.section2.subtitle")}</span>
          <h1>{translations("rose_glow.section2.title")}</h1>
        </div>
        <div className={styles.products}>
          <div className={styles.item}>
            <img src={RG6Pack} alt="Rose Glow 6pack" />

            <div>
              <div className={styles.price}>
                <span>CHF 48.00</span>
                <span>CHF 31.80</span>
              </div>
              <ButtonComponent
                label={6 + " " + translations("label.pack")}
                class={"btn btn-primary"}
                handleClick={() => handleOrderNow(6)}
              />
            </div>
          </div>
          <div className={styles.item}>
            <img src={RG12Pack} alt="Rose Glow 12pack" />
            <div>
              <div className={styles.price}>
                <span>CHF 96.00</span>
                <span>CHF 63.60</span>
              </div>
              <ButtonComponent
                label={12 + " " + translations("label.pack")}
                class={"btn btn-primary"}
                handleClick={() => handleOrderNow(12)}
              />
            </div>
          </div>
          <div className={styles.item}>
            <img src={RG24Pack} alt="Rose Glow 24pack" />
            <div>
              <div className={styles.price}>
                <span>CHF 192.00</span>
                <span>CHF 127.20</span>
              </div>
              <ButtonComponent
                label={24 + " " + translations("label.pack")}
                class={"btn btn-primary"}
                handleClick={() => handleOrderNow(24)}
              />
            </div>
          </div>
        </div>
        <div className={styles.labels}>
          <span>{translations("rose_glow.section2.info1")}</span>
          <span>{translations("rose_glow.section2.info2")}</span>
          <ButtonComponent
            label={translations("label.order_now")}
            class={"btn btn-primary"}
            handleClick={() => handleOrderNow(6)}
          />
        </div>

        <div className={styles.aboutRoseGlow}>
          <span>Rose Glow {translations("label.is")} </span>
          <br />
          <span> {translations("label.not_just_a_wine")} </span>
        </div>

        <div className={styles.canInfo}>
          <span>{translations("label.rose_glow.info1")}</span>
          <br />
          <span>{translations("label.rose_glow.info2")}</span>
        </div>
      </div>
      <div className={styles.roseGlassContent}>
        <div>
          <span>{translations("label.dont_miss_out")} </span>
          <br />
          <span> {translations("label.the_newest_gem")} </span>
        </div>
      </div>

      <div className={styles.canInfo}>
        <span>{translations("label.rose_glow_info3")}</span>
        <br />
        <span>{translations("label.rose_glow_info4")}</span>
      </div>

      <div className={styles.furtherDetailsContainer}>
        <h1>{translations("label.further_details")}</h1>
        <span> {translations("label.further_details.info")} </span>

        {isMobile ? (
          <div className={styles.wineDetailsMobile}>
            <div>
              <span>{translations("label.country")}</span>
              <span>{translations("label.best_before")}</span>
              <span>{translations("label.appellation")}</span>
              <span>{translations("label.vintage")}</span>
              <span>{translations("label.type")}</span>
              <span>{translations("label.region")}</span>
              <span>{translations("label.alcohol")}</span>
              <span>{translations("label.grape_varietal")}</span>
            </div>
            <div>
              <span>{translations("label.italy")}</span>
              <span>{translations("label.now_till_2025")}</span>
              <span>N/A</span>
              <span>2023</span>
              <span>{translations("label.tuscany")}</span>
              <span>Sangiovese</span>
              <span>12%</span>
              <span>Rosé</span>
            </div>
          </div>
        ) : (
          <div className={styles.wineDetails}>
            <div className={styles.row}>
              <div>
                <span>{translations("label.country")}</span>
                <span>{translations("label.italy")}</span>
              </div>
              <div>
                <span>{translations("label.best_before")}</span>
                <span>{translations("label.now_till_2025")}</span>
              </div>
              <div>
                <span>{translations("label.appellation")}</span>
                <span>N/A</span>
              </div>
              <div>
                <span>{translations("label.vintage")}</span>
                <span>2023</span>
              </div>
            </div>
            <div className={styles.row}>
              <div>
                <span>{translations("label.region")}</span>
                <span>{translations("label.tuscany")}</span>
              </div>
              <div>
                <span>{translations("label.grape_varietal")}</span>
                <span>Sangiovese</span>
              </div>
              <div>
                <span>{translations("label.alcohol")}</span>
                <span>12%</span>
              </div>
              <div>
                <span>{translations("label.type")}</span>
                <span>Rosé</span>
              </div>
            </div>
          </div>
        )}

        <div className={styles.quoteBox}>
          <StarSign />
          <h1>{translations("label.light_rose_title")}</h1>
        </div>
        <div className={styles.lightRoseLabel}>
          <span>{translations("label.light_rose_subtitle")}</span>
        </div>
      </div>
    </div>
  );
};

export default RoseGlowLanding;
