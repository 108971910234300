import { produce } from "immer";
import { PersonalLedgerInterface } from "types/reducers/personal-ledger.reducer.type";
import {
  ADD_WINE_BEGIN,
  ADD_WINE_FAILED,
  ADD_WINE_SUCCESS,
  CLEAR_ACTION,
  DELETE_ADDED_WINE_BEGIN,
  DELETE_ADDED_WINE_FAILED,
  DELETE_ADDED_WINE_SUCCESS,
  GET_ADDED_WINES_BEGIN,
  GET_ADDED_WINES_FAILED,
  GET_ADDED_WINES_SUCCESS,
  GET_PRODUCT_TRANSACTIONS_BEGIN,
  GET_PRODUCT_TRANSACTIONS_FAILED,
  GET_PRODUCT_TRANSACTIONS_SUCCESS,
  GET_SEARCHED_JOURNAL_WINES_BEGIN,
  GET_SEARCHED_JOURNAL_WINES_FAILED,
  GET_SEARCHED_JOURNAL_WINES_SUCCESS,
  GET_USER_BLIND_TASTINGS_BEGIN,
  GET_USER_BLIND_TASTINGS_FAILED,
  GET_USER_BLIND_TASTINGS_SUCCESS,
  GET_USER_ORDERS_BEGIN,
  GET_USER_ORDERS_FAILED,
  GET_USER_ORDERS_SUCCESS,
  RATE_ADDED_WINE_BEGIN,
  RATE_ADDED_WINE_FAILED,
  RATE_ADDED_WINE_SUCCESS,
  RATE_WINE_BEGIN,
  RATE_WINE_FAILED,
  RATE_WINE_SUCCESS,
  REQUEST_TO_SUPPLY_WINE_BEGIN,
  REQUEST_TO_SUPPLY_WINE_FAILED,
  REQUEST_TO_SUPPLY_WINE_SUCCESS,
  SET_ADDED_WINE_TASTE_BEGIN,
  SET_ADDED_WINE_TASTE_FAILED,
  SET_ADDED_WINE_TASTE_SUCCESS,
  SET_PRODUCT_TASTE_BEGIN,
  SET_PRODUCT_TASTE_FAILED,
  SET_PRODUCT_TASTE_SUCCESS,
} from "./constants";

const initialState: PersonalLedgerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  orders: null,
  productTransactions: null,
  userBlindTastings: null,
  addedWines: null,
  searchedWines: null,
};

const PersonalLedgerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLEAR_ACTION:
        draft.action = initialState.action;
        break;
      case GET_USER_ORDERS_BEGIN:
      case RATE_WINE_BEGIN:
      case RATE_ADDED_WINE_BEGIN:
      case SET_PRODUCT_TASTE_BEGIN:
      case SET_ADDED_WINE_TASTE_BEGIN:
      case GET_PRODUCT_TRANSACTIONS_BEGIN:
      case GET_USER_BLIND_TASTINGS_BEGIN:
      case ADD_WINE_BEGIN:
      case GET_ADDED_WINES_BEGIN:
      case REQUEST_TO_SUPPLY_WINE_BEGIN:
      case GET_SEARCHED_JOURNAL_WINES_BEGIN:
      case DELETE_ADDED_WINE_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case GET_USER_ORDERS_FAILED:
      case RATE_WINE_FAILED:
      case RATE_ADDED_WINE_FAILED:
      case SET_PRODUCT_TASTE_FAILED:
      case SET_ADDED_WINE_TASTE_FAILED:
      case GET_PRODUCT_TRANSACTIONS_FAILED:
      case GET_USER_BLIND_TASTINGS_FAILED:
      case ADD_WINE_FAILED:
      case GET_ADDED_WINES_FAILED:
      case REQUEST_TO_SUPPLY_WINE_FAILED:
      case GET_SEARCHED_JOURNAL_WINES_FAILED:
      case DELETE_ADDED_WINE_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case GET_USER_ORDERS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.searchedWines = null;
        draft.orders = action.payload;
        break;
      case RATE_WINE_SUCCESS:
      case RATE_ADDED_WINE_SUCCESS:
      case SET_PRODUCT_TASTE_SUCCESS:
      case SET_ADDED_WINE_TASTE_SUCCESS:
      case ADD_WINE_SUCCESS:
      case REQUEST_TO_SUPPLY_WINE_SUCCESS:
      case DELETE_ADDED_WINE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case GET_PRODUCT_TRANSACTIONS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.productTransactions = action.payload;
        break;
      case GET_USER_BLIND_TASTINGS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.userBlindTastings = action.payload;
        draft.searchedWines = null;
        break;
      case GET_ADDED_WINES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.addedWines = action.payload;
        draft.searchedWines = null;
        break;
      case GET_SEARCHED_JOURNAL_WINES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.orders = null;
        draft.userBlindTastings = null;
        draft.addedWines = null;
        draft.searchedWines =
          action.payload?.length > 0 ? action.payload : null;
        break;
      default:
        break;
    }
  });

export default PersonalLedgerReducer;
