import globalSaga from "App/saga";
import authenticationSaga from "pages/Authentication/saga";
import gamificationSaga from "pages/Gamification/saga";
import investmentsSaga from "pages/Investments/saga";
import profileSaga from "pages/Profile/saga";
import purchasingWineSaga from "pages/Purchasing/saga";
import personalLedgerSaga from "pages/PersonalLedger/saga";
import { all, fork } from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    fork(globalSaga),
    fork(authenticationSaga),
    fork(profileSaga),
    fork(investmentsSaga),
    fork(purchasingWineSaga),
    fork(gamificationSaga),
    fork(personalLedgerSaga),
  ]);
}
