import { useTranslation } from "react-i18next";

import styles from "./ChangeEmail.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import { GlobalIState } from "types/RootState";
import { changeEmailBegin } from "pages/Profile/actions";
import { CHANGE_EMAIL_SUCCESS } from "pages/Profile/constants";
import { setLeftPartItem, setRightPartItem } from "App/actions";
import ConfirmByEmail from "./ConfirmByEmail/ConfirmByEmail.page";

interface Props {}

export default function ChangeEmail(props: Props) {
  const {
    action: { actionName },
    client,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [newEmail, setNewEmail] = useState("");

  const handleChangeEmail = () => {
    dispatch(changeEmailBegin({ old_email: client?.email, email: newEmail }));
  };

  useEffect(() => {
    if (actionName === CHANGE_EMAIL_SUCCESS) {
      if (isMobile) {
        dispatch(setLeftPartItem(<ConfirmByEmail />));
      } else {
        dispatch(setRightPartItem(<ConfirmByEmail />));
      }
    }
  }, [actionName]);

  return (
    <div className={styles.changeEmailWrapper}>
      <h1>{translations("label.change_email")}</h1>
      <div className={styles.fields}>
        <h2>{translations("label.email_address")}</h2>
        <CustomInputComponent
          type="email"
          name="email"
          className="secondInput"
          placeholder={translations("label.current_email")}
          value={client?.email}
          disabled
          onChange={(e) => console.log(e)}
        />
        <CustomInputComponent
          type="email"
          name="newEmail"
          className="secondInput"
          placeholder={translations("label.new_email")}
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
      </div>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          disabled={newEmail.length === 0}
          handleClick={() => handleChangeEmail()}
        />
      </div>
    </div>
  );
}
