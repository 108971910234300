import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Toast.module.scss";
import { useTranslation } from "react-i18next";
import {
  ErrorToastIcon,
  RoundedErrorInfo,
  SuccessToastIcon,
} from "assets/icons/icons";
import StorageService from "services/storage.service";

function CustomToast() {
  const { toaster } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { t: translations } = useTranslation();
  const wineNameMissingCookie = StorageService.getDataByKey(
    "error_wine_name_missing"
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        {toaster?.type === "SUCCESS" ? <SuccessToastIcon /> : null}
        {toaster?.type === "ERROR" ? <ErrorToastIcon /> : null}
      </div>
      <div className={styles.content}>
        {toaster?.type === "SUCCESS" ? (
          <span className={styles.title} style={{ color: "#58C322" }}>
            {translations("label.success")}
          </span>
        ) : null}
        {toaster?.type === "ERROR" ? (
          <span className={styles.title} style={{ color: "#FF5741" }}>
            {translations("label.error")}
          </span>
        ) : null}
        <span
          style={{
            color:
              toaster?.type === "SUCCESS"
                ? "#58C322"
                : toaster?.type === "ERROR"
                ? "#FF5741"
                : "#000",
          }}
        >
          {wineNameMissingCookie
            ? translations(toaster?.message) + " " + wineNameMissingCookie
            : translations(toaster?.message)}
        </span>
      </div>
    </div>
  );
}

export default CustomToast;
