import { useTranslation } from "react-i18next";
import styles from "./ChangePassword.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import PasswordInput from "components/PasswordInput/PasswordInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import {
  getDefaultColour,
  hasEightCharacters,
  hasLowercaseLetter,
  hasNumbers,
  hasSymbols,
  hasUppercaseLetter,
} from "utils/utils";
import { GlobalIState } from "types/RootState";
import { changePasswordBegin, clearAction } from "pages/Profile/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import { CHANGE_PASSWORD_SUCCESS } from "pages/Profile/constants";
import PasswordChangeEmailConfirm from "./PasswordChangeEmailConfirm/PasswordChangeEmailConfirm.page";
import { useNavigate } from "react-router-dom";
import SetPasscode from "../SetPasscode/SetPasscode.page";

export default function ChangePassword() {
  const {
    action: { isSuccess, isLoading, actionName },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const disabled =
    state.currentPassword === state.newPassword ||
    !hasUppercaseLetter(state.newPassword) ||
    !hasLowercaseLetter(state.newPassword) ||
    !hasNumbers(state.newPassword) ||
    !hasSymbols(state.newPassword) ||
    !hasEightCharacters(state.newPassword);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    if (isSuccess && actionName === CHANGE_PASSWORD_SUCCESS) {
      if (isMobile) {
        dispatch(setLeftPartItem(<PasswordChangeEmailConfirm />));
      } else {
        dispatch(setRightPartItem(<PasswordChangeEmailConfirm />));
      }
    }
    return () => {
      dispatch(clearAction());
    };
  }, [isSuccess]);

  const handleSave = () => {
    dispatch(
      changePasswordBegin({
        old_password: state.currentPassword,
        password: state.newPassword,
      })
    );
  };

  return (
    <div className={styles.changePasswordContent}>
      <h1>{translations("label.change_strong_password")}</h1>
      <div className={styles.fields}>
        <h2>{translations("label.enter_current_and_new")}</h2>
        <PasswordInput
          name="currentPassword"
          className="secondInput"
          placeholder={translations("label.current_password")}
          value={state.currentPassword}
          onChange={(e) => handleChange(e)}
        />
        <PasswordInput
          name="newPassword"
          className="secondInput"
          placeholder={translations("label.new_password")}
          value={state.newPassword}
          onChange={(e) => handleChange(e)}
        />

        <div className={styles.hints}>
          <span style={{ color: getDefaultColour(defaultColour) }}>
            {translations("label.change_password.hint1")}
          </span>
          <span
            style={{
              color: !hasUppercaseLetter(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint2")}
          </span>
          <span
            style={{
              color: !hasLowercaseLetter(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint3")}
          </span>
          <span
            style={{
              color: !hasNumbers(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint4")}
          </span>
          <span
            style={{
              color: !hasSymbols(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint5")}
          </span>
          <span
            style={{
              color: !hasEightCharacters(state.newPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint6")}
          </span>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonComponent
            label={translations("button.save")}
            class={"btn btn-primary"}
            disabled={disabled}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />

          <div
            className={styles.changeAuthSecurityLabel}
            onClick={() => {
              if (isMobile) navigate("/set-passcode");
              else dispatch(setMiddlepartItem(<SetPasscode />));
            }}
          >
            <span>{translations("label.remove_and_change_to_passcode?")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
