import { useTranslation } from "react-i18next";

import { useState } from "react";
import { useAppDispatch } from "hooks";

import styles from "./WarningModal.module.scss";
import ButtonComponent from "components/Button/Button.component";
import { closeModal, setLeftPartItem, setRightPartItem } from "App/actions";
import { declinePrivacyPolicy } from "pages/Profile/actions";

interface Props {
  type: string;
}

export default function WarningModal(props: Props) {
  const { type } = props;
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();

  const handleConfirm = () => {
    dispatch(closeModal());
    dispatch(setLeftPartItem("1"));
    dispatch(setRightPartItem(""));
    if (type === "privacyPolicy") {
      dispatch(declinePrivacyPolicy());
    }
  };

  const handleAcceptInstead = () => {
    dispatch(closeModal());
  };

  return (
    <div className={styles.warningWrapper}>
      <span>
        {translations(
          `label.warning.${
            type === "termsAndConditions"
              ? "terms_and_conditions"
              : "privacy_policy"
          }_decline.hint1`
        )}
      </span>
      <span>
        {translations(
          `label.warning.${
            type === "termsAndConditions"
              ? "terms_and_conditions"
              : "privacy_policy"
          }_decline.hint2`
        )}
      </span>
      <span>
        {translations(
          `label.warning.${
            type === "termsAndConditions"
              ? "terms_and_conditions"
              : "privacy_policy"
          }_decline.hint3`
        )}
      </span>

      <div className={styles.buttons}>
        <ButtonComponent
          label={translations("label.confirm")}
          class={"btn btn-primary-rounded"}
          handleClick={() => handleConfirm()}
        />
        <div className="underlinedBtn" onClick={() => handleAcceptInstead()}>
          <span>{translations("label.accept_instead")}</span>
        </div>
      </div>
    </div>
  );
}
