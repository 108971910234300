import { useTranslation } from "react-i18next";

import styles from "./SignupPersonalDetails.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import CustomInputComponent from "components/CustomInput/CustomInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";

import { GlobalIState } from "types/RootState";
import { getCountrySelectOptions, getDefaultColour } from "utils/utils";
import { setPersonalDetailsBegin } from "pages/Authentication/actions";
import StorageService from "services/storage.service";
import { useNavigate } from "react-router-dom";
import { PreviousIcon } from "assets/icons/icons";
import { SET_PERSONAL_DETAILS_SUCCESS } from "pages/Authentication/constants";
import SelectComponent from "components/Select/Select.component";

interface Props {}

export default function SignupPersonalDetails(props: Props) {
  const {
    action: { actionName },
    countries,
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const {
    action: { isLoading },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const registeredUser = StorageService.getDataByKey("registeredUser");
  const [state, setState] = useState({
    firstname: registeredUser ? registeredUser?.firstname : "",
    lastname: registeredUser ? registeredUser?.lastname : "",
    ra_country: "",
  });
  const [selectedSalutation, setselectedSalutation] = useState<string>(
    registeredUser ? registeredUser?.salutation : ""
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    const capitalizedValue =
      value.length === 1
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : value;

    setState((prev) => ({
      ...prev,
      [name]: capitalizedValue,
    }));
  };

  const handleSave = () => {
    let data = {
      salutation: selectedSalutation,
      firstname: state.firstname,
      lastname: state.lastname,
      ra_country: state.ra_country,
    };
    dispatch(setPersonalDetailsBegin(data));
  };

  useEffect(() => {
    if (actionName === SET_PERSONAL_DETAILS_SUCCESS) {
      navigate("/signup/finish");
    }
  }, [actionName]);

  return (
    <div className={styles.personalDetailsWrapper}>
      <div className={styles.header}>
        <div
          className={styles.previousIcon}
          onClick={() => {
            navigate(-1);
          }}
        >
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
      <h1>{translations("label.personal_details")}</h1>

      <div className={styles.content}>
        <div className={styles.fields}>
          <span className={styles.topLabel}>
            {translations("label.salutation")}
          </span>
          <div className={styles.salutations}>
            <div
              className={styles.salutationItem}
              style={
                selectedSalutation === "Mr."
                  ? {
                      background: "#000",
                      color: "#fff",
                    }
                  : {}
              }
              onClick={() => setselectedSalutation("Mr.")}
            >
              {translations("label.mr")}
            </div>
            <div
              className={styles.salutationItem}
              style={
                selectedSalutation === "Mrs."
                  ? {
                      background: "#000",
                      color: "#fff",
                    }
                  : {}
              }
              onClick={() => setselectedSalutation("Mrs.")}
            >
              {translations("label.mrs")}
            </div>
            <div
              className={styles.salutationItem}
              style={
                selectedSalutation === "Ms."
                  ? {
                      background: "#000",
                      color: "#fff",
                    }
                  : {}
              }
              onClick={() => setselectedSalutation("Ms.")}
            >
              {translations("label.ms")}
            </div>
          </div>
        </div>
        <div className={styles.fields}>
          <div className={styles.input}>
            <CustomInputComponent
              type="text"
              name="firstname"
              label={translations("label.firstname")}
              value={state.firstname}
              maxLength={50}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className={styles.input}>
            <CustomInputComponent
              type="text"
              name="lastname"
              label={translations("label.last_name")}
              value={state.lastname}
              maxLength={50}
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className={styles.selectInput}>
            <SelectComponent
              name={"ra_country"}
              // className="secondSelectStyle"
              label={translations("label.country_of_residence")}
              value={state.ra_country ? state.ra_country : 0}
              options={getCountrySelectOptions(countries)}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          disabled={!state.firstname || !state.lastname || !selectedSalutation}
          handleClick={() => handleSave()}
        />
      </div>
    </div>
  );
}
