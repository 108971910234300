import React, { ReactNode, memo } from "react";
import styles from "./CustomInput.module.scss";
import { getDefaultColour } from "utils/utils";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
interface Props {
  value: string;
  label?: String | ReactNode;
  name?: string;
  placeholder?: string;
  type: string;
  className?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  onKeyDown?: (e) => void;
  onBlur?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.ChangeEventHandler<HTMLInputElement>;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  minLength?: number;
  maxLength?: number;
  style?: object;
  checked?: boolean;
  pattern?: string;
}

const CustomInput: React.FC<Props> = ({
  style,
  name,
  error,
  label,
  ...props
}) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const checkboxAndRadio = ["checkbox", "radio"];
  const isRadioOrCheckbox = checkboxAndRadio.some((item) =>
    props.type.includes(item)
  );

  return (
    <div
      className={
        isRadioOrCheckbox ? styles.checkboxOrRadioSection : styles.inputSection
      }
      style={style}
    >
      {label ? (
        <label
          htmlFor={name}
          className={styles.inputLabel}
          style={isRadioOrCheckbox ? { marginLeft: "10px" } : {}}
        >
          {label}
        </label>
      ) : null}
      <input
        value={props?.value ? props?.value : ""}
        id={name}
        name={name}
        className={
          // isRadioOrCheckbox ? styles.checkboxOrRadioInput : styles.customInput
          `${
            props.className === "secondInput"
              ? styles.secondInput
              : styles.mainInput
          }`
        }
        style={
          props.className === "secondInput"
            ? ({
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties)
            : ({
                borderLeft:
                  props?.value?.length >= 1
                    ? "3px solid transparent"
                    : `3px solid ${getDefaultColour(defaultColour)}`,
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties)
        }
        {...props}
      />
      {error?.length > 0 ? <span className="errorMessage">{error}</span> : null}
    </div>
  );
};

export default memo(CustomInput);
