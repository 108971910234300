import { call, put, takeLatest } from "redux-saga/effects";

import {
  cancelEventRegistrationFailed,
  cancelEventRegistrationSuccess,
  getCelebrityTakeoverTransactionsFailed,
  getCelebrityTakeoverTransactionsSuccess,
  getEventsDetailsFailed,
  getEventsDetailsSuccess,
  getEventsFailed,
  getEventsSuccess,
  registerFriendsOnEventFailed,
  registerFriendsOnEventSuccess,
  registerOnEventFailed,
  registerOnEventSuccess,
  removeFriendFromEventFailed,
  removeFriendFromEventSuccess,
  updateInvitedFriendFailed,
  updateInvitedFriendSuccess,
} from "./actions";
import * as globalService from "services/global.service";
import {
  CANCEL_EVENT_REGISTRATION_BEGIN,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN,
  GET_EVENTS_BEGIN,
  GET_EVENT_DETAILS_BEGIN,
  REGISTER_FRIENDS_ON_EVENT_BEGIN,
  REGISTER_ON_EVENT_BEGIN,
  REMOVE_FRIEND_FROM_EVENT_BEGIN,
  UPDATE_INVITED_FRIEND_BEGIN,
} from "./constants";
import { openToaster } from "App/actions";

export function* getEventsData() {
  try {
    const response = yield call(globalService.getEvents);
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getEventsSuccess(response?.data?.data));
    } else {
      yield put(getEventsFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getEventsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getEventDetails(action) {
  try {
    const response = yield call(globalService.getEventById, action.payload);
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getEventsDetailsSuccess(response?.data?.data));
    } else {
      yield put(getEventsDetailsFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getEventsDetailsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* registerClientOnEvent(action) {
  try {
    const response = yield call(globalService.registerOnEvent, action.payload);
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(registerOnEventSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(registerOnEventFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(registerOnEventFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* cancelEventRegistration(action) {
  try {
    const response = yield call(
      globalService.cancelEventRegistration,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(cancelEventRegistrationSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(cancelEventRegistrationFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(cancelEventRegistrationFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* registerFriendsOnEvent(action) {
  try {
    const response = yield call(
      globalService.registerFriendsOnEvent,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(registerFriendsOnEventSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(registerFriendsOnEventFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(registerFriendsOnEventFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* removeFriendFromEvent(action) {
  try {
    const response = yield call(
      globalService.removeFriendFromEvent,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(removeFriendFromEventSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(removeFriendFromEventFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(removeFriendFromEventFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateInvitedFriend(action) {
  try {
    const response = yield call(
      globalService.updateInvitedFriend,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateInvitedFriendSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateInvitedFriendFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateInvitedFriendFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getTakeoverTransactionsById(action) {
  try {
    const response = yield call(
      globalService.getTakeoverTransactionsById,
      action.payload
    );
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getCelebrityTakeoverTransactionsSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(
        getCelebrityTakeoverTransactionsFailed(response?.response?.data)
      );
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(
      getCelebrityTakeoverTransactionsFailed(e?.response?.data?.message)
    );
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* globalSaga() {
  yield takeLatest(GET_EVENTS_BEGIN, getEventsData);
  yield takeLatest(GET_EVENT_DETAILS_BEGIN, getEventDetails);
  yield takeLatest(REGISTER_ON_EVENT_BEGIN, registerClientOnEvent);
  yield takeLatest(CANCEL_EVENT_REGISTRATION_BEGIN, cancelEventRegistration);
  yield takeLatest(REGISTER_FRIENDS_ON_EVENT_BEGIN, registerFriendsOnEvent);
  yield takeLatest(REMOVE_FRIEND_FROM_EVENT_BEGIN, removeFriendFromEvent);
  yield takeLatest(UPDATE_INVITED_FRIEND_BEGIN, updateInvitedFriend);
  yield takeLatest(
    GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN,
    getTakeoverTransactionsById
  );
}
