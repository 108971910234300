import { ISendCode } from "types/reducers/authentication.reducer.type";
import {
  FETCH_COUNTRIES_BEGIN,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILED,
  LOG_IN_BEGIN,
  LOG_IN_FAILED,
  LOG_IN_SUCCESS,
  LOG_OUT,
  REGISTER_BEGIN,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  SET_PERSONAL_DETAILS_BEGIN,
  SET_PERSONAL_DETAILS_FAILED,
  SET_PERSONAL_DETAILS_SUCCESS,
  CANCEL_SIGNUP,
  ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN,
  ACCEPT_EVENT_INVITATION_AND_REGISTER_SUCCESS,
  ACCEPT_EVENT_INVITATION_AND_REGISTER_FAILED,
  SEND_VERIFY_EMAIL_BEGIN,
  SEND_VERIFY_EMAIL_SUCCESS,
  SEND_VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_BEGIN,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILED,
  SEND_RESET_PASSWORD_BEGIN,
  SEND_RESET_PASSWORD_SUCCESS,
  SEND_RESET_PASSWORD_FAILED,
  CANCEL_SIGNUP_BY_EMAIL_TOKEN,
  LOG_IN_BY_TOKEN,
  CHECK_IF_HAS_PASSCODE_BEGIN,
  CHECK_IF_HAS_PASSCODE_SUCCESS,
  CHECK_IF_HAS_PASSCODE_FAILED,
  SEND_SMS_CODE_BEGIN,
  SEND_SMS_CODE_SUCCESS,
  SEND_SMS_CODE_FAILED,
  CLEAR_ACTION,
  SET_IS_LOGGED_IN,
} from "./constants";

export const fetchCountriesBegin = (payload: string) => ({
  type: FETCH_COUNTRIES_BEGIN,
  payload: payload,
});

export const fetchCountriesSuccess = (data) => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload: data,
});

export const fetchCountriesFailed = (error) => ({
  type: FETCH_COUNTRIES_FAILED,
  payload: error,
});

export const logInBegin = (
  user: string,
  password: string,
  country_phone: string,
  phone: string,
  google_auth_code: string,
  login_method: string,
  two_factor_code: string,
  sms_code?: number
) => {
  return {
    type: LOG_IN_BEGIN,
    payload: {
      user,
      password,
      country_phone,
      phone,
      google_auth_code,
      login_method,
      two_factor_code,
      sms_code,
    },
  };
};

export const logInSuccess = (payload) => {
  return {
    type: LOG_IN_SUCCESS,
    payload: payload,
  };
};

export const logInFailed = (payload) => {
  return {
    type: LOG_IN_FAILED,
    payload: payload,
  };
};

export const logInByToken = (payload) => {
  return {
    type: LOG_IN_BY_TOKEN,
    payload: payload,
  };
};

export const logOut = () => {
  return {
    type: LOG_OUT,
  };
};

export const registerBegin = (
  email: string,
  password: string,
  country_phone: string,
  phone: string,
  sign_up_method: string,
  language: string,
  referral_code: string,
  is_passcode: boolean
) => {
  return {
    type: REGISTER_BEGIN,
    payload: {
      email,
      password,
      country_phone,
      phone,
      sign_up_method,
      language,
      referral_code,
      is_passcode,
    },
  };
};

export const registerSuccess = (payload) => {
  return {
    type: REGISTER_SUCCESS,
    payload: payload,
  };
};

export const registerFailed = (payload) => {
  return {
    type: REGISTER_FAILED,
    payload: payload,
  };
};

export const setPersonalDetailsBegin = (data) => ({
  type: SET_PERSONAL_DETAILS_BEGIN,
  payload: data,
});

export const setPersonalDetailsSuccess = (data) => ({
  type: SET_PERSONAL_DETAILS_SUCCESS,
  payload: data,
});

export const setPersonalDetailsFailed = (error) => ({
  type: SET_PERSONAL_DETAILS_FAILED,
  payload: error,
});

export const cancelSignup = () => ({
  type: CANCEL_SIGNUP,
});

export const cancelSignupByEmailToken = (emailToken: string) => ({
  type: CANCEL_SIGNUP_BY_EMAIL_TOKEN,
  payload: emailToken,
});

export const acceptEventInvitationAndRegisterBegin = (token, data) => ({
  type: ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN,
  payload: { token: token, data: data },
});

export const acceptEventInvitationAndRegisterSuccess = (data) => ({
  type: ACCEPT_EVENT_INVITATION_AND_REGISTER_SUCCESS,
  payload: data,
});

export const acceptEventInvitationAndRegisterFailed = (error) => ({
  type: ACCEPT_EVENT_INVITATION_AND_REGISTER_FAILED,
  payload: error,
});

export const sendVerifyEmailBegin = () => ({
  type: SEND_VERIFY_EMAIL_BEGIN,
});

export const sendVerifyEmailSuccess = (data) => ({
  type: SEND_VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const sendVerifyEmailFailed = (error) => ({
  type: SEND_VERIFY_EMAIL_FAILED,
  payload: error,
});

export const verifyEmailBegin = (data) => ({
  type: VERIFY_EMAIL_BEGIN,
  payload: data,
});

export const verifyEmailSuccess = (data) => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload: data,
});

export const verifyEmailFailed = (error) => ({
  type: VERIFY_EMAIL_FAILED,
  payload: error,
});

export const sendRequestResetPasswordBegin = (data) => ({
  type: SEND_RESET_PASSWORD_BEGIN,
  payload: data,
});

export const sendRequestResetPasswordSuccess = (data) => ({
  type: SEND_RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const sendRequestResetPasswordFailed = (error) => ({
  type: SEND_RESET_PASSWORD_FAILED,
  payload: error,
});

export const checkIfHasPasscodeBegin = (data: {
  email: string;
  sign_up: boolean;
  lang: string;
}) => ({
  type: CHECK_IF_HAS_PASSCODE_BEGIN,
  payload: data,
});

export const checkIfHasPasscodeSuccess = (data) => ({
  type: CHECK_IF_HAS_PASSCODE_SUCCESS,
  payload: data,
});

export const checkIfHasPasscodeFailed = (error) => ({
  type: CHECK_IF_HAS_PASSCODE_FAILED,
  payload: error,
});

export const sendSmsCodeBegin = (data: ISendCode) => ({
  type: SEND_SMS_CODE_BEGIN,
  payload: data,
});

export const sendSmsCodeSuccess = (data) => ({
  type: SEND_SMS_CODE_SUCCESS,
  payload: data,
});

export const sendSmsCodeFailed = (error) => ({
  type: SEND_SMS_CODE_FAILED,
  payload: error,
});

export const clearAction = () => ({
  type: CLEAR_ACTION,
});

export const setIsLoggedIn = () => {
  return {
    type: SET_IS_LOGGED_IN,
  };
};
