import React, { useEffect, useRef, useState } from "react";
import styles from "./AllTransactions.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchTransactionDetailsBegin,
  fetchUserTransactionsBegin,
} from "../actions";
import { GlobalIState } from "types/RootState";

import { BackButtonIcon, BulletPointIcon } from "assets/icons/icons";
import {
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getTransactionLabel,
} from "utils/utils";
import { useTranslation } from "react-i18next";

import dayjs from "dayjs";

interface Props {
  showHighlightedBox: string;
  setShowHighlightedBox: React.Dispatch<React.SetStateAction<string>>;
}

function AllTransactions(props: Props) {
  const { showHighlightedBox, setShowHighlightedBox } = props;
  const {
    transactions,
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [shownTransactionsLength, setShownTransactionsLength] = useState(3);

  const handleLoadMore = () => {
    setShownTransactionsLength(transactions.length);
  };

  const handleOpenTransactionDetails = (id) => {
    setShowHighlightedBox("transaction-details");
    dispatch(fetchTransactionDetailsBegin(id));
  };

  useEffect(() => {
    if (!transactions) {
      dispatch(fetchUserTransactionsBegin(1));
    }
  }, [transactions]);

  return (
    <>
      <div className={styles.header}>
        <div className="pointer" onClick={() => setShowHighlightedBox("")}>
          <BackButtonIcon color={getDefaultColour(defaultColour)} />
        </div>
        <h2>{translations("label.latest_transactions")}</h2>
      </div>
      {transactions?.length === 0 ? (
        <span>{translations("label.no_transactions_history")}</span>
      ) : (
        <div
          className={styles.content}
          style={
            {
              "--color": getDefaultColour(defaultColour),
            } as React.CSSProperties
          }
        >
          <div>
            {(transactions?.length === 1
              ? transactions
              : transactions?.slice(0, shownTransactionsLength)
            )?.map((date) => {
              return date?.transactions?.map((item) => {
                return (
                  <div className={styles.transaction} key={item.id}>
                    <div className={styles.upperItems}>
                      <div
                        className="pointer"
                        onClick={() => handleOpenTransactionDetails(item?.id)}
                      >
                        <BulletPointIcon
                          color={getDefaultColour(defaultColour)}
                        />
                        <span className={styles.transactionCategory}>
                          {item?.transactionType?.name}
                        </span>
                      </div>
                      <div
                        className="pointer"
                        onClick={() => handleOpenTransactionDetails(item?.id)}
                      >
                        {dayjs(item.created_at).format("DD/MM/YYYY")}
                      </div>
                    </div>
                    <div
                      className="pointer"
                      onClick={() => handleOpenTransactionDetails(item?.id)}
                      style={{ color: getDefaultColour(defaultColour) }}
                    >
                      {translations(
                        getTransactionLabel(item?.transactionType?.name)[0]
                      ) +
                        ` ${getCurrencyStr(item.currency)} ${formatPrice(
                          item.amount
                        )} ` +
                        translations(
                          getTransactionLabel(item?.transactionType.name)[1]
                        )}
                    </div>
                  </div>
                );
              });
            })}
            {transactions?.length >= 2 && shownTransactionsLength === 3 ? (
              <div
                className={styles.loadMoreButton}
                onClick={() => handleLoadMore()}
              >
                {translations("button.more")}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
}

export default AllTransactions;
