import { produce } from "immer";
import StorageService from "services/storage.service";

import { PurchasingReducerInterface } from "types/reducers/purchasing.reducer.type";
import {
  CLEAR_CART_ITEMS,
  DELETE_CART_ITEM,
  UPDATE_QUANTITY,
  POPULATE_CART_ITEMS,
  SET_CART_ITEMS,
  FETCH_PRODUCTS_BEGIN,
  FETCH_PRODUCTS_FAILED,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCT_DETAILS_BEGIN,
  FETCH_PRODUCT_DETAILS_FAILED,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  UPDATE_SHIPMENT_ADDRESS_BEGIN,
  UPDATE_SHIPMENT_ADDRESS_FAILED,
  UPDATE_SHIPMENT_ADDRESS_SUCCESS,
  ORDER_BEGIN,
  ORDER_FAILED,
  ORDER_SUCCESS,
  SEND_ORDER_INVOICE_BEGIN,
  SEND_ORDER_INVOICE_FAILED,
  SEND_ORDER_INVOICE_SUCCESS,
  GET_CUSTOMER_PORTAL_BEGIN,
  GET_CUSTOMER_PORTAL_FAILED,
  GET_CUSTOMER_PORTAL_SUCCESS,
  SET_FILTERS,
  GET_PURCHASING_FILTERS_BEGIN,
  GET_PURCHASING_FILTERS_FAILED,
  GET_PURCHASING_FILTERS_SUCCESS,
  GET_REGIONS_FILTERS_BEGIN,
  GET_REGIONS_FILTERS_FAILED,
  GET_REGIONS_FILTERS_SUCCESS,
  GET_APPELLATIONS_FILTERS_BEGIN,
  GET_APPELLATIONS_FILTERS_FAILED,
  GET_APPELLATIONS_FILTERS_SUCCESS,
  ORDER_AS_GUEST_BEGIN,
  ORDER_AS_GUEST_FAILED,
  ORDER_AS_GUEST_SUCCESS,
  SEND_GUEST_ORDER_INVOICE_BEGIN,
  SEND_GUEST_ORDER_INVOICE_FAILED,
  SEND_GUEST_ORDER_INVOICE_SUCCESS,
  TOGGLE_BOOKMARK_PRODUCT_BEGIN,
  TOGGLE_BOOKMARK_PRODUCT_FAILED,
  TOGGLE_BOOKMARK_PRODUCT_SUCCESS,
  FETCH_PRODUCT_BOOKMARKS_BEGIN,
  FETCH_PRODUCT_BOOKMARKS_FAILED,
  FETCH_PRODUCT_BOOKMARKS_SUCCESS,
  SET_PRODUCT_BOOKMARKS_COOKIE,
  POPULATE_PRODUCT_BOOKMARKS,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_FAILED,
  SUBMIT_PRODUCT_BOOKMARKS_STORED_SUCCESS,
  FETCH_PRODUCTS_COUNT_BEGIN,
  FETCH_PRODUCTS_COUNT_FAILED,
  FETCH_PRODUCTS_COUNT_SUCCESS,
  CLEAR_FILTERS,
  GET_LAST_ORDER_BEGIN,
  GET_LAST_ORDER_FAILED,
  GET_LAST_ORDER_SUCCESS,
  GET_AD_PRODUCT_INFO_BEGIN,
  GET_AD_PRODUCT_INFO_FAILED,
  GET_AD_PRODUCT_INFO_SUCCESS,
  GET_GUEST_LAST_ORDER_BEGIN,
  GET_GUEST_LAST_ORDER_FAILED,
  GET_GUEST_LAST_ORDER_SUCCESS,
  CLEAR_COUNTRY_REGION_APPELLATION_FILTER,
  POPULATE_FILTERS,
} from "./constants";
import { reorderProducts, updateProductQuantity } from "utils/utils";

const initialState: PurchasingReducerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  cartItems: [],
  products: null,
  productDetails: null,
  productBookmarks: [],
  order_data: null,
  session_url: "",
  is_winner: false,
  is_taken_over: false,
  celebrity_image_url: "",
  celebrity_name: "",
  client_secret: null,
  customer_portal_url: null,
  filters: [
    { filter: "Type", value: "" },
    { filter: "Price", value: "" },
    { filter: "Country", value: "" },
    { filter: "Region", value: "" },
    { filter: "Appellation", value: "" },
    { filter: "Grapes", value: "" },
    { filter: "Producer", value: "" },
    { filter: "Vintage", value: "" },
    { filter: "Pairing", value: "" },
    { filter: "Sort", value: "" },
  ],
  filterOptions: null,
  regionFilterOptions: null,
  appellationsFilterOptions: null,
  filteredProductsCount: null,
  lastOrder: null,
  adProductData: null,
};

const productBookmarksCookie = () => {
  return localStorage.getItem("product_bookmarks");
};

const PurchasingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_CART_ITEMS:
        const index = draft?.cartItems?.findIndex(
          (product) => product.id === action.payload.id
        );
        if (index === -1) {
          const newCartItem = {
            ...action.payload,
            quantity: action.payload?.quantity ? action.payload?.quantity : 1,
            total:
              Number(action?.payload?.price?.replace(/[^\d.]/g, "")) *
              action?.payload?.quantity,
          };
          draft.cartItems = [...draft.cartItems, newCartItem];
          localStorage.setItem(
            "cartItems",
            JSON.stringify([...draft.cartItems])
          );
        } else console.debug("Item is already in shopping cart");
        break;
      case DELETE_CART_ITEM:
        const filtered = state.cartItems.filter((el) => {
          return el.id !== action.payload;
        });
        localStorage.setItem("cartItems", JSON.stringify([...filtered]));
        StorageService.deleteCookie("voucher");
        draft.cartItems = [...filtered];
        break;
      case UPDATE_QUANTITY:
        const updatedCart = updateProductQuantity(
          action.payload.id,
          action.payload.operation,
          state.cartItems
        );
        localStorage.setItem("cartItems", JSON.stringify([...updatedCart]));
        draft.cartItems = [...updatedCart];
        break;
      case POPULATE_CART_ITEMS:
        draft.cartItems = action.payload;
        localStorage.setItem("cartItems", JSON.stringify([...action.payload]));
        break;
      case CLEAR_CART_ITEMS:
        localStorage.setItem("cartItems", JSON.stringify([]));
        StorageService.deleteCookie("voucher");
        draft.action = state.action;
        draft.cartItems = [];
        break;
      case FETCH_PRODUCTS_BEGIN:
      case FETCH_PRODUCT_DETAILS_BEGIN:
      case UPDATE_SHIPMENT_ADDRESS_BEGIN:
      case ORDER_BEGIN:
      case ORDER_AS_GUEST_BEGIN:
      case SEND_ORDER_INVOICE_BEGIN:
      case SEND_GUEST_ORDER_INVOICE_BEGIN:
      case GET_CUSTOMER_PORTAL_BEGIN:
      case GET_PURCHASING_FILTERS_BEGIN:
      case GET_REGIONS_FILTERS_BEGIN:
      case GET_APPELLATIONS_FILTERS_BEGIN:
      case TOGGLE_BOOKMARK_PRODUCT_BEGIN:
      case FETCH_PRODUCT_BOOKMARKS_BEGIN:
      case SUBMIT_PRODUCT_BOOKMARKS_STORED_BEGIN:
      case FETCH_PRODUCTS_COUNT_BEGIN:
      case GET_LAST_ORDER_BEGIN:
      case GET_AD_PRODUCT_INFO_BEGIN:
      case GET_GUEST_LAST_ORDER_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case FETCH_PRODUCTS_FAILED:
      case FETCH_PRODUCT_DETAILS_FAILED:
      case UPDATE_SHIPMENT_ADDRESS_FAILED:
      case ORDER_FAILED:
      case ORDER_AS_GUEST_FAILED:
      case SEND_ORDER_INVOICE_FAILED:
      case SEND_GUEST_ORDER_INVOICE_FAILED:
      case GET_CUSTOMER_PORTAL_FAILED:
      case GET_PURCHASING_FILTERS_FAILED:
      case GET_REGIONS_FILTERS_FAILED:
      case GET_APPELLATIONS_FILTERS_FAILED:
      case TOGGLE_BOOKMARK_PRODUCT_FAILED:
      case FETCH_PRODUCT_BOOKMARKS_FAILED:
      case SUBMIT_PRODUCT_BOOKMARKS_STORED_FAILED:
      case FETCH_PRODUCTS_COUNT_FAILED:
      case GET_LAST_ORDER_FAILED:
      case GET_GUEST_LAST_ORDER_FAILED:
      case GET_AD_PRODUCT_INFO_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case FETCH_PRODUCTS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.products = action.payload;
        break;
      case FETCH_PRODUCT_DETAILS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.productDetails = action.payload;
        break;
      case UPDATE_SHIPMENT_ADDRESS_SUCCESS:
      case SEND_ORDER_INVOICE_SUCCESS:
      case SEND_GUEST_ORDER_INVOICE_SUCCESS:
      case TOGGLE_BOOKMARK_PRODUCT_SUCCESS:
      case SUBMIT_PRODUCT_BOOKMARKS_STORED_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case ORDER_SUCCESS:
      case ORDER_AS_GUEST_SUCCESS:
        localStorage.setItem("cartItems", JSON.stringify([]));
        localStorage.setItem("orderData", JSON.stringify(action.payload));
        StorageService.deleteCookie("orderType");
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.order_data = action.payload.data;
        draft.session_url = action.payload.session_url;
        draft.is_winner = action.payload.is_winner;
        draft.is_taken_over = action.payload.is_taken_over;
        draft.celebrity_image_url = action.payload.celebrity_image_url;
        draft.celebrity_name = action.payload.celebrity_name;
        // draft.client_secret = action.payload.client_secret;
        break;
      case GET_CUSTOMER_PORTAL_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.customer_portal_url = action.payload;
        break;
      case GET_REGIONS_FILTERS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.regionFilterOptions = action.payload;
        break;
      case GET_APPELLATIONS_FILTERS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.appellationsFilterOptions = action.payload;
        break;
      case SET_FILTERS:
        const value = action.payload.value;
        const updatedFilters = [...draft.filters];

        const filterIndex = updatedFilters.findIndex(
          (filter) => filter.filter === action.payload.filter
        );

        if (filterIndex !== -1) {
          updatedFilters[filterIndex] = {
            ...updatedFilters[filterIndex],
            value,
          };
          draft.filters = updatedFilters;
          StorageService.setKeyValue("filters", updatedFilters);
        }
        break;
      case POPULATE_FILTERS:
        const productFiltersCookie = () =>
          StorageService.getDataByKey("filters");
        draft.filters = productFiltersCookie();
        break;
      case CLEAR_FILTERS:
        draft.action.actionName = action.type;
        draft.filters = initialState.filters;
        break;
      case GET_PURCHASING_FILTERS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.filterOptions = action.payload;
        break;
      case FETCH_PRODUCT_BOOKMARKS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.productBookmarks = action.payload;
        break;
      case SET_PRODUCT_BOOKMARKS_COOKIE:
        const productBookmarksStored = JSON.parse(productBookmarksCookie());
        const idx = productBookmarksStored?.findIndex(
          (product) => product.id === action.payload.id
        );
        const arrayWithoutSelectedProduct = productBookmarksStored?.filter(
          (item) => item.id !== action.payload.id
        );
        if (idx === -1) {
          const data = [...draft.productBookmarks, action.payload];
          draft.productBookmarks = data;
          localStorage.setItem("product_bookmarks", JSON.stringify(data));
        } else {
          draft.productBookmarks = arrayWithoutSelectedProduct;
          localStorage.setItem(
            "product_bookmarks",
            JSON.stringify(arrayWithoutSelectedProduct)
          );
        }
        break;
      case POPULATE_PRODUCT_BOOKMARKS:
        localStorage.setItem(
          "product_bookmarks",
          JSON.stringify(action.payload)
        );
        draft.productBookmarks = action.payload;
        break;
      case FETCH_PRODUCTS_COUNT_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.filteredProductsCount = action.payload;
        break;
      case GET_LAST_ORDER_SUCCESS:
      case GET_GUEST_LAST_ORDER_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.lastOrder = action.payload;
        break;
      case GET_AD_PRODUCT_INFO_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.adProductData = action.payload;
        break;
      case CLEAR_COUNTRY_REGION_APPELLATION_FILTER:
        draft.filters = draft.filters.map((filter) => {
          if (
            filter.filter === "Country" ||
            filter.filter === "Region" ||
            filter.filter === "Appellation"
          ) {
            return { ...filter, value: [] };
          }

          return filter;
        });
        break;
      default:
        break;
    }
  });

export default PurchasingReducer;
