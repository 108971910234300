import { ITastings } from "types/reducers/personal-ledger.reducer.type";
import {
  ADD_WINE_BEGIN,
  ADD_WINE_FAILED,
  ADD_WINE_SUCCESS,
  DELETE_ADDED_WINE_BEGIN,
  DELETE_ADDED_WINE_FAILED,
  DELETE_ADDED_WINE_SUCCESS,
  GET_ADDED_WINES_BEGIN,
  GET_ADDED_WINES_FAILED,
  GET_ADDED_WINES_SUCCESS,
  GET_PRODUCT_TRANSACTIONS_BEGIN,
  GET_PRODUCT_TRANSACTIONS_FAILED,
  GET_PRODUCT_TRANSACTIONS_SUCCESS,
  GET_SEARCHED_JOURNAL_WINES_BEGIN,
  GET_SEARCHED_JOURNAL_WINES_FAILED,
  GET_SEARCHED_JOURNAL_WINES_SUCCESS,
  GET_USER_BLIND_TASTINGS_BEGIN,
  GET_USER_BLIND_TASTINGS_FAILED,
  GET_USER_BLIND_TASTINGS_SUCCESS,
  GET_USER_ORDERS_BEGIN,
  GET_USER_ORDERS_FAILED,
  GET_USER_ORDERS_SUCCESS,
  RATE_ADDED_WINE_BEGIN,
  RATE_ADDED_WINE_FAILED,
  RATE_ADDED_WINE_SUCCESS,
  RATE_WINE_BEGIN,
  RATE_WINE_FAILED,
  RATE_WINE_SUCCESS,
  REQUEST_TO_SUPPLY_WINE_BEGIN,
  REQUEST_TO_SUPPLY_WINE_FAILED,
  REQUEST_TO_SUPPLY_WINE_SUCCESS,
  SET_ADDED_WINE_TASTE_BEGIN,
  SET_ADDED_WINE_TASTE_FAILED,
  SET_ADDED_WINE_TASTE_SUCCESS,
  SET_PRODUCT_TASTE_BEGIN,
  SET_PRODUCT_TASTE_FAILED,
  SET_PRODUCT_TASTE_SUCCESS,
} from "./constants";

export const getUserOrdersBegin = (searchValue?: string) => ({
  type: GET_USER_ORDERS_BEGIN,
  payload: searchValue,
});

export const getUserOrdersSuccess = (data) => ({
  type: GET_USER_ORDERS_SUCCESS,
  payload: data,
});

export const getUserOrdersFailed = (error) => ({
  type: GET_USER_ORDERS_FAILED,
  payload: error,
});

export const rateWineBegin = (data) => ({
  type: RATE_WINE_BEGIN,
  payload: data,
});

export const rateWineSuccess = (data) => ({
  type: RATE_WINE_SUCCESS,
  payload: data,
});

export const rateWineFailed = (error) => ({
  type: RATE_WINE_FAILED,
  payload: error,
});

export const rateAddedWineBegin = (data) => ({
  type: RATE_ADDED_WINE_BEGIN,
  payload: data,
});

export const rateAddedWineSuccess = (data) => ({
  type: RATE_ADDED_WINE_SUCCESS,
  payload: data,
});

export const rateAddedWineFailed = (error) => ({
  type: RATE_ADDED_WINE_FAILED,
  payload: error,
});

export const setProductTasteBegin = (data: {
  product_id: number;
  tastings: ITastings[];
  isBlind: boolean;
}) => ({
  type: SET_PRODUCT_TASTE_BEGIN,
  payload: data,
});

export const setProductTasteSuccess = (data) => ({
  type: SET_PRODUCT_TASTE_SUCCESS,
  payload: data,
});

export const setProductTasteFailed = (error) => ({
  type: SET_PRODUCT_TASTE_FAILED,
  payload: error,
});

export const setAddedWineTasteBegin = (data: {
  product_id: number;
  tastings: ITastings[];
}) => ({
  type: SET_ADDED_WINE_TASTE_BEGIN,
  payload: data,
});

export const setAddedWineTasteSuccess = (data) => ({
  type: SET_ADDED_WINE_TASTE_SUCCESS,
  payload: data,
});

export const setAddedWineTasteFailed = (error) => ({
  type: SET_ADDED_WINE_TASTE_FAILED,
  payload: error,
});

export const getProductTransactionsBegin = (product_id) => ({
  type: GET_PRODUCT_TRANSACTIONS_BEGIN,
  payload: product_id,
});

export const getProductTransactionsSuccess = (data) => ({
  type: GET_PRODUCT_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getProductTransactionsFailed = (error) => ({
  type: GET_PRODUCT_TRANSACTIONS_FAILED,
  payload: error,
});

export const getUserBlindTastingsBegin = (searchValue?: string) => ({
  type: GET_USER_BLIND_TASTINGS_BEGIN,
  payload: searchValue,
});

export const getUserBlindTastingsSuccess = (data) => ({
  type: GET_USER_BLIND_TASTINGS_SUCCESS,
  payload: data,
});

export const getUserBlindTastingsFailed = (error) => ({
  type: GET_USER_BLIND_TASTINGS_FAILED,
  payload: error,
});

export const addWineBegin = (data: {
  wine_name: string;
  producer: string;
  bottle_size: string;
  type: string;
  country: string;
  region: string;
  location: string;
  inventory: number;
  custom_field?: any;
  image: any;
}) => ({
  type: ADD_WINE_BEGIN,
  payload: data,
});

export const addWineSuccess = () => ({
  type: ADD_WINE_SUCCESS,
});

export const addWineFailed = (error) => ({
  type: ADD_WINE_FAILED,
  payload: error,
});

export const getAddedWinesBegin = (searchValue?: string) => ({
  type: GET_ADDED_WINES_BEGIN,
  payload: searchValue,
});

export const getAddedWinesSuccess = (data) => ({
  type: GET_ADDED_WINES_SUCCESS,
  payload: data,
});

export const getAddedWinesFailed = (error) => ({
  type: GET_ADDED_WINES_FAILED,
  payload: error,
});

export const requestToSupplyWineBegin = (wineId?: string) => ({
  type: REQUEST_TO_SUPPLY_WINE_BEGIN,
  payload: wineId,
});

export const requestToSupplyWineSuccess = (data) => ({
  type: REQUEST_TO_SUPPLY_WINE_SUCCESS,
  payload: data,
});

export const requestToSupplyWineFailed = (error) => ({
  type: REQUEST_TO_SUPPLY_WINE_FAILED,
  payload: error,
});

export const getSearchedJournalWinesBegin = (searchValue?: string) => ({
  type: GET_SEARCHED_JOURNAL_WINES_BEGIN,
  payload: searchValue,
});

export const getSearchedJournalWinesSuccess = (data) => ({
  type: GET_SEARCHED_JOURNAL_WINES_SUCCESS,
  payload: data,
});

export const getSearchedJournalWinesFailed = (error) => ({
  type: GET_SEARCHED_JOURNAL_WINES_FAILED,
  payload: error,
});

export const deleteAddedWineBegin = (data) => ({
  type: DELETE_ADDED_WINE_BEGIN,
  payload: data,
});

export const deleteAddedWineSuccess = (data) => ({
  type: DELETE_ADDED_WINE_SUCCESS,
  payload: data,
});

export const deleteAddedWineFailed = (error) => ({
  type: DELETE_ADDED_WINE_FAILED,
  payload: error,
});
