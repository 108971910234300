const scope = "vinfinity/authentication";

export const FETCH_COUNTRIES_BEGIN = `${scope}/FETCH_COUNTRIES_BEGIN`;
export const FETCH_COUNTRIES_SUCCESS = `${scope}/FETCH_COUNTRIES_SUCCESS`;
export const FETCH_COUNTRIES_FAILED = `${scope}/FETCH_COUNTRIES_FAILED`;

export const LOG_IN_BEGIN = `${scope}/LOG_IN_BEGIN`;
export const LOG_IN_FAILED = `${scope}/LOG_IN_FAILED`;
export const LOG_IN_SUCCESS = `${scope}/LOG_IN_SUCCESS`;
export const LOG_OUT = `${scope}/LOG_OUT`;
export const LOG_IN_BY_TOKEN = `${scope}/LOG_IN_BY_TOKEN`;

export const REGISTER_BEGIN = `${scope}/REGISTER_BEGIN`;
export const REGISTER_FAILED = `${scope}/REGISTER_FAILED`;
export const REGISTER_SUCCESS = `${scope}/REGISTER_SUCCESS`;

export const SET_PERSONAL_DETAILS_BEGIN = `${scope}/SET_PERSONAL_DETAILS_BEGIN`;
export const SET_PERSONAL_DETAILS_FAILED = `${scope}/SET_PERSONAL_DETAILS_FAILED`;
export const SET_PERSONAL_DETAILS_SUCCESS = `${scope}/SET_PERSONAL_DETAILS_SUCCESS`;

export const CANCEL_SIGNUP = `${scope}/CANCEL_SIGNUP`;
export const CANCEL_SIGNUP_BY_EMAIL_TOKEN = `${scope}/CANCEL_SIGNUP_BY_EMAIL_TOKEN`;

export const ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN = `${scope}/ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN`;
export const ACCEPT_EVENT_INVITATION_AND_REGISTER_FAILED = `${scope}/ACCEPT_EVENT_INVITATION_AND_REGISTER_FAILED`;
export const ACCEPT_EVENT_INVITATION_AND_REGISTER_SUCCESS = `${scope}/ACCEPT_EVENT_INVITATION_AND_REGISTER_SUCCESS`;

export const SEND_VERIFY_EMAIL_BEGIN = `${scope}/SEND_VERIFY_EMAIL_BEGIN`;
export const SEND_VERIFY_EMAIL_FAILED = `${scope}/SEND_VERIFY_EMAIL_FAILED`;
export const SEND_VERIFY_EMAIL_SUCCESS = `${scope}/SEND_VERIFY_EMAIL_SUCCESS`;

export const VERIFY_EMAIL_BEGIN = `${scope}/VERIFY_EMAIL_BEGIN`;
export const VERIFY_EMAIL_FAILED = `${scope}/VERIFY_EMAIL_FAILED`;
export const VERIFY_EMAIL_SUCCESS = `${scope}/VERIFY_EMAIL_SUCCESS`;

export const SEND_RESET_PASSWORD_BEGIN = `${scope}/SEND_RESET_PASSWORD_BEGIN`;
export const SEND_RESET_PASSWORD_FAILED = `${scope}/SEND_RESET_PASSWORD_FAILED`;
export const SEND_RESET_PASSWORD_SUCCESS = `${scope}/SEND_RESET_PASSWORD_SUCCESS`;

export const CHECK_IF_HAS_PASSCODE_BEGIN = `${scope}/CHECK_IF_HAS_PASSCODE_BEGIN`;
export const CHECK_IF_HAS_PASSCODE_FAILED = `${scope}/CHECK_IF_HAS_PASSCODE_FAILED`;
export const CHECK_IF_HAS_PASSCODE_SUCCESS = `${scope}/CHECK_IF_HAS_PASSCODE_SUCCESS`;

export const SEND_SMS_CODE_BEGIN = `${scope}/SEND_SMS_CODE_BEGIN`;
export const SEND_SMS_CODE_FAILED = `${scope}/SEND_SMS_CODE_FAILED`;
export const SEND_SMS_CODE_SUCCESS = `${scope}/SEND_SMS_CODE_SUCCESS`;

export const CLEAR_ACTION = `${scope}/CLEAR_ACTION`;
export const SET_IS_LOGGED_IN = `${scope}/SET_IS_LOGGED_IN`;
