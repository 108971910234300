import {
  CHECK_ANSWER_BEGIN,
  CHECK_ANSWER_FAILED,
  CHECK_ANSWER_SUCCESS,
  CHECK_BLANK_ANSWER_BEGIN,
  CHECK_BLANK_ANSWER_FAILED,
  CHECK_BLANK_ANSWER_SUCCESS,
  CLEAR_CHECK_ANSWER,
  GET_CLIENT_OBJECTIVES_BEGIN,
  GET_CLIENT_OBJECTIVES_FAILED,
  GET_CLIENT_OBJECTIVES_SUCCESS,
  GET_CLIENT_TIMELINE_BEGIN,
  GET_CLIENT_TIMELINE_FAILED,
  GET_CLIENT_TIMELINE_SUCCESS,
  GET_MEDALS_LEAGUE_TABLE_BEGIN,
  GET_MEDALS_LEAGUE_TABLE_FAILED,
  GET_MEDALS_LEAGUE_TABLE_SUCCESS,
  GET_REFERRALS_TABLE_BEGIN,
  GET_REFERRALS_TABLE_FAILED,
  GET_REFERRALS_TABLE_SUCCESS,
  GET_TODAYS_GAME_BEGIN,
  GET_TODAYS_GAME_FAILED,
  GET_TODAYS_GAME_SUCCESS,
  GET_TODAYS_QUESTION_BEGIN,
  GET_TODAYS_QUESTION_FAILED,
  GET_TODAYS_QUESTION_SUCCESS,
  SUBMIT_FILL_THE_BLANK_BEGIN,
  SUBMIT_FILL_THE_BLANK_FAILED,
  SUBMIT_FILL_THE_BLANK_SUCCESS,
  SUBMIT_QUIZ_BEGIN,
  SUBMIT_QUIZ_FAILED,
  SUBMIT_QUIZ_SUCCESS,
  SUBMIT_TRUE_OR_FALSE_BEGIN,
  SUBMIT_TRUE_OR_FALSE_FAILED,
  SUBMIT_TRUE_OR_FALSE_SUCCESS,
} from "./constants";

export const getTodaysQuestionBegin = (lang: string) => ({
  type: GET_TODAYS_QUESTION_BEGIN,
  payload: lang,
});

export const getTodaysQuestionSuccess = (data) => ({
  type: GET_TODAYS_QUESTION_SUCCESS,
  payload: data,
});

export const getTodaysQuestionFailed = (error) => ({
  type: GET_TODAYS_QUESTION_FAILED,
  payload: error,
});

// SINGLE CHOICE
export const submitQuizBegin = (data) => ({
  type: SUBMIT_QUIZ_BEGIN,
  payload: data,
});

export const submitQuizSuccess = (data) => ({
  type: SUBMIT_QUIZ_SUCCESS,
  payload: data,
});

export const submitQuizFailed = (error) => ({
  type: SUBMIT_QUIZ_FAILED,
  payload: error,
});

export const checkAnswerBegin = (answer_id) => ({
  type: CHECK_ANSWER_BEGIN,
  payload: answer_id,
});

export const checkAnswerSuccess = (data) => ({
  type: CHECK_ANSWER_SUCCESS,
  payload: data,
});

export const checkAnswerFailed = (error) => ({
  type: CHECK_ANSWER_FAILED,
  payload: error,
});

export const clearCheckAnswer = () => ({
  type: CLEAR_CHECK_ANSWER,
});

// FILL IN THE BLANK
export const fillInTheBlankSubmitBegin = (data: {
  type: number;
  answer: string;
}) => ({
  type: SUBMIT_FILL_THE_BLANK_BEGIN,
  payload: data,
});

export const fillInTheBlankSubmitSuccess = (data) => ({
  type: SUBMIT_FILL_THE_BLANK_SUCCESS,
  payload: data,
});

export const fillInTheBlankSubmitFailed = (error) => ({
  type: SUBMIT_FILL_THE_BLANK_FAILED,
  payload: error,
});

export const checkBlankAnswerBegin = (data: {
  answer: string;
  language: string;
}) => ({
  type: CHECK_BLANK_ANSWER_BEGIN,
  payload: data,
});

export const checkBlankAnswerSuccess = (data) => ({
  type: CHECK_BLANK_ANSWER_SUCCESS,
  payload: data,
});

export const checkBlankAnswerFailed = (error) => ({
  type: CHECK_BLANK_ANSWER_FAILED,
  payload: error,
});

// TRUE OR FALSE
export const submitTrueOrFalseBegin = (data: number[]) => ({
  type: SUBMIT_TRUE_OR_FALSE_BEGIN,
  payload: data,
});

export const submitTrueOrFalseSuccess = (data) => ({
  type: SUBMIT_TRUE_OR_FALSE_SUCCESS,
  payload: data,
});

export const submitTrueOrFalseFailed = (error) => ({
  type: SUBMIT_TRUE_OR_FALSE_FAILED,
  payload: error,
});
export const getClientObjectivesBegin = () => ({
  type: GET_CLIENT_OBJECTIVES_BEGIN,
});

export const getClientObjectivesSuccess = (data) => ({
  type: GET_CLIENT_OBJECTIVES_SUCCESS,
  payload: data,
});

export const getClientObjectivesFailed = (error) => ({
  type: GET_CLIENT_OBJECTIVES_FAILED,
  payload: error,
});

export const getReferralsTableBegin = () => ({
  type: GET_REFERRALS_TABLE_BEGIN,
});

export const getReferralsTableSuccess = (data) => ({
  type: GET_REFERRALS_TABLE_SUCCESS,
  payload: data,
});

export const getReferralsTableFailed = (error) => ({
  type: GET_REFERRALS_TABLE_FAILED,
  payload: error,
});

export const getMedalsTableBegin = () => ({
  type: GET_MEDALS_LEAGUE_TABLE_BEGIN,
});

export const getMedalsTableSuccess = (data) => ({
  type: GET_MEDALS_LEAGUE_TABLE_SUCCESS,
  payload: data,
});

export const getMedalsTableFailed = (error) => ({
  type: GET_MEDALS_LEAGUE_TABLE_FAILED,
  payload: error,
});

export const getClientTimelineBegin = () => ({
  type: GET_CLIENT_TIMELINE_BEGIN,
});

export const getClientTimelineSuccess = (data) => ({
  type: GET_CLIENT_TIMELINE_SUCCESS,
  payload: data,
});

export const getClientTimelineFailed = (error) => ({
  type: GET_CLIENT_TIMELINE_FAILED,
  payload: error,
});

export const getTodaysGameBegin = () => ({
  type: GET_TODAYS_GAME_BEGIN,
});

export const getTodaysGameSuccess = (data) => ({
  type: GET_TODAYS_GAME_SUCCESS,
  payload: data,
});

export const getTodaysGameFailed = (error) => ({
  type: GET_TODAYS_GAME_FAILED,
  payload: error,
});
