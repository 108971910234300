import { useTranslation } from "react-i18next";

import styles from "./PersonalDetails.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import CustomInputComponent from "components/CustomInput/CustomInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import InputMui from "components/InputMui/InputMui";
import dayjs from "dayjs";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { clearAction, updatePersonalDetailsBegin } from "../actions";

import _ from "lodash";
import { RoundedPlusIcon } from "assets/icons/icons";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import AddPhone from "./AddPhone/AddPhone.page";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import AddEmail from "./AddEmail/AddEmail.page";
import { UPDATE_PERSONAL_DETAILS_SUCCESS } from "../constants";
import { useSearchParams } from "react-router-dom";

interface Props {}

export default function PersonalDetails(props: Props) {
  const {
    action: { isLoading, isSuccess, actionName },
    client,
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const isMobile = useCheckMobileScreen();
  const [birthDate, setBirthDate] = useState(
    client?.date_of_birth ? client?.date_of_birth : null
  );
  const [state, setState] = useState({
    firstname: client?.firstname ?? "",
    lastname: client?.lastname ?? "",
    email: client?.email ?? "",
    mobileNumber:
      client?.country_phone !== null && client?.phone !== null
        ? client?.country_phone + client?.phone
        : "",
  });
  const [selectedSalutation, setselectedSalutation] = useState<string>(
    client?.salutation ?? ""
  );
  const disabled = _.isEqual(
    {
      salutation: client?.salutation,
      firstname: client?.firstname,
      lastname: client?.lastname,
      date_of_birth: client?.date_of_birth,
    },
    {
      salutation: selectedSalutation,
      firstname: state?.firstname,
      lastname: state?.lastname,
      date_of_birth: birthDate,
    }
  );

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleBirthDateChange = (e) => {
    setBirthDate(dayjs(e.$d).format());
  };

  useEffect(() => {
    if (isSuccess && actionName === UPDATE_PERSONAL_DETAILS_SUCCESS) {
      if (isMobile) {
        dispatch(setLeftPartItem("1"));
      } else {
        dispatch(setMiddlepartItem(null));
        dispatch(setRightPartItem(null));
      }
    }
    return () => {
      dispatch(clearAction());
    };
  }, [isSuccess]);

  const handleSave = () => {
    let data = {
      salutation: selectedSalutation,
      firstname: state.firstname,
      lastname: state.lastname,
      date_of_birth: birthDate ? dayjs(birthDate).format("YYYY/MM/DD") : null,
    };
    dispatch(updatePersonalDetailsBegin(data));
    if (stepParam) {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (client) {
      setState({
        ...state,
        mobileNumber:
          client?.country_phone !== null && client?.phone
            ? client?.country_phone + client?.phone
            : "",
        email: client?.email,
      });
    }
  }, [client]);

  return (
    <div className={styles.personalDetailsWrapper}>
      <h1>{translations("label.personal_details")}</h1>
      <div className={styles.fields}>
        <h2>{translations("label.name")}</h2>
        {/* {client?.salutation !== "" ? (
          <CustomInputComponent
            type="text"
            name="salutation"
            className="secondInput"
            placeholder={translations("label.salutation")}
            value={translations(getSalutation(client?.salutation)) ?? ""}
            onChange={(e) => handleChange(e)}
          />
        ) : ( */}
        <div className={styles.salutations}>
          <div
            className={styles.salutationItem}
            style={
              selectedSalutation === "Mr."
                ? {
                    background: `${getDefaultColour(defaultColour)}`,
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => setselectedSalutation("Mr.")}
          >
            {translations("label.mr")}
          </div>
          <div
            className={styles.salutationItem}
            style={
              selectedSalutation === "Mrs."
                ? {
                    background: `${getDefaultColour(defaultColour)}`,
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => setselectedSalutation("Mrs.")}
          >
            {translations("label.mrs")}
          </div>
          <div
            className={styles.salutationItem}
            style={
              selectedSalutation === "Ms."
                ? {
                    background: `${getDefaultColour(defaultColour)}`,
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => setselectedSalutation("Ms.")}
          >
            {translations("label.ms")}
          </div>
        </div>
        {/* )} */}
        <CustomInputComponent
          type="text"
          name="firstname"
          className="secondInput"
          placeholder={translations("label.name")}
          value={state.firstname}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="lastname"
          className="secondInput"
          placeholder={translations("label.lastname")}
          value={state.lastname}
          onChange={(e) => handleChange(e)}
        />
        <InputMui
          label={translations("label.birthdate")}
          type="date"
          name="dateOfBirth"
          value={birthDate}
          onChange={(e) => handleBirthDateChange(e)}
          required
          style={
            {
              "--color": getDefaultColour(defaultColour),
            } as React.CSSProperties
          }
        />
      </div>
      <div className={styles.fields}>
        <h2>{translations("label.contact_details")}</h2>
        <div className={styles.withIcons}>
          <CustomInputComponent
            type="text"
            name="mobileNumber"
            className="secondInput"
            placeholder={translations("label.mobile_number")}
            style={{ pointerEvents: "none" }}
            value={state.mobileNumber}
            onChange={(e) => handleChange(e)}
          />
          {!client?.phone ? (
            <div
              className={styles.clickableIcon}
              onClick={() => {
                if (isMobile) {
                  dispatch(setLeftPartItem(<AddPhone />));
                } else dispatch(setRightPartItem(<AddPhone />));
              }}
            >
              <RoundedPlusIcon color={getDefaultColour(defaultColour)} />
            </div>
          ) : null}
        </div>
        <div className={styles.withIcons}>
          <CustomInputComponent
            type="text"
            name="email"
            className="secondInput"
            placeholder={translations("label.email")}
            style={{ pointerEvents: "none" }}
            value={state.email}
            onChange={(e) => handleChange(e)}
          />
          {!client?.email ? (
            <div
              className={styles.clickableIcon}
              onClick={() => {
                if (isMobile) {
                  dispatch(setLeftPartItem(<AddEmail />));
                } else dispatch(setRightPartItem(<AddEmail />));
              }}
            >
              <RoundedPlusIcon color={getDefaultColour(defaultColour)} />
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          disabled={disabled}
          handleClick={() => handleSave()}
        />
        {/* {isReadOnly ? (
          <ButtonComponent
            label={translations("button.change")}
            class={"btn btn-white-bordered"}
            isLoading={isLoading}
            handleClick={() => setIsReadOnly(false)}
          />
        ) : (
          <ButtonComponent
            label={translations("button.save")}
            class={"btn btn-primary"}
            handleClick={() => handleSave()}
          />
        )} */}
      </div>
    </div>
  );
}
