import { useTranslation } from "react-i18next";
import styles from "./PourAndScore.module.scss";
import {
  ArrowLeft,
  ArrowLeftWhite,
  InfoIcon,
  InfoModalCloseIcon,
  RoundedInfoArrowUp,
} from "assets/icons/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import CoinImage from "assets/images/Coin.png";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkAnswerBegin,
  getTodaysQuestionBegin,
  submitQuizBegin,
} from "../actions";
import StorageService from "services/storage.service";
import { CHECK_ANSWER_SUCCESS, SUBMIT_QUIZ_SUCCESS } from "../constants";
import { getClientBegin, getUserReferralsBegin } from "pages/Profile/actions";
import { closeModal, openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";

import Lottie from "react-lottie";
import * as pourAndScoreAnimation from "assets/animations/pour-and-score.json";
import ButtonComponent from "components/Button/Button.component";

export default function PourAndScore() {
  const { defaultColour, client, referredClients } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    todays_question,
    answer_correctness,
    todaysGame,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState({
    activeStep: "",
    selectedAnswerId: 0,
    view: "bottle",
    isCorrect: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const stepParam = searchParams.get("step");
  const submitParam = searchParams.get("submit");
  const quizAnswerIdCookie = StorageService.getDataByKey("quizAnswerId");
  const showAlreadyPlayedGameCookie = StorageService.getDataByKey(
    "showAlreadyPlayedGame"
  );
  const [showRecentlyJoinedFriendBox, setShowRecentlyJoinedFriendBox] =
    useState(false);
  const isLoggedIn = () => StorageService.getDataByKey("token");

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: pourAndScoreAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleStartGame = () => {
    navigate("/games/pour-and-score?step=quiz");
  };

  const handleQuizSubmit = () => {
    if (state.isCorrect !== null && state.view !== "submit") {
      setState({ ...state, view: "submit" });
      return;
    }

    if (answer_correctness || answer_correctness === false) {
      if (isLoggedIn()) dispatch(submitQuizBegin(quizAnswerIdCookie));
      else {
        StorageService.setKeyValue(
          "lastRouteCookie",
          "/games/pour-and-score?submit=true"
        );
        navigate("/login", {
          state: {
            lastRoute: "/games/pour-and-score?submit=true",
          },
        });
      }
    } else {
      dispatch(checkAnswerBegin(state.selectedAnswerId));
      StorageService.setKeyValue("quizAnswerId", state.selectedAnswerId);
    }
  };

  const handleOpenAlreadyPlayedPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.alreadyPlayedBox}>
            <div className={styles.mainContentPopup}>
              <div>
                <InfoIcon color="#fff" />
              </div>
              <div>
                <span>{translations("label.already_played_game")}</span>
              </div>
            </div>

            <div className={styles.bottomPart}>
              <div>
                <span>{translations("label.info")}</span>
              </div>
              <div
                className="pointer"
                onClick={() => {
                  StorageService.deleteCookie("showAlreadyPlayedGame");
                  dispatch(closeModal());
                }}
              >
                <RoundedInfoArrowUp />
              </div>
            </div>
          </div>
        ),
        type: modalTypes.smInfoOrange,
      })
    );
  };

  useEffect(() => {
    dispatch(getTodaysQuestionBegin(getLanguage()));
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
  }, [state.activeStep]);

  useEffect(() => {
    if (stepParam === "quiz") {
      setState({ ...state, activeStep: "playQuiz" });
    }

    if (submitParam) {
      dispatch(submitQuizBegin(quizAnswerIdCookie));
      searchParams.delete("submit");
      setSearchParams(searchParams);
      StorageService.deleteCookie("quizAnswerId");
    }
  }, [stepParam, submitParam]);

  useEffect(() => {
    if (
      (actionName === SUBMIT_QUIZ_SUCCESS && !submitParam) ||
      client?.has_played
    ) {
      navigate("/games");
      window.location.reload();
    }

    if (actionName === CHECK_ANSWER_SUCCESS) {
      setState({ ...state, isCorrect: answer_correctness });
    }
  }, [actionName]);

  useEffect(() => {
    if (showAlreadyPlayedGameCookie) {
      handleOpenAlreadyPlayedPopup();
    }
  }, [showAlreadyPlayedGameCookie]);

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(getUserReferralsBegin(true));
    }
  }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      if (client?.has_played) {
        navigate("/purchasing");
      } else {
        window.history.back();
      }
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate, client?.has_played]);

  useEffect(() => {
    if (!client && isLoggedIn()) {
      dispatch(getClientBegin());
    }
  }, [client]);

  useEffect(() => {
    if (todaysGame !== "Pour and Score") {
      navigate("/games");
      window.location.reload();
    }
  }, [todaysGame]);

  return (
    <div
      className={styles.gamificationWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {state.activeStep === "" ? (
        <div className={styles.content}>
          <div className={styles.animation}>
            <Lottie
              options={defaultOptions}
              // isStopped={this.state.isStopped}
              // isPaused={this.state.isPaused}
            />
          </div>
          <div className={styles.gameInfo}>
            <div>
              <h1>{translations("label.pour_and_score")}</h1>
              <span>{translations("label.pour_and_score_subtitle")}</span>
            </div>
            <ButtonComponent
              label={translations("label.start_game")}
              class={"btn btn-primary"}
              style={{ backgroundColor: "#FF6333" }}
              handleClick={() => handleStartGame()}
            />
          </div>
        </div>
      ) : state.activeStep === "playQuiz" ? (
        <div className={styles.playQuizGameBox}>
          <div className={styles.header}>
            <div
              onClick={() => {
                navigate("/games");
                setState({ ...state, activeStep: "" });
              }}
            >
              <InfoModalCloseIcon color="#fff" />
            </div>

            <div className={styles.questionBox}>
              <h2>{translations("label.daily_quiz")}</h2>

              <div className={styles.subtitle}>
                <span>{translations("label.pour_and_score")}</span>
              </div>

              {state.view === "choose" ? (
                <div className={styles.chooseWineContainer}>
                  <div
                    className={styles.options}
                    style={{
                      pointerEvents: state.isCorrect === null ? "all" : "none",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          state.selectedAnswerId ===
                          todays_question?.firstAnswerId
                            ? answer_correctness
                              ? "#B5FF6C"
                              : state.isCorrect === false
                              ? "#FF4141"
                              : "#fff"
                            : "transparent",
                      }}
                      onClick={() =>
                        setState({
                          ...state,
                          selectedAnswerId: todays_question?.firstAnswerId,
                        })
                      }
                    >
                      <img src={todays_question?.firstAnswer} alt="Glass" />
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          state.selectedAnswerId ===
                          todays_question?.secondAnswerId
                            ? answer_correctness
                              ? "#B5FF6C"
                              : state.isCorrect === false
                              ? "#FF4141"
                              : "#fff"
                            : "transparent",
                      }}
                      onClick={() =>
                        setState({
                          ...state,
                          selectedAnswerId: todays_question?.secondAnswerId,
                        })
                      }
                    >
                      <img src={todays_question?.secondAnswer} alt="Glass" />
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          state.selectedAnswerId ===
                          todays_question?.thirdAnswerId
                            ? answer_correctness
                              ? "#B5FF6C"
                              : state.isCorrect === false
                              ? "#FF4141"
                              : "#fff"
                            : "transparent",
                      }}
                      onClick={() =>
                        setState({
                          ...state,
                          selectedAnswerId: todays_question?.thirdAnswerId,
                        })
                      }
                    >
                      <img src={todays_question?.thirdAnswer} alt="Glass" />
                    </div>
                  </div>

                  {state.isCorrect === null ? (
                    <div
                      className={styles.seeWineLabel}
                      onClick={() => setState({ ...state, view: "bottle" })}
                    >
                      <ArrowLeftWhite />
                      <span>{translations("label.see_wine_again")}</span>
                    </div>
                  ) : (
                    <div
                      className={styles.medalsHint}
                      style={{ color: state.isCorrect ? "#B5FF6C" : "#FF4141" }}
                    >
                      <span>
                        {translations(
                          state.isCorrect
                            ? "label.correct_answer"
                            : "label.incorrect_answer"
                        )}
                      </span>
                    </div>
                  )}
                </div>
              ) : state.view !== "submit" ? (
                <div className={styles.shownWine}>
                  <img
                    src={todays_question?.question_image_url}
                    alt="Wine"
                    className={styles.wineBottle}
                  />
                  <span>{todays_question?.wine_name}</span>
                </div>
              ) : null}
            </div>

            {state.view === "submit" ? (
              <div className={styles.answeredCoinBox}>
                <img src={CoinImage} alt={"Coin"} />
              </div>
            ) : (
              <div className={styles.glassesContent}></div>
            )}

            {state.view === "submit" ? (
              <>
                {!isLoggedIn() ? (
                  <div className={styles.notLoggedInUser}>
                    <span>
                      {translations("label.login_to_retrieve_medals")}
                    </span>
                  </div>
                ) : null}
                <div className={styles.earnedMedals}>
                  <span>
                    {answer_correctness ? 15 : 5} {translations("label.medals")}
                  </span>
                </div>
              </>
            ) : null}

            <div
              className={styles.submitAnswerButton}
              style={{
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
              onClick={() =>
                state.view === "bottle"
                  ? setState({
                      ...state,
                      view: state.isCorrect !== null ? "submit" : "choose",
                    })
                  : handleQuizSubmit()
              }
            >
              <span>
                {translations(
                  state.view === "bottle"
                    ? "label.see_glasses"
                    : state.view === "submit"
                    ? isLoggedIn()
                      ? "label.get_medals"
                      : "button.log_in"
                    : state.isCorrect !== null
                    ? "button.next"
                    : "button.submit"
                )}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
