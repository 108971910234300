import { useTranslation } from "react-i18next";
import styles from "./CredentialsChangeSuccess.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import ButtonComponent from "components/Button/Button.component";
import { RoundedSuccessIcon } from "assets/icons/icons";

import { useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import ChangeLoginCredentials from "pages/Profile/ChangeLoginCredentials/ChangeLoginCredentials.page";

interface Props {
  type?: string;
}

export default function CredentialsChangeSuccess(props: Props) {
  const { type } = props;
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    if (isMobile) {
      dispatch(setLeftPartItem("1"));
    } else {
      dispatch(setLeftPartItem("1"));
      dispatch(setMiddlepartItem(<ChangeLoginCredentials />));
      dispatch(setRightPartItem(null));
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  };

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.successStep}>
        <div>
          <h1>
            {translations(
              type === "passcode-changed"
                ? "label.passcode_change_success"
                : "label.password_change_success"
            )}
          </h1>
          <h2>
            {translations(
              type === "passcode-changed"
                ? "label.passcode_change_subtitle"
                : "label.password_change_subtitle"
            )}
          </h2>
        </div>

        <div className={styles.icon}>
          <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
        </div>
        <div className={styles.button}>
          <ButtonComponent
            label={translations("button.close")}
            class={"btn btn-primary"}
            handleClick={() => handleClose()}
          />
        </div>
      </div>
    </div>
  );
}
