import { produce } from "immer";

import { InvestmentsReducerInterface } from "types/reducers/investments.reducer.type";
import {
  CLEAR_LOGGED_IN_GRAPH_DATA,
  FETCH_CLIENT_BALANCE_SIMULATION_BEGIN,
  FETCH_CLIENT_BALANCE_SIMULATION_FAILED,
  FETCH_CLIENT_BALANCE_SIMULATION_SUCCESS,
  FETCH_GEOGRAPHIC_ALLOCATION_BEGIN,
  FETCH_GEOGRAPHIC_ALLOCATION_FAILED,
  FETCH_GEOGRAPHIC_ALLOCATION_SUCCESS,
  FETCH_LATEST_TRANSACTIONS_BEGIN,
  FETCH_LATEST_TRANSACTIONS_FAILED,
  FETCH_LATEST_TRANSACTIONS_SUCCESS,
  FETCH_OUTLOOK_SIMULATION_BEGIN,
  FETCH_OUTLOOK_SIMULATION_FAILED,
  FETCH_OUTLOOK_SIMULATION_SUCCESS,
  FETCH_TRANSACTION_DETAILS_BEGIN,
  FETCH_TRANSACTION_DETAILS_FAILED,
  FETCH_TRANSACTION_DETAILS_SUCCESS,
  FETCH_USER_TRANSACTIONS_BEGIN,
  FETCH_USER_TRANSACTIONS_FAILED,
  FETCH_USER_TRANSACTIONS_SUCCESS,
  FETCH_WINES_BEGIN,
  FETCH_WINES_FAILED,
  FETCH_WINES_SUCCESS,
  FETCH_WINE_COLLECTION_BEGIN,
  FETCH_WINE_COLLECTION_FAILED,
  FETCH_WINE_COLLECTION_SUCCESS,
  FETCH_WINE_DETAILS_BEGIN,
  FETCH_WINE_DETAILS_FAILED,
  FETCH_WINE_DETAILS_SUCCESS,
  FETCH_WINE_PERFORMANCE_BEGIN,
  FETCH_WINE_PERFORMANCE_FAILED,
  FETCH_WINE_PERFORMANCE_SUCCESS,
  GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN,
  GET_WEEKLY_PERFORMANCE_CLIENT_FAILED,
  GET_WEEKLY_PERFORMANCE_CLIENT_SUCCESS,
  GET_WEEKLY_PERFORMANCE_GUEST_BEGIN,
  GET_WEEKLY_PERFORMANCE_GUEST_FAILED,
  GET_WEEKLY_PERFORMANCE_GUEST_SUCCESS,
  PAYPAL_USER_TOP_UP_BEGIN,
  PAYPAL_USER_TOP_UP_FAILED,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED,
  PAYPAL_USER_TOP_UP_SUBSCRIPTION_SUCCESS,
  PAYPAL_USER_TOP_UP_SUCCESS,
  STRIPE_USER_TOP_UP_BEGIN,
  STRIPE_USER_TOP_UP_FAILED,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED,
  STRIPE_USER_TOP_UP_SUBSCRIPTION_SUCCESS,
  STRIPE_USER_TOP_UP_SUCCESS,
  UPDATE_TRANSACTIONS_STATUS_BEGIN,
  UPDATE_TRANSACTIONS_STATUS_FAILED,
  UPDATE_TRANSACTIONS_STATUS_SUCCESS,
} from "./constants";
import StorageService from "services/storage.service";

const initialState: InvestmentsReducerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  redirectUrl: "",
  transactions: null,
  transactionDetails: null,
  latestTransactions: null,
  isLatestTransactionsLoading: false,
  wineCollectionData: null,
  wines: null,
  wineDetails: null,
  balanceSimulation: null,
  outlookSimulation: null,
  geographicAllocation: null,
  winePerformance: null,
  weeklyPerformance: null,
};

const InvestmentsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case STRIPE_USER_TOP_UP_BEGIN:
      case STRIPE_USER_TOP_UP_SUBSCRIPTION_BEGIN:
      case PAYPAL_USER_TOP_UP_BEGIN:
      case PAYPAL_USER_TOP_UP_SUBSCRIPTION_BEGIN:
      case FETCH_USER_TRANSACTIONS_BEGIN:
      case FETCH_TRANSACTION_DETAILS_BEGIN:
      case FETCH_WINE_COLLECTION_BEGIN:
      case FETCH_WINES_BEGIN:
      case FETCH_WINE_DETAILS_BEGIN:
      case FETCH_CLIENT_BALANCE_SIMULATION_BEGIN:
      case FETCH_OUTLOOK_SIMULATION_BEGIN:
      case FETCH_GEOGRAPHIC_ALLOCATION_BEGIN:
      case FETCH_WINE_PERFORMANCE_BEGIN:
      case GET_WEEKLY_PERFORMANCE_GUEST_BEGIN:
      case GET_WEEKLY_PERFORMANCE_CLIENT_BEGIN:
      case UPDATE_TRANSACTIONS_STATUS_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case FETCH_LATEST_TRANSACTIONS_BEGIN:
        draft.isLatestTransactionsLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case STRIPE_USER_TOP_UP_FAILED:
      case STRIPE_USER_TOP_UP_SUBSCRIPTION_FAILED:
      case PAYPAL_USER_TOP_UP_FAILED:
      case PAYPAL_USER_TOP_UP_SUBSCRIPTION_FAILED:
      case FETCH_USER_TRANSACTIONS_FAILED:
      case FETCH_TRANSACTION_DETAILS_FAILED:
      case FETCH_WINE_COLLECTION_FAILED:
      case FETCH_WINES_FAILED:
      case FETCH_WINE_DETAILS_FAILED:
      case FETCH_CLIENT_BALANCE_SIMULATION_FAILED:
      case FETCH_OUTLOOK_SIMULATION_FAILED:
      case FETCH_GEOGRAPHIC_ALLOCATION_FAILED:
      case FETCH_WINE_PERFORMANCE_FAILED:
      case GET_WEEKLY_PERFORMANCE_GUEST_FAILED:
      case GET_WEEKLY_PERFORMANCE_CLIENT_FAILED:
      case UPDATE_TRANSACTIONS_STATUS_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case FETCH_LATEST_TRANSACTIONS_FAILED:
        draft.isLatestTransactionsLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case STRIPE_USER_TOP_UP_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.redirectUrl = action.payload;
        break;
      case STRIPE_USER_TOP_UP_SUBSCRIPTION_SUCCESS:
      case PAYPAL_USER_TOP_UP_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.redirectUrl = action.payload.url;
        break;
      case PAYPAL_USER_TOP_UP_SUBSCRIPTION_SUCCESS:
        StorageService.setKeyValue("agreementId", action?.payload?.agreementId);
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.redirectUrl = action.payload.url;
        break;
      case FETCH_USER_TRANSACTIONS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.transactions = action.payload;
        break;
      case FETCH_TRANSACTION_DETAILS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.transactionDetails = action.payload;
        break;
      case FETCH_LATEST_TRANSACTIONS_SUCCESS:
        draft.isLatestTransactionsLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.latestTransactions = action.payload;
        break;
      case FETCH_WINE_COLLECTION_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.wineCollectionData = action.payload;
        break;
      case FETCH_WINES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.wines = action.payload;
        break;
      case FETCH_WINE_DETAILS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.wineDetails = action.payload;
        break;
      case FETCH_CLIENT_BALANCE_SIMULATION_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.balanceSimulation = action.payload
          ?.map((item) => item)
          .filter((item) => {
            if (item && item.balance !== 0) return item;
          });
        break;
      case FETCH_OUTLOOK_SIMULATION_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.outlookSimulation = action.payload;
        break;
      case FETCH_GEOGRAPHIC_ALLOCATION_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.geographicAllocation = action.payload;
        break;
      case FETCH_WINE_PERFORMANCE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.winePerformance = null;
        draft.winePerformance = action.payload;
        break;
      case GET_WEEKLY_PERFORMANCE_GUEST_SUCCESS:
      case GET_WEEKLY_PERFORMANCE_CLIENT_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.weeklyPerformance = null;
        draft.weeklyPerformance = action.payload;
        break;
      case UPDATE_TRANSACTIONS_STATUS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case CLEAR_LOGGED_IN_GRAPH_DATA:
        draft.balanceSimulation = null;
        draft.weeklyPerformance = null;
        break;
      default:
        break;
    }
  });

export default InvestmentsReducer;
