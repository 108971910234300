import { useTranslation } from "react-i18next";

import styles from "./ConfirmBySms.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import { useEffect, useState } from "react";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import ButtonComponent from "components/Button/Button.component";
import StorageService from "services/storage.service";
import { confirmChangePhoneNumberBegin } from "pages/Profile/actions";
import { GlobalIState } from "types/RootState";
import { CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS } from "pages/Profile/constants";
import { setLeftPartItem, setRightPartItem } from "App/actions";
import PhoneNumberUpdated from "../PhoneNumberUpdated/PhoneNumberUpdated.page";

interface Props {}

export default function ConfirmBySms(props: Props) {
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [receivedCode, setReceivedCode] = useState("");
  const phoneCookieStored = StorageService.getDataByKey("userPhone");

  const handleSubmit = () => {
    let data = {
      country_phone: phoneCookieStored?.country_phone,
      phone_number: phoneCookieStored?.phone_number,
      token: receivedCode,
    };

    dispatch(confirmChangePhoneNumberBegin(data));
  };

  useEffect(() => {
    if (actionName === CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS) {
      if (isMobile) {
        dispatch(setLeftPartItem(<PhoneNumberUpdated />));
      } else {
        dispatch(setRightPartItem(<PhoneNumberUpdated />));
      }
    }
  }, [actionName]);

  return (
    <div className={styles.confirmByEmailWrapper}>
      <h1>{translations("label.confirm_by_sms_code")}</h1>
      <span className={styles.linkSentLabel}>
        {translations("label.confirm_by_sms_code.hint")}{" "}
        {phoneCookieStored?.country_phone +
          " " +
          phoneCookieStored?.phone_number}
      </span>

      <div className={styles.codeInput}>
        <CustomInputComponent
          type="text"
          name="receivedCode"
          label={translations("label.sms_code")}
          value={receivedCode}
          onChange={(e) => setReceivedCode(e.target.value)}
        />
        <ButtonComponent
          label={translations("button.submit")}
          class={"btn btn-primary"}
          disabled={receivedCode.length === 0}
          handleClick={() => handleSubmit()}
        />
      </div>

      <span className={styles.hintText}>
        {translations("label.resend_code.hint")}
      </span>

      <div
        className={styles.resendEmail}
        onClick={() => console.log("Resend code")}
      >
        <span>{translations("label.resend_code")}</span>
      </div>
    </div>
  );
}
