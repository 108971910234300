import { useTranslation } from "react-i18next";
import styles from "./InvitedFriendEventConfirm.module.scss";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import {
  getDefaultColour,
  hasEightCharacters,
  hasLowercaseLetter,
  hasNumbers,
  hasSymbols,
  hasUppercaseLetter,
} from "utils/utils";
import { useEffect, useState } from "react";
import PasswordInput from "components/PasswordInput/PasswordInput.component";
import ButtonComponent from "components/Button/Button.component";
import { openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import { acceptEventInvitationAndRegisterBegin } from "pages/Authentication/actions";
import { AcceptEventInvitationAndRegisterAsUserInterface } from "types/pages/auth.page.type";

import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import StorageService from "services/storage.service";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import AccountSetUp from "pages/Authentication/Signup/AccountSetUp/AccountSetUp.page";

export default function InvitedFriendEventConfirm() {
  const {
    defaultColour,
    termsAndConditions,
    privacyPolicy,
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);

  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const [selectedSalutation, setselectedSalutation] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const acceptedEventInvite = StorageService.getDataByKey(
    "acceptedEventInvite"
  );
  const expiredAcceptedEventInvite = () => {
    return StorageService.getDataByKey("expiredAcceptedEventInvite");
  };
  let firstNameParam = searchParams.get("first_name");
  let lastNameParam = searchParams.get("last_name");
  let emailParam = searchParams.get("email");
  let tokenParam = searchParams.get("token");
  const [firstName, setFirstName] = useState<string>(firstNameParam);
  const [lastName, setLastName] = useState<string>(lastNameParam);
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const disableButton =
    !hasUppercaseLetter(password) ||
    !hasLowercaseLetter(password) ||
    !hasNumbers(password) ||
    !hasSymbols(password) ||
    !hasEightCharacters(password);

  const handleTermsAndConditionsPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className={styles.shownText}
            dangerouslySetInnerHTML={{
              __html: termsAndConditions?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handlePrivacyPolicyPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className="shownText"
            dangerouslySetInnerHTML={{
              __html: privacyPolicy?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handleSubmit = () => {
    let data: AcceptEventInvitationAndRegisterAsUserInterface = {
      salutation: selectedSalutation,
      first_name: firstName,
      last_name: lastName,
      email: emailParam.replace(" ", "+"),
      password: password,
    };
    dispatch(acceptEventInvitationAndRegisterBegin(tokenParam, data));
  };

  useEffect(() => {
    if (expiredAcceptedEventInvite() === true) {
      setIsExpired(true);
    }
  }, [expiredAcceptedEventInvite()]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        {acceptedEventInvite ? (
          <AccountSetUp
            isAcceptEventInvite={true}
            first_name={firstName}
            last_name={lastName}
          />
        ) : isExpired ? (
          <AccountSetUp
            isTokenForEventExpired={true}
            first_name={firstName}
            last_name={lastName}
          />
        ) : (
          <>
            <h1>{translations("label.friend_joining_event.title")}</h1>
            <h3>{translations("label.friend_joining_event.subtitle")}</h3>

            <div className={styles.fields}>
              <div className={styles.salutations}>
                <div
                  className={styles.salutationItem}
                  style={
                    selectedSalutation === "Mr."
                      ? {
                          background: `${getDefaultColour(defaultColour)}`,
                          color: "#fff",
                        }
                      : {}
                  }
                  onClick={() => setselectedSalutation("Mr.")}
                >
                  {translations("label.mr")}
                </div>
                <div
                  className={styles.salutationItem}
                  style={
                    selectedSalutation === "Mrs."
                      ? {
                          background: `${getDefaultColour(defaultColour)}`,
                          color: "#fff",
                        }
                      : {}
                  }
                  onClick={() => setselectedSalutation("Mrs.")}
                >
                  {translations("label.mrs")}
                </div>
                <div
                  className={styles.salutationItem}
                  style={
                    selectedSalutation === "Ms."
                      ? {
                          background: `${getDefaultColour(defaultColour)}`,
                          color: "#fff",
                        }
                      : {}
                  }
                  onClick={() => setselectedSalutation("Ms.")}
                >
                  {translations("label.ms")}
                </div>
              </div>
              <div className={styles.shownData}>
                <div className={styles.oneCell}>
                  <CustomInputComponent
                    type="text"
                    name="first_name"
                    label={translations("label.first_name")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className={styles.oneCell}>
                  <CustomInputComponent
                    type="text"
                    name="last_name"
                    label={translations("label.last_name")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
                <div className={styles.oneCell}>
                  <div>
                    <span className={styles.label}>
                      {translations("label.email")}
                    </span>
                  </div>
                  <div className={styles.emailField}>
                    {emailParam.replace(" ", "+")}
                  </div>
                </div>
              </div>
              <div className={styles.passwordField}>
                <PasswordInput
                  name="password"
                  label={translations("label.password")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className={styles.bottomActions}>
                <ButtonComponent
                  label={translations("button.submit")}
                  class={"btn btn-primary"}
                  isLoading={isLoading}
                  disabled={disableButton}
                  handleClick={() => handleSubmit()}
                />
                <div className={styles.agreementHint}>
                  <span>
                    {translations("label.by_signing,you_agree_to_the")}
                  </span>
                  <span
                    className={styles.link}
                    style={
                      {
                        "--color": getDefaultColour(defaultColour),
                      } as React.CSSProperties
                    }
                    onClick={() => handleTermsAndConditionsPopup()}
                  >
                    {translations("label.terms_of_use")}
                  </span>
                  <span>{translations("label.and")}</span>
                  <span
                    className={styles.link}
                    style={
                      {
                        "--color": getDefaultColour(defaultColour),
                      } as React.CSSProperties
                    }
                    onClick={() => handlePrivacyPolicyPopup()}
                  >
                    {translations("label.privacy_policy")}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </LoaderWrapper>
  );
}
