import { useNavigate, useSearchParams } from "react-router-dom";
import styles from "./AfterPayment.module.scss";
import { RoundedSuccessIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import { getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useEffect } from "react";

import i18n from "localizations/config";
import { getClientBegin } from "pages/Profile/actions";
import { logInByToken } from "pages/Authentication/actions";
import { getGuestLastOrderBegin } from "../actions";

interface Props {}

function AfterPayment(props: Props) {
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const { lastOrder } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenParam = searchParams.get("token");
  const guestEmailParam = searchParams.get("guestEmail");
  const paymentStatusParam = searchParams.get("status");
  const isTakeOverParam = searchParams.get("takeover");

  const orderStored: any = lastOrder;

  const handleSubmit = () => {
    if (paymentStatusParam === "success") {
      navigate(
        tokenParam
          ? `/order/summary?token=${tokenParam}`
          : guestEmailParam
          ? `/order/summary?guestEmail=${decodeURI(
              guestEmailParam.replace(" ", "+")
            )}`
          : "/order/summary"
      );
    } else {
      navigate("/purchasing");
    }
  };

  // useEffect(() => {
  //   if (order_data) {
  //     if (typeParam) {
  //       let data: ISendOrderInvoiceAsGuest = {
  //         first_name: guestUserInfoCookie?.firstName,
  //         last_name: guestUserInfoCookie?.lastName,
  //         email: guestUserInfoCookie?.email,
  //         da_street: guestUserDeliveryInfoCookie?.da_street,
  //         da_city: guestUserDeliveryInfoCookie?.da_city,
  //         da_country:
  //           selectedCountry ?? guestUserDeliveryInfoCookie?.da_country,
  //         da_zip: guestUserDeliveryInfoCookie?.da_zip,
  //         order_id: order_data?.id,
  //       };
  //       dispatch(sendGuestOrderInvoiceBegin(data));
  //     } else dispatch(sendOrderInvoiceBegin(order_data?.id));
  //   }
  // }, [order_data]);

  useEffect(() => {
    if (tokenParam) {
      dispatch(logInByToken(tokenParam));
    }
  }, [tokenParam]);

  useEffect(() => {
    if (isLoggedIn && !client) {
      dispatch(getClientBegin());
    }
  }, [isLoggedIn, client]);

  useEffect(() => {
    if (guestEmailParam) {
      dispatch(getGuestLastOrderBegin(decodeURI(guestEmailParam)));
    }
  }, [guestEmailParam]);

  useEffect(() => {
    if (orderStored?.guest_user_id) {
      i18n.changeLanguage(orderStored?.guestUser?.language?.toLowerCase());
      localStorage.setItem("lang", orderStored?.guestUser?.language);
    }
  }, [orderStored]);

  return (
    <div
      className={styles.cardWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {paymentStatusParam === "success" ? (
        <>
          <div>
            <h1>
              {translations(
                isTakeOverParam === "true"
                  ? "label.order_takeover_success"
                  : "label.payment_success.title"
              )}
            </h1>
          </div>

          <div className={styles.icon}>
            <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
          </div>
        </>
      ) : (
        <>
          <div>
            <h1>{translations("error.ooops_something_went_wrong")}</h1>
          </div>

          <div className={styles.coloredLabel}>
            <span>
              {translations("error.this_payment_couldnt_be_processed")}
            </span>
            <br />
            <span>
              {translations("error.please_try_again_or_another_payment_method")}
            </span>
          </div>
        </>
      )}

      <div className={styles.button}>
        <ButtonComponent
          label={translations(
            paymentStatusParam === "success" ? "button.next" : "button.close"
          )}
          class={"btn btn-primary"}
          handleClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}

export default AfterPayment;
