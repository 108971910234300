import { useTranslation } from "react-i18next";

import styles from "./ChangeLoginCredentials.module.scss";
import { ArrowRightTableIcon } from "assets/icons/icons";

import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { getDefaultColour } from "utils/utils";
import ChangeEmail from "./ChangeEmail/ChangeEmail.page";
import ChangePhoneNumber from "./ChangePhoneNumber/ChangePhoneNumber.page";
import { setLeftPartItem, setRightPartItem } from "App/actions";
import { sendVerifyEmailBegin } from "pages/Authentication/actions";
import { useEffect } from "react";
import { SEND_VERIFY_EMAIL_SUCCESS } from "pages/Authentication/constants";
import ConfirmEmail from "./ConfirmEmail/ConfirmEmail.page";
import { useNavigate } from "react-router-dom";

interface Props {}

export default function ChangeLoginCredentials(props: Props) {
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const navigate = useNavigate();

  const changeLoginCredentialsOperations = [
    // {
    //   order: 1,
    //   name: "label.change_password",
    //   onClick: () => {
    //     if (isMobile) dispatch(setLeftPartItem(<ChangePassword />));
    //     else {
    //       dispatch(setRightPartItem(<ChangePassword />));
    //     }
    //   },
    // },
    {
      order: 1,
      name: "label.confirm_email",
      onClick: () => dispatch(sendVerifyEmailBegin()),
    },
    {
      order: 2,
      name: "label.change_email",
      onClick: () => {
        if (isMobile) dispatch(setLeftPartItem(<ChangeEmail />));
        else {
          dispatch(setRightPartItem(<ChangeEmail />));
        }
      },
    },
    {
      order: 3,
      name: "label.change_phone_number",
      onClick: () => {
        if (isMobile) dispatch(setLeftPartItem(<ChangePhoneNumber />));
        else {
          dispatch(setRightPartItem(<ChangePhoneNumber />));
        }
      },
    },
    {
      order: 4,
      name: "Gmail",
      onClick: () => console.log(),
    },
  ];

  const loginCredentials = () => {
    return changeLoginCredentialsOperations.filter((item) => {
      // if (client?.google_user && item.order === 1) return false;
      if (
        (client?.isVerified && item.order === 1) ||
        (client?.login_method === "phone" && item.order === 1)
      )
        return false;
      if (!client?.phone && item.order === 3) return false;
      if (
        (!client?.email && item.order === 2) ||
        (client?.google_user && item.order === 2)
      )
        return false;

      if (!client?.google_user && item.order === 4) return false;

      return true;
    });
  };

  useEffect(() => {
    if (actionName === SEND_VERIFY_EMAIL_SUCCESS) {
      if (isMobile) navigate("/confirm-email");
      else {
        dispatch(setRightPartItem(<ConfirmEmail />));
      }
    }
  }, [actionName]);

  return (
    <div className={styles.changeLoginCredentialsWrapper}>
      <h1>{translations("label.change_login_credentials")}</h1>
      <div className={styles.chlCContent}>
        {loginCredentials().map((item, index) => {
          return (
            <div
              key={item.order}
              className={styles.wrap}
              style={{
                borderBottom: `2px solid ${getDefaultColour(defaultColour)}`,
              }}
              onClick={item.onClick}
            >
              <div className={styles.item}>
                <div className={styles.itemOrder}>
                  <span style={{ color: getDefaultColour(defaultColour) }}>
                    {index + 1}
                  </span>
                </div>
                <div>
                  <span className={styles.itemName}>
                    {translations(item.name)}
                  </span>
                </div>
              </div>
              <div>
                {item?.order !== 4 ? (
                  <ArrowRightTableIcon
                    color={getDefaultColour(defaultColour)}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
