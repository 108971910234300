import styles from "./WineDetails.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import WineDetailsChart from "../WineDetailsChart/WineDetailsChart.page";
import { getCurrencyStr, getDefaultColour } from "utils/utils";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useSearchParams } from "react-router-dom";
import { fetchWineDetailsBegin, fetchWinePerformanceBegin } from "../actions";
import { useEffect, useState } from "react";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { scrollToTop } from "App/actions";

function WineDetails() {
  const {
    action: { isLoading },
    wineDetails,
    winePerformance,
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [lastItem, setLastItem] = useState<any>(null);

  const [searchParams] = useSearchParams();
  const idParam = searchParams.get("id");
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (idParam) {
      dispatch(fetchWineDetailsBegin(idParam));
      dispatch(fetchWinePerformanceBegin(idParam));
    }
  }, [idParam]);

  useEffect(() => {
    if (isMobile) {
      dispatch(scrollToTop());
    }
  }, []);

  useEffect(() => {
    if (winePerformance) {
      setLastItem(winePerformance[winePerformance.length - 1]);
    }
  }, [winePerformance]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.detailsWrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <h2>{wineDetails?.name}</h2>
          <span>{wineDetails?.year}</span>
          <span>{wineDetails?.unit_size}</span>
        </div>
        <div
          className={styles.detailsWrapper}
          style={
            {
              "--color": getDefaultColour(defaultColour),
            } as React.CSSProperties
          }
        >
          <div className={styles.chart}>
            <WineDetailsChart />
          </div>

          <div className={styles.marketPriceContent}>
            <div>
              <span>{translations("label.market_price")}</span>
            </div>
            <div className={styles.mainInfo}>
              <div>
                <div className={styles.title}>
                  <span>{translations("label.bottle_price")}</span>
                </div>
                <div>
                  <span>
                    {getCurrencyStr(client?.currency)} {lastItem?.market_price}
                  </span>
                </div>
              </div>
              <div>
                <div className={styles.title}>
                  <span>{translations("label.profit_loss")}</span>
                </div>
                <div>
                  <span>{lastItem?.market_profit_loss} %</span>
                </div>
              </div>
            </div>
            <div className={styles.marketPriceHint}>
              <span>{translations("label.market_price_hint")}</span>
            </div>
          </div>

          <div className={styles.textInfo}>
            <div className={styles.header}>
              <h3>{translations("label.about_the_winery")}</h3>
              <span>{wineDetails?.subtitle}</span>
            </div>

            <div className={styles.historyInfo}>
              <span>{wineDetails?.history}</span>
              <span className={styles.verticalText}>
                {translations("label.history")}
              </span>
            </div>

            <div className={styles.appearanceInfo}>
              <span>{wineDetails?.appearance}</span>
              <span className={styles.verticalText}>
                {translations("label.style_and_terroir")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default WineDetails;
