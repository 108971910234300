import { useEffect, useState } from "react";
import { formatPrice, getDefaultColour } from "utils/utils";
import styles from "./CelebrityTakeoverTransactions.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import VinfinityLogo from "assets/images/VinfinityLogo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getCelebrityTakeoverTransactionsBegin } from "App/actions";
import { Order } from "types/reducers/global.reducer.type";
import dayjs from "dayjs";

interface Column {
  id: "fullname" | "email" | "order_date" | "totalAmountTakenOver";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number | string | Date) => string;
}

function CelebrityTakeoverTransactions() {
  const { celebrityTakeoverTransactions } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState<Order[]>([]);
  const [searchParams] = useSearchParams();
  const idParam = searchParams.get("id");

  const columns: readonly Column[] = [
    { id: "fullname", label: "Full name", minWidth: 170 },
    { id: "email", label: "E-Mail", minWidth: 100 },
    {
      id: "order_date",
      label: "Order Date",
      minWidth: 170,
      align: "right",
    },
    {
      id: "totalAmountTakenOver",
      label: "Total",
      minWidth: 100,
      align: "right",
    },
  ];

  useEffect(() => {
    if (celebrityTakeoverTransactions?.orders?.length !== 0)
      setRows(celebrityTakeoverTransactions?.orders);
  }, [celebrityTakeoverTransactions]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (idParam) {
      dispatch(getCelebrityTakeoverTransactionsBegin(idParam));
    }
  }, [idParam, dispatch]);

  return (
    <div
      className={styles.contentWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header} onClick={() => navigate("/")}>
        <img src={VinfinityLogo} alt="Vinfinity Logo" />
      </div>
      <div className={styles.table}>
        <h3>{celebrityTakeoverTransactions?.take_over_name}</h3>
        <h1>
          Celebrity takeover{" "}
          {dayjs(celebrityTakeoverTransactions?.start_date).format(
            "DD/MM/YYYY"
          )}{" "}
          -{" "}
          {dayjs(celebrityTakeoverTransactions?.end_date).format("DD/MM/YYYY")}
        </h1>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          let value = row[column.id];

                          if (column.id === "order_date" && value) {
                            value = dayjs(value).format("DD/MM/YYYY");
                          }

                          if (column.id === "totalAmountTakenOver") {
                            value = `CHF ${formatPrice(
                              Number(value).toFixed(2).toString()
                            )}`;
                          }

                          const displayValue =
                            typeof value === "string" ||
                            typeof value === "number"
                              ? value.toString()
                              : "";

                          return (
                            <TableCell key={column.id} align={column.align}>
                              {displayValue}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <div className={styles.total}>
          <span>{translations("label.total")}:</span>
          <h1>
            CHF{" "}
            {formatPrice(
              Number(celebrityTakeoverTransactions?.totalAmountTakenOver)
                .toFixed(2)
                .toString()
            )}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default CelebrityTakeoverTransactions;
