import React, { ReactNode, memo } from "react";
import styles from "./Select.module.scss";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";

interface Options {
  value: any;
  label: string;
}

interface Props {
  value?: any;
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  options: Options[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  style?: object;
}

const Select: React.FC<Props> = ({ options, style, error, type, ...props }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();

  return (
    <div style={style}>
      {error?.length > 0 ? <span className="errorMessage">{error}</span> : null}
      {props.className !== "secondSelectStyle" ? (
        <div className={styles.inputLabel}>
          <label htmlFor={props.name}>{props.label}</label>
        </div>
      ) : null}
      <select
        name={props.name}
        className={
          type === "secondSelectStyle" ? styles.secondSelect : styles.mainSelect
        }
        value={props.value ? props.value : 0}
        style={{
          borderLeft: `3px solid ${getDefaultColour(defaultColour)}`,
          color: `${getDefaultColour(defaultColour)}`,
        }}
        {...props}
      >
        <option value="0" disabled>
          {/* {translations("label.please_select")} */}
          {props.className === "secondSelectStyle"
            ? translations("label.country")
            : null}
        </option>

        {options?.map((item, index) => {
          return (
            <option key={index} value={item.value ? item.value : null}>
              {item.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default memo(Select);
