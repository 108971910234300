import { InfoModalCloseIcon } from "assets/icons/icons";
import styles from "./ProductFilters.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import {
  formatPrice,
  getFilterOptions,
  getFilterValue,
  updateFiltersArray,
} from "utils/utils";
import { useEffect, useState } from "react";
import { closeModal, openToaster } from "App/actions";
import { useTranslation } from "react-i18next";
import Slider from "@mui/material/Slider";
import MultiSelectWithSearchDropdown from "components/MultiSelectWithSearchDropdown/MultiSelectWithSearchDropdown.component";
import {
  clearFilters,
  fetchProductsBegin,
  fetchProductsCountBegin,
  setFilters,
} from "../actions";
import StorageService from "services/storage.service";
import { useSearchParams } from "react-router-dom";

const minDistance = 10;

function ProductFilters() {
  const { client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    filters,
    filterOptions,
    regionFilterOptions,
    appellationsFilterOptions,
    filteredProductsCount,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({ typeFilterValue: "" });
  const [priceValue, setPriceValue] = useState<number[]>([0, 550]);
  const [showRegionFilter, setShowRegionFilter] = useState(false);
  const [showAppellationFilter, setShowAppellationFilter] = useState(false);
  const countryFilter = getFilterValue("Country", filters);
  const regionFilter = getFilterValue("Region", filters);
  const priceSliderCookie = StorageService.getDataByKey("priceSlider");
  const searchValueCookie = StorageService.getDataByKey("search");
  const [searchParams] = useSearchParams();
  const streamParam = searchParams.get("stream");
  const typeFilterOptions = getFilterOptions(filterOptions?.types, "name");
  const countryFilterOptions = getFilterOptions(
    filterOptions?.countries,
    "country_name"
  );
  const grapesFilterOptions = getFilterOptions(
    filterOptions?.grapes,
    "varietal_name"
  );
  const producersFilterOptions = getFilterOptions(
    filterOptions?.producers,
    "producer_name"
  );
  const vintageFilterOptions = getFilterOptions(
    filterOptions?.vintages,
    "vintage_name"
  );
  const pairingFilterOptions = getFilterOptions(
    filterOptions?.pairing,
    "pairing"
  );

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  const handleChange1 = (
    event: Event,
    newValue: number | number[],
    activeThumb: number
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPriceValue([
        Math.min(newValue[0], priceValue[1] - minDistance),
        priceValue[1],
      ]);
      StorageService.setKeyValue("priceSlider", [
        Math.min(newValue[0], priceValue[1] - minDistance),
        priceValue[1],
      ]);
    } else {
      setPriceValue([
        priceValue[0],
        Math.max(newValue[1], priceValue[0] + minDistance),
      ]);
      StorageService.setKeyValue("priceSlider", [
        priceValue[0],
        Math.max(newValue[1], priceValue[0] + minDistance),
      ]);
    }
  };

  const handleModalClose = () => {
    dispatch(closeModal());
  };

  const handleSeeResults = () => {
    if (priceValue[1] <= priceValue[0]) {
      dispatch(
        openToaster("ERROR", translations("error.small_highest_price_value"))
      );
      return;
    }
    dispatch(
      fetchProductsBegin(
        streamParam,
        1,
        searchValueCookie ? searchValueCookie : "",
        updateFiltersArray(filters.filter((item) => item.value.length >= 1)),
        getLanguageToken()
      )
    );
    dispatch(closeModal());
  };

  const handleClearFilters = () => {
    dispatch(clearFilters());
    StorageService.deleteCookie("priceSlider");
    StorageService.setKeyValue("clear_filters", true);
    setPriceValue([0, 550]);
  };

  const handlePriceInputsChange = (order, value) => {
    if (order === 0) {
      setPriceValue([Number(value), priceValue[1]]);

      dispatch(
        setFilters({
          filter: "Price",
          value: [value.toString(), priceValue[1].toString()],
        })
      );
    } else {
      setPriceValue([priceValue[0], Number(value)]);
      dispatch(
        setFilters({
          filter: "Price",
          value: [priceValue[0].toString(), value.toString()],
        })
      );
    }
  };

  useEffect(() => {
    if (countryFilter && countryFilter?.length !== 0) {
      setShowRegionFilter(true);
    } else setShowRegionFilter(false);

    if (regionFilter && regionFilter?.length !== 0) {
      setShowAppellationFilter(true);
    } else setShowAppellationFilter(false);

    dispatch(
      fetchProductsCountBegin(
        searchValueCookie ? searchValueCookie : "",
        filters?.filter((item) => item?.value?.length >= 1)?.length === 0
          ? [{}]
          : updateFiltersArray(filters.filter((item) => item.value.length >= 1))
      )
    );
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (priceSliderCookie) {
      setPriceValue(priceSliderCookie);
    }
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.filtersWrapper}>
        <div>
          <div className={styles.header}>
            {/* <div></div> */}
            <div className={styles.priceFields}>
              <span className={styles.ttl}>{translations("label.price")}</span>
              <div className={styles.value}>
                <input
                  placeholder="CHF"
                  value={formatPrice(priceValue[0].toString())}
                  onChange={(e) => handlePriceInputsChange(0, e.target.value)}
                />
              </div>
              <div className={styles.priceSlider}>
                <Slider
                  getAriaLabel={() => "Minimum distance"}
                  value={priceValue}
                  onChange={handleChange1}
                  valueLabelDisplay="auto"
                  min={0}
                  max={550}
                  color="primary"
                  disableSwap
                  onChangeCommitted={() => {
                    dispatch(
                      setFilters({
                        filter: "Price",
                        value: [
                          priceValue[0].toString(),
                          priceValue[1].toString(),
                        ],
                      })
                    );
                  }}
                  sx={{
                    color: "#000",
                  }}
                />
              </div>
              <div className={styles.value}>
                <input
                  placeholder="CHF"
                  value={formatPrice(priceValue[1].toString())}
                  style={
                    priceValue[1] <= priceValue[0] ? { borderColor: "red" } : {}
                  }
                  onChange={(e) => handlePriceInputsChange(1, e.target.value)}
                />
              </div>
            </div>
            {/* <div className={styles.searchInput}>
              <SearchIcon color={getDefaultColour(defaultColour)} />
              <input
                type="text"
                placeholder="Search"
                value={state.searchValue}
                onChange={(e) =>
                  setState({ ...state, searchValue: e.target.value })
                }
              />
            </div> */}

            <div className={styles.rightSideButtons}>
              {/* <div
                className={styles.clearFiltersBtn}
                onClick={() => handleClearFilters()}
              >
                <span>{translations("label.clear_filters")}</span>
              </div> */}
              <div className="pointer" onClick={() => handleModalClose()}>
                <InfoModalCloseIcon />
              </div>
            </div>
          </div>
          {/* <div className={styles.priceFields}>
            <span className={styles.ttl}>{translations("label.price")}</span>
            <div className={styles.value}>
              <input
                placeholder="CHF"
                value={formatPrice(priceValue[0].toString())}
                onChange={(e) =>
                  setPriceValue([Number(e.target.value), priceValue[1]])
                }
              />
            </div>
            <div className={styles.priceSlider}>
              <Slider
                getAriaLabel={() => "Minimum distance"}
                value={priceValue}
                onChange={handleChange1}
                valueLabelDisplay="auto"
                min={0}
                max={550}
                color="primary"
                disableSwap
                onChangeCommitted={() => {
                  dispatch(
                    setFilters({
                      filter: "Price",
                      value: [
                        priceValue[0].toString(),
                        priceValue[1].toString(),
                      ],
                    })
                  );
                }}
                sx={{
                  color: "#000",
                }}
              />
            </div>
            <div className={styles.value}>
              <input
                placeholder="CHF"
                value={formatPrice(priceValue[1].toString())}
                onChange={(e) =>
                  setPriceValue([priceValue[0], Number(e.target.value)])
                }
              />
            </div>
          </div> */}
        </div>

        <div className={styles.filters}>
          <div className={styles.item}>
            <span className={styles.title}>{translations("label.type")}</span>
            <MultiSelectWithSearchDropdown
              type="Type"
              label={translations("label.type")}
              options={typeFilterOptions}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.title}>
              {translations("label.country")}
            </span>
            <MultiSelectWithSearchDropdown
              type="Country"
              label={translations("label.country")}
              options={countryFilterOptions}
            />
          </div>
          {regionFilterOptions?.length !== 0 && showRegionFilter ? (
            <div className={styles.item}>
              <span className={styles.title}>
                {translations("label.region")}
              </span>
              <MultiSelectWithSearchDropdown
                type="Region"
                label={translations("label.region")}
                options={regionFilterOptions}
              />
            </div>
          ) : null}

          {appellationsFilterOptions?.length !== 0 &&
          showAppellationFilter &&
          showRegionFilter ? (
            <div className={styles.item}>
              <span className={styles.title}>
                {translations("label.appellation")}
              </span>
              <MultiSelectWithSearchDropdown
                type="Appellation"
                label={translations("label.appellation")}
                options={appellationsFilterOptions}
              />
            </div>
          ) : null}
          <div className={styles.item}>
            <span className={styles.title}>{translations("label.year")}</span>
            <MultiSelectWithSearchDropdown
              type="Vintage"
              label={translations("label.year")}
              options={vintageFilterOptions}
            />
          </div>
          {!showRegionFilter && !showAppellationFilter ? (
            <div className={styles.item}></div>
          ) : null}
          <div className={styles.item}>
            <span className={styles.title}>
              {translations("label.producer")}
            </span>
            <MultiSelectWithSearchDropdown
              type="Producer"
              label={translations("label.producer")}
              options={producersFilterOptions}
            />
          </div>
          <div className={styles.item}>
            <span className={styles.title}>{translations("label.grapes")}</span>
            <MultiSelectWithSearchDropdown
              type="Grapes"
              label={translations("label.grapes")}
              options={grapesFilterOptions}
            />
          </div>

          <div className={styles.item}>
            <span className={styles.title}>
              {translations("label.pairing")}
            </span>
            <MultiSelectWithSearchDropdown
              type="Pairing"
              label={translations("label.pairing")}
              options={pairingFilterOptions}
            />
          </div>
        </div>
        <div className={styles.bottomActions}>
          <div
            className={styles.clearFiltersBtn}
            onClick={() => handleClearFilters()}
          >
            <span>{translations("label.clear_filters")}</span>
          </div>
          <div className={styles.mainBtn} onClick={() => handleSeeResults()}>
            <span>
              {translations("label.see_results")} ({filteredProductsCount})
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductFilters;
