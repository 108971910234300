import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./MobileProductDetails.module.scss";
import {
  AcclaimsIcon,
  BackButtonIcon,
  ColorIcon,
  DataIcon,
  NoteText,
  PairingIcon,
  TastingIcon,
} from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getClientTastingLabel, getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useEffect } from "react";
import { fetchProductDetailsBegin } from "pages/Purchasing/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

function MobileProductDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const {
    action: { isLoading },
    orders,
    productTransactions,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const [searchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");
  const isMobile = useCheckMobileScreen();
  const productOrder = orders?.find(
    (item) => item?.product?.id === Number(productIdParam)
  );

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  useEffect(() => {
    if (productIdParam) {
      dispatch(fetchProductDetailsBegin(productIdParam, getLanguage()));
    }
  }, [productIdParam]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.content}>
          <div className={styles.card}>
            <div className={styles.imagePart}>
              <img src={productOrder?.product?.product_image} />
            </div>
            <div className={styles.rightPart}>
              <div>
                <div className={styles.title}>
                  <span>Producer</span>
                </div>
                <div>
                  {productOrder?.product?.wine_name?.length > 25
                    ? productOrder?.product?.wine_name?.substring(0, 25) + "..."
                    : productOrder?.product?.wine_name}{" "}
                </div>
              </div>
              <div>
                <div className={styles.title}>
                  <span>{translations("label.bottle_size")}</span>
                </div>
                <div className={styles.bottleSizeLabel}>
                  <span>{productOrder?.product?.bottle_size}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.producerLabel}>
            <span>
              {productDetails?.herotext?.length > 155
                ? productDetails?.herotext?.substring(0, 155) + "..."
                : productDetails?.herotext}
            </span>
          </div>
          <div className={styles.middleLabels}>
            <div>
              <span>{productDetails?.productProducer}</span>
            </div>
            <div>
              <span>{productDetails?.wine_name}</span>
            </div>
            <div>
              <span>{productDetails?.vintage}</span>
            </div>

            <div className={styles.priceLabel}>
              {productDetails?.old_price ? (
                <>
                  <span className={styles.discount}>
                    CHF {productDetails?.old_price}
                  </span>
                  <br />
                </>
              ) : null}
              <span>CHF {productDetails?.price}</span>
            </div>
          </div>

          <div className={styles.labels}>
            <div className={styles.noteText}>
              <span>{productDetails?.note}</span>
              <span className={styles.verticalText}>
                <NoteText color={getDefaultColour(defaultColour)} />
              </span>
            </div>

            <div className={styles.noteText}>
              <div className={styles.data}>
                <div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.country")}:
                    </span>
                    <span> {productDetails?.productCountry}</span>
                  </div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.region")}:
                    </span>
                    <span> {productDetails?.region}</span>
                  </div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.appellation")}:
                    </span>
                    <span> {productDetails?.appellation}</span>
                  </div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.best_before")}:
                    </span>
                    <span> {productDetails?.best_before}</span>
                  </div>
                </div>

                <div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.vintage")}:
                    </span>
                    <span> {productDetails?.vintage}</span>
                  </div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.type")}:
                    </span>
                    <span> {productDetails?.productType}</span>
                  </div>
                  <div>
                    <span className={styles.title}>
                      {translations("label.alcohol")}:
                    </span>
                    <span> {productDetails?.alcohol}</span>
                  </div>
                </div>
              </div>
              <div className={styles.varietal}>
                <span className={styles.title}>
                  {translations("label.grape_varietal")}:
                </span>
                <span> {productDetails?.productGrapes}</span>
              </div>

              <span className={styles.verticalText}>
                <DataIcon color={getDefaultColour(defaultColour)} />
              </span>
            </div>

            <div className={styles.noteText}>
              <span>{productDetails?.color}</span>
              <span className={styles.verticalText}>
                <ColorIcon color={getDefaultColour(defaultColour)} />
              </span>
            </div>

            <div className={styles.noteText}>
              <span>{productDetails?.tasting}</span>
              <span className={styles.verticalText}>
                <TastingIcon color={getDefaultColour(defaultColour)} />
              </span>
            </div>

            <div className={styles.noteText}>
              <span>{productDetails?.pairing}</span>
              <span className={styles.verticalText}>
                <PairingIcon color={getDefaultColour(defaultColour)} />
              </span>
            </div>

            {productDetails?.scores?.length !== 0 ? (
              <div className={styles.noteText}>
                {productDetails?.scores?.map((item) => {
                  return (
                    <div className={styles.score}>
                      <span>
                        {item.score_name} {item.score} / {item.max_score}
                      </span>
                    </div>
                  );
                })}
                <span className={styles.verticalText}>
                  <AcclaimsIcon color={getDefaultColour(defaultColour)} />
                </span>
              </div>
            ) : null}
          </div>
          <div className={styles.deliveryDetails}>
            <div>
              <span>{translations("label.delivery_label1")}</span>
            </div>
            <div></div>
            <div>
              <span>{translations("label.delivery_label3")}</span>
            </div>
          </div>
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default MobileProductDetails;
