import { useTranslation } from "react-i18next";

import styles from "./AccountClosure.module.scss";

import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { RoundedClockIcon, RoundedSuccessIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";
import { setLeftPartItem, setMiddlepartItem } from "App/actions";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { requestAccountClosure } from "../actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

interface Props {}

export default function AccountClosure(props: Props) {
  const {
    action: { isLoading },
    client,
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [activeStep, setActiveStep] = useState(
    client?.account_closure_requested
      ? "hasRequestedAccountClosure"
      : "accountClosure"
  );
  const isMobile = useCheckMobileScreen();

  const handleSubmit = () => {
    dispatch(requestAccountClosure());
    setActiveStep("hasRequestedAccountClosure");
  };

  const handleClose = () => {
    if (isMobile) {
      dispatch(setLeftPartItem("1"));
    } else {
      dispatch(setLeftPartItem("1"));
      dispatch(setMiddlepartItem(null));
    }
  };

  return (
    <div className={styles.accountClosureWrapper}>
      <h1 className={styles.title}>
        {translations(
          activeStep === "accountClosure"
            ? "label.account_closure.title"
            : "label.account_closure.on_success.title"
        )}
      </h1>

      {activeStep === "accountClosure" ? (
        <h2 className={styles.subTitle}>
          {translations("label.account_closure.subtitle")}
        </h2>
      ) : null}
      <span className={styles.hint}>
        {translations(
          activeStep === "accountClosure"
            ? "label.account_closure.hint1"
            : "label.account_closure.on_success.hint1"
        )}
      </span>

      <div className={styles.icon}>
        {activeStep === "accountClosure" ? (
          <RoundedClockIcon color={getDefaultColour(defaultColour)} />
        ) : (
          <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
        )}
      </div>

      <span className={styles.hint}>
        {translations(
          activeStep === "accountClosure"
            ? "label.account_closure.hint2"
            : "label.account_closure.on_success.hint2"
        )}
      </span>

      <div className={styles.button}>
        <ButtonComponent
          label={translations(
            activeStep === "accountClosure" ? "button.submit" : "button.close"
          )}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() =>
            activeStep === "accountClosure" ? handleSubmit() : handleClose()
          }
        />
      </div>
    </div>
  );
}
