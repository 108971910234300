import {
  ArrowDownIcon,
  DownArrow,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  InfoModalCloseIcon,
  RateTastingIcon,
  SecondRateWineEmoji,
  SelectedOptionArrow,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import styles from "./BlindTasting.module.scss";
import React, { useEffect, useState } from "react";
import { getDefaultColour, getTastingColorOptions } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import { openToaster } from "App/actions";
import {
  rateWineBegin,
  setProductTasteBegin,
} from "pages/PersonalLedger/actions";
import {
  RATE_WINE_SUCCESS,
  SET_PRODUCT_TASTE_SUCCESS,
} from "pages/PersonalLedger/constants";
import TasteWine from "pages/PersonalLedger/MyOrders/TasteWine/TasteWine.page";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { ITastings } from "types/reducers/personal-ledger.reducer.type";

function BlindTasting() {
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const {
    action: { actionName },
    orders,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { t: translations } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let productIdParam = searchParams.get("id");
  // const [selectedRating, setSelectedRating] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const order = orders?.filter(
    (item) => item.product?.id === Number(productIdParam)
  );
  const [step, setStep] = useState(1);
  const [selectedValues, setSelectedValues] = useState({
    body: "",
    aroma: "",
    flavor: "",
    acidity: "",
    sweetness: "",
  });
  // const tastingOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const handleClose = () => {
    navigate("/purchasing");
  };

  const handleNext = () => {
    if (isLoggedIn) {
      if (step === 1) {
        if (selectedOption !== "") {
          dispatch(
            rateWineBegin({
              product_id: productIdParam,
              rating: Number(selectedOption),
              isBlind: true,
            })
          );
        } else
          dispatch(openToaster(translations("label.please_select_one_option")));
      }

      if (step === 2) {
        const checkIfEmptyValues = Object.values(selectedValues).some(
          (value) => value === ""
        );

        let data: ITastings[] = Object.keys(selectedValues).map((key) => ({
          type: key,
          rating: Number(selectedValues[key]),
        }));

        if (checkIfEmptyValues) {
          dispatch(
            openToaster("ERROR", translations("error.tasting_mandatory_fields"))
          );
          return;
        }

        dispatch(
          setProductTasteBegin({
            product_id: Number(productIdParam),
            tastings: data,
            isBlind: true,
          })
        );
      }
    } else {
      navigate("/login", {
        state: {
          lastRoute: `/blind-tasting?id=${productIdParam}`,
        },
      });
    }
  };

  const rateOptions = [
    { id: "1", icon: <FirstRateWineEmoji /> },
    { id: "2", icon: <SecondRateWineEmoji /> },
    { id: "3", icon: <ThirdRateWineEmoji /> },
    { id: "4", icon: <FourthRateWineEmoji /> },
    { id: "5", icon: <FifthRateWineEmoji /> },
  ];

  const tasteOptions = [
    {
      id: 1,
      label: translations("label.taste.body"),
      leftLabel: translations("label.light"),
      rightLabel: translations("label.full"),
    },
    {
      id: 2,
      label: translations("label.taste.aroma"),
      leftLabel: translations("label.fruity"),
      rightLabel: translations("label.earthy"),
    },
    {
      id: 3,
      label: translations("label.taste.flavor"),
      leftLabel: translations("label.fresh"),
      rightLabel: translations("label.developed"),
    },
    {
      id: 4,
      label: translations("label.taste.acidity"),
      leftLabel: translations("label.low"),
      rightLabel: translations("label.high"),
    },
    {
      id: 5,
      label: translations("label.taste.sweetness"),
      leftLabel: translations("label.dry"),
      rightLabel: translations("label.sweet"),
    },
  ];

  const getItemName = (item) => {
    switch (item) {
      case 1:
        return "body";
      case 2:
        return "aroma";
      case 3:
        return "flavor";
      case 4:
        return "acidity";
      case 5:
        return "sweetness";
      default:
        return "body";
    }
  };

  const hasSpecificValue = (id, order) => {
    switch (id) {
      case 1:
        return selectedValues.body == order;
      case 2:
        return selectedValues.aroma == order;
      case 3:
        return selectedValues.flavor == order;
      case 4:
        return selectedValues.acidity == order;
      case 5:
        return selectedValues.sweetness == order;
    }
  };

  const handleTasteChange = (name, value) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [getItemName(name)]: value,
    }));
  };

  const handleChange = (id: string) => {
    setSelectedOption(id);
  };

  useEffect(() => {
    if (actionName === RATE_WINE_SUCCESS) {
      setStep(2);
    }
    if (actionName === SET_PRODUCT_TASTE_SUCCESS) {
      navigate("/feedback-submitted");
    }
  }, [actionName]);

  return (
    <div
      className={styles.playQuizGameBox}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div>
        <div className={styles.header}>
          <div onClick={() => handleClose()}>
            <InfoModalCloseIcon color="#fff" />
          </div>
        </div>

        <div className={styles.content}>
          <h1>{translations("label.your_taste")}</h1>
          <span className={styles.subtitle}>
            {translations("label.how_did_you_like_the_wine?")}
          </span>
        </div>

        {step === 1 ? (
          <>
            <div className={styles.rateTasting}>
              {rateOptions?.map((item) => (
                <div
                  key={item.id}
                  className={`${styles.option} ${
                    selectedOption === item.id ? styles.activeOption : ""
                  }`}
                  onClick={() => handleChange(item.id)}
                >
                  {selectedOption === item.id ? (
                    <div className={styles.icon}>
                      <SelectedOptionArrow />
                    </div>
                  ) : null}
                  {item?.icon}
                </div>
              ))}
            </div>
            <div className={styles.labels}>
              <div>
                <span>{translations("label.not_at_all")}</span>
              </div>
              <div>
                <span>{translations("label.fantastic")}</span>
              </div>
            </div>
          </>
        ) : step === 2 ? (
          <div className={styles.tasteOptionsWrapper}>
            {tasteOptions.map((item) => (
              <div key={item.id} className={styles.tasteItem}>
                <div className={styles.upperContainer}>
                  <span>{item.label}</span>
                </div>
                <div className={styles.colorOptions}>
                  <div className={styles.options}>
                    {getTastingColorOptions()?.map((col, idx) => (
                      <div
                        key={idx}
                        className={styles.color}
                        style={
                          isMobile
                            ? {
                                width: hasSpecificValue(item.id, col.order)
                                  ? 55
                                  : 45,
                                height: 45,
                                backgroundColor: col.color,
                                border: `2px solid ${
                                  hasSpecificValue(item.id, col.order)
                                    ? "#fff"
                                    : col.strokeColor
                                }`,
                              }
                            : {
                                width: hasSpecificValue(item.id, col.order)
                                  ? 60
                                  : 50,
                                height: 45,
                                backgroundColor: col.color,
                                border: `2px solid ${
                                  hasSpecificValue(item.id, col.order)
                                    ? "#fff"
                                    : col.strokeColor
                                }`,
                              }
                        }
                        onClick={() => handleTasteChange(item.id, col.order)}
                      >
                        {/* {hasSpecificValue(item.id, col.order) ? (
                          <div className={styles.arrowIcon}>
                            <DownArrow />
                          </div>
                        ) : null} */}
                      </div>
                    ))}
                  </div>
                </div>
                <div className={styles.bottomContainer}>
                  <div>{<span>{item.leftLabel}</span>}</div>
                  <div>
                    <span>{item.rightLabel}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {step === 2 ? (
        <div className={styles.scrollDownLabel}>
          <span>{translations("label.scroll_down")}</span>
          <ArrowDownIcon color="#fff" />
        </div>
      ) : null}
      <div className={styles.nextButton}>
        <ButtonComponent
          label={translations(isLoggedIn ? "button.next" : "label.login")}
          class={"btn btn-primary"}
          // disabled={selectedRating === ""}
          handleClick={() => handleNext()}
        />
      </div>
    </div>
  );
}

export default BlindTasting;
