import {
  AcceptEventInvitationAndRegisterAsUserInterface,
  LoginParams,
  RegisterUserParams,
  SetPersonalDetailsInterface,
} from "types/pages/auth.page.type";
import axiosInstance from "./api";
import StorageService from "./storage.service";
import { ISendCode } from "types/reducers/authentication.reducer.type";

export const getCountries = () => {
  return axiosInstance.get(`/countries`);
};

export const getCountriesNoAuth = () => {
  return axiosInstance.get(`/countries/all`);
};

export const logIn = (loginPayload: LoginParams) => {
  return axiosInstance.post(`/auth/login`, loginPayload);
};

export const registerUser = (registerUserPayload: RegisterUserParams) => {
  return axiosInstance.post(`/register`, registerUserPayload);
};

export const setPersonalDetails = (payload: SetPersonalDetailsInterface) => {
  return axiosInstance.put(`/clients/personal-details`, payload);
};

export const refreshToken = async () => {
  try {
    const response = await axiosInstance.post("/auth/refresh");

    StorageService.setKeyValue("shouldRefreshToken", false);
    const newAccessToken = response?.data?.access_token;
    let expiresInCookie = StorageService.getDataByKey("expiresIn");
    StorageService.setKeyValue("token", newAccessToken);
    // StorageService.setKeyValue("expiresIn", +expiresInCookie + 900000);
    StorageService.setKeyValue("expiresIn", +expiresInCookie + 54000000);
    return newAccessToken;
  } catch (error) {
    console.log("err", error);
  }
};

export const cancelSignup = () => {
  return axiosInstance.put(`/auth/cancel-signup`);
};

export const cancelSignupByEmailToken = (emailtoken: string) => {
  return axiosInstance.put(`/auth/cancel-signup-by-email-token/${emailtoken}`);
};

export const acceptEventInvitationAndRegisterUser = (
  token: string,
  payload: AcceptEventInvitationAndRegisterAsUserInterface
) => {
  return axiosInstance.put(`/events/accept-invitation?token=${token}`, payload);
};

export const sendVerifyEmail = () => {
  return axiosInstance.post("/clients/send-verify-email");
};

export const verifyEmail = (token: string) => {
  return axiosInstance.put(`/verify/email?token=${token}`);
};

export const requestResetPassword = (email: string) => {
  return axiosInstance.post(`/reset-password`, { user: email });
};

export const checkIfHasPasscode = (data: {
  email: string;
  sign_up: boolean;
  lang: string;
}) => {
  return axiosInstance.post("/auth/check/client", data);
};

export const sendSmsCode = (data: ISendCode) => {
  return axiosInstance.post("/auth/check/sms-code", data);
};
