import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./LoaderWrapper.module.scss";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

const LoaderWrapper = React.memo(({ isLoading, children }: any) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  if (isLoading)
    return (
      <div
        className={styles.spinner}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      ></div>
    );

  if (!children) return null;

  return typeof children === "function" ? children() : children;
});
export default LoaderWrapper;
