import axios from "axios";
import { refreshToken } from "./authentication.service";
import { logOut } from "pages/Authentication/actions";
import { store } from "store/store";
import StorageService from "./storage.service";
import properties from "properties";
import { clearCartItems } from "pages/Purchasing/actions";

const instance = axios.create({
  withCredentials: false,
  baseURL: properties.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const getShouldRefreshToken = () =>
  StorageService.getDataByKey("shouldRefreshToken");

instance.interceptors.request.use(function (config) {
  const getToken = () => StorageService.getDataByKey("token");

  config.headers.Authorization = getToken() ? `Bearer ${getToken()}` : "";
  return config;
});

// LOGOUT USER IF REQUEST RETURNS FORBIDDEN 403
instance.interceptors.response.use(
  (response) => {
    if (getShouldRefreshToken()) {
      refreshToken();
      StorageService.setKeyValue("shouldRefreshToken", false);
    }
    return response;
  },
  async (error) => {
    if (error.response.status === 403) {
      store.dispatch(logOut());
      store.dispatch(clearCartItems());
      StorageService.deleteCookie("token");
      StorageService.deleteCookie("expiresIn");
      window.location.reload();
      // throw error;
    }

    return Promise.reject(error);
  }
);

export default instance;
