import { getDefaultColour } from "utils/utils";
import styles from "./MembershipTimeline.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useEffect, useState } from "react";
import { getClientTimelineBegin } from "pages/Gamification/actions";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";

const MembershipTimeline = () => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { clientTimeline } = useAppSelector(
    (state: GlobalIState) => state.GamificationReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!clientTimeline) dispatch(getClientTimelineBegin());
    else {
      setData([
        ...clientTimeline?.filter((item) => item?.completed),
        {
          id: null,
          name: "...",
          created_at: "",
          updated_at: "",
          order: 1,
          completed: false,
        },
        {
          id: null,
          name: "...",
          created_at: "",
          updated_at: "",
          order: 1,
          completed: false,
        },
        {
          id: null,
          name: "...",
          created_at: "",
          updated_at: "",
          order: 1,
          completed: false,
        },
      ]);
    }
  }, [clientTimeline]);

  return (
    <div
      className={styles.timeline}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h2 className={styles.title}>
        {translations("label.membership_timeline_title")}
      </h2>
      {data?.map((item, index) => (
        <div key={index} className={styles.timelineRow}>
          {index % 2 === 0 ? (
            <>
              <div className={`${styles.timelineItem} ${styles.left}`}>
                <div className={styles.timelineContent}>
                  <span
                    style={{
                      opacity: item?.completed ? 1 : 0.3,
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              </div>
              <div
                className={styles.timelineDot}
                style={{
                  opacity: item?.completed ? 1 : 0.3,
                }}
              ></div>
              <div className={`${styles.timelineItem} ${styles.empty}`}></div>
            </>
          ) : (
            <>
              <div className={`${styles.timelineItem} ${styles.empty}`}></div>
              <div
                className={styles.timelineDot}
                style={{
                  opacity: item?.completed ? 1 : 0.3,
                }}
              ></div>
              <div className={`${styles.timelineItem} ${styles.right}`}>
                <div className={styles.timelineItem}>
                  <span
                    style={{
                      opacity: item?.completed ? 1 : 0.3,
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MembershipTimeline;
