import { useTranslation } from "react-i18next";

import styles from "./ChangePhoneNumber.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import { GlobalIState } from "types/RootState";
import SelectComponent from "components/Select/Select.component";
import {
  getCountriesSelectOptions,
  getDefaultColour,
  getOnlyNumbersFromString,
} from "utils/utils";
import { CHANGE_PHONE_NUMBER_SUCCESS } from "pages/Profile/constants";
import { setLeftPartItem, setRightPartItem } from "App/actions";
import ConfirmBySms from "./ConfirmBySms/ConfirmBySms.page";
import { changePhoneNumberBegin } from "pages/Profile/actions";
import StorageService from "services/storage.service";

interface Props {}

export default function ChangePhoneNumber(props: Props) {
  const {
    action: { actionName },
    client,
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [state, setState] = useState({
    oldPhoneNumber: client?.phone ? client?.phone : "",
    newPhoneNumber: "",
  });
  const [oldCountryCode, setOldCountryCode] = useState(
    client?.country_phone
      ? getOnlyNumbersFromString(client?.country_phone)?.toString()
      : ""
  );
  const [newCountryCode, setNewCountryCode] = useState("");
  const isMobile = useCheckMobileScreen();
  const countriesFiltered = countries?.filter((item) => item?.is_visible);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = () => {
    let data = {
      country_phone: "+" + newCountryCode,
      phone_number: state.newPhoneNumber,
      old_country_phone: "+" + oldCountryCode,
      old_phone_number: state.oldPhoneNumber,
    };

    StorageService.setKeyValue("userPhone", data);
    dispatch(changePhoneNumberBegin(data));
  };

  useEffect(() => {
    if (actionName === CHANGE_PHONE_NUMBER_SUCCESS) {
      if (isMobile) {
        dispatch(setLeftPartItem(<ConfirmBySms />));
      } else {
        dispatch(setRightPartItem(<ConfirmBySms />));
      }
    }
  }, [actionName]);

  return (
    <div className={styles.changePhoneNumberWrapper}>
      <h1>{translations("label.change_login_phone_number")}</h1>
      <div className={styles.fields}>
        <h2>{translations("label.enter_old_and_new_phone_number")}</h2>

        <div className={styles.singleInput}>
          <span
            style={
              {
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties
            }
          >
            {translations("label.phone_number.old")}
          </span>
          <div className={styles.phoneInputs}>
            <div
              className={styles.countryInput}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                  pointerEvents: "none",
                } as React.CSSProperties
              }
              data-chosen-value={
                oldCountryCode !== "" ? "+" + oldCountryCode : ""
              }
            >
              <SelectComponent
                name={"countryCode"}
                options={getCountriesSelectOptions(countriesFiltered)}
                value={oldCountryCode}
                onChange={(e) =>
                  setOldCountryCode(e.target.value.replace(/\D/g, ""))
                }
                label={translations("label.country")}
              />
            </div>
            <div className={styles.phoneInput}>
              <CustomInputComponent
                type="tel"
                name="oldPhoneNumber"
                label={translations("label.phone_number")}
                minLength={7}
                maxLength={12}
                value={state.oldPhoneNumber}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>

        <div className={styles.singleInput}>
          <span
            style={
              {
                "--color": getDefaultColour(defaultColour),
              } as React.CSSProperties
            }
          >
            {translations("label.phone_number.new")}
          </span>
          <div className={styles.phoneInputs}>
            <div
              className={styles.countryInput}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              data-chosen-value={
                newCountryCode !== "" ? "+" + newCountryCode : ""
              }
            >
              <SelectComponent
                name={"countryCode"}
                options={getCountriesSelectOptions(countriesFiltered)}
                value={newCountryCode}
                onChange={(e) =>
                  setNewCountryCode(e.target.value.replace(/\D/g, ""))
                }
                label={translations("label.country")}
              />
            </div>
            <div className={styles.phoneInput}>
              <CustomInputComponent
                type="tel"
                name="newPhoneNumber"
                label={translations("label.phone_number")}
                minLength={7}
                maxLength={12}
                value={state.newPhoneNumber}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        {/* <CustomInputComponent
          type="text"
          name="currentPhoneNumber"
          className="secondInput"
          value={
            client?.phone
              ? client?.phone
              : translations("label.current_phone_number")
          }
          disabled
          onChange={(e) => console.log(e)}
        />
        <CustomInputComponent
          type="text"
          name="newPhoneNumber"
          className="secondInput"
          placeholder={translations("label.new_phone_number")}
          value={newPhoneNumber}
          onChange={(e) => setNewPhoneNumber(e.target.value)}
        /> */}
      </div>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          disabled={state.newPhoneNumber.length === 0}
          handleClick={() => handleSubmit()}
        />
      </div>
    </div>
  );
}
