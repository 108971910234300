import React, { useEffect, useRef, useState } from "react";
import styles from "./LatestTransactions.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchLatestTransactionsBegin,
  fetchTransactionDetailsBegin,
  fetchUserTransactionsBegin,
} from "../actions";
import { GlobalIState } from "types/RootState";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { BulletPointIcon } from "assets/icons/icons";
import {
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getTransactionLabel,
} from "utils/utils";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import dayjs from "dayjs";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "App/actions";

import { FETCH_USER_TRANSACTIONS_SUCCESS } from "../constants";

interface Props {
  showHighlightedBox: string;
  setShowHighlightedBox: React.Dispatch<React.SetStateAction<string>>;
}

function LatestTransactions(props: Props) {
  const { showHighlightedBox, setShowHighlightedBox } = props;
  const {
    action: { isLoading, actionName },
    transactions,
    latestTransactions,
    isLatestTransactionsLoading,
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const firstTransaction = () => {
    if (transactions) {
      return transactions[0]?.transactions[0]?.id;
    }
  };

  const handleLoadMoreTransactions = () => {
    if (isMobile) {
      navigate("/wallet/transactions?type=1");
    } else {
      dispatch(fetchUserTransactionsBegin(1));
    }
  };

  useEffect(() => {
    if (isLoggedIn) dispatch(fetchLatestTransactionsBegin());
  }, [isLoggedIn]);

  useEffect(() => {
    if (actionName === FETCH_USER_TRANSACTIONS_SUCCESS && firstTransaction()) {
      dispatch(fetchTransactionDetailsBegin(firstTransaction()));
      setShowHighlightedBox("transaction-details");
      dispatch(scrollToTop());
    }
  }, [actionName]);

  return (
    <>
      {latestTransactions?.length !== 0 ? (
        <div className={styles.content}>
          <div>
            {latestTransactions?.map((item) => {
              return (
                <div className={styles.latestTransactionsItem} key={item.id}>
                  <div className={styles.upperItems}>
                    <div>
                      <BulletPointIcon
                        color={getDefaultColour(defaultColour)}
                      />
                      <span className={styles.transactionCategory}>
                        {item?.transactionType?.name}
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#000" }}>
                        {dayjs(item.created_at).format("DD/MM/YYYY")}
                      </span>
                    </div>
                  </div>
                  <div>
                    {item?.transactionType?.name +
                      ` ${getCurrencyStr(item.currency)} ${formatPrice(
                        item.amount
                      )} `}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.loadMoreTransactionsBtn}>
            <ButtonComponent
              label={translations("button.more")}
              class={"btn btn-primary"}
              style={{
                border: `1px solid ${getDefaultColour(defaultColour)}`,
                backgroundColor: "#fff",
                color: getDefaultColour(defaultColour),
                borderRadius: 100,
              }}
              isLoading={isLoading}
              handleClick={() => handleLoadMoreTransactions()}
            />
          </div>
        </div>
      ) : (
        <span>{translations("label.no_transactions_history")}</span>
      )}
    </>
  );
}

export default LatestTransactions;
