import React, { useEffect, useRef, useState } from "react";
import styles from "./OutlookSimulationChart.module.scss";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import {
  filterOutlookSimulationData,
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
} from "utils/utils";
import { fetchOutlookSimulationBegin } from "../actions";
import { useTranslation } from "react-i18next";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { RefreshIcon } from "assets/icons/icons";
import StorageService from "services/storage.service";

function OutlookSimulationChart() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    outlookSimulation,
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.InvestmentsReducer);
  const dispatch = useAppDispatch();
  const chartRef = useRef(null);
  const { t: translations } = useTranslation();

  const isLoggedIn = () => StorageService.getDataByKey("token");

  const positiveSeries = filterOutlookSimulationData(
    outlookSimulation?.positiveres
  )?.map((item, index) => ({
    x: `Year ${index + 1}`,
    y: item.result,
  }));

  const negativeSeries = filterOutlookSimulationData(
    outlookSimulation?.negativeres
  )?.map((item, index) => ({
    x: `Year ${index + 1}`,
    y: item.result,
  }));

  // y: isLoggedIn() ? -item.result : item.result

  const options: ApexOptions = {
    chart: {
      height: "90%",
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        tools: {
          download: false,
        },
      },
      animations: {
        enabled: true,
        easing: "easein",
        speed: 500,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },

    tooltip: {
      y: {
        formatter: function (value, { seriesIndex }) {
          // Check if the tooltip is for the second series ("lowest")
          if (seriesIndex === 1) {
            // Add minus sign to the y-value for the second series
            return `${getCurrencyStr(client?.currency)} -${formatPrice(
              value?.toString()
            )}`;
          }
          return `${getCurrencyStr(client?.currency)} ${formatPrice(
            value?.toString()
          )}`;
        },
      },
    },
    series: [],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 0,
      colors: [getDefaultColour(defaultColour)],
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          offsetX: -30,
        },
      },
      padding: {
        left: 20,
      },
    },
    colors: ["#34FF71", "#FF6333"],
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: Array(20).fill(getDefaultColour(defaultColour)),
          fontSize: "12px",
        },
      },
      categories: Array(20)
        .fill(undefined)
        .map((_, index) => `${translations("label.year")} ${index + 1}`),
    },
    yaxis: {
      tickAmount: 4,
      forceNiceScale: true,
      floating: false,
      labels: {
        style: {
          colors: getDefaultColour(defaultColour),
        },
        formatter: (value: any) => {
          return `${getCurrencyStr(client?.currency)} ${formatPrice(
            value?.toString()
          )}`;
        },

        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    fill: {
      type: "pattern",
      opacity: 1,
      colors: [getDefaultColour(defaultColour)],
      pattern: {
        style: Array(20).fill("verticalLines"),
        width: 4,
        height: 1,
      },
    },
    legend: {
      show: false,
    },
  };

  const handleRefresh = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new ApexCharts(document.getElementById("chart"), {
      ...options,
      series: [
        {
          name: translations("label.best"),
          data: positiveSeries,
        },
        {
          name: translations("label.worst"),
          data: negativeSeries,
        },
      ],
    });

    chartRef.current.render();
  };

  useEffect(() => {
    // dispatch(fetchOutlookSimulationBegin(isLoggedIn() ? true : false));
    dispatch(fetchOutlookSimulationBegin(false));
  }, [dispatch]);

  useEffect(() => {
    if (outlookSimulation?.positiveres) {
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      chartRef.current = new ApexCharts(document.getElementById("chart"), {
        ...options,
        series: [
          {
            name: translations("label.best"),
            data: positiveSeries,
          },
          {
            name: translations("label.worst"),
            data: negativeSeries,
          },
        ],
      });

      chartRef.current.render();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [outlookSimulation]);

  return (
    <div className={styles.lineChartWrapper}>
      <div className={styles.header}>
        <div>
          <h2>{translations("label.returns_outlook_simulation")}</h2>
        </div>
        <div className={styles.refreshIcon} onClick={handleRefresh}>
          <RefreshIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
      {outlookSimulation?.positiveres ? (
        <div id="chart" style={{ width: "100%", height: "90%" }} />
      ) : (
        ""
      )}
    </div>
  );
}

export default OutlookSimulationChart;
