import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "./MapStream.module.scss";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate, useSearchParams } from "react-router-dom"; // Import useLocation
import { updateFiltersArray } from "utils/utils";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { debounce } from "lodash";

interface Props {
  setShowProductDetails?: any;
}

const MapStream: React.FC<Props> = (props) => {
  const { setShowProductDetails } = props;
  const { products, filters } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const navigate = useNavigate();
  const mapRef = useRef<L.Map>(null);
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();
  const refreshParam = searchParams.get("refresh");
  const defaultMapState = isMobile
    ? { center: [45.41376349, 4.736319825], zoom: 5 }
    : { center: [42.83569550641452, 9.239095448286891], zoom: 6 };

  const [visibleMarkers, setVisibleMarkers] = useState(() => {
    const savedMarkers = localStorage.getItem("visibleMarkers");
    return savedMarkers ? JSON.parse(savedMarkers) : [];
  });

  const [mapState, setMapState] = useState(() => {
    const savedMapState = localStorage.getItem("mapState");
    return savedMapState ? JSON.parse(savedMapState) : defaultMapState;
  });

  const saveMapState = (map: L.Map) => {
    const center = map.getCenter();
    const zoom = map.getZoom();
    localStorage.setItem(
      "mapState",
      JSON.stringify({
        center: [center.lat, center.lng],
        zoom: zoom,
      })
    );
  };

  const saveVisibleMarkers = (markers) => {
    localStorage.setItem("visibleMarkers", JSON.stringify(markers));
  };

  const handleMarkerClick = (location) => {
    const map = mapRef.current;
    if (map) {
      saveMapState(map);
      saveVisibleMarkers(visibleMarkers);
    }
    if (isMobile) navigate(`/purchasing/product?id=${location.id}`);
    else return setShowProductDetails({ id: location.id, show: true });
  };

  const createCustomIcon = (location) =>
    L.divIcon({
      html: `<div class="${styles.customMarker}"><div class="${styles.label}">${
        location.wine_name?.length > 15
          ? location.wine_name?.substring(0, 15) + "..."
          : location?.wine_name
      }</div><div class="${styles.price}">CHF ${location.price}</div></div>`,
      className: "custom-marker-icon",
    });

  const updateVisibleMarkers = () => {
    const map = mapRef.current;
    if (map && products) {
      const bounds = map.getBounds();
      const visible = products.filter((item) => {
        const latLng = L.latLng(Number(item.lat), Number(item.lng));
        return bounds.contains(latLng);
      });
      setVisibleMarkers(visible);
      saveVisibleMarkers(visible);
    }
  };

  const debouncedUpdateVisibleMarkers = debounce(updateVisibleMarkers, 300);

  useEffect(() => {
    const map = mapRef.current;
    if (map) {
      map.setView(mapState.center, mapState.zoom);

      if (
        products &&
        products.length > 0 &&
        updateFiltersArray(filters.filter((item) => item.value.length >= 1))
          ?.length !== 0
      ) {
        const bounds = L.latLngBounds(
          products.map((item) => [Number(item.lat), Number(item.lng)])
        );
        map.fitBounds(bounds);
      }

      map.on("moveend", debouncedUpdateVisibleMarkers);
      updateVisibleMarkers();
    }
  }, [products, mapState]);

  useEffect(() => {
    if (refreshParam === "true") {
      setMapState(defaultMapState);
      setVisibleMarkers([]);
      localStorage.removeItem("mapState");
      localStorage.removeItem("visibleMarkers");
      searchParams.delete("refresh");
      setSearchParams(searchParams);
    }
  }, [refreshParam]);

  return (
    <MapContainer
      center={mapState.center}
      zoom={mapState.zoom}
      zoomControl={false}
      ref={mapRef}
      minZoom={2}
      maxZoom={18}
      worldCopyJump={false}
      maxBoundsViscosity={1.0}
      maxBounds={[
        [-85, -180], // South-West corner of the world
        [85, 180], // North-East corner of the world
      ]}
      style={{ width: "100%", height: "100%" }}
      className={styles.mapContainer}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />

      <MarkerClusterGroup>
        {visibleMarkers.map((item, index) => (
          <Marker
            key={index}
            position={[Number(item.lat), Number(item.lng)]}
            icon={createCustomIcon(item)}
            eventHandlers={{
              click: () => handleMarkerClick(item),
            }}
          />
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default MapStream;
