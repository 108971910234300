import { useTranslation } from "react-i18next";
import styles from "./ForgotPassword.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";

import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";

import { GlobalIState } from "types/RootState";
import { resetPasswordBegin } from "pages/Profile/actions";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import { sendRequestResetPasswordBegin } from "../actions";
import { SEND_RESET_PASSWORD_SUCCESS } from "../constants";
import { useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [email, setEmail] = useState("");
  const isPasscode = window.location.pathname.includes("passcode");

  const handleSave = () => {
    dispatch(sendRequestResetPasswordBegin(email));
  };

  useEffect(() => {
    if (actionName === SEND_RESET_PASSWORD_SUCCESS) {
      navigate(
        isPasscode
          ? "/forgot-passcode/email-sent"
          : "/forgot-password/email-sent"
      );
    }
  }, [actionName]);

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.header}>
        <h1>
          {translations(
            isPasscode ? "label.forgot_passcode?" : "label.forgot_password?"
          )}
        </h1>
        <span>
          {translations(
            isPasscode
              ? "label.forgot_passcode.subtitle"
              : "label.forgot_password.subtitle"
          )}
        </span>
      </div>
      <div className={styles.fields}>
        <CustomInputComponent
          type="text"
          name="email"
          className="secondInput"
          placeholder={translations("label.email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={styles.buttonContainer}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
