import { useTranslation } from "react-i18next";
import styles from "./Lucky100CHFWinner.module.scss";
import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import { useNavigate } from "react-router-dom";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { EarnedBonusIcon } from "assets/icons/icons";
import { useEffect } from "react";
import { scrollToTop } from "App/actions";

function Lucky100CHFWinner() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { session_url } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();

  const handleContinue = () => {
    window.open(session_url, "_self");
  };

  useEffect(() => {
    dispatch(scrollToTop());
  }, []);

  return (
    <div
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
      className={styles.wrapper}
    >
      <h1>
        {translations("label.lucky_you")} <br />
        {translations("label.you_won_100chf")}
      </h1>
      <div className={styles.icon}>
        <EarnedBonusIcon />
      </div>
      <div className={styles.content}>
        <span>{translations("subtitle.100chf_winner")}</span>
        <ul>
          <li>{translations("label.100chf_winner.list1")}</li>
          <li>{translations("label.100chf_winner.list2")}</li>
        </ul>

        <div className={styles.colored}>
          <span>{translations("hint.100chf_winner")}</span>
        </div>
      </div>

      <ButtonComponent
        label={translations("label.continue")}
        class={"btn btn-primary"}
        handleClick={() => handleContinue()}
      />
    </div>
  );
}

export default Lucky100CHFWinner;
