const ENV = process.env.REACT_APP_ENV;
const ACCOUNT_HOLDER = process.env.REACT_APP_BANK_TRANSFER_ACCOUNT_HOLDER;
const IBAN = process.env.REACT_APP_BANK_TRANSFER_IBAN;
const SWIFT_BIC = process.env.REACT_APP_BANK_TRANSFER_SWIFT_BIC;
const BANK_ADDRESS = process.env.REACT_APP_BANK_TRANSFER_BANK_ADDRESS;
const TOPUP_REFERENCE = process.env.REACT_APP_BANK_TRANSFER_TOPUP_REFERENCE;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const GOOGLE_CLIENT_SECRET = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
const GOOGLE_REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL;
const API_URL = process.env.REACT_APP_API_URL;

const properties = {
  ENV,
  ACCOUNT_HOLDER,
  IBAN,
  SWIFT_BIC,
  BANK_ADDRESS,
  TOPUP_REFERENCE,
  STRIPE_KEY,
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
  GOOGLE_REDIRECT_URL,
  API_URL,
};

export default properties;
