import { useTranslation } from "react-i18next";

import styles from "./AccountSetUp.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import { GlobalIState } from "types/RootState";
import StorageService from "services/storage.service";
import VinfinityWhiteLogo from "assets/images/VinfinityWhiteLogo.png";
import CardImage from "assets/images/Card.png";
import ButtonComponent from "components/Button/Button.component";
import { useNavigate } from "react-router-dom";
import {
  capitalizeFirstLetter,
  formatTextWithDots,
  getDefaultColour,
} from "utils/utils";
import { sendVerifyEmailBegin } from "pages/Authentication/actions";
import { useEffect } from "react";
import { getClientBegin } from "pages/Profile/actions";
import { PreviousIcon } from "assets/icons/icons";

interface Props {
  isAcceptEventInvite?: boolean;
  isTokenForEventExpired?: boolean;
  first_name?: string;
  last_name?: string;
}

export default function AccountSetUp(props: Props) {
  const { isAcceptEventInvite, isTokenForEventExpired, first_name, last_name } =
    props;
  const {
    client,
    action: { isLoading },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();

  const getRegisteredUserInfo = StorageService.getDataByKey("registeredUser");
  const lastRouteCookie = StorageService.getDataByKey("lastRouteCookie");
  const eventIdCookie = StorageService.getDataByKey("eventId");

  const handleNext = () => {
    if (isAcceptEventInvite) {
      navigate(`/events?id=${eventIdCookie}`);
      StorageService.deleteCookie("acceptedEventInvite");
      StorageService.deleteCookie("eventId");
    } else if (isTokenForEventExpired) {
      StorageService.deleteCookie("expiredAcceptedEventInvite");
      navigate("/");
    } else {
      // StorageService.deleteCookie("registerStep");
      // StorageService.deleteCookie("registeredUser");
      // navigate(lastRouteCookie ? lastRouteCookie : "/");
      // StorageService.deleteCookie("lastRouteCookie");
      navigate("/bonus");
    }

    // dispatch(sendVerifyEmailBegin());
  };

  useEffect(() => {
    if (!client) {
      dispatch(getClientBegin());
    }
  }, [client]);

  return (
    <div className={styles.accountSetupWrapper}>
      <div className={styles.header}>
        <div className={styles.previousIcon} onClick={() => navigate(-1)}>
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
      <h1>
        {translations("label.welcome") +
          ", " +
          capitalizeFirstLetter(
            first_name ? first_name : getRegisteredUserInfo?.firstname
          )}
      </h1>
      <span className={styles.hint}>
        {translations("label.account_set_up.hint")}
      </span>

      <div className={styles.card}>
        <div>
          <div className={styles.logo}>
            <img src={VinfinityWhiteLogo} alt="Vinfinity Logo" />
          </div>
          <div className={styles.info}>
            <span>{translations("label.membership_id")}</span>
            <span className={styles.membershipId}>{client?.membership_id}</span>
            {first_name && last_name ? (
              <span className={styles.username}>
                {first_name.length > 25
                  ? first_name.substring(0, 25) + "..."
                  : first_name + " " + formatTextWithDots(last_name, 25)}
              </span>
            ) : (
              <span className={styles.username}>
                {getRegisteredUserInfo?.firstname.length > 25
                  ? getRegisteredUserInfo?.firstname?.substring(0, 25) + "..."
                  : getRegisteredUserInfo?.firstname +
                    " " +
                    formatTextWithDots(getRegisteredUserInfo?.lastname, 25)}
              </span>
            )}
          </div>
        </div>
        <img src={CardImage} alt="User card" className={styles.cardImage} />
        {/* <div className={styles.iconContainer}>
          <UserRegisterSuccessCard />
        </div> */}
      </div>

      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.next")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleNext()}
          style={{ marginBottom: 20 }}
        />
      </div>
    </div>
  );
}
