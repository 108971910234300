import { useTranslation } from "react-i18next";
import styles from "./DeliveryInfo.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  checkSelectValueOnInit,
  getCountrySelectOptions,
  getDefaultColour,
} from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { BackButtonIcon, CopyIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import SelectComponent from "components/Select/Select.component";
import ButtonComponent from "components/Button/Button.component";

import _ from "lodash";
import { getClientBegin } from "pages/Profile/actions";
import { updateShipmentAddressBegin } from "../actions";
import StorageService from "services/storage.service";
import { fetchCountriesBegin } from "pages/Authentication/actions";
import { openToaster, scrollToTop } from "App/actions";

export default function DeliveryInfo() {
  const {
    defaultColour,
    client,
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [state, setState] = useState({
    billing_name: "",
    billing_street: "",
    billing_city: "",
    billing_country: "219",
    billing_zip: "",
    billing_company_name: "",
    delivery_name: "",
    da_street: "",
    da_city: "",
    da_zip: "",
    da_country: "219",
    delivery_company_name: "",
  });
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");
  const [billingAddOption, setBillingAddOption] = useState("sameAsDelivery");
  const guestUserDeliveryInfoCookie = StorageService.getDataByKey(
    "guestUserDeliveryInfo"
  );
  const guestUserInfoCookie = StorageService.getDataByKey("guest_user");

  const isLoggedIn = () => {
    return StorageService.getDataByKey("token");
  };

  const normalize = (value) => (value === undefined ? null : value);

  const isBillingSameAsDelivery = _.isEqual(
    {
      name: normalize(state.delivery_name),
      street: normalize(state.da_street),
      city: normalize(state.da_city),
      zip: normalize(state.da_zip),
      country: normalize(state.da_country),
    },
    {
      name: normalize(state?.billing_name),
      street: normalize(state?.billing_street),
      city: normalize(state?.billing_city),
      zip: normalize(state?.billing_zip),
      country: normalize(state?.billing_country),
    }
  );

  const disabled = _.isEqual(state, {
    billing_name: client?.billing_name,
    billing_street: client?.billing_street,
    billing_city: client?.billing_city,
    billing_zip: client?.billing_zip,
    billing_country: client?.billing_country,
    delivery_name: client?.delivery_name,
    da_street: client?.da_street,
    da_city: client?.da_city,
    da_zip: client?.da_zip,
    da_country: client?.da_country,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    const capitalizedValue =
      value.length === 1
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : value;

    setState((prev) => ({
      ...prev,
      [name]: capitalizedValue,
    }));
  };

  const handleSave = () => {
    let data = {
      delivery_name: state.delivery_name,
      da_street: state.da_street,
      da_city: state.da_city,
      da_zip: state.da_zip,
      da_country: checkSelectValueOnInit(state.da_country),
      delivery_company_name: state.delivery_company_name,
      billing_name:
        billingAddOption === "sameAsDelivery"
          ? state.delivery_name
          : state.billing_name,
      billing_street:
        billingAddOption === "sameAsDelivery"
          ? state.da_street
          : state.billing_street,
      billing_city:
        billingAddOption === "sameAsDelivery"
          ? state.da_city
          : state.billing_city,
      billing_zip:
        billingAddOption === "sameAsDelivery"
          ? state.da_zip
          : state.billing_zip,
      billing_country:
        billingAddOption === "sameAsDelivery"
          ? checkSelectValueOnInit(state.da_country)
          : checkSelectValueOnInit(state.billing_country),
      billing_company_name:
        billingAddOption === "sameAsDelivery"
          ? state.delivery_company_name
          : state.billing_company_name,
    };

    const filteredData = { ...data };
    delete filteredData.delivery_company_name;
    delete filteredData.billing_company_name;

    const isAnyFieldEmpty = Object.values(filteredData).some(
      (value) => value === ""
    );

    if (isAnyFieldEmpty) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_all_fields"))
      );
      return;
    }
    if (state.da_country !== "219") {
      dispatch(openToaster("ERROR", translations("error.delivery_country")));
      return;
    }

    if (isLoggedIn()) {
      setState({
        ...state,
        delivery_name: state.delivery_name,
        da_street: state.da_street,
        da_city: state.da_city,
        da_zip: state.da_zip,
        da_country: checkSelectValueOnInit(state.da_country)?.toString(),
        delivery_company_name: state.delivery_company_name,
        billing_name: state.billing_name,
        billing_street: state.billing_street,
        billing_city: state.billing_city,
        billing_zip: state.billing_zip,
        billing_country: checkSelectValueOnInit(
          state.billing_country
        )?.toString(),
        billing_company_name: state.billing_company_name,
      });
      dispatch(updateShipmentAddressBegin(data));
      navigate("/cart");
    } else {
      StorageService.setKeyValue("guestUserDeliveryInfo", data);
      navigate("/cart?type=guest");
    }
  };

  useEffect(() => {
    if (!client && isLoggedIn()) dispatch(getClientBegin());
    if (isLoggedIn()) {
      dispatch(fetchCountriesBegin("withAuth"));
    } else {
      dispatch(fetchCountriesBegin("withoutAuth"));
    }

    if (client) {
      setState({
        billing_name:
          client?.billing_name?.length > 0
            ? client?.billing_name
            : client?.firstname + " " + client?.lastname,
        billing_street: client?.billing_street,
        billing_city: client?.billing_city,
        billing_zip: client?.billing_zip,
        billing_country: client?.billing_country?.toString(),
        billing_company_name: client?.billing_company_name,
        delivery_name:
          client?.delivery_name?.length > 0
            ? client?.delivery_name
            : client?.firstname + " " + client?.lastname,
        da_street: client?.da_street,
        da_city: client?.da_city,
        da_zip: client?.da_zip,
        da_country: client?.da_country?.toString(),
        delivery_company_name: client?.delivery_company_name,
      });
    }
  }, [client]);

  useEffect(() => {
    if (guestUserDeliveryInfoCookie) {
      setState({
        billing_name: guestUserDeliveryInfoCookie?.billing_name
          ? guestUserDeliveryInfoCookie?.billing_name
          : guestUserInfoCookie?.firstname +
            " " +
            guestUserInfoCookie?.lastname,
        billing_street: guestUserDeliveryInfoCookie?.billing_street,
        billing_city: guestUserDeliveryInfoCookie?.billing_city,
        billing_zip: guestUserDeliveryInfoCookie?.billing_zip,
        billing_country: guestUserDeliveryInfoCookie?.billing_country,
        billing_company_name: guestUserDeliveryInfoCookie?.billing_company_name,
        delivery_name: guestUserDeliveryInfoCookie?.delivery_name
          ? guestUserDeliveryInfoCookie?.delivery_name
          : guestUserInfoCookie?.firstname +
            " " +
            guestUserInfoCookie?.lastname,
        da_street: guestUserDeliveryInfoCookie?.da_street,
        da_city: guestUserDeliveryInfoCookie?.da_city,
        da_zip: guestUserDeliveryInfoCookie?.da_zip,
        da_country: guestUserDeliveryInfoCookie?.da_country?.toString(),
        delivery_company_name:
          guestUserDeliveryInfoCookie?.delivery_company_name,
      });
    } else if (guestUserInfoCookie) {
      setState({
        ...state,
        billing_name: guestUserDeliveryInfoCookie?.billing_name
          ? guestUserDeliveryInfoCookie?.billing_name
          : guestUserInfoCookie?.firstname +
            " " +
            guestUserInfoCookie?.lastname,
        delivery_name: guestUserDeliveryInfoCookie?.delivery_name
          ? guestUserDeliveryInfoCookie?.delivery_name
          : guestUserInfoCookie?.firstname +
            " " +
            guestUserInfoCookie?.lastname,
      });
    }

    dispatch(scrollToTop());
  }, []);

  useEffect(() => {
    if (isBillingSameAsDelivery) {
      setBillingAddOption("sameAsDelivery");
    } else setBillingAddOption("other");
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        {!isMobile ? (
          <div
            className="pointer"
            onClick={() =>
              navigate(typeParam === "guest" ? "/cart?type=guest" : "/cart")
            }
          >
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
        ) : null}
        <div className={styles.title}>
          <h2>{translations("label.address_details")}</h2>
        </div>
      </div>
      <div className={styles.fields}>
        <div className={styles.header}>
          <div>
            <h2>{translations("label.delivery_address")}</h2>
          </div>
          <div></div>
        </div>
        <CustomInputComponent
          type="text"
          name="delivery_name"
          className="secondInput"
          placeholder={translations("label.full_name")}
          value={state.delivery_name}
          maxLength={30}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="delivery_company_name"
          className="secondInput"
          placeholder={"c/o"}
          value={state.delivery_company_name}
          maxLength={30}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="da_street"
          className="secondInput"
          placeholder={translations("label.street")}
          value={state.da_street}
          maxLength={30}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="da_city"
          className="secondInput"
          placeholder={translations("label.city")}
          value={state.da_city}
          maxLength={15}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="da_zip"
          className="secondInput"
          placeholder={translations("label.zip_code")}
          value={state.da_zip}
          maxLength={15}
          onChange={(e) => handleChange(e)}
        />
        <SelectComponent
          name={"da_country"}
          className="secondSelectStyle"
          label={translations("label.country")}
          value={state.da_country ? state.da_country : 0}
          options={getCountrySelectOptions(countries)}
          style={{
            pointerEvents: state?.da_country === "219" ? "none" : "all",
          }}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className={styles.fields}>
        {/* <div className={styles.header}>
          <div>
            <h2>{translations("label.billing_address")}</h2>
          </div>
        </div> */}

        <div className={styles.header}>
          <div>
            <h2>{translations("label.billing_address")}</h2>
          </div>
        </div>
        <div
          className={styles.addressOptions}
          onClick={() => setBillingAddOption("sameAsDelivery")}
        >
          <div
            style={{
              backgroundColor:
                billingAddOption === "sameAsDelivery"
                  ? getDefaultColour(defaultColour)
                  : "transparent",
            }}
            className={styles.radio}
          ></div>
          <div>
            <span>{translations("label.same_as_delivery_address")}</span>
          </div>
        </div>
        <div
          className={styles.addressOptions}
          onClick={() => setBillingAddOption("other")}
        >
          <div
            style={{
              backgroundColor:
                billingAddOption === "other"
                  ? getDefaultColour(defaultColour)
                  : "transparent",
            }}
            className={styles.radio}
          ></div>
          <div>
            <span>{translations("label.other_billing_address")}</span>
          </div>
        </div>

        {billingAddOption === "other" ? (
          <div style={{ marginTop: 20 }}>
            <CustomInputComponent
              type="text"
              name="billing_name"
              className="secondInput"
              placeholder={translations("label.full_name")}
              value={state.billing_name}
              maxLength={30}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_company_name"
              className="secondInput"
              placeholder={"c/o"}
              value={state.billing_company_name}
              maxLength={30}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_street"
              className="secondInput"
              placeholder={translations("label.street")}
              value={state.billing_street}
              maxLength={30}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_city"
              className="secondInput"
              placeholder={translations("label.city")}
              value={state.billing_city}
              maxLength={15}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_zip"
              className="secondInput"
              placeholder={translations("label.zip_code")}
              value={state.billing_zip}
              maxLength={15}
              onChange={(e) => handleChange(e)}
            />
            <SelectComponent
              name={"billing_country"}
              className="secondSelectStyle"
              label={translations("label.country")}
              value={state.billing_country ? state.billing_country : 0}
              options={getCountrySelectOptions(countries)}
              onChange={(e) => handleChange(e)}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          disabled={disabled}
          handleClick={() => handleSave()}
        />
      </div>
    </div>
  );
}
