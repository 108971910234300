import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { GlobalIState } from "types/RootState";

import styles from "./HowItWorks.module.scss";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BackButtonIcon, DownArrowRounded } from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import { useEffect } from "react";
import MembershipTable from "../MembershipTable/MembershipTable.component";
import LevelsTable from "./LevelsTable/LevelsTable.component";
import { Tooltip } from "@mui/material";

function HowItWorks() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let activeView = searchParams.get("view");

  useEffect(() => {
    if (!activeView) {
      searchParams.set("view", "games");
      setSearchParams(searchParams);
    }
  }, [activeView]);

  return (
    <div
      className={styles.pageWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {isMobile ? (
        <div className={styles.header}>
          <div
            className={styles.leftPart}
            onClick={() => navigate("/membership")}
          >
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
          <div className={styles.middleLabels}>
            <span>{translations("label.how_it_works")}</span>
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.middleLabels}>
            <span>{translations("label.how_it_works")}</span>
          </div>
        </div>
      )}

      {isMobile ? (
        <div className={styles.tabs}>
          <div
            style={{
              backgroundColor: activeView === "games" ? "#000" : "transparent",
              color: activeView === "games" ? "#fff" : "#000",
            }}
            onClick={() => {
              searchParams.set("view", "games");
              setSearchParams(searchParams);
            }}
          >
            <span>{translations("label.games")}</span>
          </div>
          <div
            style={{
              backgroundColor: activeView === "medals" ? "#000" : "transparent",
              color: activeView === "medals" ? "#fff" : "#000",
            }}
            onClick={() => {
              searchParams.set("view", "medals");
              setSearchParams(searchParams);
            }}
          >
            <span>{translations("label.medals")}</span>
          </div>
          <div
            style={{
              backgroundColor: activeView === "status" ? "#000" : "transparent",
              color: activeView === "status" ? "#fff" : "#000",
            }}
            onClick={() => {
              searchParams.set("view", "status");
              setSearchParams(searchParams);
            }}
          >
            <span>{translations("label.status")}</span>
          </div>
        </div>
      ) : null}

      <div className={styles.content}>
        {activeView === "games" ? (
          <div className={styles.gamesContent}>
            <div className={styles.labels}>
              <div className={styles.firstLabel}>
                <span>{translations("label.games.hint1")}</span>
              </div>
              <div>
                <span>{translations("label.games.hint2")}</span>
              </div>
            </div>
            <div className={styles.tableCard}>
              <MembershipTable type="referrals" />
            </div>
          </div>
        ) : activeView === "medals" ? (
          <div className={styles.medalsContent}>
            <div className={styles.labels}>
              <div className={styles.firstLabel}>
                <span>{translations("label.medals.hint1")}</span>
              </div>
              <div>
                <ul>
                  <li>{translations("label.medals.list_item1")}</li>
                  <li>{translations("label.medals.list_item2")}</li>
                  <li>{translations("label.medals.list_item3")}</li>
                </ul>
              </div>
              <div>
                <span>{translations("label.medals_hint3")}</span>
              </div>
            </div>
            <div className={styles.tableCard}>
              <MembershipTable type="medals" />
            </div>
          </div>
        ) : activeView === "status" ? (
          <div className={styles.statusContent}>
            <div className={styles.labels}>
              <div className={styles.firstLabel}>
                <span>{translations("label.status.hint1")}</span>
              </div>
              <div className={styles.firstLabel}>
                <span>{translations("label.status.hint2")}</span>
              </div>
              <div>
                <span>{translations("label.status.hint3")}</span>
              </div>
            </div>
            <div className={styles.tableCard}>
              <div className={styles.title}>
                <span>{translations("label.membership_levels")}</span>
              </div>
              <LevelsTable />

              <div className={styles.labelUnderTable}>
                <span>{translations("label.status.hint4")}</span>
              </div>

              <div className={styles.cards}>
                <div className={styles.card}>
                  <div className={styles.right}>
                    <div className={styles.upperContent}>
                      <DownArrowRounded
                        color={getDefaultColour(defaultColour)}
                      />
                      <div>
                        <span className={styles.value}>
                          {translations("label.purchases_table")}
                        </span>
                      </div>
                    </div>
                    <div className={styles.bottomContent}>
                      <span>
                        <Tooltip
                          title={
                            translations("label.purchases.sublabel")?.length >
                            (isMobile ? 40 : 55)
                              ? translations("label.purchases.sublabel")
                              : ""
                          }
                        >
                          <span>
                            {translations("label.purchases.sublabel")?.length >
                            (isMobile ? 40 : 55)
                              ? translations("label.purchases.sublabel")?.slice(
                                  0,
                                  isMobile ? 40 : 55
                                ) + "..."
                              : translations("label.purchases.sublabel")}
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.right}>
                    <div className={styles.upperContent}>
                      <DownArrowRounded
                        color={getDefaultColour(defaultColour)}
                      />
                      <div>
                        <span className={styles.value}>
                          {isMobile
                            ? translations("label.investments_and_referrals")
                                ?.length > 35
                              ? translations(
                                  "label.investments_and_referrals"
                                )?.substring(0, 35) + "..."
                              : translations("label.investments_and_referrals")
                            : translations("label.investments_and_referrals")}
                        </span>
                      </div>
                    </div>
                    <div className={styles.bottomContent}>
                      <span>
                        {" "}
                        <Tooltip
                          title={
                            translations(
                              "label.investments_and_referrals.sublabel"
                            )?.length > (isMobile ? 40 : 55)
                              ? translations(
                                  "label.investments_and_referrals.sublabel"
                                )
                              : ""
                          }
                        >
                          <span>
                            {translations(
                              "label.investments_and_referrals.sublabel"
                            )?.length > (isMobile ? 40 : 55)
                              ? translations(
                                  "label.investments_and_referrals.sublabel"
                                )?.slice(0, isMobile ? 35 : 55) + "..."
                              : translations(
                                  "label.investments_and_referrals.sublabel"
                                )}
                          </span>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default HowItWorks;
