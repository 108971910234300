import styles from "./WalletTabs.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { GlobalIState } from "types/RootState";
import {
  formatPrice,
  getCurrencyStr,
  getDefaultColour,
  getTotalPrice,
} from "utils/utils";
import {
  setLeftPartItem,
  setMiddlepartItem,
  setRightPartItem,
} from "App/actions";
import StorageService from "services/storage.service";

interface Props {}

const WalletTabs: React.FC<Props> = (props) => {
  const { leftPartShownItem } = useAppSelector(
    (state: GlobalIState) => state.globalReducer
  );
  const { defaultColour, client, balance_total_amount_balance } =
    useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { cartItems } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();

  const getFormattedValue = (value: number | string) => {
    return isLoggedIn
      ? value?.toString()?.length <= 3
        ? Number(value)?.toFixed(2)
        : formatPrice(value?.toString())
      : "0";
  };
  const totalPrice = Number(getTotalPrice(cartItems)).toFixed(2);
  const deliveryPrice = StorageService.getDataByKey("deliveryPriceForHeader");
  const totalPriceFormatted =
    getTotalPrice(cartItems) >= 99
      ? totalPrice
      : Number(getTotalPrice(cartItems) + deliveryPrice).toFixed(2);
  const availableBalance = formatPrice(
    (
      (getTotalPrice(cartItems) !== 0 ? +totalPriceFormatted : 0) +
      balance_total_amount_balance
    )
      .toFixed(2)
      .toString()
  );

  let medalsValue = isLoggedIn
    ? formatPrice(client?.medals_balance.toString())
    : "0";
  let stakedValue = getFormattedValue(client?.staked_balance);

  const getLeftValue = () => {
    switch (leftPartShownItem) {
      case "drinking":
      case "2/drinking":
        return "4%";
      case "gaming":
      case "2/gaming":
        return "36%";
      case "staking":
      case "2/staking":
        return "66%";
      default:
        return "4%";
    }
  };

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.tabs}>
        <div
          onClick={() => {
            dispatch(setLeftPartItem("drinking"));
            dispatch(setMiddlepartItem(null));
            dispatch(setRightPartItem(null));
          }}
        >
          <span>{translations("label.purchased")}</span>
          <span className={styles.value}>CHF {availableBalance}</span>
        </div>
        <div
          onClick={() => {
            dispatch(setLeftPartItem("gaming"));
            dispatch(setMiddlepartItem(null));
            dispatch(setRightPartItem(null));
          }}
        >
          <span>{translations("label.medals")}</span>
          <span className={styles.value}>{medalsValue}</span>
        </div>
        <div
          onClick={() => {
            dispatch(setLeftPartItem("staking"));
            dispatch(setMiddlepartItem(null));
            dispatch(setRightPartItem(null));
          }}
        >
          <span>{translations("label.staked")}</span>
          <span className={styles.value}>
            {getCurrencyStr(client?.currency)} {stakedValue}
          </span>
        </div>
      </div>
      <div className={styles.border} style={{ left: getLeftValue() }}></div>
    </div>
  );
};
export default WalletTabs;
