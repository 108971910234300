import { openToaster } from "App/actions";
import { call, put, takeLatest } from "redux-saga/effects";
import * as personalLedgerService from "services/personal-ledger.service";
import {
  ADD_WINE_BEGIN,
  DELETE_ADDED_WINE_BEGIN,
  GET_ADDED_WINES_BEGIN,
  GET_PRODUCT_TRANSACTIONS_BEGIN,
  GET_SEARCHED_JOURNAL_WINES_BEGIN,
  GET_USER_BLIND_TASTINGS_BEGIN,
  GET_USER_ORDERS_BEGIN,
  RATE_ADDED_WINE_BEGIN,
  RATE_WINE_BEGIN,
  REQUEST_TO_SUPPLY_WINE_BEGIN,
  SET_ADDED_WINE_TASTE_BEGIN,
  SET_PRODUCT_TASTE_BEGIN,
} from "./constants";
import {
  addWineFailed,
  addWineSuccess,
  deleteAddedWineFailed,
  deleteAddedWineSuccess,
  getAddedWinesFailed,
  getAddedWinesSuccess,
  getProductTransactionsFailed,
  getProductTransactionsSuccess,
  getSearchedJournalWinesFailed,
  getSearchedJournalWinesSuccess,
  getUserBlindTastingsFailed,
  getUserBlindTastingsSuccess,
  getUserOrdersFailed,
  getUserOrdersSuccess,
  rateAddedWineFailed,
  rateAddedWineSuccess,
  rateWineFailed,
  rateWineSuccess,
  requestToSupplyWineFailed,
  requestToSupplyWineSuccess,
  setAddedWineTasteFailed,
  setAddedWineTasteSuccess,
  setProductTasteFailed,
  setProductTasteSuccess,
} from "./actions";
import axios from "axios";
import StorageService from "services/storage.service";

export function* getUserOrders(action) {
  try {
    const response = yield call(
      personalLedgerService.getUserOrderedWines,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getUserOrdersSuccess(response?.data?.data));
    } else {
      yield put(getUserOrdersFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* rateProductSubmit(action) {
  try {
    const response = yield call(personalLedgerService.rateWine, {
      product_id: action.payload.product_id,
      rating: action.payload.rating,
      isBlind: action.payload.isBlind ? action.payload.isBlind : false,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(rateWineSuccess(response?.data?.data));
    } else {
      yield put(rateWineFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* rateAddedWineSubmit(action) {
  try {
    const response = yield call(personalLedgerService.rateAddedWine, {
      product_id: action.payload.product_id,
      rating: action.payload.rating,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(rateAddedWineSuccess(response?.data?.data));
    } else {
      yield put(rateAddedWineFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* setProductTastings(action) {
  try {
    const response = yield call(
      personalLedgerService.setProductTaste,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(setProductTasteSuccess(response?.data?.data));
    } else {
      yield put(setProductTasteFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(setProductTasteFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* setAddedWineTaste(action) {
  try {
    const response = yield call(
      personalLedgerService.setAddedWineTaste,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(setAddedWineTasteSuccess(response?.data?.data));
    } else {
      yield put(setAddedWineTasteFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(setAddedWineTasteFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getProductTransactions(action) {
  try {
    const response = yield call(
      personalLedgerService.getProductTransactions,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getProductTransactionsSuccess(response?.data?.data));
    } else {
      yield put(getProductTransactionsFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getProductTransactionsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getUserBlindTastings(action) {
  try {
    const response = yield call(
      personalLedgerService.getUserBlindTastings,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getUserBlindTastingsSuccess(response?.data?.data));
    } else {
      yield put(getUserBlindTastingsFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

const uploadImageToS3 = async (image_file: any, uploadUrl: string) => {
  try {
    const response = await axios({
      method: "put",
      url: uploadUrl,
      headers: { "Content-Type": "image/jpeg" },
      data: image_file,
    });
    return true;
  } catch (error) {
    return error.message;
  }
};

export function* addWine(action) {
  try {
    const { image_file, ...rest } = action.payload;
    const response = yield call(personalLedgerService.addWine, rest);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      if (response?.data?.data?.uploadUrl) {
        if (uploadImageToS3(image_file, response?.data?.data?.uploadUrl)) {
          yield put(addWineSuccess());
        } else put(addWineFailed(response?.data?.message));
      } else yield put(addWineSuccess());
    } else {
      yield put(addWineFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getAddedWines(action) {
  try {
    const response = yield call(
      personalLedgerService.getAddedWines,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getAddedWinesSuccess(response?.data?.data));
    } else {
      yield put(getAddedWinesFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* requestToSupplyWine(action) {
  try {
    const response = yield call(
      personalLedgerService.requestToSupplyWine,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(requestToSupplyWineSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(requestToSupplyWineFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getSearchedWines(action) {
  try {
    const response = yield call(
      personalLedgerService.getSearchedWines,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getSearchedJournalWinesSuccess(response?.data?.data));
    } else {
      yield put(getSearchedJournalWinesFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* deleteAddedWine(action) {
  try {
    const response = yield call(
      personalLedgerService.deleteAddedWine,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(deleteAddedWineSuccess(response?.data?.data));
    } else {
      yield put(deleteAddedWineFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* personalLedgerSaga() {
  yield takeLatest(GET_USER_ORDERS_BEGIN, getUserOrders);
  yield takeLatest(RATE_WINE_BEGIN, rateProductSubmit);
  yield takeLatest(RATE_ADDED_WINE_BEGIN, rateAddedWineSubmit);
  yield takeLatest(SET_PRODUCT_TASTE_BEGIN, setProductTastings);
  yield takeLatest(SET_ADDED_WINE_TASTE_BEGIN, setAddedWineTaste);
  yield takeLatest(GET_PRODUCT_TRANSACTIONS_BEGIN, getProductTransactions);
  yield takeLatest(GET_USER_BLIND_TASTINGS_BEGIN, getUserBlindTastings);
  yield takeLatest(ADD_WINE_BEGIN, addWine);
  yield takeLatest(GET_ADDED_WINES_BEGIN, getAddedWines);
  yield takeLatest(REQUEST_TO_SUPPLY_WINE_BEGIN, requestToSupplyWine);
  yield takeLatest(GET_SEARCHED_JOURNAL_WINES_BEGIN, getSearchedWines);
  yield takeLatest(DELETE_ADDED_WINE_BEGIN, deleteAddedWine);
}
