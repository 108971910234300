import { produce } from "immer";

import { AuthenticationReducerState } from "types/reducers/authentication.reducer.type";

import {
  ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN,
  ACCEPT_EVENT_INVITATION_AND_REGISTER_FAILED,
  ACCEPT_EVENT_INVITATION_AND_REGISTER_SUCCESS,
  CHECK_IF_HAS_PASSCODE_BEGIN,
  CHECK_IF_HAS_PASSCODE_FAILED,
  CHECK_IF_HAS_PASSCODE_SUCCESS,
  CLEAR_ACTION,
  FETCH_COUNTRIES_BEGIN,
  FETCH_COUNTRIES_FAILED,
  FETCH_COUNTRIES_SUCCESS,
  LOG_IN_BEGIN,
  LOG_IN_BY_TOKEN,
  LOG_IN_FAILED,
  LOG_IN_SUCCESS,
  LOG_OUT,
  REGISTER_BEGIN,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  SEND_RESET_PASSWORD_BEGIN,
  SEND_RESET_PASSWORD_FAILED,
  SEND_RESET_PASSWORD_SUCCESS,
  SEND_SMS_CODE_BEGIN,
  SEND_SMS_CODE_FAILED,
  SEND_SMS_CODE_SUCCESS,
  SEND_VERIFY_EMAIL_BEGIN,
  SEND_VERIFY_EMAIL_FAILED,
  SEND_VERIFY_EMAIL_SUCCESS,
  SET_IS_LOGGED_IN,
  SET_PERSONAL_DETAILS_BEGIN,
  SET_PERSONAL_DETAILS_FAILED,
  SET_PERSONAL_DETAILS_SUCCESS,
  VERIFY_EMAIL_BEGIN,
  VERIFY_EMAIL_FAILED,
  VERIFY_EMAIL_SUCCESS,
} from "./constants";
import StorageService from "services/storage.service";
import { getOnlyNumbersFromString } from "utils/utils";
import dayjs from "dayjs";

const initialState: AuthenticationReducerState = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  isLoggedIn: false,
  countries: [],
  hasPasscode: false,
};

const authenticationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_COUNTRIES_BEGIN:
      case REGISTER_BEGIN:
      case SET_PERSONAL_DETAILS_BEGIN:
      case ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN:
      case SEND_VERIFY_EMAIL_BEGIN:
      case VERIFY_EMAIL_BEGIN:
      case SEND_RESET_PASSWORD_BEGIN:
      case CHECK_IF_HAS_PASSCODE_BEGIN:
      case SEND_SMS_CODE_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case LOG_IN_BEGIN:
        StorageService.deleteCookie("token");
        StorageService.deleteCookie("expiresIn");
        localStorage.removeItem("userEmail");
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case LOG_IN_FAILED:
      case FETCH_COUNTRIES_FAILED:
      case REGISTER_FAILED:
      case SET_PERSONAL_DETAILS_FAILED:
      case ACCEPT_EVENT_INVITATION_AND_REGISTER_FAILED:
      case SEND_VERIFY_EMAIL_FAILED:
      case VERIFY_EMAIL_FAILED:
      case SEND_RESET_PASSWORD_FAILED:
      case CHECK_IF_HAS_PASSCODE_FAILED:
      case SEND_SMS_CODE_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case LOG_IN_SUCCESS:
        let currentDate: any = new Date();
        // currentDate.setMinutes(
        //   currentDate.getMinutes() +
        //     (getOnlyNumbersFromString(action?.payload?.expires_in) - 5)
        // );
        currentDate.setHours(
          currentDate.getHours() +
            getOnlyNumbersFromString(action?.payload?.expires_in)
        );
        currentDate = currentDate.getTime();
        StorageService.setKeyValue("expiresIn", currentDate);
        StorageService.setKeyValue("token", action.payload.access_token);
        StorageService.setKeyValue("shouldRefreshToken", false);
        localStorage.removeItem("mapState");
        draft.isLoggedIn = true;
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case LOG_IN_BY_TOKEN:
        let newDate: any = new Date();
        // newDate.setMinutes(newDate.getMinutes() + 15);
        // newDate.setHours(newDate.getHours());
        newDate = dayjs(newDate).add(6, "month").toDate();
        newDate = newDate.getTime();
        StorageService.setKeyValue("expiresIn", newDate);
        StorageService.setKeyValue("token", action.payload);
        draft.isLoggedIn = true;
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case FETCH_COUNTRIES_SUCCESS:
        draft.countries = action.payload?.data?.data;
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case REGISTER_SUCCESS:
        StorageService.setKeyValue("registerStep", 2);
        StorageService.deleteCookie("referral_code");
        // StorageService.setKeyValue(
        //   "registerStep",
        //   action?.payload?.data?.login_method === "phone" ? "2/phone" : 2
        // );
        draft.countries = action.payload?.data?.data;
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case SET_PERSONAL_DETAILS_SUCCESS:
        StorageService.setKeyValue("registerStep", 3);
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case ACCEPT_EVENT_INVITATION_AND_REGISTER_SUCCESS:
      case SEND_VERIFY_EMAIL_SUCCESS:
      case VERIFY_EMAIL_SUCCESS:
      case SEND_RESET_PASSWORD_SUCCESS:
      case SEND_SMS_CODE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case LOG_OUT:
        draft.isLoggedIn = false;
        StorageService.deleteCookie("token");
        StorageService.deleteCookie("expiresIn");
        StorageService.deleteCookie("show_1k_medals_popup");
        StorageService.deleteCookie("1kmedals_popup_closed");
        localStorage.removeItem("userEmail");
        localStorage.removeItem("mapState");
        break;
      case CHECK_IF_HAS_PASSCODE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.hasPasscode = action.payload;
        break;
      case SET_IS_LOGGED_IN:
        draft.isLoggedIn = true;
        break;
      case CLEAR_ACTION:
        draft.action = initialState.action;
        break;
      default:
        break;
    }
  });

export default authenticationReducer;
