import {
  PaypalUserTopUpInterface,
  UserTopUpInterface,
  UserTopUpSubscriptionInterface,
} from "types/pages/investments.page.type";
import axiosInstance from "./api";

export const userTopUpByStripe = (payload: UserTopUpInterface) => {
  return axiosInstance.post(`/wines/stripe`, payload);
};

export const userTopUpSubscriptionByStripe = (
  payload: UserTopUpSubscriptionInterface
) => {
  return axiosInstance.post(`/wines/create/subscription`, payload);
};

export const userTopUpByPayPal = (payload: PaypalUserTopUpInterface) => {
  return axiosInstance.post(`/wines/payment`, payload);
};

export const paypalSuccessPayment = (payload: {
  payment_id: string;
  payer_id: string;
}) => {
  return axiosInstance.post(`/wines/success-payment`, payload);
};

export const userTopUpSubscriptionByPayPal = (payload: {
  currency: string;
  price: string;
  recurring_payment_date: number;
}) => {
  return axiosInstance.post(`/wines/payment-subscription`, payload);
};

export const paypalSubscriptionSuccessPayment = (payload: {
  agreement_id: string;
}) => {
  return axiosInstance.post(`/wines/payment-subscription-success`, payload);
};

export const fetchUserTransactions = (type: number) => {
  return axiosInstance.get(`/transactions/get-all/${type}`);
};

export const fetchTransactionDetails = (transaction_id: number) => {
  return axiosInstance.get(`/transactions/get-details/${transaction_id}`);
};

export const fetchLatestTransactions = () => {
  return axiosInstance.get("/transactions/get-latest");
};

export const fetchWineCollection = () => {
  return axiosInstance.get("/wines/images/collection");
};

export const fetchWines = () => {
  return axiosInstance.get("/wines");
};

export const fetchWineDetails = (wine_id: number) => {
  return axiosInstance.get(`/wines/details/${wine_id}`);
};

export const fetchClientBalanceSimulation = () => {
  return axiosInstance.get(`/weekly-performance/weekly-performance`);
};

export const fetchOutlookSimulation = (isLoggedIn: boolean) => {
  return axiosInstance.get(
    isLoggedIn
      ? `/investements/client-projection`
      : "/investements/default-projection"
  );
};

export const fetchGeographicAllocation = () => {
  return axiosInstance.get(`/investements/geographic-allocation`);
};

export const fetchWinePerformace = (id: number) => {
  return axiosInstance.get(`/investements/wine-performance/${id}`);
};

export const fetchWeeklyPerformance = (isLoggedIn: boolean) => {
  return axiosInstance.get(
    isLoggedIn ? "/weekly-performance/percentage" : "/weekly-performance"
  );
};

export const updateTransactionsStatusBegin = () => {
  return axiosInstance.get("/transactions/update-status");
};
