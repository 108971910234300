import { useTranslation } from "react-i18next";
import styles from "./UserInfo.module.scss";
import { useNavigate } from "react-router-dom";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { BackButtonIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";

import _ from "lodash";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import StorageService from "services/storage.service";
import { openToaster, scrollToTop } from "App/actions";
import { getClientBegin, updateUserInfoBegin } from "pages/Profile/actions";
import { UPDATE_USER_INFO_SUCCESS } from "pages/Profile/constants";

export default function UserInfo() {
  const {
    defaultColour,
    client,
    action: { isLoading, actionName },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const guestUserInfoCookie = StorageService.getDataByKey("guest_user");
  const [state, setState] = useState({
    salutation: "",
    firstname: "",
    lastname: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    const capitalizedValue =
      name !== "email" && value.length === 1
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : value;

    setState((prev) => ({
      ...prev,
      [name]: capitalizedValue,
    }));
  };

  const handleSave = () => {
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(state.email)) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.special_characters_not_allowed")
        )
      );
      return;
    }
    if (
      state.salutation === "" ||
      state.firstname === "" ||
      state.lastname === "" ||
      state.email === ""
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.personal_detail_fields_mandatory")
        )
      );
      return;
    }

    if (client) {
      dispatch(updateUserInfoBegin(state));
    } else {
      StorageService.setKeyValue("guest_user", state);
      navigate("/cart?type=guest");
    }
  };

  const handleClose = () => {
    navigate(client ? "/cart" : "/cart?type=guest");
  };

  useEffect(() => {
    if (client) {
      setState({
        salutation: client?.salutation ? client?.salutation : "",
        firstname: client?.firstname ? client?.firstname : "",
        lastname: client?.lastname ? client?.lastname : "",
        email: client?.email ? client?.email : "",
      });
    }
    if (guestUserInfoCookie) {
      setState({
        salutation: guestUserInfoCookie ? guestUserInfoCookie?.salutation : "",
        firstname: guestUserInfoCookie ? guestUserInfoCookie?.firstname : "",
        lastname: guestUserInfoCookie ? guestUserInfoCookie?.lastname : "",
        email: guestUserInfoCookie ? guestUserInfoCookie?.email : "",
      });
    }
  }, []);

  useEffect(() => {
    if (actionName === UPDATE_USER_INFO_SUCCESS) {
      dispatch(getClientBegin());
      navigate("/cart?type=user-updated");
    }
  }, [actionName]);

  useEffect(() => {
    dispatch(scrollToTop());
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        {!isMobile ? (
          <div className="pointer" onClick={() => handleClose()}>
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
        ) : null}
        <div className={styles.title}>
          <h2>{translations("label.name_and_email")}</h2>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.salutations}>
          <div
            className={styles.salutationItem}
            style={
              state.salutation === "Mr."
                ? {
                    background: `${getDefaultColour(defaultColour)}`,
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => setState({ ...state, salutation: "Mr." })}
          >
            {translations("label.mr")}
          </div>
          <div
            className={styles.salutationItem}
            style={
              state.salutation === "Mrs."
                ? {
                    background: `${getDefaultColour(defaultColour)}`,
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => setState({ ...state, salutation: "Mrs." })}
          >
            {translations("label.mrs")}
          </div>
          <div
            className={styles.salutationItem}
            style={
              state.salutation === "Ms."
                ? {
                    background: `${getDefaultColour(defaultColour)}`,
                    color: "#fff",
                  }
                : {}
            }
            onClick={() => setState({ ...state, salutation: "Ms." })}
          >
            {translations("label.ms")}
          </div>
        </div>

        <CustomInputComponent
          type="firstname"
          name="firstname"
          label={translations("label.first_names")}
          value={state.firstname}
          maxLength={50}
          onChange={(e) => handleChange(e)}
        />

        <CustomInputComponent
          type="lastname"
          name="lastname"
          label={translations("label.last_names")}
          value={state.lastname}
          maxLength={50}
          onChange={(e) => handleChange(e)}
        />

        <CustomInputComponent
          type="email"
          name="email"
          label={translations("label.email_address")}
          value={state.email}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleSave()}
        />
      </div>
    </div>
  );
}
