import { useTranslation } from "react-i18next";

import styles from "./BackgroundAtmosphere.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import { useEffect, useState } from "react";

import MobileBg1 from "assets/images/bg-mobile1.png";
import MobileBg2 from "assets/images/bg-mobile2.png";
import MobileBg3 from "assets/images/bg-mobile3.jpg";

import { SelectedItemIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";

import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import {
  resetDefaultColor,
  setDefaultColor,
  updateApplicationViewBegin,
} from "pages/Profile/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { setLeftPartItem, setRightPartItem } from "App/actions";

interface Props {}

export default function BackgroundAtmosphere(props: Props) {
  const {
    action: { isLoading },
    client,
    defaultBackground,
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [activeTab, setActiveTab] = useState("background");
  const [selectedBacgkround, setSelectedBackground] = useState(
    client?.background_id ? client?.background_id : defaultBackground
  );
  const [selectedColour, setSelectedColour] = useState<string>(
    client?.color ? client?.color : getDefaultColour(defaultColour)
  );

  const images = [
    {
      src: MobileBg1,
      alt: "1",
      onClick: () => setSelectedBackground("1"),
    },
    {
      src: MobileBg2,
      alt: "2",
      onClick: () => setSelectedBackground("2"),
    },
    {
      src: MobileBg3,
      alt: "3",
      onClick: () => setSelectedBackground("3"),
    },
  ];

  const colours = [
    {
      order: 1,
      name: "#540A9D",
      colour: "#540A9D",
      onClick: () => setSelectedColour("#540A9D"),
    },
    {
      order: 2,
      name: "#600F83",
      colour: "#600F83",
      onClick: () => setSelectedColour("#600F83"),
    },
    {
      order: 3,
      name: "#34054C",
      colour: "#34054C",
      onClick: () => setSelectedColour("#34054C"),
    },
  ];

  useEffect(() => {
    dispatch(setDefaultColor(selectedColour));

    return () => {
      if (defaultColour !== selectedColour) {
        dispatch(resetDefaultColor(client?.color));
      }
    };
  }, [selectedColour]);

  const handleUpdateApplicationView = () => {
    dispatch(
      updateApplicationViewBegin({
        color: selectedColour,
        background_id: selectedBacgkround,
      })
    );
    if (isMobile) {
      dispatch(setLeftPartItem("1"));
      dispatch(setRightPartItem(""));
    }
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  return (
    <div className={styles.backgroundAtmospherePageWrapper}>
      <h1>{translations("label.personalize_your_app")}</h1>

      <div className={styles.tabsContent}>
        <div className={styles.tabs}>
          <div
            className={styles.item}
            style={
              activeTab === "background"
                ? {
                    backgroundColor: getDefaultColour(defaultColour),
                    color: "#fff",
                  }
                : {
                    backgroundColor: "transparent",
                    color: getDefaultColour(defaultColour),
                  }
            }
            onClick={() => setActiveTab("background")}
          >
            {translations("label.background")}
          </div>
          <div
            className={styles.item}
            style={
              activeTab === "colour"
                ? {
                    backgroundColor: getDefaultColour(defaultColour),
                    color: "#fff",
                  }
                : {
                    backgroundColor: "transparent",
                    color: getDefaultColour(defaultColour),
                  }
            }
            onClick={() => setActiveTab("colour")}
          >
            {translations("label.colour")}
          </div>
        </div>

        {activeTab === "background" ? (
          <div className={styles.itemContainer}>
            <div className={styles.hint}>
              <span>{translations("label.personalize.background_hint")}</span>
            </div>
            <div className={styles.scrollable}>
              {images.map((item, index) => {
                return (
                  <div
                    className={styles.image}
                    onClick={() => item.onClick()}
                    key={index}
                  >
                    <img src={item.src} alt={item?.alt?.toString()} />
                    {item.alt == selectedBacgkround ? (
                      <div className={styles.selected}>
                        <SelectedItemIcon
                          color={getDefaultColour(defaultColour)}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className={styles.button}>
              <ButtonComponent
                label={translations("label.apply")}
                class={"btn btn-primary"}
                isLoading={isLoading}
                handleClick={() => handleUpdateApplicationView()}
              />
            </div>
          </div>
        ) : null}
        {activeTab === "colour" ? (
          <div className={styles.itemContainer}>
            <div className={styles.hint}>
              <span>{translations("label.personalize.colour_hint")}</span>
            </div>
            <div className={styles.colours}>
              {colours.map((item) => {
                return (
                  <div key={item.order} className={styles.selectedColour}>
                    <div
                      style={{ backgroundColor: item.colour }}
                      onClick={() => item.onClick()}
                    ></div>
                    {getDefaultColour(item.name) == selectedColour ? (
                      <div className={styles.selectedIcon}>
                        <SelectedItemIcon
                          color={getDefaultColour(defaultColour)}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div className={styles.colourBtnApply}>
              <ButtonComponent
                label={translations("label.apply")}
                class={"btn btn-primary"}
                isLoading={isLoading}
                handleClick={() => handleUpdateApplicationView()}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
