import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./ProductDetails.module.scss";
import { getDefaultColour, isProductBookmarked } from "utils/utils";
import {
  AcclaimsIcon,
  BackButtonIcon,
  BookmarksIcon,
  ColorIcon,
  DataIcon,
  NoteText,
  PairingIcon,
  RatingIcon,
  ShoppingCartAddIcon,
  ShoppingCartIcon,
  TastingIcon,
} from "assets/icons/icons";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useTranslation } from "react-i18next";
import {
  fetchProductBookmarksBegin,
  fetchProductDetailsBegin,
  setCartItems,
  setProductBookmarksCookie,
  toggleBookmarkProductBegin,
} from "../actions";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import StorageService from "services/storage.service";

interface Props {
  setShowProductDetails?: any;
}

function ProductDetails(props: Props) {
  const { setShowProductDetails } = props;
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    productDetails,
    productBookmarks,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams] = useSearchParams();
  let idParam = searchParams.get("id");
  const isMobile = useCheckMobileScreen();
  const productBookmarksCookie = () => {
    return JSON.parse(localStorage.getItem("product_bookmarks"));
  };
  const streamParamCookie = () => StorageService.getDataByKey("stream");

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  const handleAddToCart = () => {
    dispatch(setCartItems(productDetails));
    navigate("/cart");
  };

  const checkIfIsBookmarked = (id: number) => {
    return isProductBookmarked(productBookmarks, id);
  };

  const handleAddToBookmarks = (product) => {
    if (isLoggedIn) {
      dispatch(toggleBookmarkProductBegin(product?.id?.toString()));

      setTimeout(() => {
        dispatch(fetchProductBookmarksBegin(getLanguageToken()));
      }, 200);
    } else {
      if (!productBookmarksCookie()) {
        localStorage.setItem("product_bookmarks", JSON.stringify([]));
      }
      setTimeout(() => {
        dispatch(setProductBookmarksCookie(product));
      }, 0);
    }
  };

  useEffect(() => {
    if (idParam) {
      if (isLoggedIn) {
        dispatch(fetchProductBookmarksBegin(getLanguageToken()));
      }
      dispatch(fetchProductDetailsBegin(idParam, getLanguageToken()));
    }
  }, [idParam]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      {isMobile ? (
        <div className={styles.header}>
          <div
            onClick={() => {
              streamParamCookie() === "map"
                ? navigate("/purchasing?stream=map&refetch=1")
                : navigate(-1);
            }}
          >
            <BackButtonIcon color={getDefaultColour(defaultColour)} />
          </div>
          <div>
            <span>
              {productDetails?.region} | {productDetails?.bottle_size}
            </span>
          </div>
          <div
            className={styles.bookmarkIcon}
            onClick={() => handleAddToBookmarks(productDetails)}
          >
            <BookmarksIcon
              color={"#000"}
              fill={checkIfIsBookmarked(productDetails?.id) ? "#000" : null}
            />
          </div>
        </div>
      ) : null}
      <div
        className={styles.detailsWrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        {window.location.pathname !== "/purchasing/product" ? (
          <div className={styles.header}>
            <div
              className="pointer"
              onClick={() => setShowProductDetails({ id: null, show: null })}
            >
              <BackButtonIcon color={"#000"} />
            </div>
            <div className={styles.actionItems}>
              <span>{productDetails?.region}</span>
              <div className={styles.productRating}>
                <RatingIcon color={getDefaultColour(defaultColour)} />
                {productDetails?.scores.length !== 0 ? (
                  <span>
                    {productDetails?.scores[0]?.score} /{" "}
                    {productDetails?.scores[0]?.max_score}
                  </span>
                ) : (
                  <span>96 / 100</span>
                )}
              </div>
              <span>{productDetails?.bottle_size}</span>

              <div className={styles.buttons}>
                <div
                  className={styles.bookmarkIconWeb}
                  onClick={() => handleAddToBookmarks(productDetails)}
                >
                  <BookmarksIcon
                    color={"#000"}
                    fill={
                      checkIfIsBookmarked(productDetails?.id) ? "#000" : null
                    }
                  />
                </div>
                <div
                  className={styles.addToCartButton}
                  onClick={() => handleAddToCart()}
                >
                  <ShoppingCartIcon />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.mainInfo}>
          <div className={styles.leftPart}>
            {isMobile ? (
              <div className={styles.productRating}>
                <RatingIcon color={"#000"} />
                {productDetails?.scores.length !== 0 ? (
                  <span>
                    {productDetails?.scores[0]?.score} /{" "}
                    {productDetails?.scores[0]?.max_score}
                  </span>
                ) : (
                  <span>96 / 100</span>
                )}
              </div>
            ) : null}

            <div className={styles.producerLabel}>
              <span>
                {productDetails?.herotext?.length > 155
                  ? productDetails?.herotext?.substring(0, 155) + "..."
                  : productDetails?.herotext}
              </span>
            </div>
            <div className={styles.middleLabels}>
              <div>
                <span>{productDetails?.productProducer}</span>
              </div>
              <div>
                <span>{productDetails?.wine_name}</span>
              </div>
              <div>
                <span>{productDetails?.vintage}</span>
              </div>
            </div>
            {!isMobile ? (
              <div className={styles.priceLabel}>
                {productDetails?.old_price ? (
                  <>
                    <span className={styles.discount}>
                      CHF {productDetails?.old_price}
                    </span>
                    <br />
                  </>
                ) : null}
                <span>CHF {productDetails?.price}</span>
              </div>
            ) : null}
          </div>
          <div className={styles.rightPart}>
            <img
              src={productDetails?.product_image}
              alt={productDetails?.wine_name}
            />
          </div>
        </div>

        <div className={styles.labels}>
          <div className={styles.noteText}>
            <span>{productDetails?.note}</span>
            <span className={styles.verticalText}>
              <NoteText color={getDefaultColour(defaultColour)} />
            </span>
          </div>

          <div className={styles.noteText}>
            <div className={styles.data}>
              <div>
                <div>
                  <span className={styles.title}>
                    {translations("label.country")}:
                  </span>
                  <span> {productDetails?.productCountry}</span>
                </div>
                <div>
                  <span className={styles.title}>
                    {translations("label.region")}:
                  </span>
                  <span> {productDetails?.region}</span>
                </div>
                <div>
                  <span className={styles.title}>
                    {translations("label.appellation")}:
                  </span>
                  <span> {productDetails?.appellation}</span>
                </div>
                <div>
                  <span className={styles.title}>
                    {translations("label.best_before")}:
                  </span>
                  <span> {productDetails?.best_before}</span>
                </div>
              </div>

              <div>
                <div>
                  <span className={styles.title}>
                    {translations("label.vintage")}:
                  </span>
                  <span> {productDetails?.vintage}</span>
                </div>
                <div>
                  <span className={styles.title}>
                    {translations("label.type")}:
                  </span>
                  <span> {productDetails?.productType}</span>
                </div>
                <div>
                  <span className={styles.title}>
                    {translations("label.alcohol")}:
                  </span>
                  <span> {productDetails?.alcohol}</span>
                </div>
              </div>
            </div>
            <div className={styles.varietal}>
              <span className={styles.title}>
                {translations("label.grape_varietal")}:
              </span>
              <span> {productDetails?.productGrapes}</span>
            </div>

            <span className={styles.verticalText}>
              <DataIcon color={getDefaultColour(defaultColour)} />
            </span>
          </div>

          <div className={styles.noteText}>
            <span>{productDetails?.color}</span>
            <span className={styles.verticalText}>
              <ColorIcon color={getDefaultColour(defaultColour)} />
            </span>
          </div>

          <div className={styles.noteText}>
            <span>{productDetails?.tasting}</span>
            <span className={styles.verticalText}>
              <TastingIcon color={getDefaultColour(defaultColour)} />
            </span>
          </div>

          <div className={styles.noteText}>
            <span>{productDetails?.pairing}</span>
            <span className={styles.verticalText}>
              <PairingIcon color={getDefaultColour(defaultColour)} />
            </span>
          </div>

          {productDetails?.scores?.length !== 0 ? (
            <div className={styles.noteText}>
              {productDetails?.scores?.map((item) => {
                return (
                  <div className={styles.score}>
                    <span>
                      {item.score_name} {item.score} / {item.max_score}
                    </span>
                  </div>
                );
              })}
              <span className={styles.verticalText}>
                <AcclaimsIcon color={getDefaultColour(defaultColour)} />
              </span>
            </div>
          ) : null}
        </div>
        <div className={styles.deliveryDetails}>
          <div>
            <span>{translations("label.delivery_label1")}</span>
          </div>
          <div></div>
          <div>
            <span>{translations("label.delivery_label3")}</span>
          </div>
        </div>

        {isMobile ? (
          <div className={styles.header}>
            <div className={styles.actionsContent}>
              <div className={styles.mobilePriceBox}>
                {productDetails?.old_price ? (
                  <>
                    <span className={styles.discount}>
                      CHF {productDetails?.old_price}
                    </span>
                    <br />
                  </>
                ) : null}
                <span>CHF {productDetails?.price}</span>
              </div>
              <div
                className={styles.addToCartButton}
                onClick={() => handleAddToCart()}
              >
                <ShoppingCartAddIcon />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </LoaderWrapper>
  );
}

export default ProductDetails;
