import { useAppSelector, useAppDispatch } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./AdProduct.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  formatPrice,
  getDefaultColour,
  getTotalPrice,
  updateProductQuantity,
} from "utils/utils";
import { useEffect, useState } from "react";
import {
  fetchProductDetailsBegin,
  getAdProductDataBegin,
  populateCartItems,
  setCartItems,
} from "../actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import {
  RatingIcon,
  ShoppingCartAddIcon,
  MinusRoundedIcon,
  RoundedPlusIcon,
} from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import { openToaster } from "App/actions";

import { FETCH_PRODUCT_DETAILS_SUCCESS } from "../constants";
import ButtonComponent from "components/Button/Button.component";

import i18n from "localizations/config";
import { updateLanguageBegin } from "pages/Profile/actions";

function AdProduct() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const {
    action: { actionName },
    adProductData,
    productDetails,
    cartItems,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const langParam = searchParams.get("lang");
  const { t: translations } = useTranslation();
  const [showadProductDetails, setShowadProductDetails] = useState({
    id: null,
    show: false,
  });
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const adProductDetails = adProductData?.mainProduct;
  const relatedProducts = [
    adProductData?.firstRelatedProduct,
    adProductData?.secondRelatedProduct,
    adProductData?.thirdRelatedProduct,
  ].filter((item) => Object?.keys(item ?? {})?.length !== 0);
  const producerRelatedProducts = [
    adProductData?.firstProducerRelatedProduct,
    adProductData?.secondProducerRelatedProduct,
    adProductData?.thirdProducerRelatedProduct,
  ].filter((item) => Object?.keys(item ?? {})?.length !== 0);
  const [cart, setCart] = useState([]);
  const [totalPriceFormatted, setTotalPriceFormatted] = useState(
    formatPrice(Number(getTotalPrice(cart)).toFixed(2))
  );

  const producer = adProductData?.mainProduct?.productProducer;

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguage()));

    setTimeout(() => {
      navigate("/cart?order=ad");
    }, 200);
  };

  const handleQuantityChange = (type: string, id: number) => {
    if (type === "decrement") {
      setCart([...updateProductQuantity(id, "decrement", cart, true)]);
    } else {
      setCart([...updateProductQuantity(id, "increment", cart)]);
    }
  };

  const handleAddTheseToCart = () => {
    dispatch(
      populateCartItems([
        ...cartItems,
        ...cart.filter((item) => item.quantity !== 0),
      ])
    );

    setTimeout(() => {
      navigate("/cart?order=ad");
    }, 200);
  };

  const handleProductClick = (id: number) => {
    if (isMobile) {
      navigate(`/purchasing/product?id=${id}`);
    }
  };

  useEffect(() => {
    if (langParam) {
      i18n.changeLanguage(langParam);
      localStorage.setItem("lang", langParam);
      if (isLoggedIn && client?.language !== langParam) {
        dispatch(updateLanguageBegin(langParam));
      }

      if (id) {
        dispatch(
          getAdProductDataBegin({
            product_id: id,
            lang: client?.language ? client?.language : langParam,
          })
        );
      }
    }
  }, [id, langParam, getLanguage(), client?.language]);

  useEffect(() => {
    if (adProductData) {
      setCart([
        {
          ...adProductData?.mainProduct,
          quantity: 0,
          price: adProductData?.mainProduct?.price
            ? adProductData?.mainProduct?.price
            : 0.0,
        },
        {
          ...adProductData?.firstRelatedProduct,
          quantity: 0,
          price: adProductData?.firstRelatedProduct?.price
            ? adProductData?.firstRelatedProduct?.price
            : 0.0,
        },
        {
          ...adProductData?.secondRelatedProduct,
          quantity: 0,
          price: adProductData?.secondRelatedProduct?.price
            ? adProductData?.secondRelatedProduct?.price
            : 0.0,
        },
      ]);
    }
  }, [adProductData]);

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    setTotalPriceFormatted(formatPrice(Number(getTotalPrice(cart)).toFixed(2)));
  }, [cart]);

  return (
    <div
      className={styles.wrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {/* <LoaderWrapper isLoading={isLoading}>
        {isMobile ? (
          <div className={styles.adProductContent}>
            <div className={styles.quotePart}>{adProductData?.ad_quote}</div>
          </div>
        ) : null}
      </LoaderWrapper> */}
      <div
        className={styles.banner}
        style={{
          backgroundImage: `url(${
            isMobile
              ? adProductData?.mobile_image_url
              : adProductData?.image_url
          })`,
        }}
      >
        <div className={styles.quotePart}>
          {/* <span>{adProductData?.ad_quote}</span> */}
        </div>
      </div>

      <div className={styles.product}>
        <div className={styles.leftInfo}>
          <div className={styles.info}>
            <div className={styles.productRating}>
              <RatingIcon color={getDefaultColour(defaultColour)} />
              {/* {adProductData?.scores?.length >= 1 ? (
              <span>
                {adProductData?.scores[0]?.score} /{" "}
                {adProductData?.scores[0]?.max_score}
              </span>
            ) : (
              <span>96 / 100</span>
            )} */}
              <span>96 / 100</span>
            </div>
            <div className={styles.middleLabels}>
              <div className={styles.wineNameLabel}>
                <span>{adProductDetails?.wine_name}</span>
              </div>
              <div className={styles.vintageLabel}>
                <span>{adProductDetails?.vintage}</span>
              </div>
              <div className={styles.regionLabel}>
                <span>{adProductDetails?.region}</span>
              </div>
            </div>
          </div>
          <div className={styles.productImage}>
            <img src={adProductDetails?.product_image} alt={"Product"} />
          </div>
        </div>
        <div className={styles.middleInfo}>
          <div>
            <span>
              {adProductDetails?.herotext?.length > 155
                ? adProductDetails?.herotext?.substring(0, 155) + "..."
                : adProductDetails?.herotext}
            </span>

            <div className={styles.details}>
              <div>
                <span className={styles.colored}>
                  {translations("label.country")}:{" "}
                </span>
                <span> {adProductDetails?.productCountry}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.region")}:{" "}
                </span>
                <span> {adProductDetails?.region}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.appellation")}:{" "}
                </span>
                <span> {adProductDetails?.appellation}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.best_before")}:{" "}
                </span>
                <span> {adProductDetails?.best_before}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.vintage")}:{" "}
                </span>
                <span> {adProductDetails?.vintage}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.type")}:{" "}
                </span>
                <span> {adProductDetails?.productType}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.alcohol")}:{" "}
                </span>
                <span> {adProductDetails?.alcohol}</span>
              </div>
              <div>
                <span className={styles.colored}>
                  {translations("label.grape_varietal")}:{" "}
                </span>
                <span> {adProductDetails?.productGrapes}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.rightInfo}>
          <div className={styles.top}>
            <div>
              <div>
                <span>{translations("label.size")}</span>
              </div>
              <div>
                <span>{translations("label.quantity")}</span>
              </div>
              <div>
                <span>{translations("label.price")}</span>
              </div>
            </div>
            <div>
              <div>
                <span className={styles.bold}>
                  {adProductData?.mainProduct?.bottle_size}
                </span>
              </div>
              <div className={styles.quantityActions}>
                <div
                  onClick={() =>
                    handleQuantityChange(
                      "decrement",
                      adProductData?.mainProduct?.id
                    )
                  }
                >
                  <MinusRoundedIcon color={"#000"} />
                </div>
                <span>{cart[0]?.quantity}</span>
                <div
                  onClick={() =>
                    handleQuantityChange(
                      "increment",
                      adProductData?.mainProduct?.id
                    )
                  }
                >
                  <RoundedPlusIcon color={"#000"} />
                </div>
              </div>
              <div>
                <span className={styles.bold}>
                  CHF {adProductData?.mainProduct?.price}
                </span>
              </div>
            </div>
            {adProductData?.firstRelatedProduct?.id ? (
              <div>
                <div>
                  <span className={styles.bold}>
                    {adProductData?.firstRelatedProduct?.bottle_size}
                  </span>
                </div>
                <div className={styles.quantityActions}>
                  <div
                    onClick={() =>
                      handleQuantityChange(
                        "decrement",
                        adProductData?.firstRelatedProduct?.id
                      )
                    }
                  >
                    <MinusRoundedIcon color={"#000"} />
                  </div>
                  <span>{cart[1]?.quantity}</span>
                  <div
                    onClick={() =>
                      handleQuantityChange(
                        "increment",
                        adProductData?.firstRelatedProduct?.id
                      )
                    }
                  >
                    <RoundedPlusIcon color={"#000"} />
                  </div>
                </div>
                <div>
                  <span className={styles.bold}>
                    CHF {adProductData?.firstRelatedProduct?.price}
                  </span>
                </div>
              </div>
            ) : null}
            {adProductData?.secondRelatedProduct?.id ? (
              <div>
                <div>
                  <span className={styles.bold}>
                    {adProductData?.secondRelatedProduct?.bottle_size}
                  </span>
                </div>
                <div className={styles.quantityActions}>
                  <div
                    onClick={() =>
                      handleQuantityChange(
                        "decrement",
                        adProductData?.secondRelatedProduct?.id
                      )
                    }
                  >
                    <MinusRoundedIcon color={"#000"} />
                  </div>
                  <span>{cart[2]?.quantity}</span>
                  <div
                    onClick={() =>
                      handleQuantityChange(
                        "increment",
                        adProductData?.secondRelatedProduct?.id
                      )
                    }
                  >
                    <RoundedPlusIcon color={"#000"} />
                  </div>
                </div>
                <div>
                  <span className={styles.bold}>
                    CHF {adProductData?.secondRelatedProduct?.price}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.bottom}>
            <ButtonComponent
              label={translations("label.add_to_cart")}
              class={"btn btn-primary"}
              handleClick={() => handleAddTheseToCart()}
            />
            <div>
              <span>{translations("label.total")}</span>
              <span>CHF {totalPriceFormatted}</span>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER WINES OF PRODUCER */}
      <div className={styles.producerTitle}>
        {translations("label.other_wines_of") + producer}{" "}
      </div>
      <div
        className={styles.productSizes}
        style={{
          justifyContent:
            producerRelatedProducts?.length <= 2
              ? "flex-start"
              : "space-between",
        }}
      >
        {isMobile
          ? producerRelatedProducts?.map((item, index) => {
              return (
                <div key={index} className={`${styles.mobileProduct}`}>
                  <div className={styles.leftContent}>
                    <div className={styles.header}>
                      <div className={styles.region}>
                        <span>{item?.region}</span>
                      </div>
                      <div className={styles.productRating}>
                        <RatingIcon color={getDefaultColour(defaultColour)} />
                        {item?.scores?.length >= 1 ? (
                          <span>
                            {item?.scores[0]?.score} /{" "}
                            {item?.scores[0]?.max_score}
                          </span>
                        ) : (
                          <span>96 / 100</span>
                        )}
                      </div>
                    </div>
                    <div className={styles.category}>
                      <span>{item?.productProducer}</span>
                    </div>
                    <div>
                      <div className={styles.bottleInfo}>
                        <span>
                          {item?.vintage} | {item?.bottle_size}
                        </span>
                      </div>
                      <div className={styles.price}>
                        {adProductData?.mainProduct?.old_price ? (
                          <>
                            <span className={styles.discount}>
                              CHF
                              {formatPrice(
                                adProductData?.mainProduct.old_price?.toString()
                              )}
                            </span>
                            <br />
                          </>
                        ) : null}
                        <span>CHF {formatPrice(item?.price?.toString())}</span>
                      </div>
                      <div className={styles.name}>
                        <span>
                          {item?.wine_name?.length > 25
                            ? item?.wine_name?.substring(0, 25) + "..."
                            : item?.wine_name}
                        </span>
                      </div>

                      <div
                        className={styles.addToCartButton}
                        onClick={() => handleAddToCart(item.id)}
                      >
                        <ShoppingCartAddIcon />
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.rightContent}
                    onClick={() => handleProductClick(item?.id)}
                  >
                    <img
                      className={styles.productImage}
                      src={item?.product_image}
                      alt={item?.productProducer}
                    />
                  </div>
                </div>
              );
            })
          : producerRelatedProducts?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.product}
                  style={{
                    marginRight: producerRelatedProducts?.length <= 2 ? 20 : 0,
                  }}
                >
                  <div className={styles.infoParts}>
                    {item?.is_special ? (
                      <div className={styles.specialInfo}>
                        <div className={styles.productRating}>
                          <RatingIcon />
                          {item?.scores?.length >= 1 ? (
                            <span>
                              {item?.scores[0]?.score} /{" "}
                              {item?.scores[0]?.max_score}
                            </span>
                          ) : (
                            <span>96 / 100</span>
                          )}
                        </div>
                        <div>
                          <div
                            className={styles.name}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <div>
                              <span>
                                {showadProductDetails.show &&
                                item?.wine_name?.length > 15
                                  ? item?.wine_name?.substring(0, 15) + "..."
                                  : item?.wine_name}
                              </span>
                            </div>
                            <br />
                            <div
                              className={styles.vintageLabel}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <span>{item?.vintage}</span>
                            </div>
                          </div>

                          <div
                            className={styles.bottleSizeLabel}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <span>{item?.bottle_size}</span>
                          </div>
                          <div
                            className={styles.regionLabel}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <span>{item?.region}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div
                          className={styles.productRating}
                          onClick={() => handleProductClick(item?.id)}
                        >
                          <RatingIcon />
                          {item?.scores?.length >= 1 ? (
                            <span>
                              {item?.scores[0]?.score} /{" "}
                              {item?.scores[0]?.max_score}
                            </span>
                          ) : (
                            <span>96 / 100</span>
                          )}
                        </div>

                        <div>
                          <div
                            className={styles.name}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <div>
                              <span>
                                {showadProductDetails.show &&
                                item?.wine_name?.length > 15
                                  ? item?.wine_name?.substring(0, 15) + "..."
                                  : item?.wine_name}
                              </span>
                            </div>
                            <br />
                            <div
                              className={styles.vintageLabel}
                              onClick={() => handleProductClick(item?.id)}
                            >
                              <span>{item?.vintage}</span>
                            </div>
                          </div>

                          <div
                            className={styles.bottleSizeLabel}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <span>{item?.bottle_size}</span>
                          </div>
                          <div
                            className={styles.regionLabel}
                            onClick={() => handleProductClick(item?.id)}
                          >
                            <span>{item?.region}</span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className={styles.bottoms}>
                      <div
                        className={styles.price}
                        onClick={() => handleProductClick(item?.id)}
                      >
                        {item?.old_price ? (
                          <>
                            <span className={styles.discount}>
                              CHF
                              {formatPrice(item.old_price?.toString())}
                            </span>
                            <br />
                          </>
                        ) : null}
                        <span>CHF {item?.price}</span>
                      </div>

                      <div className={styles.productActions}>
                        <div
                          className={styles.addToCartButton}
                          onClick={() => handleAddToCart(item.id)}
                        >
                          <ShoppingCartAddIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.imagePart}
                    onClick={() => handleProductClick(item?.id)}
                  >
                    <img
                      className={styles.productImage}
                      style={{
                        height: 260,
                      }}
                      src={item?.product_image}
                      alt={item?.productProducer}
                    />
                  </div>

                  <div className={styles.productInfo}>
                    <div className={styles.boldText}>
                      <span>{item?.hover_text}</span>
                    </div>
                    <div>
                      <span>{item?.pairing}</span>
                    </div>
                    {/* <div
                      className={`pointer ${styles.boldText}`}
                      onClick={() => handleProductClick(item?.id)}
                    >
                      <ViewDetailsArrowRight
                        color={getDefaultColour(defaultColour)}
                      />
                      <span>{translations("label.details")}</span>
                    </div> */}
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}

export default AdProduct;
