import { useTranslation } from "react-i18next";

import styles from "./AddressDetails.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import CustomInputComponent from "components/CustomInput/CustomInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import { GlobalIState } from "types/RootState";
import { clearAction, updateAddressDetailsBegin } from "../actions";
import SelectComponent from "components/Select/Select.component";
import {
  getCountrySelectOptions,
  checkSelectValueOnInit,
  getDefaultColour,
} from "utils/utils";

import _ from "lodash";
import { setLeftPartItem, setMiddlepartItem } from "App/actions";
import { UPDATE_ADDRESS_DETAILS_SUCCESS } from "../constants";
import { AddressIcon, CopyIcon } from "assets/icons/icons";
import { fetchCountriesBegin } from "pages/Authentication/actions";

interface Props {}

export default function AddressDetails(props: Props) {
  const {
    client,
    action: { isLoading, isSuccess, actionName },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [state, setState] = useState({
    residency_name:
      client?.residency_name?.length > 0
        ? client?.residency_name
        : client?.firstname + " " + client?.lastname,
    ra_street: client?.ra_street ?? null,
    ra_city: client?.ra_city ?? null,
    ra_zip: client?.ra_zip ?? null,
    ra_country: client?.ra_country !== null ? client?.ra_country : null,
    delivery_name:
      client?.delivery_name?.length > 0
        ? client?.delivery_name
        : client?.firstname + " " + client?.lastname,
    da_street: client?.da_street ?? null,
    delivery_company_name: client?.delivery_company_name ?? null,
    da_city: client?.da_city ?? null,
    da_zip: client?.da_zip ?? null,
    da_country: client?.da_country !== null ? client?.da_country : null,
    billing_name:
      client?.billing_name?.length > 0
        ? client?.billing_name
        : client?.firstname + " " + client?.lastname,
    billing_street: client?.billing_street ?? null,
    billing_city: client?.billing_city ?? null,
    billing_zip: client?.billing_zip ?? null,
    billing_country:
      client?.billing_country !== null ? client?.billing_country : null,
    billing_company_name: client?.billing_company_name ?? null,
  });
  const [deliveryAddOption, setDeliveryAddOption] = useState("sameAsResidency");
  const [billingAddOption, setBillingAddOption] = useState("sameAsResidency");

  const disabled = _.isEqual(state, {
    residency_name: client?.residency_name,
    ra_street: client?.ra_street,
    ra_city: client?.ra_city,
    ra_zip: client?.ra_zip,
    ra_country: client?.ra_country,
    delivery_name: client?.delivery_name,
    delivery_company_name: client?.delivery_company_name,
    da_street: client?.da_street,
    da_city: client?.da_city,
    da_zip: client?.da_zip,
    da_country: client?.da_country,
    billing_name: client?.billing_name,
    billing_street: client?.billing_street,
    billing_city: client?.billing_city,
    billing_zip: client?.billing_zip,
    billing_country: client?.billing_country,
    billing_company_name: client?.billing_company_name,
  });
  const normalize = (value) => (value === undefined ? null : value);

  const isDeliverySameAsResidency = _.isEqual(
    {
      name: normalize(state.delivery_name),
      street: normalize(state.da_street),
      city: normalize(state.da_city),
      zip: normalize(state.da_zip),
      country: normalize(state.da_country),
    },
    {
      name: normalize(client?.residency_name),
      street: normalize(client?.ra_street),
      city: normalize(client?.ra_city),
      zip: normalize(client?.ra_zip),
      country: normalize(client?.ra_country),
    }
  );

  const isBillingSameAsResidency = _.isEqual(
    {
      name: normalize(state.billing_name),
      street: normalize(state.billing_street),
      city: normalize(state.billing_city),
      zip: normalize(state.billing_zip),
      country: normalize(state.billing_country),
    },
    {
      name: normalize(client?.residency_name),
      street: normalize(client?.ra_street),
      city: normalize(client?.ra_city),
      zip: normalize(client?.ra_zip),
      country: normalize(client?.ra_country),
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    const capitalizedValue =
      value.length === 1
        ? value.charAt(0).toUpperCase() + value.slice(1)
        : value;

    setState((prev) => ({
      ...prev,
      [name]: capitalizedValue,
    }));
  };

  const handleAddressUpdate = () => {
    let data = {
      residency_name: state.residency_name,
      ra_street: state.ra_street,
      ra_city: state.ra_city,
      ra_zip: state.ra_zip,
      ra_country: checkSelectValueOnInit(state.ra_country),
      delivery_name:
        deliveryAddOption === "sameAsResidency"
          ? state.residency_name
          : state.delivery_name,
      delivery_company_name: state.delivery_company_name,
      da_street:
        deliveryAddOption === "sameAsResidency"
          ? state.ra_street
          : state.da_street,
      da_city:
        deliveryAddOption === "sameAsResidency" ? state.ra_city : state.da_city,
      da_zip:
        deliveryAddOption === "sameAsResidency" ? state.ra_zip : state.da_zip,
      da_country:
        deliveryAddOption === "sameAsResidency"
          ? checkSelectValueOnInit(state.ra_country)
          : checkSelectValueOnInit(state.da_country),
      billing_name:
        deliveryAddOption === "sameAsResidency"
          ? state.residency_name
          : state.billing_name,
      billing_company_name: client?.billing_company_name,
      billing_street:
        billingAddOption === "sameAsResidency"
          ? state.ra_street
          : state.billing_street,
      billing_city:
        billingAddOption === "sameAsResidency"
          ? state.ra_city
          : state.billing_city,
      billing_zip:
        billingAddOption === "sameAsResidency"
          ? state.ra_zip
          : state.billing_zip,
      billing_country:
        billingAddOption === "sameAsResidency"
          ? checkSelectValueOnInit(state.ra_country)
          : checkSelectValueOnInit(state.billing_country),
    };
    dispatch(updateAddressDetailsBegin(data));
  };

  useEffect(() => {
    if (!countries) {
      dispatch(fetchCountriesBegin("withAuth"));
    }
  }, [countries]);

  useEffect(() => {
    if (isDeliverySameAsResidency) {
      setDeliveryAddOption("sameAsResidency");
    } else setDeliveryAddOption("other");

    if (isBillingSameAsResidency) {
      setBillingAddOption("sameAsResidency");
    } else setBillingAddOption("other");
  }, [isDeliverySameAsResidency, isBillingSameAsResidency, client]);

  useEffect(() => {
    if (isSuccess && actionName === UPDATE_ADDRESS_DETAILS_SUCCESS) {
      if (isMobile) {
        dispatch(setLeftPartItem("1"));
      } else {
        dispatch(setMiddlepartItem(null));
      }
    }
    return () => {
      dispatch(clearAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <div
      className={styles.addressDetailsWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h1>{translations("label.address_details")}</h1>

      <div className={styles.fields}>
        <div className={styles.header}>
          <div>
            <h2>{translations("label.residency_address")}</h2>
          </div>
        </div>
        <CustomInputComponent
          type="text"
          name="residency_name"
          className="secondInput"
          placeholder={translations("label.full_name")}
          value={state.residency_name}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="ra_street"
          className="secondInput"
          placeholder={translations("label.street")}
          value={state.ra_street}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="ra_city"
          className="secondInput"
          placeholder={translations("label.city")}
          value={state.ra_city}
          onChange={(e) => handleChange(e)}
        />
        <CustomInputComponent
          type="text"
          name="ra_zip"
          className="secondInput"
          placeholder={translations("label.zip_code")}
          value={state.ra_zip}
          onChange={(e) => handleChange(e)}
        />
        <SelectComponent
          name={"ra_country"}
          className="secondSelectStyle"
          label={translations("label.country")}
          value={state.ra_country ? state.ra_country : 0}
          options={getCountrySelectOptions(countries)}
          onChange={(e) => handleChange(e)}
        />
      </div>

      <div className={styles.fields}>
        <div className={styles.header}>
          <div>
            <h2>{translations("label.delivery_address")}</h2>
          </div>
        </div>

        <div
          className={styles.addressOptions}
          onClick={() => setDeliveryAddOption("sameAsResidency")}
        >
          <div
            style={{
              backgroundColor:
                deliveryAddOption === "sameAsResidency"
                  ? getDefaultColour(defaultColour)
                  : "transparent",
            }}
            className={styles.radio}
          ></div>
          <div>
            <span>{translations("label.same_as_residency_address")}</span>
          </div>
        </div>
        <div
          className={styles.addressOptions}
          onClick={() => setDeliveryAddOption("other")}
        >
          <div
            style={{
              backgroundColor:
                deliveryAddOption === "other"
                  ? getDefaultColour(defaultColour)
                  : "transparent",
            }}
            className={styles.radio}
          ></div>
          <div>
            <span>{translations("label.other_delivery_address")}</span>
          </div>
        </div>
        {deliveryAddOption === "other" ? (
          <div style={{ marginTop: 20 }}>
            <CustomInputComponent
              type="text"
              name="delivery_name"
              className="secondInput"
              placeholder={translations("label.full_name")}
              value={state.delivery_name}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="delivery_company_name"
              className="secondInput"
              placeholder={"c/o"}
              value={state.delivery_company_name}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="da_street"
              className="secondInput"
              placeholder={translations("label.street")}
              value={state.da_street}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="da_city"
              className="secondInput"
              placeholder={translations("label.city")}
              value={state.da_city}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="da_zip"
              className="secondInput"
              placeholder={translations("label.zip_code")}
              value={state.da_zip}
              onChange={(e) => handleChange(e)}
            />
            <SelectComponent
              name={"da_country"}
              className="secondSelectStyle"
              label={translations("label.country")}
              value={state.da_country ? state.da_country : 0}
              options={getCountrySelectOptions(countries)}
              style={{
                pointerEvents: state?.da_country === "219" ? "none" : "all",
              }}
              onChange={(e) => handleChange(e)}
            />
          </div>
        ) : null}
      </div>

      <div className={styles.fields}>
        <div className={styles.header}>
          <div>
            <h2>{translations("label.billing_address")}</h2>
          </div>
        </div>
        <div
          className={styles.addressOptions}
          onClick={() => setBillingAddOption("sameAsResidency")}
        >
          <div
            style={{
              backgroundColor:
                billingAddOption === "sameAsResidency"
                  ? getDefaultColour(defaultColour)
                  : "transparent",
            }}
            className={styles.radio}
          ></div>
          <div>
            <span>{translations("label.same_as_residency_address")}</span>
          </div>
        </div>
        <div
          className={styles.addressOptions}
          onClick={() => setBillingAddOption("other")}
        >
          <div
            style={{
              backgroundColor:
                billingAddOption === "other"
                  ? getDefaultColour(defaultColour)
                  : "transparent",
            }}
            className={styles.radio}
          ></div>
          <div>
            <span>{translations("label.other_billing_address")}</span>
          </div>
        </div>

        {billingAddOption === "other" ? (
          <div style={{ marginTop: 20 }}>
            <CustomInputComponent
              type="text"
              name="billing_name"
              className="secondInput"
              placeholder={translations("label.full_name")}
              value={state.billing_name}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_company_name"
              className="secondInput"
              placeholder={translations("c/o")}
              value={state.billing_company_name}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_street"
              className="secondInput"
              placeholder={translations("label.street")}
              value={state.billing_street}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_city"
              className="secondInput"
              placeholder={translations("label.city")}
              value={state.billing_city}
              onChange={(e) => handleChange(e)}
            />
            <CustomInputComponent
              type="text"
              name="billing_zip"
              className="secondInput"
              placeholder={translations("label.zip_code")}
              value={state.billing_zip}
              onChange={(e) => handleChange(e)}
            />
            <SelectComponent
              name={"billing_country"}
              className="secondSelectStyle"
              label={translations("label.country")}
              value={state.billing_country ? state.billing_country : 0}
              options={getCountrySelectOptions(countries)}
              onChange={(e) => handleChange(e)}
            />
          </div>
        ) : null}
      </div>
      <div className={styles.buttonContainer}>
        {/* {isReadOnly ? (
          <ButtonComponent
            label={translations("button.change")}
            class={"btn btn-white-bordered"}
            isLoading={isLoading}
            handleClick={() => setIsReadOnly(false)}
          />
        ) : ( */}
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          disabled={disabled}
          handleClick={() => handleAddressUpdate()}
        />
        {/* )} */}
      </div>
    </div>
  );
}
