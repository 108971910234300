import { useTranslation } from "react-i18next";

import styles from "./EmailConfirm.module.scss";

import { useAppDispatch } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

import { useState } from "react";

interface Props {}

export default function EmailConfirm(props: Props) {
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [newEmail, setNewEmail] = useState("");

  return (
    <div className={styles.confirmByEmailWrapper}>
      <h1>{translations("label.confirm_by_email")}</h1>
      <span className={styles.linkSentLabel}>
        {translations("label.email_confirmation_link_sent")}
      </span>

      <h2 className={styles.highlightedTitle}>
        {translations("label.check_your_email_inbox")}
      </h2>

      <span className={styles.hintText}>
        {translations("label.confirm_by_email_hint")}
      </span>

      <div
        className={styles.resendEmail}
        onClick={() => console.log("Resend email")}
      >
        <span>{translations("label.resend_email")}</span>
      </div>
    </div>
  );
}
