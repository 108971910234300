import { useTranslation } from "react-i18next";

import styles from "./ResetPasswordEmailConfirm.module.scss";

interface Props {}

export default function ResetPasswordEmailConfirm(props: Props) {
  const { t: translations } = useTranslation();

  return (
    <div className={styles.pwResetEmailConfirmWrapper}>
      <div>
        <h1>{translations("label.confirm_by_email")}</h1>
        <span className={styles.linkSentLabel}>
          {translations("label.email_confirmation_link_sent")}
        </span>

        <h2 className={styles.highlightedTitle}>
          {translations("label.check_your_email_inbox")}
        </h2>

        <span className={styles.hintText}>
          {translations("label.confirm_by_email_hint")}
        </span>

        <div
          className={styles.resendEmail}
          onClick={() => console.log("Resend email")}
        >
          <span>{translations("label.resend_email")}</span>
        </div>
      </div>
    </div>
  );
}
