import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import styles from "./ThanksForFeedback.module.scss";
import { getDefaultColour } from "utils/utils";
import { ThanksForFeedbackIcon } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";

function ThanksForFeedback() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/purchasing");
  };

  return (
    <div
      className={styles.thankYouWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <ThanksForFeedbackIcon color={getDefaultColour(defaultColour)} />
      <span className={styles.label}>{translations("label.thank_you")}</span>
      <span className={styles.subtitle}>
        {translations("label.thank_you.subtitle")}
      </span>

      <div className={styles.closeButton}>
        <ButtonComponent
          label={translations("button.close")}
          class={"btn btn-primary"}
          handleClick={() => handleClose()}
        />
      </div>
    </div>
  );
}

export default ThanksForFeedback;
