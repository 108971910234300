import { produce } from "immer";

import { ProfileReducerInterface } from "types/reducers/profile.reducer.type";
import {
  ADD_EMAIL_BEGIN,
  ADD_EMAIL_FAILED,
  ADD_EMAIL_SUCCESS,
  ADD_PHONE_BEGIN,
  ADD_PHONE_FAILED,
  ADD_PHONE_SUCCESS,
  AGREE_TO_PRIVACY_POLICY,
  AGREE_TO_TERMS_AND_CONDITIONS,
  CHANGE_AUTH_SECURITY_BEGIN,
  CHANGE_AUTH_SECURITY_FAILED,
  CHANGE_AUTH_SECURITY_SUCCESS,
  CHANGE_EMAIL_BEGIN,
  CHANGE_EMAIL_FAILED,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PHONE_NUMBER_BEGIN,
  CHANGE_PHONE_NUMBER_FAILED,
  CHANGE_PHONE_NUMBER_SUCCESS,
  CHECK_IF_HAS_SAVED_CARD_BEGIN,
  CHECK_IF_HAS_SAVED_CARD_FAILED,
  CHECK_IF_HAS_SAVED_CARD_SUCCESS,
  CLEAR_ACTION,
  CLEAR_CLIENT,
  CLEAR_S3_LINK,
  CONFIRM_ADD_EMAIL_BEGIN,
  CONFIRM_ADD_EMAIL_FAILED,
  CONFIRM_ADD_EMAIL_SUCCESS,
  CONFIRM_ADD_PHONE_BEGIN,
  CONFIRM_ADD_PHONE_FAILED,
  CONFIRM_ADD_PHONE_SUCCESS,
  CONFIRM_CHANGE_EMAIL_BEGIN,
  CONFIRM_CHANGE_EMAIL_SUCCESS,
  CONFIRM_CHANGE_PHONE_NUMBER_BEGIN,
  CONFIRM_CHANGE_PHONE_NUMBER_FAILED,
  CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS,
  CONFIRM_PASSWORD_CHANGE_BEGIN,
  CONFIRM_PASSWORD_CHANGE_FAILED,
  DEACTIVATE_TWO_FACTOR_AUTH_BEGIN,
  DEACTIVATE_TWO_FACTOR_AUTH_FAILED,
  DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS,
  DECLINE_PRIVACY_POLICY,
  DECLINE_TERMS_AND_CONDITIONS,
  GENERATE_TWO_FACTOR_AUTH_BEGIN,
  GENERATE_TWO_FACTOR_AUTH_FAILED,
  GENERATE_TWO_FACTOR_AUTH_SUCCESS,
  GET_CLIENT_BEGIN,
  GET_CLIENT_FAILED,
  GET_CLIENT_SUCCESS,
  GET_PENDING_DELIVERY_BALANCE_BEGIN,
  GET_PENDING_DELIVERY_BALANCE_FAILED,
  GET_PENDING_DELIVERY_BALANCE_SUCCESS,
  GET_PRIVACY_POLICY,
  GET_PRIVACY_POLICY_SUCCESS,
  GET_REFERRAL_CODE_BEGIN,
  GET_REFERRAL_CODE_FAILED,
  GET_REFERRAL_CODE_SUCCESS,
  GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN,
  GET_S3_UPLOAD_REFERRAL_IMAGE_FAILED,
  GET_S3_UPLOAD_REFERRAL_IMAGE_SUCCESS,
  GET_TERMS_AND_CONDITIONS,
  GET_TERMS_AND_CONDITIONS_SUCCESS,
  GET_USER_REFERRALS_BEGIN,
  GET_USER_REFERRALS_FAILED,
  GET_USER_REFERRALS_SUCCESS,
  GET_WALLET_TOTAL_AMOUNT_BEGIN,
  GET_WALLET_TOTAL_AMOUNT_FAILED,
  GET_WALLET_TOTAL_AMOUNT_SUCCESS,
  REQUEST_ACCOUNT_CLOSURE,
  RESET_DEFAULT_COLOR,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_SUCCESS,
  SET_DEFAULT_COLOR,
  UPDATE_ADDRESS_DETAILS_BEGIN,
  UPDATE_ADDRESS_DETAILS_FAILED,
  UPDATE_ADDRESS_DETAILS_SUCCESS,
  UPDATE_APPLICATION_VIEW_BEGIN,
  UPDATE_APPLICATION_VIEW_FAILED,
  UPDATE_APPLICATION_VIEW_SUCCESS,
  UPDATE_LANGUAGE_BEGIN,
  UPDATE_LANGUAGE_FAILED,
  UPDATE_LANGUAGE_SUCCESS,
  UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN,
  UPDATE_NOTIFICATION_IDENTIFICATION_FAILED,
  UPDATE_NOTIFICATION_IDENTIFICATION_SUCCESS,
  UPDATE_PERSONAL_DETAILS_BEGIN,
  UPDATE_PERSONAL_DETAILS_FAILED,
  UPDATE_PERSONAL_DETAILS_SUCCESS,
  UPDATE_PROFILE_PICTURE_BEGIN,
  UPDATE_PROFILE_PICTURE_FAILED,
  UPDATE_PROFILE_PICTURE_SUCCESS,
  UPDATE_USER_INFO_BEGIN,
  UPDATE_USER_INFO_FAILED,
  UPDATE_USER_INFO_SUCCESS,
  VERIFY_TWO_FACTOR_AUTH_BEGIN,
  VERIFY_TWO_FACTOR_AUTH_FAILED,
  VERIFY_TWO_FACTOR_AUTH_SUCCESS,
} from "./constants";

import i18n from "localizations/config";

const getApplicationViewDefaults = () => {
  return JSON.parse(localStorage.getItem("applicationViewSettings"));
};

const initialState: ProfileReducerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  client: null,
  defaultBackground: getApplicationViewDefaults()?.background_id ?? "1",
  defaultColour: getApplicationViewDefaults()?.color ?? "#600F83",
  termsAndConditions: null,
  privacyPolicy: null,
  pending_delivery_balance: null,
  balance_total_amount_balance: null,
  referralLink: "",
  qrCode: "",
  referredClients: null,
  hasSavedCard: false,
  twoFactorQrCode: "",
  manualEntryAuthenticationCode: "",
  s3UploadImageReferralLink: "",
};

const ProfilePageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CLEAR_ACTION:
        draft.action = initialState.action;
        break;
      case CHANGE_PASSWORD_BEGIN:
      case GET_CLIENT_BEGIN:
      case UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN:
      case UPDATE_LANGUAGE_BEGIN:
      case RESET_PASSWORD_BEGIN:
      case UPDATE_PROFILE_PICTURE_BEGIN:
      case UPDATE_PERSONAL_DETAILS_BEGIN:
      case UPDATE_ADDRESS_DETAILS_BEGIN:
      case UPDATE_APPLICATION_VIEW_BEGIN:
      case GET_TERMS_AND_CONDITIONS:
      case GET_PRIVACY_POLICY:
      case ADD_PHONE_BEGIN:
      case ADD_EMAIL_BEGIN:
      case GET_PENDING_DELIVERY_BALANCE_BEGIN:
      case CONFIRM_PASSWORD_CHANGE_BEGIN:
      case CHANGE_EMAIL_BEGIN:
      case CONFIRM_CHANGE_EMAIL_BEGIN:
      case CONFIRM_ADD_EMAIL_BEGIN:
      case CHANGE_PHONE_NUMBER_BEGIN:
      case CONFIRM_CHANGE_PHONE_NUMBER_BEGIN:
      case CONFIRM_ADD_PHONE_BEGIN:
      case GET_REFERRAL_CODE_BEGIN:
      case GET_USER_REFERRALS_BEGIN:
      case CHECK_IF_HAS_SAVED_CARD_BEGIN:
      case GENERATE_TWO_FACTOR_AUTH_BEGIN:
      case VERIFY_TWO_FACTOR_AUTH_BEGIN:
      case DEACTIVATE_TWO_FACTOR_AUTH_BEGIN:
      case GET_WALLET_TOTAL_AMOUNT_BEGIN:
      case UPDATE_USER_INFO_BEGIN:
      case CHANGE_AUTH_SECURITY_BEGIN:
      case GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case CHANGE_PASSWORD_FAILED:
      case GET_CLIENT_FAILED:
      case UPDATE_NOTIFICATION_IDENTIFICATION_FAILED:
      case UPDATE_LANGUAGE_FAILED:
      case RESET_PASSWORD_FAILED:
      case UPDATE_PROFILE_PICTURE_FAILED:
      case UPDATE_PERSONAL_DETAILS_FAILED:
      case UPDATE_ADDRESS_DETAILS_FAILED:
      case UPDATE_APPLICATION_VIEW_FAILED:
      case ADD_PHONE_FAILED:
      case ADD_EMAIL_FAILED:
      case GET_PENDING_DELIVERY_BALANCE_FAILED:
      case CONFIRM_PASSWORD_CHANGE_FAILED:
      case CHANGE_EMAIL_FAILED:
      case CONFIRM_PASSWORD_CHANGE_FAILED:
      case CONFIRM_ADD_EMAIL_FAILED:
      case CHANGE_PHONE_NUMBER_FAILED:
      case CONFIRM_CHANGE_PHONE_NUMBER_FAILED:
      case CONFIRM_ADD_PHONE_FAILED:
      case GET_REFERRAL_CODE_FAILED:
      case GET_USER_REFERRALS_FAILED:
      case CHECK_IF_HAS_SAVED_CARD_FAILED:
      case GENERATE_TWO_FACTOR_AUTH_FAILED:
      case VERIFY_TWO_FACTOR_AUTH_FAILED:
      case DEACTIVATE_TWO_FACTOR_AUTH_FAILED:
      case GET_WALLET_TOTAL_AMOUNT_FAILED:
      case UPDATE_USER_INFO_FAILED:
      case CHANGE_AUTH_SECURITY_FAILED:
      case GET_S3_UPLOAD_REFERRAL_IMAGE_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case CHANGE_PASSWORD_SUCCESS:
      case UPDATE_LANGUAGE_SUCCESS:
      case RESET_PASSWORD_SUCCESS:
      case REQUEST_ACCOUNT_CLOSURE:
      case CHANGE_PASSWORD_SUCCESS:
      case CHANGE_EMAIL_SUCCESS:
      case CONFIRM_CHANGE_EMAIL_SUCCESS:
      case CONFIRM_ADD_EMAIL_SUCCESS:
      case CHANGE_PHONE_NUMBER_SUCCESS:
      case CONFIRM_CHANGE_PHONE_NUMBER_SUCCESS:
      case CONFIRM_ADD_PHONE_SUCCESS:
      case UPDATE_USER_INFO_SUCCESS:
      case CHANGE_AUTH_SECURITY_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case UPDATE_APPLICATION_VIEW_SUCCESS:
      case ADD_PHONE_SUCCESS:
      case ADD_EMAIL_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client = action.payload;
        break;
      case GET_CLIENT_SUCCESS:
        localStorage.setItem("userEmail", action.payload.email);
        if (action?.payload?.language) {
          localStorage.setItem(
            "lang",
            action?.payload?.language?.toString()?.toLowerCase()
          );
          i18n.changeLanguage(
            action?.payload?.language?.toString()?.toLowerCase()
          );
        } else localStorage.setItem("lang", "en");
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client = action.payload;
        draft.defaultColour = action.payload.color;
        draft.defaultBackground = action.payload.background_id;
        break;
      case GET_TERMS_AND_CONDITIONS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.termsAndConditions = action.payload;
        break;
      case GET_PRIVACY_POLICY_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.privacyPolicy = action.payload;
        break;
      case AGREE_TO_TERMS_AND_CONDITIONS:
        draft.client.terms_id = "2";
        break;
      case DECLINE_TERMS_AND_CONDITIONS:
        draft.client.terms_id = null;
        break;
      case AGREE_TO_PRIVACY_POLICY:
        draft.client.policy_id = "2";
        break;
      case DECLINE_PRIVACY_POLICY:
        draft.client.policy_id = null;
        break;
      case UPDATE_NOTIFICATION_IDENTIFICATION_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client = action.payload;
        break;
      case UPDATE_PROFILE_PICTURE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client.profile_picture = action.payload;
        break;
      case UPDATE_PERSONAL_DETAILS_SUCCESS:
      case UPDATE_ADDRESS_DETAILS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client = action.payload;
        break;
      case SET_DEFAULT_COLOR:
        draft.defaultColour = action.payload;
        break;
      case RESET_DEFAULT_COLOR:
        draft.defaultColour = action.payload;
        break;
      case GET_PENDING_DELIVERY_BALANCE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.pending_delivery_balance = action.payload;
        break;
      case GET_WALLET_TOTAL_AMOUNT_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.balance_total_amount_balance = action.payload;
        break;
      case GET_REFERRAL_CODE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.referralLink = action.payload.referralLink;
        draft.qrCode = action.payload.qrCode;
        break;
      case GET_USER_REFERRALS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.referredClients = action.payload;
        break;
      case CHECK_IF_HAS_SAVED_CARD_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.hasSavedCard = action.payload;
        break;
      case GENERATE_TWO_FACTOR_AUTH_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.twoFactorQrCode = action.payload?.qrCode;
        draft.manualEntryAuthenticationCode = action.payload?.manualEntryKey;
        break;
      case VERIFY_TWO_FACTOR_AUTH_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client.two_factor_auth_enabled = true;
        break;
      case DEACTIVATE_TWO_FACTOR_AUTH_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.client.two_factor_auth_enabled = false;
        break;
      case GET_S3_UPLOAD_REFERRAL_IMAGE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.s3UploadImageReferralLink = action.payload;
        break;
      case CLEAR_S3_LINK:
        draft.s3UploadImageReferralLink = "";
        break;
      case CLEAR_CLIENT:
        draft.client = null;
        break;
      default:
        break;
    }
  });

export default ProfilePageReducer;
