import { useTranslation } from "react-i18next";
import styles from "./ResetPasscode.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import { GlobalIState } from "types/RootState";

import PasscodeInput from "components/PasscodeInput/PasscodeInput.component";
import { useEffect, useState } from "react";
import {
  HidePasswordIcon,
  PreviousIcon,
  ShowPasswordIcon,
} from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { openToaster } from "App/actions";
import { resetPasswordBegin } from "pages/Profile/actions";
import { RESET_PASSWORD_SUCCESS } from "pages/Profile/constants";

export default function ResetPasscode() {
  const {
    action: { actionName, isLoading, error },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [passcode, setPasscode] = useState("");
  const [showPasscode, setShowPasscode] = useState(true);
  const token = searchParams.get("token");

  const handleSave = () => {
    if (passcode?.length !== 4) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_passcode_field"))
      );
      return;
    }
    let data = {
      token: token,
      password: passcode,
    };

    dispatch(resetPasswordBegin(data));
  };

  useEffect(() => {
    if (actionName === RESET_PASSWORD_SUCCESS) {
      dispatch(openToaster("SUCCESS", translations("label.passcode_changed")));
      searchParams.delete("token");
      searchParams.delete("email");
      setSearchParams(searchParams);
      navigate("/login");
    }
  }, [actionName]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={styles.previousIcon}
          onClick={() => {
            navigate(-1);
          }}
        >
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>

      <div className={styles.content}>
        <h1>{translations("label.passcode")}</h1>

        <div className={styles.passcodeContainer}>
          <h3>{translations("label.create_4_digit_passcode")}</h3>
          <div
            className={styles.showPasscodeLabel}
            onClick={() => setShowPasscode(!showPasscode)}
          >
            <span>
              {translations(
                showPasscode ? "label.hide_passcode" : "label.show_passcode"
              )}
            </span>
            <div>
              {showPasscode ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>
          <PasscodeInput
            length={4}
            showPasscode={showPasscode}
            onChange={setPasscode}
          />
        </div>

        <div className={styles.saveButton}>
          <ButtonComponent
            label={translations("button.save")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
