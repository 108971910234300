import { produce } from "immer";
import { GamificationReducerReducerInterface } from "types/reducers/gamification.reducer.type";
import {
  CHECK_ANSWER_BEGIN,
  CHECK_ANSWER_FAILED,
  CHECK_ANSWER_SUCCESS,
  CHECK_BLANK_ANSWER_BEGIN,
  CHECK_BLANK_ANSWER_FAILED,
  CHECK_BLANK_ANSWER_SUCCESS,
  CLEAR_CHECK_ANSWER,
  GET_CLIENT_OBJECTIVES_BEGIN,
  GET_CLIENT_OBJECTIVES_FAILED,
  GET_CLIENT_OBJECTIVES_SUCCESS,
  GET_CLIENT_TIMELINE_BEGIN,
  GET_CLIENT_TIMELINE_FAILED,
  GET_CLIENT_TIMELINE_SUCCESS,
  GET_MEDALS_LEAGUE_TABLE_BEGIN,
  GET_MEDALS_LEAGUE_TABLE_FAILED,
  GET_MEDALS_LEAGUE_TABLE_SUCCESS,
  GET_REFERRALS_TABLE_BEGIN,
  GET_REFERRALS_TABLE_FAILED,
  GET_REFERRALS_TABLE_SUCCESS,
  GET_TODAYS_GAME_SUCCESS,
  GET_TODAYS_QUESTION_BEGIN,
  GET_TODAYS_QUESTION_FAILED,
  GET_TODAYS_QUESTION_SUCCESS,
  SUBMIT_FILL_THE_BLANK_BEGIN,
  SUBMIT_FILL_THE_BLANK_FAILED,
  SUBMIT_FILL_THE_BLANK_SUCCESS,
  SUBMIT_QUIZ_BEGIN,
  SUBMIT_QUIZ_FAILED,
  SUBMIT_QUIZ_SUCCESS,
  SUBMIT_TRUE_OR_FALSE_BEGIN,
  SUBMIT_TRUE_OR_FALSE_FAILED,
  SUBMIT_TRUE_OR_FALSE_SUCCESS,
} from "./constants";

const initialState: GamificationReducerReducerInterface = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  todays_question: null,
  answer_correctness: null,
  answer: "",
  clientObjectives: {
    games: { count: 0 },
    investments: { count: 0, next: 0 },
    referrals: { count: 0, next: 0 },
    purchases: { count: 0, next: 0 },
  },
  referralsLeagueTable: null,
  currentClientReferralsRanking: null,
  medalsLeagueTable: null,
  currentClientMedalsRanking: null,
  clientTimeline: null,
  todaysGame: "",
};

const GamificationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_TODAYS_QUESTION_BEGIN:
      case CHECK_ANSWER_BEGIN:
      case SUBMIT_QUIZ_BEGIN:
      case GET_CLIENT_OBJECTIVES_BEGIN:
      case GET_REFERRALS_TABLE_BEGIN:
      case GET_MEDALS_LEAGUE_TABLE_BEGIN:
      case GET_CLIENT_TIMELINE_BEGIN:
      case SUBMIT_FILL_THE_BLANK_BEGIN:
      case CHECK_BLANK_ANSWER_BEGIN:
      case SUBMIT_TRUE_OR_FALSE_BEGIN:
      case GET_TODAYS_QUESTION_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case GET_TODAYS_QUESTION_FAILED:
      case CHECK_ANSWER_FAILED:
      case SUBMIT_QUIZ_FAILED:
      case GET_CLIENT_OBJECTIVES_FAILED:
      case GET_REFERRALS_TABLE_FAILED:
      case GET_MEDALS_LEAGUE_TABLE_FAILED:
      case GET_CLIENT_TIMELINE_FAILED:
      case SUBMIT_FILL_THE_BLANK_FAILED:
      case CHECK_BLANK_ANSWER_FAILED:
      case SUBMIT_TRUE_OR_FALSE_FAILED:
      case GET_TODAYS_QUESTION_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case GET_TODAYS_QUESTION_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.todays_question = action.payload;
        break;
      case CHECK_ANSWER_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;

        if (action.payload.answer_id) {
          draft.answer = action.payload.answer_id;
          draft.answer_correctness = action.payload.is_correct;
        } else draft.answer_correctness = action.payload;

        break;
      case CHECK_BLANK_ANSWER_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.answer = action.payload.answer;
        draft.answer_correctness = action.payload.is_correct;
        break;
      case SUBMIT_QUIZ_SUCCESS:
      case SUBMIT_FILL_THE_BLANK_SUCCESS:
      case SUBMIT_TRUE_OR_FALSE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case GET_CLIENT_OBJECTIVES_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.clientObjectives = action.payload;
        break;
      case GET_REFERRALS_TABLE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.referralsLeagueTable = action.payload.table;
        draft.currentClientReferralsRanking = action.payload.currentClient;
        break;
      case GET_MEDALS_LEAGUE_TABLE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.medalsLeagueTable = action.payload.table;
        draft.currentClientMedalsRanking = action.payload.currentClient;
        break;
      case GET_CLIENT_TIMELINE_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.clientTimeline = action.payload;
        break;
      case CLEAR_CHECK_ANSWER:
        draft.answer_correctness = null;
        break;
      case GET_TODAYS_GAME_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.todaysGame = action.payload;
        break;
      default:
        break;
    }
  });

export default GamificationReducer;
