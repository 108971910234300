import { getDefaultColour } from "utils/utils";
import styles from "./InviteReferrals.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { HowItWorksIcon, InfoIcon, ReferralBoxIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import { openModal, openToaster } from "App/actions";
import { useEffect, useState } from "react";
import { getReferralCodeBegin } from "pages/Profile/actions";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import QRCode from "qrcode";
import FbIcon from "assets/images/fb.png";
import XIcon from "assets/images/x.png";
import IgIcon from "assets/images/ig.png";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import { modalTypes } from "types/reducers/modal.reducer.type";

function InviteReferrals() {
  const {
    action: { isLoading },
    client,
    defaultColour,
    referralLink,
    qrCode,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [qrCodeGenerated, setQrCodeGenerated] = useState("");
  const isMobile = useCheckMobileScreen();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink);
    dispatch(openToaster("SUCCESS", translations("label.copied")));
  };

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const navigateTo = (to: string) => {
    navigate(`/referral/share?to=${to}`);
  };

  const handleInfoPopup = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.infoPopup}>
            <span>{translations("referral_popup.label1")}</span>
            <span>{translations("referral_popup.label2")}</span>
            <span>{translations("referral_popup.label3")}</span>

            <div className={styles.table}>
              <div>
                <span className={styles.bold}>
                  {translations("label.number")}
                </span>
                <span>3</span>
                <span>5</span>
                <span>10</span>
                <span>20</span>
                <span>30</span>
                <span>50</span>
                <span>125</span>
                <span>250</span>
                <span>500</span>
              </div>
              <div>
                <span className={styles.bold}>
                  {translations("label.bonus_medals")}
                </span>
                <span>1'250</span>
                <span>2'000</span>
                <span>3'500</span>
                <span>5'000</span>
                <span>7'500</span>
                <span>10'000</span>
                <span>15'000</span>
                <span>20'000</span>
                <span>35'000</span>
              </div>
            </div>
          </div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  useEffect(() => {
    dispatch(getReferralCodeBegin());

    if (client?.referral_code) {
      let urlFormatted = `https://vinfinity.app/signup?referral_code=${client?.referral_code}&firstname=${client?.firstname}&lastname=${client?.lastname}&lang=${getLanguage()}`;
      QRCode.toDataURL(urlFormatted, function (err, url) {
        setQrCodeGenerated(url);
      });
    }
  }, [client]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.infoBtn} onClick={() => handleInfoPopup()}>
          <HowItWorksIcon />
        </div>
        <h1>{translations("title.invite_friend_with_rewards")}</h1>

        <div className={styles.subtitle}>
          <span>{translations("subtitle.invite_friend_with_rewards")}</span>
        </div>

        <div className={styles.qrCode}>
          <span>{translations("label.your_personal_qr_code")}</span>
          <img src={qrCodeGenerated ? qrCodeGenerated : qrCode} alt="QR Code" />
        </div>
        <ButtonComponent
          label={translations("button.copy_link")}
          class={"btn"}
          handleClick={() => handleCopyLink()}
        />

        {/* {isMobile ? (
          <div className={styles.socialsContent}>
            <span>{translations("label.share_on_social_media")}</span>

            <div className={styles.shareOnSocials}>
              <div onClick={() => navigateTo("ig")}>
                <img src={IgIcon} alt="Instagram" />
                <span>
                  {translations("label.share_on")}
                  <br /> Instagram
                </span>
              </div>
              <div onClick={() => navigateTo("fb")}>
                <img src={FbIcon} alt="Facebook" />
                <span>
                  {translations("label.share_on")}
                  <br /> Facebook
                </span>
              </div>
              <div onClick={() => navigateTo("x")}>
                <img src={XIcon} alt="X" />
                <span>
                  {translations("label.share_on")}
                  <br />X
                </span>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    </LoaderWrapper>
  );
}

export default InviteReferrals;
