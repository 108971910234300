import styles from "./ShareInvitation.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useTranslation } from "react-i18next";

import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ButtonComponent from "components/Button/Button.component";
import VinfinityLogo from "assets/images/VinfinityWhiteLogo.png";
import { GoldCoinIcon } from "assets/icons/icons";
import {
  getReferralCodeBegin,
  getS3UploadReferralImageBegin,
} from "pages/Profile/actions";
import QRCode from "qrcode";
import html2canvas from "html2canvas";
import axios from "axios";

function ShareInvitation() {
  const {
    client,
    qrCode,
    action: { isLoading },
    s3UploadImageReferralLink,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const imageRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const toParam = searchParams.get("to");
  const [qrCodeGenerated, setQrCodeGenerated] = useState("");

  const getSocialName = () => {
    switch (toParam) {
      case "fb":
        return "Facebook";
        break;
      case "ig":
        return "Instagram";
        break;
      case "x":
        return "X";
        break;
      default:
        return "Facebook";
        break;
    }
  };

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const uploadImageToS3 = async (image_file: any, uploadUrl: string) => {
    try {
      const response = await axios({
        method: "put",
        url: uploadUrl,
        headers: { "Content-Type": "image/jpeg" },
        data: image_file,
      });
    } catch (error) {
      return error.message;
    }
  };

  const shareToInstagramStories = (imageS3Link: string) => {
    const imageUrl =
      "https://res.cloudinary.com/dpb7vykfg/image/upload/v1727786483/products/01J941JZANXH1E7YTMY2Y81716.png";
    const url = `instagram://story?source_url=${encodeURIComponent(imageUrl)}`;
    window.open(url);
  };

  const shareToFacebook = (imageS3Link: string) => {
    const imageUrl = imageS3Link;

    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      imageUrl
    )}`;
    window.open(url, "_blank");
  };

  const shareToTwitter = (imageS3Link: string) => {
    // const tweetText = "Check out this image!";
    const tweetText = " ";
    const imageUrl = imageS3Link;

    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&url=${encodeURIComponent(imageUrl)}`;
    window.open(url, "_blank");
  };

  const handleShare = async () => {
    if (imageRef.current === null) return;

    html2canvas(imageRef.current, {
      scale: 2,
      width: imageRef.current.offsetWidth,
      height: imageRef.current.offsetHeight,
    }).then(async (canvas) => {
      const dataUrl = canvas.toDataURL("image/png");
      if (navigator.share) {
        navigator
          .share({
            title: "Share Image",
            url: dataUrl,
          })
          .then(() => {
            // alert("Image shared successfully!");
          })
          .catch((error) => {
            // alert("Error sharing: " + error);
          });
      } else {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "image.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setTimeout(() => {
          alert(
            translations("alert.image_downloaded_to_device_share_on_socials", {
              social: getSocialName(),
            })
          );
        }, 3000);
      }

      // await dispatch(getS3UploadReferralImageBegin());
      // await uploadImageToS3(dataUrl, s3UploadImageReferralLink);
    });
  };

  const share = () => {
    switch (toParam) {
      case "fb":
        shareToFacebook(s3UploadImageReferralLink);
        break;
      case "ig":
        shareToInstagramStories(s3UploadImageReferralLink);
        break;
      case "x":
        shareToTwitter(s3UploadImageReferralLink);
        break;
      default:
        shareToFacebook(s3UploadImageReferralLink);
        break;
    }
  };

  useEffect(() => {
    dispatch(getReferralCodeBegin());

    if (client?.referral_code) {
      let urlFormatted = `https://vinfinity.app/signup?referral_code=${client?.referral_code}&firstname=${client?.firstname}&lastname=${client?.lastname}&lang=${getLanguage()}`;
      QRCode.toDataURL(urlFormatted, function (err, url) {
        setQrCodeGenerated(url);
      });
    }
  }, [client]);

  // useEffect(() => {
  //   if (s3UploadImageReferralLink?.length > 0) {
  //     // share();
  //   }
  // }, [s3UploadImageReferralLink]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className={styles.content}>
        <h1>
          {translations("label.share_to_invite_your_friends", {
            social: getSocialName(),
          })}
        </h1>

        <div ref={imageRef} className={styles.boxImage}>
          <div className={styles.header}>
            <div className={styles.left}>
              <div>
                <h3>
                  {translations("label.buy_tasty_wine_like_never_before")}
                </h3>
              </div>
              <div className={styles.leftBottom}>
                <GoldCoinIcon />
                <span>{translations("label.sign_up_and_get_1k_medals")}</span>
              </div>
            </div>
            <div>
              <img src={VinfinityLogo} alt="Vinfinity" />
            </div>
          </div>
          <div className={styles.footer}>
            <div>
              <span>
                {translations("label.invitation_from")}{" "}
                {client?.firstname + " " + client?.lastname}
              </span>
            </div>
            <div>
              <img
                src={qrCodeGenerated ? qrCodeGenerated : qrCode}
                alt="QR Code"
              />
            </div>
          </div>
        </div>

        <ButtonComponent
          label={translations("button.download")}
          class={"btn btn-primary"}
          handleClick={() => handleShare()}
        />
      </div>
    </LoaderWrapper>
  );
}

export default ShareInvitation;
