import { memo } from "react";
import styles from "./Button.module.scss";
import LoaderBounce from "components/Loader/bounce/LoaderBounce.component";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { CircularProgress } from "@mui/material";

interface Props {
  type?: "button" | "submit" | "reset";
  label: string;
  class?: string;
  isLoading?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  iconClass?: string;
}
const Button: React.FC<Props> = ({ label, isLoading, iconClass, ...props }) => {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  return (
    <button
      className={`${styles.mainButton} ${props.class} ${
        isLoading ? styles.loadingButton : null
      }`}
      onClick={props.handleClick}
      disabled={props.disabled}
      style={
        props.style
          ? props.style
          : {
              backgroundColor: getDefaultColour(defaultColour),
              color: "#fff",
            }
      }
      type={props.type}
    >
      {/* {iconClass && !isLoading ? <i className={iconClass} style={{ paddingRight: 5 }}></i> : null} */}
      {isLoading ? (
        <span className={styles.loader}>
          <LoaderBounce />
          {/* <CircularProgress color="inherit" /> */}
        </span>
      ) : label.length > 15 ? (
        label.substring(0, 15) + "..."
      ) : (
        label
      )}
    </button>
  );
};
export default memo(Button);
