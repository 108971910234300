import { useTranslation } from "react-i18next";
import styles from "./SignupPasscode.module.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import PasscodeInput from "components/PasscodeInput/PasscodeInput.component";
import { useEffect, useState } from "react";
import {
  HidePasswordIcon,
  PreviousIcon,
  ShowPasswordIcon,
} from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { openToaster } from "App/actions";
import {
  checkIfHasPasscodeBegin,
  registerBegin,
} from "pages/Authentication/actions";
import StorageService from "services/storage.service";

export default function SignupPasscode() {
  const {
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [searchParams, setSearchParams] = useSearchParams();
  const [passcode, setPasscode] = useState("");
  const [showPasscode, setShowPasscode] = useState(true);
  const emailParam = searchParams.get("email");
  const referralCodeParamCookie = StorageService.getDataByKey("referral_code");

  const getStoredLanguage = () => {
    return localStorage.getItem("lang");
  };

  const isLoggedIn = () => StorageService.getDataByKey("token");
  const langStored = () => localStorage.getItem("lang")?.toUpperCase();

  const handleSave = () => {
    if (passcode?.length !== 4) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_passcode_field"))
      );
      return;
    }

    if (emailParam === "") {
      dispatch(openToaster("ERROR", translations("error.email_is_mandatory")));
      return;
    }

    StorageService.deleteCookie("registeredUser");
    dispatch(
      registerBegin(
        emailParam.replaceAll(" ", "+"),
        passcode,
        "",
        "",
        "email",
        getStoredLanguage(),
        referralCodeParamCookie ? referralCodeParamCookie : "",
        true
      )
    );
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/signup/personal-details");
    }
  }, [isLoggedIn()]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <div
          className={styles.previousIcon}
          onClick={() => {
            searchParams.delete("email");
            setSearchParams(searchParams);
            window.history.back();
          }}
        >
          <PreviousIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>

      <div className={styles.content}>
        <h1>{translations("label.enter_passcode")}</h1>
        <span className={styles.colored}>
          {translations("label.check_your_email")}!
        </span>

        <div className={styles.passcodeContainer}>
          <h3>{translations("label.enter_the_4_digit_code_sent")}</h3>
          <div
            className={styles.showPasscodeLabel}
            onClick={() => setShowPasscode(!showPasscode)}
          >
            <span>
              {translations(
                showPasscode ? "label.hide_passcode" : "label.show_passcode"
              )}
            </span>
            <div>
              {showPasscode ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>
          <PasscodeInput
            length={4}
            showPasscode={showPasscode}
            onChange={setPasscode}
          />

          <div className={styles.hint}>
            <span>{translations("label.passcode_info")}</span>
          </div>
        </div>

        <div className={styles.saveButton}>
          <ButtonComponent
            label={translations("label.continue")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>

        <div className={styles.hintText}>
          <span>{translations("label.resend_code.hint")}</span>
        </div>

        <div
          className={styles.resendEmail}
          onClick={() =>
            dispatch(
              checkIfHasPasscodeBegin({
                email: emailParam.replaceAll(" ", "+"),
                sign_up: true,
                lang: langStored().toLowerCase(),
              })
            )
          }
        >
          <span>{translations("label.resend_code")}</span>
        </div>
      </div>
    </div>
  );
}
