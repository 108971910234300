import { useTranslation } from "react-i18next";
import styles from "./Investments.module.scss";

import { GlobalIState } from "types/RootState";
import { useAppDispatch, useAppSelector } from "hooks";
import { getDefaultColour } from "utils/utils";
import BalanceSimulationChart from "./BalanceSimulationChart/BalanceSimulationChart.page";
import GeographicAllocationChart from "./GeographicAllocationChart/GeographicAllocationChart.page";
import ReturnsOverTimeChart from "./ReturnsOverTimeChart/ReturnsOverTimeChart.page";
import LatestTransactions from "./LatestTransactions/LatestTransactions.page";
import { useEffect, useRef, useState } from "react";
import { scrollToTop } from "App/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import AllTransactions from "./AllTransactions/AllTransactions.page";
import AllWineCollection from "./AllWineCollection/AllWineCollection.page";
import OutlookSimulationChart from "./OutlookSimulationChart/OutlookSimulationChart.page";
import TransactionDetails from "pages/Wallet/Transactions/TransactionDetails/TransactionDetails.page";

import WineDetails from "./WineDetails/WineDetails.page";
import StorageService from "services/storage.service";
import ButtonComponent from "components/Button/Button.component";

export default function Investments() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [showHighlightedBox, setShowHighlightedBox] = useState("");
  const boxRef = useRef(null);
  const biggerHeightBoxRef = useRef(null);
  const isLoggedIn = () => StorageService.getDataByKey("token");

  const handleViewWineCollection = () => {
    if (isMobile) {
      navigate("/investments/wine-collection");
    } else {
      setShowHighlightedBox("the-wine-collection");
      dispatch(scrollToTop());
    }
  };

  useEffect(() => {
    setShowHighlightedBox("");
  }, []);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {showHighlightedBox !== "" ? (
        <div
          className={`${
            showHighlightedBox.includes("the-wine-collection") &&
            styles.wineCollectionContainer
          } ${
            showHighlightedBox.includes("transaction-details") &&
            styles.transactionDetailsTop
          }
          ${
            showHighlightedBox === "wine-details" && styles.wineDetailsContainer
          }
          `}
          style={{
            height: ["transaction-details", "the-wine-collection"].includes(
              showHighlightedBox
            )
              ? "unset"
              : showHighlightedBox === "wine-details"
              ? "unset"
              : "70dvh",
            flexWrap: showHighlightedBox.includes("the-wine-collection")
              ? "wrap"
              : "nowrap",
          }}
        >
          <div
            className={`${styles.highlightedBox}`}
            style={{
              width:
                showHighlightedBox === "the-wine-collection" ? "100%" : "unset",
            }}
          >
            {/* <div className="pointer" onClick={() => setShowHighlightedBox("")}>
              <BackButtonIcon color={getDefaultColour(defaultColour)} />
            </div> */}
            {showHighlightedBox.includes("transaction") ? (
              <AllTransactions
                showHighlightedBox={showHighlightedBox}
                setShowHighlightedBox={setShowHighlightedBox}
              />
            ) : null}

            {showHighlightedBox.includes("wine") ? (
              <AllWineCollection
                setShowHighlightedBox={setShowHighlightedBox}
              />
            ) : null}
          </div>
          {showHighlightedBox === "transaction-details" ? (
            <div className={styles.halfContainer}>
              <TransactionDetails />
            </div>
          ) : null}
          {showHighlightedBox === "wine-details" ? (
            <div
              className={styles.halfContainer}
              style={{
                boxShadow: "0px 0px 12.57034px 0px rgba(0, 0, 0, 0.12)",
              }}
            >
              <WineDetails />
            </div>
          ) : null}
        </div>
      ) : null}

      <div className={styles.boxesBelow}>
        <div className={styles.box} ref={boxRef} style={{ minHeight: 410 }}>
          <BalanceSimulationChart />
        </div>
        <div className={styles.box} style={{ minHeight: 410 }}>
          <ReturnsOverTimeChart />
        </div>
        <div className={styles.box} style={{ minHeight: 410 }}>
          <GeographicAllocationChart />
        </div>
        <div className={styles.box} style={{ minHeight: 410 }}>
          {/* <h2>
          {translations(
            isLoggedIn ? "label.returns_since_start" : "label.exemplary_returns"
          )}
        </h2> */}
          {/* <div
          className={styles.valueLabels}
          style={{
            borderBottom: `1px solid ${getDefaultColour(defaultColour)}30`,
          }}
        >
          <div>{translations("label.net_wine_returns")}</div>
          <div>30.5 %</div>
        </div> */}
          {/* <h2>{translations("label.returns_outlook_simulation")}</h2> */}
          <OutlookSimulationChart />
        </div>
        {!showHighlightedBox.includes("wine") ? (
          <div
            ref={biggerHeightBoxRef}
            className={`${styles.box} ${styles.biggerHeight} ${styles.wineCollectionBox} pointer`}
            onClick={() => handleViewWineCollection()}
          >
            <h2>{translations("label.the_wine_collection")}</h2>
            {/* <TheWineCollection /> */}
          </div>
        ) : null}
        {!showHighlightedBox.includes("transaction") && isLoggedIn() ? (
          <div
            className={styles.latestTransactionsBox}
            style={{
              width: biggerHeightBoxRef?.current?.offsetWidth - 60,
              // height: biggerHeightBoxRef?.current?.offsetHeight - 60,
            }}
          >
            <h2>{translations("label.latest_transactions")}</h2>
            <LatestTransactions
              showHighlightedBox={showHighlightedBox}
              setShowHighlightedBox={setShowHighlightedBox}
            />
          </div>
        ) : null}

        {/* {isLoggedIn() ? (
          <div
            className={styles.twoBoxes}
            style={{
              width: !isLoggedIn()
                ? "40dvw"
                : showHighlightedBox === ""
                ? "100%"
                : boxRef.current.offsetWidth,
              flexDirection: !isLoggedIn()
                ? "column"
                : showHighlightedBox === "" && !isMobile
                ? "row"
                : "column",
              maxWidth: "100%",
            }}
          >
            <div
              className={`${styles.box} ${!isLoggedIn() && styles.smallerBox}`}
              style={{
                width:
                  showHighlightedBox !== "" && boxRef.current.offsetWidth - 60,
                height: "100%",
                minHeight: 140,
              }}
            >
              <h2>{translations("label.your_transaction_ledger")}</h2>
              <div className={styles.actionContent}>
                <div className={styles.label}>
                  <span>{translations("label.transaction_ledger.hint")}</span>
                </div>
                <div>
                  <ButtonComponent
                    label={translations("button.load")}
                    class={"btn btn-primary"}
                    style={{
                      border: `1px solid ${getDefaultColour(defaultColour)}`,
                      backgroundColor: "#fff",
                      color: getDefaultColour(defaultColour),
                      borderRadius: 100,
                    }}
                    handleClick={() => console.log()}
                  />
                </div>
              </div>
            </div>
            <div
              className={`${styles.box} ${!isLoggedIn() && styles.smallerBox}`}
              style={{
                width:
                  showHighlightedBox !== "" &&
                  isLoggedIn() &&
                  boxRef.current.offsetWidth - 60,
                height: "100%",
                minHeight: 170,
              }}
            >
              <h2>{translations("label.your_ownership_certificate")}</h2>
              <div className={styles.actionContent}>
                <div className={styles.label}>
                  <span>
                    {translations("label.ownership_certificate.hint")}
                  </span>
                </div>
                <div>
                  <ButtonComponent
                    label={translations("button.load")}
                    class={"btn btn-primary"}
                    style={{
                      border: `1px solid ${getDefaultColour(defaultColour)}`,
                      backgroundColor: "#fff",
                      color: getDefaultColour(defaultColour),
                      borderRadius: 100,
                    }}
                    handleClick={() => console.log()}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    </div>
  );
}
