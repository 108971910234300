import { useAppDispatch, useAppSelector } from "hooks";
import { useState, useRef, useEffect } from "react"; // Import useRef and useEffect
import { GlobalIState } from "types/RootState";

import styles from "./SortingFilter.module.scss";
import { ArrowDownIcon, SortingIcon } from "assets/icons/icons";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { getDefaultColour, updateFiltersArray } from "utils/utils";
import StorageService from "services/storage.service";
import { fetchProductsBegin, setFilters } from "pages/Purchasing/actions";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

interface Props {
  label?: string;
  className?: string;
  options: any[];
}

export default function SortingFilter(props: Props) {
  const { label, options } = props;
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { filters } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );

  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [applyFilter, setApplyFilter] = useState(false);
  const sortValueCookie = StorageService.getDataByKey("sort_value");
  const searchValueCookie = StorageService.getDataByKey("search");
  const [searchParams] = useSearchParams();
  const streamParam = searchParams.get("stream");

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSelect = (value: string) => {
    if (sortValueCookie === value) {
      setSelectedValue("");
      StorageService.deleteCookie("sort_value");
      dispatch(
        setFilters({
          filter: "Sort",
          value: [],
        })
      );
    } else {
      setSelectedValue(value);
      StorageService.setKeyValue("sort_value", value);
      dispatch(
        setFilters({
          filter: "Sort",
          value: [value],
        })
      );
      setIsDropdownOpen(!isDropdownOpen);
    }

    if (!isMobile) {
      setApplyFilter(true);
    }
  };

  useEffect(() => {
    if (sortValueCookie) {
      setSelectedValue(sortValueCookie);
    }
  }, [sortValueCookie]);

  useEffect(() => {
    if (applyFilter) {
      setApplyFilter(false);
      StorageService.setKeyValue("products_page", 1);
      if (filters?.filter((item) => item?.value?.length >= 1)?.length === 0) {
        dispatch(
          fetchProductsBegin(
            streamParam,
            1,
            searchValueCookie ? searchValueCookie : "",
            [{}],
            getLanguageToken()
          )
        );
      } else {
        dispatch(
          fetchProductsBegin(
            streamParam,
            1,
            searchValueCookie ? searchValueCookie : "",
            updateFiltersArray(
              filters.filter((item) => item.value.length >= 1)
            ),
            getLanguageToken()
          )
        );
      }
    }
    // eslint-disable-next-line
  }, [filters, applyFilter]);

  return (
    <div ref={dropdownRef} className={styles.container}>
      <div className={styles.dropdownLabel} onClick={handleToggleDropdown}>
        <span>{label}</span>
        <SortingIcon color={"#000"} />
      </div>

      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.filters}>
            {options?.map((option, index) => (
              <div
                key={index}
                className={styles.option}
                onClick={() => handleSelect(option.value)}
                style={
                  selectedValue === option.value
                    ? {
                        backgroundColor: getDefaultColour(defaultColour),
                        color: "#fff",
                      }
                    : {}
                }
              >
                <span>{translations(option.label)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
