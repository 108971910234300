import { useTranslation } from "react-i18next";
import styles from "./NotFound.module.scss";

export default function NotFound() {
  const { t: translations } = useTranslation();
  return (
    <div className={styles.container}>
      <h1>{translations("error.page_not_found!")}</h1>
    </div>
  );
}
