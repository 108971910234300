import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import styles from "./PurchasedQuantity.module.scss";
import { BackButtonIcon, ShoppingCartAddIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import { getProductTransactionsBegin } from "pages/PersonalLedger/actions";
import dayjs from "dayjs";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";

import { openToaster } from "App/actions";
import { formatPrice } from "utils/utils";
import StorageService from "services/storage.service";

interface Props {
  setOpenedCard?: any;
  product_id: string;
}

function PurchasedQuantity(props: Props) {
  const { setOpenedCard, product_id } = props;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const { productTransactions } = useAppSelector(
    (state: GlobalIState) => state.PersonalLedgerReducer
  );

  const getTotalAmount = (data) => {
    if (data) {
      return data?.reduce((acc, transaction) => {
        return (
          acc +
          transaction.order.orderDetails.reduce(
            (acc, item) => acc + Number(item.total_price),
            0
          )
        );
      }, 0);
    }
  };

  const totalAmount = getTotalAmount(productTransactions);
  const [shouldAddToCart, setShouldAddToCart] = useState(false);

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleAddToCart = () => {
    dispatch(fetchProductDetailsBegin(product_id, getLanguage()));
    setShouldAddToCart(true);
  };

  useEffect(() => {
    if (product_id) dispatch(getProductTransactionsBegin(product_id));
  }, [product_id]);

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div
          className={styles.backButton}
          onClick={() =>
            setOpenedCard({
              isExpanded: false,
              item: null,
              category: "",
            })
          }
        >
          <BackButtonIcon color={"#000"} />
        </div>
      </div>

      <div className={styles.content}>
        <h2>{translations("label.transaction_history")}</h2>

        <div className={styles.tableBox}>
          <table>
            <tr className={styles.tbHeader}>
              <th>{translations("label.ordered_items")}</th>
              <th>{translations("label.date")}</th>
              <th>{translations("label.quantity")}</th>
              <th>{translations("label.price")}</th>
            </tr>

            {productTransactions?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item?.order?.orderDetails[0]?.product?.wine_name} -{" "}
                    {item?.order?.orderDetails[0]?.product?.bottle_size}
                  </td>
                  <td>{dayjs(item?.order?.order_date).format("DD.MM.YYYY")}</td>
                  <td>{item?.order?.orderDetails[0]?.quantity}x</td>
                  <td className={styles.price}>
                    {item?.currency}{" "}
                    {Number(item?.order?.orderDetails[0]?.unit_price).toFixed(
                      2
                    )}
                  </td>
                </tr>
              );
            })}
            <tfoot>
              <tr>
                <td>{translations("label.total_purchases")}</td>
                <td></td>
                <td></td>
                <td>CHF {formatPrice(totalAmount?.toFixed(2))}</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className={styles.mainButton}>
          <div className={styles.reorderBtn} onClick={() => handleAddToCart()}>
            <ShoppingCartAddIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasedQuantity;
