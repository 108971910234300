import { Outlet } from "react-router-dom";

import styles from "./LandingPage.module.scss";
import ModalComponent from "components/Modal/Modal.component";

export default function LandingPageMiddleware() {
  return (
    <div className={styles.mainWrapper}>
      <Outlet />
      <ModalComponent />
    </div>
  );
}
