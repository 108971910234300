import { useTranslation } from "react-i18next";

import styles from "./Language.module.scss";
import { ArrowRightTableIcon } from "assets/icons/icons";

import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { setLeftPartItem, setMiddlepartItem } from "App/actions";
import i18n from "localizations/config";
import { getDefaultColour } from "utils/utils";
import { updateLanguageBegin } from "pages/Profile/actions";
import {
  fetchProductBookmarksBegin,
  fetchProductDetailsBegin,
  fetchProductsBegin,
  getPurchasingFiltersBegin,
} from "pages/Purchasing/actions";
import { fetchCountriesBegin } from "pages/Authentication/actions";
import { getTodaysQuestionBegin } from "pages/Gamification/actions";
import { useSearchParams } from "react-router-dom";

interface Props {}

interface ChangeLanguageInterface {
  order: number;
  name: string;
  onClick: () => void;
}

export default function Language(props: Props) {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { productDetails } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const streamParam = searchParams.get("stream");

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  const handleLanguageSet = (lang: string) => {
    if (isLoggedIn) {
      dispatch(updateLanguageBegin(lang.toLowerCase()));
    }
    i18n.changeLanguage(lang.toLowerCase());
    localStorage.setItem("lang", lang);
    if (isMobile) {
      dispatch(setLeftPartItem("1"));
    } else {
      dispatch(setMiddlepartItem(null));
    }

    if (productDetails) {
      dispatch(
        fetchProductDetailsBegin(productDetails?.id, getLanguageToken())
      );
    }

    dispatch(fetchProductsBegin(streamParam, 1, "", [{}], getLanguageToken()));
    dispatch(getPurchasingFiltersBegin(getLanguageToken()));
    dispatch(fetchProductBookmarksBegin(getLanguageToken()));
    dispatch(getTodaysQuestionBegin(getLanguageToken()));
    setTimeout(() => {
      if (isLoggedIn) {
        dispatch(fetchCountriesBegin("withAuth"));
      } else {
        dispatch(fetchCountriesBegin("withoutAuth"));
      }
    }, 2000);
  };

  const languages: ChangeLanguageInterface[] = [
    {
      order: 1,
      name: "English",
      onClick: () => handleLanguageSet("en"),
    },
    {
      order: 2,
      name: "Deutsch",
      onClick: () => handleLanguageSet("de"),
    },
    {
      order: 3,
      name: "Français",
      onClick: () => handleLanguageSet("fr"),
    },
    {
      order: 4,
      name: "Italiano",
      onClick: () => handleLanguageSet("it"),
    },
    // {
    //   order: 5,
    //   name: "Espagnol",
    //   onClick: () => handleLanguageSet("es"),
    // },
  ];

  return (
    <div className={styles.languagePageWrapper}>
      <h1>{translations("label.select_language")}</h1>
      {languages.map((item) => {
        return (
          <div
            key={item.order}
            className={styles.wrap}
            style={{
              borderBottom: `2px solid ${getDefaultColour(defaultColour)}`,
            }}
            onClick={item.onClick}
          >
            <div className={styles.language}>
              <div className={styles.languageOrder}>
                <span style={{ color: getDefaultColour(defaultColour) }}>
                  {item.order}
                </span>
              </div>
              <div className={styles.languageName}>
                <span>{item.name}</span>
              </div>
            </div>
            <div>
              <ArrowRightTableIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
        );
      })}
    </div>
  );
}
