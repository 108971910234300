import React, { useEffect, useRef, useState } from "react";
import styles from "./WineDetailsChart.module.scss";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { formatPrice, getCurrencyStr, getDefaultColour } from "utils/utils";
import { RefreshIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

interface Props {
  id: number;
}

function WineDetailsChart() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { wineDetails, winePerformance } = useAppSelector(
    (state: GlobalIState) => state.InvestmentsReducer
  );
  const dispatch = useAppDispatch();
  const chartRef = useRef(null);
  const { t: translations } = useTranslation();
  const [state, setState] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
  });

  const options: ApexOptions = {
    chart: {
      height: 350,
      type: "area",
      zoom: {
        enabled: false,
      },
      toolbar: {
        tools: {
          download: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: [getDefaultColour(defaultColour)],
    },
    grid: {
      show: false,
    },
    colors: ["#34FF71"],
    xaxis: {
      categories: [],
    },
    series: [],
    yaxis: {
      tickAmount: 3,
      floating: false,
      labels: {
        style: {
          colors: getDefaultColour(defaultColour),
        },
        offsetY: -7,
        offsetX: 0,
        formatter: (value: any) => {
          return `${getCurrencyStr(client?.currency)} ${formatPrice(
            value.toString()
          )}`;
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      marker: {
        show: true,
        fillColors: ["#34FF71"],
      },
    },
    fill: {
      type: "gradient",
      opacity: 1,
      colors: [getDefaultColour(defaultColour)],
    },
  };

  useEffect(() => {
    if (winePerformance?.length > 1) {
      chartRef.current.chart.updateOptions({
        xaxis: {
          categories: winePerformance?.map(
            (item) => `${dayjs(item.date).format("MM / YYYY")}`
          ),
        },
      });

      chartRef.current.chart.updateSeries([
        {
          name: " ",
          data: winePerformance.map((item) => item.market_price),
        },
      ]);
    }
  });

  return (
    <div className={styles.lineChartWrapper}>
      {winePerformance ? (
        <ReactApexChart
          ref={chartRef}
          options={options}
          series={state.series}
          type="area"
          width={"100%"}
          height={"90%"}
        />
      ) : null}
    </div>
  );
}

export default WineDetailsChart;
