import { useTranslation } from "react-i18next";

import styles from "./TermsAndConditions.module.scss";
import CheckboxRoundStandardComponent from "components/Checkbox/CheckboxRoundStandard/CheckboxRoundStandard.component";
import ButtonComponent from "components/Button/Button.component";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import { termsAndConditionsAgreement } from "../../actions";
import { openModal } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import WarningModal from "../WarningModal/WarningModal.page";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

interface Props {}

export default function TermsAndConditions(props: Props) {
  const {
    client,
    action: { isLoading },
    defaultColour,
    termsAndConditions,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [agreed, setAgreed] = useState(false);
  const isMobile = useCheckMobileScreen();

  const handleAgreeClick = () => {
    dispatch(termsAndConditionsAgreement());
    window.location.reload();
  };

  const handleDecline = () => {
    dispatch(
      openModal({
        content: <WarningModal type="termsAndConditions" />,
        type: modalTypes.smWarning,
      })
    );
  };

  return (
    <div className={styles.termsAndConditionsWrapper}>
      <div className={styles.header}>
        <div className={styles.datedLabel}>
          <span style={{ color: getDefaultColour(defaultColour) }}>
            {translations("label.dated")} 01.01.2024
          </span>
        </div>
        <h1 className={styles.titleLabel}>
          {translations("label.terms_and_conditions")}
        </h1>
      </div>
      <LoaderWrapper isLoading={isLoading}>
        <div
          className={styles.shownText}
          style={{
            height:
              client?.terms_id !== client?.latest_terms_id
                ? isMobile
                  ? 330
                  : 770
                : "100%",
          }}
          dangerouslySetInnerHTML={{
            __html: termsAndConditions?.text,
          }}
        ></div>
      </LoaderWrapper>

      {client?.terms_id !== client?.latest_terms_id ? (
        <div className={styles.actionCard}>
          <CheckboxRoundStandardComponent
            label={translations(
              "label.have_read_and_agree_to_terms_and_conditions"
            )}
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          />

          <div className={styles.buttons}>
            <ButtonComponent
              label={translations("button.agree")}
              class={"btn btn-primary-rounded"}
              disabled={!agreed}
              isLoading={isLoading}
              handleClick={() => handleAgreeClick()}
            />
            <div className="underlinedBtn" onClick={() => handleDecline()}>
              <span style={{ color: getDefaultColour(defaultColour) }}>
                {translations("button.decline")}
              </span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
