import { Navigate, Route, Routes } from "react-router-dom";

import {
  EstablishConnectionMiddleware,
  AppWrapperMiddleware,
  ProtectedRoutesMiddleware,
  PublicRoutesMiddleware,
  LandingPageMiddleware,
} from "middlewares";

import NotFound from "pages/NotFound/NotFound.page";

import "./styles/App.scss";
import Concierge from "pages/Concierge/Concierge.page";
import Events from "pages/Events/Events.page";
import Profile from "pages/Profile/Profile.page";
import Wallet from "pages/Wallet/Wallet.page";
import Purchasing from "pages/Purchasing/Purchasing.page";

import Investments from "pages/Investments/Investments.page";

import Modal from "react-modal";
import LogoAndQuoteWrapper from "middlewares/LogoAndQuoteWrapper";
import InvitedFriendEventConfirm from "pages/InvitedFriendEventConfirm/InvitedFriendEventConfirm.page";
import Topup from "pages/Investments/Topup/Topup.page";
import BankTransfer from "pages/Investments/Topup/BankTransfer/BankTransfer.page";
import Transactions from "pages/Wallet/Transactions/Transactions.page";
import TransactionDetails from "pages/Wallet/Transactions/TransactionDetails/TransactionDetails.page";
import VerifyEmailSuccess from "pages/Authentication/VerifyEmailSuccess/VerifyEmailSuccess.page";
import AllWineCollection from "pages/Investments/AllWineCollection/AllWineCollection.page";
import WineDetails from "pages/Investments/WineDetails/WineDetails.page";
import Cart from "pages/Purchasing/CartDetails/CartDetails.page";
import DeliveryInfo from "pages/Purchasing/DeliveryInfo/DeliveryInfo.page";
import ProductDetails from "pages/Purchasing/ProductDetails/ProductDetails.page";
import AfterPayment from "pages/Purchasing/AfterPayment/AfterPayment.page";
import OrderSummary from "pages/Purchasing/OrderSummary/OrderSummary.page";
import DefaultWrapper from "middlewares/DefaultWrapper";
import ForgotPassword from "pages/Authentication/ForgotPassword/ForgotPassword.page";
import ResetPassword from "pages/Authentication/ForgotPassword/ResetPassword/ResetPassword.page";
import ResetPasswordEmailConfirm from "pages/Authentication/ForgotPassword/ResetPassword/ResetPasswordEmailConfirm/ResetPasswordEmailConfirm.page";
import BlindTasting from "pages/BlindTasting/BlindTasting.page";
import ThanksForFeedback from "pages/BlindTasting/ThanksForFeedback/ThanksForFeedback.page";
import Bookmarks from "pages/Purchasing/Bookmarks/Bookmarks.page";
import TermsOfUse from "pages/TermsOfUse/TermsOfUse.page";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy.page";
import InviteReferrals from "pages/Referrals/InviteReferrals/InviteReferrals.page";
import AdProduct from "pages/Purchasing/AdProduct/AdProduct.page";
import DeleteAccountByToken from "pages/Authentication/DeleteAccountByToken/DeleteAccountByToken.page";
import MobilePurchasedQuantity from "pages/PersonalLedger/MyOrders/MobilePurchasedQuantity/MobilePurchasedQuantity.page";
import MobileRateAndTaste from "pages/PersonalLedger/MyOrders/MobileRateAndTastePage/MobileRateAndTaste.page";
import MobileProductDetails from "pages/PersonalLedger/MyOrders/MobileProductDetails/MobileProductDetails.page";
import Journal from "pages/PersonalLedger/Journal.page";
import AddWine from "pages/PersonalLedger/MyOrders/AddWine/AddWine.page";
import GuestAfterPurchase from "pages/Purchasing/GuestAfterPurchase/GuestAfterPurchase.page";
import Impressum from "pages/Impressum/Impressum.page";
import UserInfo from "pages/Purchasing/GuestUserInfo/UserInfo.page";
import Membership from "pages/Membership/Membership.page";
import BonusPage from "pages/Authentication/AuthPage/BonusPage/BonusPage.page";
import FirstPurchaseBonusPage from "pages/Profile/FirstPurchaseBonus/FirstPurchaseBonus.page";
import HowItWorks from "pages/Membership/HowItWorks/HowItWorks.page";
import RoseGlowLanding from "pages/LandingPage/RoseGlowLanding/RoseGlowLanding.page";
import Signup from "pages/Authentication/Signup/Signup.page";
import SignupPasscode from "pages/Authentication/Signup/SignupPasscode/SignupPasscode.page";
import SignupPersonalDetails from "pages/Authentication/Signup/SignupPersonalDetails/SignupPersonalDetails.page";
import AccountSetUp from "pages/Authentication/Signup/AccountSetUp/AccountSetUp.page";
import Login from "pages/Authentication/Login/Login.page";
import LoginPasscode from "pages/Authentication/Login/LoginPasscode/LoginPasscode.page";
import EnterTwoFactor from "pages/Authentication/AuthPage/EnterTwoFactor/EnterTwoFactor.page";
import LoginPassword from "pages/Authentication/Login/LoginPassword/LoginPassword.page";
import AlternativeAuthMethods from "pages/Authentication/AlternativeAuthMethods/AlternativeAuthMethods.page";
import SignupByPhone from "pages/Authentication/Signup/SignupByPhone/SignupByPhone.page";
import SignupByPhoneAuthSms from "pages/Authentication/Signup/SignupByPhoneAuthSms/SignupByPhoneAuthSms.page";
import LoginByPhone from "pages/Authentication/Login/LoginByPhone/LoginByPhone.page";
import LoginByPhoneAuthSms from "pages/Authentication/Login/LoginByPhoneAuthSms/LoginByPhoneAuthSms.page";
import ConfirmEmail from "pages/Profile/ChangeLoginCredentials/ConfirmEmail/ConfirmEmail.page";
import ChangePasscode from "pages/Profile/ChangePasscode/ChangePasscode.page";
import ResetPasscode from "pages/Authentication/ForgotPassword/ResetPasscode/ResetPasscode.page";
import SetStrongPassword from "pages/Profile/SetStrongPassword/SetStrongPassword.page";
import ChpConfirmEmail from "pages/Profile/ChangePasscode/ChpConfirmEmail/ChpConfirmEmail.page";
import SetPasscode from "pages/Profile/SetPasscode/SetPasscode.page";
import Lucky100CHFWinner from "pages/Purchasing/CartDetails/Lucky100CHFWinner/Lucky100CHFWinner.page";
import ShareInvitation from "pages/Referrals/ShareInvitation/ShareInvitation.page";
import OrderTakeover from "pages/Purchasing/CartDetails/OrderTakeover/OrderTakeover.page";
import GamesInfo from "pages/Gamification/GamesInfo/GamesInfo.page";
import Games from "pages/Gamification/Games.page";
import GamesOld from "pages/Gamification/GamesOld/GamesOld.page";
import SingleChoiceGame from "pages/Gamification/SingleChoiceGame/SingleChoiceGame.page";
import FillInTheBlank from "pages/Gamification/FillInTheBlank/FillInTheBlank.page";
import TrueOrFalseGame from "pages/Gamification/TrueOrFalseGame/TrueOrFalseGame.page";
import AudioGame from "pages/Gamification/AudioGame/AudioGame.page";
import PourAndScore from "pages/Gamification/PourAndScore/PourAndScore.page";
import CelebrityTakeoverTransactions from "pages/CelebrityTakeoverTransactions/CelebrityTakeoverTransactions.page";

Modal.setAppElement("body");

function App() {
  return (
    <Routes>
      <Route element={<EstablishConnectionMiddleware />}>
        {/* Protected Routes */}
        <Route element={<ProtectedRoutesMiddleware />}>
          <Route element={<AppWrapperMiddleware />}>
            {/* <Route path={"test"} element={<Test />} /> */}
            <Route path={"wallet"} element={<Wallet />} />
            <Route path={"wallet/transactions"} element={<Transactions />} />
            <Route
              path={"wallet/transactions/details"}
              element={<TransactionDetails />}
            />
            <Route path={"profile"} element={<Profile />} />
            <Route path={"change-passcode"} element={<ChangePasscode />} />
            <Route
              path={"change-passcode/confirm"}
              element={<ChpConfirmEmail />}
            />
            <Route
              path={"set-strong-password"}
              element={<SetStrongPassword />}
            />
            <Route path={"set-passcode"} element={<SetPasscode />} />
            <Route path={"confirm-email"} element={<ConfirmEmail />} />
            <Route path={"invite-referral"} element={<InviteReferrals />} />
            <Route path={"referral/share"} element={<ShareInvitation />} />
            <Route
              path={"investments/topup/bank-transfer"}
              element={<BankTransfer />}
            />
            <Route path={"journal"} element={<Journal />} />
            <Route
              path={"journal/transactions"}
              element={<MobilePurchasedQuantity />}
            />
            <Route
              path={"journal/rate-and-taste"}
              element={<MobileRateAndTaste />}
            />
            <Route
              path={"journal/product-details"}
              element={<MobileProductDetails />}
            />
            <Route path={"add-wine"} element={<AddWine />} />
            <Route path="membership" element={<Membership />} />
            <Route path="membership/how-it-works" element={<HowItWorks />} />
            <Route path="bonus" element={<BonusPage />} />
            <Route
              path="bonus/first-purchase"
              element={<FirstPurchaseBonusPage />}
            />
          </Route>
        </Route>

        {/* Public Routes */}
        <Route element={<PublicRoutesMiddleware />}>
          {/* Layout that will be used for Authentication pages */}
          {/* <Route element={<AuthLayoutMiddleware />}>
            <Route path={"auth"} element={<Auth />} />
          </Route> */}

          {/* Layout that will be used for regular public route pages */}
          <Route element={<AppWrapperMiddleware />}>
            {/* <Route path={"auth"} element={<Auth />} /> */}
            <Route path={"login"} element={<Login />} />
            <Route path={"/login/phone"} element={<LoginByPhone />} />
            <Route path={"/login/auth-sms"} element={<LoginByPhoneAuthSms />} />
            <Route path={"/login/passcode"} element={<LoginPasscode />} />
            <Route path={"/login/password"} element={<LoginPassword />} />
            <Route path={"/login/two-factor"} element={<EnterTwoFactor />} />
            <Route path={"signup"} element={<Signup />} />
            <Route path={"/signup/passcode"} element={<SignupPasscode />} />
            <Route path={"/signup/phone"} element={<SignupByPhone />} />
            <Route
              path={"/signup/auth-sms"}
              element={<SignupByPhoneAuthSms />}
            />
            <Route
              path="auth/alternative"
              element={<AlternativeAuthMethods />}
            />
            <Route
              path={"/signup/personal-details"}
              element={<SignupPersonalDetails />}
            />
            <Route path={"/signup/finish"} element={<AccountSetUp />} />
            <Route path={"forgot-password"} element={<ForgotPassword />} />
            <Route path={"forgot-passcode"} element={<ForgotPassword />} />
            <Route
              path={"forgot-password/email-sent"}
              element={<ResetPasswordEmailConfirm />}
            />
            <Route
              path={"forgot-passcode/email-sent"}
              element={<ResetPasswordEmailConfirm />}
            />
            <Route path={"reset-password"} element={<ResetPassword />} />
            <Route path={"reset-passcode"} element={<ResetPasscode />} />
            {/* <Route path={"notifications"} element={<Notifications />} /> */}
            <Route path={"concierge"} element={<Concierge />} />
            {/* <Route path={"events"} element={<Events />} />
            <Route path={"events/details"} element={<EventDetails />} />
            <Route
              path={"events/registered"}
              element={<MobileRegisterSuccess />}
            /> */}
            <Route path={"purchasing"} element={<Purchasing />} />
            <Route path={"/purchasing/product"} element={<ProductDetails />} />
            <Route path={"cart"} element={<Cart />} />
            <Route path={"cart/lucky-winner"} element={<Lucky100CHFWinner />} />
            <Route path={"/cart/takeover"} element={<OrderTakeover />} />
            <Route path={"/purchasing/payment"} element={<AfterPayment />} />
            <Route path={"/order/summary"} element={<OrderSummary />} />
            <Route path={"cart/user-info"} element={<UserInfo />} />
            <Route path={"cart/delivery"} element={<DeliveryInfo />} />
            <Route path={"games"} element={<Games />} />
            <Route path="games/info" element={<GamesInfo />} />
            <Route path={"games/question"} element={<SingleChoiceGame />} />
            <Route
              path={"/games/fill-in-the-blank"}
              element={<FillInTheBlank />}
            />
            <Route
              path={"/games/true-or-false"}
              element={<TrueOrFalseGame />}
            />
            <Route path={"/games/audio-questions"} element={<AudioGame />} />
            <Route path={"/games/pour-and-score"} element={<PourAndScore />} />
            <Route path={"investments"} element={<Investments />} />
            <Route path={"investments/topup"} element={<Topup />} />
            <Route path={"verify-email"} element={<VerifyEmailSuccess />} />
            <Route
              path={"investments/wine-collection"}
              element={<AllWineCollection />}
            />
            <Route
              path={"investments/wine-collection/details"}
              element={<WineDetails />}
            />
            <Route path={"blind-tasting"} element={<BlindTasting />} />
            <Route
              path={"feedback-submitted"}
              element={<ThanksForFeedback />}
            />
            <Route path={"bookmarks"} element={<Bookmarks />} />
            <Route path={"terms-of-use"} element={<TermsOfUse />} />
            <Route path={"privacy-policy"} element={<PrivacyPolicy />} />
            <Route path={"impressum"} element={<Impressum />} />
            <Route path={"product/promotion"} element={<AdProduct />} />
            <Route path={"delete-account"} element={<DeleteAccountByToken />} />
            <Route path={"guest-signup"} element={<GuestAfterPurchase />} />
          </Route>

          {/* Layout for invited friends to be registered */}
          <Route element={<LogoAndQuoteWrapper />}>
            <Route
              path={"events/confirm"}
              element={<InvitedFriendEventConfirm />}
            />
          </Route>
          {/* Simple wrapper */}
          <Route element={<DefaultWrapper />}>
            <Route
              path={"takeover/transactions"}
              element={<CelebrityTakeoverTransactions />}
            />
          </Route>

          {/* Landing pages */}
          <Route element={<LandingPageMiddleware />}>
            <Route path={"rose-glow"} element={<RoseGlowLanding />} />
          </Route>
        </Route>

        <Route path={"*"} element={<NotFound />} />
      </Route>
      <Route path={"/"} element={<NoRoute />} />
    </Routes>
  );
}

const NoRoute = () => {
  return <Navigate to={"/purchasing"} />;
};

export default App;
