import { useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { GlobalIState } from "types/RootState";
import styles from "./OrderTakeover.module.scss";
import { GiftBox } from "assets/icons/icons";
import ButtonComponent from "components/Button/Button.component";

function OrderTakeover() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { session_url, celebrity_name, celebrity_image_url } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );

  const { t: translations } = useTranslation();

  const handleContinue = () => {
    window.open(session_url, "_self");
  };

  return (
    <div className={styles.container}>
      <GiftBox />
      <h1>{translations("label.you_are_the_lucky_one")}</h1>
      <span>{translations("label.your_payment_will_be_taken_up")}</span>
      <div className={styles.imageBox}>
        <img src={celebrity_image_url} alt="Celebrity" />
        <div className={styles.overlay}>
          <p>{celebrity_name}</p>
        </div>
      </div>

      <div className={styles.hint}>
        <span>{translations("hint.100chf_winner")}</span>
      </div>

      <ButtonComponent
        label={translations("label.continue")}
        class={"btn btn-primary"}
        handleClick={() => handleContinue()}
      />
    </div>
  );
}

export default OrderTakeover;
