import ButtonComponent from "components/Button/Button.component";
import React, { useState } from "react";
import styles from "../AddWine.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  handleAddCustomValueSubmit: (data: any) => void;
}

function AddCustomFieldModal(props: Props) {
  const { handleAddCustomValueSubmit } = props;
  const { t: translations } = useTranslation();
  const [customFields, setCustomFields] = useState({ label: "", value: "" });

  return (
    <div className={styles.addCustomFieldContent}>
      <h2>{translations("label.add_custom_field")}</h2>
      <div className={styles.inputs}>
        <input
          type="text"
          name="label"
          placeholder={translations("label.field_label")}
          value={customFields.label}
          maxLength={50}
          onChange={(e) =>
            setCustomFields((prev) => ({
              ...prev,
              label: e.target.value,
            }))
          }
        />
        <input
          name="value"
          placeholder={translations("label.field_value")}
          value={customFields.value}
          maxLength={50}
          onChange={(e) =>
            setCustomFields((prev) => ({
              ...prev,
              value: e.target.value,
            }))
          }
        />
      </div>
      <div className={styles.submitButton}>
        <ButtonComponent
          label={translations("button.create_field")}
          class={"btn btn-primary"}
          handleClick={() => handleAddCustomValueSubmit(customFields)}
        />
      </div>
    </div>
  );
}

export default AddCustomFieldModal;
