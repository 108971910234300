import React from "react";
import Modal from "react-modal";
import styles from "./Modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { GlobalIState } from "types/RootState";
import { closeModal } from "App/actions";
import { InfoModalCloseIcon, CloseFirstTypeIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import { getDefaultColour } from "utils/utils";

interface Props {
  modalTitle?: String;
}

const ModalComponent: React.FC<Props> = (props) => {
  const { modalTitle } = props;
  const dispatch = useDispatch();
  const { t: translations } = useTranslation();
  const { isOpen, modalContent, modalStyle, hideModalHeader, type } =
    useSelector((state: GlobalIState) => state.modalReducer);
  const { defaultColour } = useSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const hasModalStyle = Object.keys(modalStyle || {}).length > 0;

  const customStyles = {
    content: modalStyle,
  };

  const onModalClose = () => {
    dispatch(closeModal());
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
        onRequestClose={onModalClose}
        className={styles[`${type}Content`]}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
        contentLabel="Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div></div>
        {![
          "smInfo",
          "smError",
          "smWarning",
          "mdNoBgNoClose",
          "smInfoOrange",
          "mdContent",
        ].includes(type) ? (
          <div className={styles.modalClose} onClick={onModalClose}></div>
        ) : null}
        {["smInfo", "smWarning"].includes(type) ? (
          <div className={styles.infoHeader}>
            <div
              className={styles.title}
              style={{ color: type === "smWarning" ? " #FF1069" : "#000" }}
            >
              <span>
                {translations(
                  type === "smWarning" ? "label.warning" : "label.info"
                )}
              </span>
            </div>
            <div className={styles.closeIcon} onClick={onModalClose}>
              <InfoModalCloseIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
        ) : null}
        <div className="body">{modalContent}</div>
      </Modal>
    </React.Fragment>
  );
};

export default ModalComponent;
