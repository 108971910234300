import React, { ReactNode, memo, useState } from "react";
import styles from "./CheckboxToggle.module.scss";

interface Props {
  checked?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  color?: string;
}

const CheckboxToggle: React.FC<Props> = (props) => {
  return (
    <label
      className={styles.switch}
      style={
        {
          "--color": props.color || "#600F83",
        } as React.CSSProperties
      }
    >
      <input
        type="checkbox"
        checked={props.checked ? props.checked : false}
        {...props}
      />
      <div className={styles.slider}></div>
    </label>
  );
};

export default memo(CheckboxToggle);
