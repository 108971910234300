import { call, put, takeLatest } from "redux-saga/effects";
import {
  getCountries,
  getCountriesNoAuth,
} from "services/authentication.service";
import {
  acceptEventInvitationAndRegisterFailed,
  acceptEventInvitationAndRegisterSuccess,
  checkIfHasPasscodeFailed,
  checkIfHasPasscodeSuccess,
  fetchCountriesFailed,
  fetchCountriesSuccess,
  logInBegin,
  registerFailed,
  registerSuccess,
  sendRequestResetPasswordFailed,
  sendRequestResetPasswordSuccess,
  sendSmsCodeFailed,
  sendSmsCodeSuccess,
  sendVerifyEmailFailed,
  sendVerifyEmailSuccess,
  setPersonalDetailsFailed,
  setPersonalDetailsSuccess,
  verifyEmailFailed,
  verifyEmailSuccess,
} from "./actions";
import * as authenticationService from "services/authentication.service";
import { logInSuccess, logInFailed } from "./actions";
import {
  ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN,
  CANCEL_SIGNUP,
  CANCEL_SIGNUP_BY_EMAIL_TOKEN,
  CHECK_IF_HAS_PASSCODE_BEGIN,
  FETCH_COUNTRIES_BEGIN,
  LOG_IN_BEGIN,
  REGISTER_BEGIN,
  SEND_RESET_PASSWORD_BEGIN,
  SEND_SMS_CODE_BEGIN,
  SEND_VERIFY_EMAIL_BEGIN,
  SET_PERSONAL_DETAILS_BEGIN,
  VERIFY_EMAIL_BEGIN,
} from "./constants";
import { openToaster } from "App/actions";
import StorageService from "services/storage.service";
import { getClientBegin } from "pages/Profile/actions";

export function* fetchCountries(action) {
  try {
    const response = yield call(
      action.payload === "withAuth" ? getCountries : getCountriesNoAuth
    );
    yield put(fetchCountriesSuccess(response));
  } catch (e) {
    yield put(fetchCountriesFailed(e));
  }
}

export function* logIn(action) {
  try {
    const response = yield call(authenticationService.logIn, {
      user: action.payload.user,
      password: action.payload.password,
      country_phone:
        action.payload.country_phone.length >= 2
          ? action.payload.country_phone
          : "",
      phone: action.payload.phone,
      google_auth_code: action.payload.google_auth_code,
      login_method: action.payload.login_method,
      two_factor_code: action.payload.two_factor_code,
      sms_code: action.payload.sms_code,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200 &&
      response?.data?.access_token
    ) {
      yield put(logInSuccess(response?.data));
      yield put(getClientBegin());
    } else {
      yield put(logInFailed(response?.data?.message));
      if (response?.data?.message === "error.client_doesnt_exists") {
        StorageService.setKeyValue("show_signup_popup", true);
      } else {
        yield put(openToaster("ERROR", response?.data?.message));
      }
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data));
    yield put(logInFailed(e?.response?.data));
  }
}

export function* registerUser(action) {
  try {
    const response = yield call(authenticationService.registerUser, {
      email: action.payload.email,
      password: action.payload.password,
      country_phone:
        action.payload.country_phone.length >= 2
          ? action.payload.country_phone
          : "",
      phone: action.payload.phone,
      sign_up_method: action.payload.sign_up_method,
      language: action.payload.language,
      referral_code: action.payload.referral_code,
      is_passcode: action.payload.is_passcode,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(registerSuccess(response?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
      if (action.payload.sign_up_method !== "phone") {
        yield put(
          logInBegin(
            action.payload.email,
            action.payload.password,
            action.payload.country_phone.length >= 2
              ? action.payload.country_phone
              : "",
            action.payload.phone,
            "",
            action.payload.sign_up_method,
            ""
          )
        );
      }
    } else {
      yield put(registerFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    console.log("2", e?.response);
    yield put(openToaster("ERROR", e?.response?.data?.message));
    yield put(registerFailed(e?.response?.data?.message));
  }
}

export function* setPersonalDetails(action) {
  try {
    const response = yield call(authenticationService.setPersonalDetails, {
      salutation: action.payload.salutation,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      ra_country: action.payload.ra_country,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      StorageService.setKeyValue("registeredUser", {
        salutation: action.payload.salutation,
        firstname: action.payload.firstname,
        lastname: action.payload.lastname,
      });
      yield put(setPersonalDetailsSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(setPersonalDetailsFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(setPersonalDetailsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* cancelSignup(action) {
  try {
    const response = yield call(authenticationService.cancelSignup);
    yield put(openToaster("SUCCESS", response?.data?.data));
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* cancelSignupByEmailToken(action) {
  try {
    const response = yield call(
      authenticationService.cancelSignupByEmailToken,
      action.payload
    );
    yield put(openToaster("SUCCESS", response?.data?.data));
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* acceptEventInvitationAndRegisterUser(action) {
  try {
    const response = yield call(
      authenticationService.acceptEventInvitationAndRegisterUser,
      action.payload.token,
      action.payload.data
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      if (response?.data?.data?.dashboard) {
        StorageService.setKeyValue("expiredAcceptedEventInvite", true);
      } else {
        StorageService.setKeyValue("acceptedEventInvite", true);
      }
      if (response?.data?.data?.event_id) {
        StorageService.setKeyValue("eventId", response?.data?.data?.event_id);
      }
      yield put(acceptEventInvitationAndRegisterSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
      yield put(
        logInBegin(
          action.payload.data.email,
          action.payload.data.password,
          "",
          "",
          "",
          "email",
          ""
        )
      );
    } else {
      yield put(
        acceptEventInvitationAndRegisterFailed(response?.data?.message)
      );
    }
  } catch (e) {
    yield put(acceptEventInvitationAndRegisterFailed(e?.response?.data));
  }
}

export function* sendVerifyEmail(action) {
  try {
    const response = yield call(authenticationService.sendVerifyEmail);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(sendVerifyEmailSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(sendVerifyEmailFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(sendVerifyEmailFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* verifyEmail(action) {
  try {
    const response = yield call(
      authenticationService.verifyEmail,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(verifyEmailSuccess(response?.data?.data));
    } else {
      yield put(verifyEmailFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(verifyEmailFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* sendResetPassword(action) {
  try {
    const response = yield call(
      authenticationService.requestResetPassword,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(sendRequestResetPasswordSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(sendRequestResetPasswordFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(sendRequestResetPasswordFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* checkIfHasPasscode(action) {
  try {
    const response = yield call(
      authenticationService.checkIfHasPasscode,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(checkIfHasPasscodeSuccess(response?.data?.data?.is_passcode));
    } else {
      yield put(checkIfHasPasscodeFailed(response?.response?.data));
      if (response?.data?.message === "error.client_doesnt_exists") {
        StorageService.setKeyValue("show_signup_popup", true);
      } else {
        yield put(openToaster("ERROR", response?.data?.message));
      }
    }
  } catch (e) {
    yield put(checkIfHasPasscodeFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* sendSmsCode(action) {
  try {
    const response = yield call(
      authenticationService.sendSmsCode,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(sendSmsCodeSuccess(response?.data?.data?.is_passcode));
      yield put(openToaster("SUCCESS", "label.code_sent"));
    } else {
      yield put(sendSmsCodeFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(sendSmsCodeFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* authenticationSaga() {
  yield takeLatest(FETCH_COUNTRIES_BEGIN, fetchCountries);
  yield takeLatest(LOG_IN_BEGIN, logIn);
  yield takeLatest(REGISTER_BEGIN, registerUser);
  yield takeLatest(SET_PERSONAL_DETAILS_BEGIN, setPersonalDetails);
  yield takeLatest(CANCEL_SIGNUP, cancelSignup);
  yield takeLatest(CANCEL_SIGNUP_BY_EMAIL_TOKEN, cancelSignupByEmailToken);
  yield takeLatest(
    ACCEPT_EVENT_INVITATION_AND_REGISTER_BEGIN,
    acceptEventInvitationAndRegisterUser
  );
  yield takeLatest(SEND_VERIFY_EMAIL_BEGIN, sendVerifyEmail);
  yield takeLatest(VERIFY_EMAIL_BEGIN, verifyEmail);
  yield takeLatest(SEND_RESET_PASSWORD_BEGIN, sendResetPassword);
  yield takeLatest(CHECK_IF_HAS_PASSCODE_BEGIN, checkIfHasPasscode);
  yield takeLatest(SEND_SMS_CODE_BEGIN, sendSmsCode);
}
