import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useAppDispatch, useAppSelector } from "hooks";
import { getTermsAndConditionsBegin } from "pages/Profile/actions";
import { useEffect } from "react";
import { GlobalIState } from "types/RootState";
import styles from "./TermsOfUse.module.scss";

function TermsOfUse() {
  const {
    action: { isLoading },
    termsAndConditions,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTermsAndConditionsBegin());
  }, []);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        dangerouslySetInnerHTML={{
          __html: termsAndConditions?.text,
        }}
        className={styles.wrapper}
      ></div>
    </LoaderWrapper>
  );
}

export default TermsOfUse;
