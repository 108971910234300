import { produce } from "immer";
import { GlobalReducerState } from "types/reducers/global.reducer.type";
import {
  CANCEL_EVENT_REGISTRATION_BEGIN,
  CANCEL_EVENT_REGISTRATION_FAILED,
  CANCEL_EVENT_REGISTRATION_SUCCESS,
  CLEAR_ACTION,
  CLEAR_EVENTS_FILTER,
  CLEAR_SCROLL_TO_TOP,
  CLOSE_TOASTER,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_FAILED,
  GET_CELEBRITY_TAKEOVER_TRANSACTIONS_SUCCESS,
  GET_EVENTS_BEGIN,
  GET_EVENTS_FAILED,
  GET_EVENTS_SUCCESS,
  GET_EVENT_DETAILS_BEGIN,
  GET_EVENT_DETAILS_FAILED,
  GET_EVENT_DETAILS_SUCCESS,
  LEFT_PART_SHOWN_ITEM,
  MIDDLE_PART_SHOWN_ITEM,
  OPEN_TOASTER,
  REGISTER_FRIENDS_ON_EVENT_BEGIN,
  REGISTER_FRIENDS_ON_EVENT_FAILED,
  REGISTER_FRIENDS_ON_EVENT_SUCCESS,
  REGISTER_ON_EVENT_BEGIN,
  REGISTER_ON_EVENT_FAILED,
  REGISTER_ON_EVENT_SUCCESS,
  REMOVE_FRIEND_FROM_EVENT_BEGIN,
  REMOVE_FRIEND_FROM_EVENT_FAILED,
  REMOVE_FRIEND_FROM_EVENT_SUCCESS,
  RIGHT_PART_SHOWN_ITEM,
  SCROLL_TO_TOP,
  SET_EVENTS_FILTER,
  UPDATE_INVITED_FRIEND_BEGIN,
  UPDATE_INVITED_FRIEND_FAILED,
  UPDATE_INVITED_FRIEND_SUCCESS,
} from "./constants";
import StorageService from "services/storage.service";

const initialState: GlobalReducerState = {
  action: {
    isLoading: false,
    error: null,
    message: "",
    isSuccess: false,
    actionName: null,
  },
  toaster: { isOpen: false, type: null, message: null },
  leftPartShownItem: "1",
  previousLeftPartShownItem: null,
  rightPartShownItem: null,
  middlePartShownItem: null,
  events: null,
  filteredEvents: null,
  eventDetails: null,
  scrollTop: false,
  eventSelectedCategory: "All",
  celebrityTakeoverTransactions: null,
};

const globalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case OPEN_TOASTER:
        draft.toaster.isOpen = true;
        draft.toaster.type = action.payload.type;
        draft.toaster.message = action.payload.message;
        break;
      case CLOSE_TOASTER:
        draft.toaster.isOpen = false;
        draft.toaster.type = "";
        draft.toaster.message = "";
        break;
      case LEFT_PART_SHOWN_ITEM:
        draft.previousLeftPartShownItem = initialState.leftPartShownItem;
        draft.leftPartShownItem = action.payload;
        break;
      case MIDDLE_PART_SHOWN_ITEM:
        draft.middlePartShownItem = action.payload;
        break;
      case RIGHT_PART_SHOWN_ITEM:
        draft.rightPartShownItem = action.payload;
        break;
      case GET_EVENTS_BEGIN:
      case GET_EVENT_DETAILS_BEGIN:
      case REGISTER_ON_EVENT_BEGIN:
      case CANCEL_EVENT_REGISTRATION_BEGIN:
      case REGISTER_FRIENDS_ON_EVENT_BEGIN:
      case REMOVE_FRIEND_FROM_EVENT_BEGIN:
      case UPDATE_INVITED_FRIEND_BEGIN:
      case GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN:
        draft.action.isLoading = true;
        draft.action.isSuccess = false;
        draft.action.error = null;
        draft.action.actionName = action.type;
        break;
      case REGISTER_ON_EVENT_FAILED:
      case CANCEL_EVENT_REGISTRATION_FAILED:
      case REGISTER_FRIENDS_ON_EVENT_FAILED:
      case REMOVE_FRIEND_FROM_EVENT_FAILED:
      case UPDATE_INVITED_FRIEND_FAILED:
      case GET_CELEBRITY_TAKEOVER_TRANSACTIONS_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case REGISTER_ON_EVENT_FAILED:
        StorageService.setKeyValue("registerOnEvent", false);
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        break;
      case GET_EVENTS_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        draft.events = null;
        break;
      case GET_EVENT_DETAILS_FAILED:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        draft.eventDetails = null;
        break;
      case GET_EVENTS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        draft.filteredEvents = action.payload;
        draft.events = action.payload;
        break;
      case GET_EVENT_DETAILS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.eventDetails = null;
        draft.action.actionName = action.type;
        draft.eventDetails = action.payload;
        break;
      case CANCEL_EVENT_REGISTRATION_SUCCESS:
      case REGISTER_FRIENDS_ON_EVENT_SUCCESS:
      case REMOVE_FRIEND_FROM_EVENT_SUCCESS:
      case UPDATE_INVITED_FRIEND_SUCCESS:
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case REGISTER_ON_EVENT_SUCCESS:
      case REGISTER_FRIENDS_ON_EVENT_SUCCESS:
        StorageService.setKeyValue("registerOnEvent", false);
        draft.action.isLoading = false;
        draft.action.isSuccess = true;
        draft.action.actionName = action.type;
        break;
      case SET_EVENTS_FILTER:
        draft.eventSelectedCategory = action.payload;
        draft.filteredEvents.nextEvent = state.events.nextEvent.filter(
          (item) => item.eventCategory.name === action.payload
        );
        draft.filteredEvents.upcoming = state.events.upcoming.filter(
          (item) => item.eventCategory.name === action.payload
        );
        draft.filteredEvents.oldEvents = state.events.oldEvents.filter(
          (item) => item.eventCategory.name === action.payload
        );
        break;
      case CLEAR_EVENTS_FILTER:
        draft.eventSelectedCategory = "All";
        draft.filteredEvents = state.events;
        break;
      case SCROLL_TO_TOP:
        draft.scrollTop = true;
        break;
      case CLEAR_SCROLL_TO_TOP:
        draft.scrollTop = false;
        break;
      case CLEAR_ACTION:
        draft.action = initialState.action;
        break;
      case GET_CELEBRITY_TAKEOVER_TRANSACTIONS_SUCCESS:
        draft.action.isLoading = false;
        draft.action.error = action.payload ? action.payload : "Unknown error";
        draft.action.isSuccess = false;
        draft.action.actionName = action.type;
        draft.celebrityTakeoverTransactions = action.payload;
        break;
      default:
        break;
    }
  });

export default globalReducer;
