const scope = "vinfinity/globlalReducer";

export const REQUEST_BEGIN = `${scope}/REQUEST_BEGIN`;
export const REQUEST_FAILED = `${scope}/REQUEST_FAILED`;
export const REQUEST_SUCCESS = `${scope}/REQUEST_SUCCESS`;
export const OPEN_MODAL = `${scope}/OPEN_MODAL`;
export const CLOSE_MODAL = `${scope}/CLOSE_MODAL`;
export const HIDE_MODAL_HEADER = "HIDE_MODAL_HEADER";
export const OPEN_TOASTER = `${scope}/OPEN_TOASTER`;
export const CLOSE_TOASTER = `${scope}/CLOSE_TOASTER`;
export const LEFT_PART_SHOWN_ITEM = `${scope}/LEFT_PART_SHOWN_ITEM`;
export const MIDDLE_PART_SHOWN_ITEM = `${scope}/MIDDLE_PART_SHOWN_ITEM`;
export const RIGHT_PART_SHOWN_ITEM = `${scope}/RIGHT_PART_SHOWN_ITEM`;
export const CLEAR_ACTION = `${scope}/CLEAR_ACTION`;

export const GET_EVENTS_BEGIN = `${scope}/GET_EVENTS_BEGIN`;
export const GET_EVENTS_FAILED = `${scope}/GET_EVENTS_FAILED`;
export const GET_EVENTS_SUCCESS = `${scope}/GET_EVENTS_SUCCESS`;

export const SET_EVENTS_FILTER = `${scope}/SET_EVENTS_FILTER`;
export const CLEAR_EVENTS_FILTER = `${scope}/CLEAR_EVENTS_FILTER`;

export const GET_EVENT_DETAILS_BEGIN = `${scope}/GET_EVENT_DETAILS_BEGIN`;
export const GET_EVENT_DETAILS_FAILED = `${scope}/GET_EVENT_DETAILS_FAILED`;
export const GET_EVENT_DETAILS_SUCCESS = `${scope}/GET_EVENT_DETAILS_SUCCESS`;

export const REGISTER_ON_EVENT_BEGIN = `${scope}/REGISTER_ON_EVENT_BEGIN`;
export const REGISTER_ON_EVENT_FAILED = `${scope}/REGISTER_ON_EVENT_FAILED`;
export const REGISTER_ON_EVENT_SUCCESS = `${scope}/REGISTER_ON_EVENT_SUCCESS`;

export const CANCEL_EVENT_REGISTRATION_BEGIN = `${scope}/CANCEL_EVENT_REGISTRATION_BEGIN`;
export const CANCEL_EVENT_REGISTRATION_FAILED = `${scope}/CANCEL_EVENT_REGISTRATION_FAILED`;
export const CANCEL_EVENT_REGISTRATION_SUCCESS = `${scope}/CANCEL_EVENT_REGISTRATION_SUCCESS`;

export const REGISTER_FRIENDS_ON_EVENT_BEGIN = `${scope}/REGISTER_FRIENDS_ON_EVENT_BEGIN`;
export const REGISTER_FRIENDS_ON_EVENT_FAILED = `${scope}/REGISTER_FRIENDS_ON_EVENT_FAILED`;
export const REGISTER_FRIENDS_ON_EVENT_SUCCESS = `${scope}/REGISTER_FRIENDS_ON_EVENT_SUCCESS`;

export const REMOVE_FRIEND_FROM_EVENT_BEGIN = `${scope}/REMOVE_FRIEND_FROM_EVENT_BEGIN`;
export const REMOVE_FRIEND_FROM_EVENT_FAILED = `${scope}/REMOVE_FRIEND_FROM_EVENT_FAILED`;
export const REMOVE_FRIEND_FROM_EVENT_SUCCESS = `${scope}/REMOVE_FRIEND_FROM_EVENT_SUCCESS`;

export const UPDATE_INVITED_FRIEND_BEGIN = `${scope}/UPDATE_INVITED_FRIEND_BEGIN`;
export const UPDATE_INVITED_FRIEND_FAILED = `${scope}/UPDATE_INVITED_FRIEND_FAILED`;
export const UPDATE_INVITED_FRIEND_SUCCESS = `${scope}/UPDATE_INVITED_FRIEND_SUCCESS`;

export const SCROLL_TO_TOP = `${scope}/SCROLL_TO_TOP`;
export const CLEAR_SCROLL_TO_TOP = `${scope}/CLEAR_SCROLL_TO_TOP`;

export const GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN = `${scope}/GET_CELEBRITY_TAKEOVER_TRANSACTIONS_BEGIN`;
export const GET_CELEBRITY_TAKEOVER_TRANSACTIONS_FAILED = `${scope}/GET_CELEBRITY_TAKEOVER_TRANSACTIONS_FAILED`;
export const GET_CELEBRITY_TAKEOVER_TRANSACTIONS_SUCCESS = `${scope}/GET_CELEBRITY_TAKEOVER_TRANSACTIONS_SUCCESS`;
