import { GlobalIState } from "types/RootState";
import styles from "./Membership.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { formatPrice, getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getClientBegin } from "pages/Profile/actions";
import {
  getClientObjectivesBegin,
  getClientTimelineBegin,
} from "pages/Gamification/actions";
import {
  DownArrowRounded,
  MembershipEventsAttendedIcon,
  MembershipMonthsInvestedIcon,
  MembershipPlayedIcon,
  MembershipPurchasesIcon,
  ScrollDownIcon,
  ScrollUpIcon,
} from "assets/icons/icons";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import MembershipTable from "./MembershipTable/MembershipTable.component";
import MembershipTimeline from "./MembershipTimeline/MembershipTimeline.component";
import { Tooltip } from "@mui/material";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";

function Membership() {
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    clientObjectives,
  } = useAppSelector((state: GlobalIState) => state.GamificationReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [showTimeline, setShowTimeline] = useState(false);
  const isMobile = useCheckMobileScreen();
  const nextLevelsLabelsLength = isMobile ? 25 : 30;

  useEffect(() => {
    if (!client) {
      dispatch(getClientBegin());
    }

    dispatch(getClientObjectivesBegin());
    dispatch(getClientTimelineBegin());
  }, [client]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.container}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <div className={styles.statusesContainer}>
            <div className={styles.status}>
              <span>
                {translations("label.your_status")}: {client?.membership?.name}
              </span>
            </div>
            <div className={styles.status}>
              <span>
                {translations("label.your_medals")}:{" "}
                {formatPrice(client?.medals_balance.toString())}
              </span>
            </div>
          </div>

          <div className={styles.objectivesContainer}>
            <div>
              <MembershipPurchasesIcon />
              <div className={styles.labelContent}>
                <span className={styles.count}>
                  {clientObjectives?.purchases?.count}{" "}
                </span>
                <Tooltip
                  title={
                    translations("label.purchases")?.length > 25
                      ? translations("label.purchases")
                      : ""
                  }
                >
                  <span>
                    {translations("label.purchases")?.length > 25
                      ? translations("label.purchases")?.slice(0, 25) + "..."
                      : translations("label.purchases")}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div>
              <MembershipPlayedIcon />
              <div className={styles.labelContent}>
                <span className={styles.count}>
                  {clientObjectives?.games?.count + " "}
                </span>
                <Tooltip
                  title={
                    translations("label.games_played")?.length > 25
                      ? translations("label.games_played")
                      : ""
                  }
                >
                  <span>
                    {translations("label.games_played")?.length > 25
                      ? translations("label.games_played")?.slice(0, 25) + "..."
                      : translations("label.games_played")}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div>
              <MembershipMonthsInvestedIcon />
              <div className={styles.labelContent}>
                <span className={styles.count}>
                  {clientObjectives?.investments?.count}{" "}
                </span>
                <Tooltip
                  title={
                    translations("label.months_invested")?.length > 25
                      ? translations("label.months_invested")
                      : ""
                  }
                >
                  <span>
                    {translations("label.months_invested")?.length > 25
                      ? translations("label.months_invested")?.slice(0, 25) +
                        "..."
                      : translations("label.months_invested")}
                  </span>
                </Tooltip>
              </div>
            </div>
            <div>
              <MembershipEventsAttendedIcon />
              <div className={styles.labelContent}>
                <span className={styles.count}>0</span>
                <Tooltip
                  title={
                    translations("label.events_attended")?.length > 25
                      ? translations("label.events_attended")
                      : ""
                  }
                >
                  <span>
                    {translations("label.events_attended")?.length > 25
                      ? translations("label.events_attended")?.slice(0, 25) +
                        "..."
                      : translations("label.events_attended")}
                  </span>
                </Tooltip>
              </div>
            </div>
          </div>

          <div className={styles.nextLevelsContainer}>
            <h3>{translations("label.your_next_levels")}</h3>

            <div className={styles.cards}>
              <div className={styles.card}>
                <div className={styles.left}>
                  <span>{translations("label.buying")}</span>
                </div>
                <div className={styles.right}>
                  <div className={styles.upperContent}>
                    <DownArrowRounded color={getDefaultColour(defaultColour)} />
                    <span className={styles.value}>
                      CHF{" "}
                      {formatPrice(
                        Number(clientObjectives?.purchases?.next)
                          .toFixed(2)
                          .toString()
                      )}
                    </span>
                  </div>
                  <div className={styles.bottomContent}>
                    <Tooltip
                      title={
                        translations("label.to_next_discount_reduction")
                          ?.length > nextLevelsLabelsLength
                          ? translations("label.to_next_discount_reduction")
                          : ""
                      }
                    >
                      <span>
                        {translations("label.to_next_discount_reduction")
                          ?.length > nextLevelsLabelsLength
                          ? translations(
                              "label.to_next_discount_reduction"
                            )?.slice(0, nextLevelsLabelsLength) + "..."
                          : translations("label.to_next_discount_reduction")}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.left}>
                  <span>
                    {translations("label.referrals")?.length > 15
                      ? translations("label.referrals")?.substring(0, 15) +
                        "..."
                      : translations("label.referrals")}
                  </span>
                </div>
                <div className={styles.right}>
                  <div className={styles.upperContent}>
                    <DownArrowRounded color={getDefaultColour(defaultColour)} />
                    <div>
                      <span className={styles.value}>
                        {clientObjectives?.referrals?.next}{" "}
                      </span>
                    </div>
                  </div>
                  <div className={styles.bottomContent}>
                    <Tooltip
                      title={
                        translations("label.to_next_benefit_unlock")?.length >
                        nextLevelsLabelsLength
                          ? translations("label.to_next_benefit_unlock")
                          : ""
                      }
                    >
                      <span>
                        {translations("label.to_next_benefit_unlock")?.length >
                        nextLevelsLabelsLength
                          ? translations("label.to_next_benefit_unlock")?.slice(
                              0,
                              nextLevelsLabelsLength
                            ) + "..."
                          : translations("label.to_next_benefit_unlock")}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.left}>
                  <span>{translations("label.staking")}</span>
                </div>
                <div className={styles.right}>
                  <div className={styles.upperContent}>
                    <DownArrowRounded color={getDefaultColour(defaultColour)} />
                    <span className={styles.value}>
                      CHF{" "}
                      {formatPrice(
                        Number(clientObjectives?.investments?.next)
                          .toFixed(2)
                          .toString()
                      )}
                    </span>
                  </div>
                  <div className={styles.bottomContent}>
                    <Tooltip
                      title={
                        translations("label.to_next_booster_bonus")?.length >
                        nextLevelsLabelsLength
                          ? translations("label.to_next_booster_bonus")
                          : ""
                      }
                    >
                      <span>
                        {translations("label.to_next_booster_bonus")?.length >
                        nextLevelsLabelsLength
                          ? translations("label.to_next_booster_bonus")?.slice(
                              0,
                              nextLevelsLabelsLength
                            ) + "..."
                          : translations("label.to_next_booster_bonus")}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={styles.openTimelineBox}
            style={{ opacity: 0.5 }}
            // onClick={() => setShowTimeline(!showTimeline)}
          >
            {showTimeline ? <ScrollUpIcon /> : <ScrollDownIcon />}
          </div>

          {showTimeline ? <MembershipTimeline /> : null}
        </div>

        <div className={styles.tableCard}>
          <MembershipTable type="referrals" />
        </div>

        <div className={styles.tableCard}>
          <MembershipTable type="medals" />
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default Membership;
