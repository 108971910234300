import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./GamesInfo.module.scss";
import { getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import {
  GoldCoinIcon,
  QuestionMarkIcon,
  ReferralsHeartIcon,
} from "assets/icons/icons";
import { useNavigate } from "react-router-dom";

function GamesInfo() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <h1>{translations("label.vinfinity_games")}</h1>

      <span className={styles.colored}>
        {translations("label.your_daily_dose_of_lightning_up")}
      </span>
      <br />
      <div className={styles.subtitle}>
        <span>{translations("label.games_info")}</span>
      </div>
      <div className={styles.boxes}>
        <div className={styles.first}>
          <QuestionMarkIcon />
          <span>{translations("label.learn_through_games")}</span>
        </div>
        <div className={styles.second}>
          <span>{translations("label.win_medals_and_convert_them")}</span>
          <GoldCoinIcon />
        </div>
        <div className={styles.third}>
          <ReferralsHeartIcon />
          <span>{translations("label.make_referrals")}</span>
        </div>
      </div>

      <div className={styles.button} onClick={() => navigate("/membership")}>
        <span>{translations("label.see_your_membership_status")}</span>
      </div>
    </div>
  );
}

export default GamesInfo;
