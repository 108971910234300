import { call, put, takeLatest } from "redux-saga/effects";

import * as profileService from "services/profile.service";
import {
  addEmailFailed,
  addEmailSuccess,
  addPhoneFailed,
  addPhoneSuccess,
  changeAuthSecurityFailed,
  changeAuthSecuritySuccess,
  changeEmailFailed,
  changeEmailSuccess,
  changePasswordFailed,
  changePasswordSuccess,
  changePhoneNumberFailed,
  changePhoneNumberSuccess,
  checkIfHasSavedCardFailed,
  checkIfHasSavedCardSuccess,
  confirmAddEmailFailed,
  confirmAddEmailSuccess,
  confirmAddPhoneFailed,
  confirmAddPhoneSuccess,
  confirmChangePhoneNumberFailed,
  confirmChangePhoneNumberSuccess,
  confirmPasswordChangeFailed,
  confirmPasswordChangeSuccess,
  deactivateTwoFactorAuthFailed,
  deactivateTwoFactorAuthSuccess,
  generateTwoFactorAuthFailed,
  generateTwoFactorAuthSuccess,
  getClientFailed,
  getClientSuccess,
  getPendingDeliveryBalanceFailed,
  getPendingDeliveryBalanceSuccess,
  getPrivacyPolicySuccess,
  getReferralCodeFailed,
  getReferralCodeSuccess,
  getS3UploadReferralImageFailed,
  getS3UploadReferralImageSuccess,
  getTermsAndConditionsSuccess,
  getUserReferralsFailed,
  getUserReferralsSuccess,
  getWalletTotalAmountFailed,
  getWalletTotalAmountSuccess,
  resetPasswordFailed,
  resetPasswordSuccess,
  updateAddressDetailsFailed,
  updateAddressDetailsSuccess,
  updateApplicationViewFailed,
  updateApplicationViewSuccess,
  updateLanguageFailed,
  updateLanguageSuccess,
  updateNotificationIdentFailed,
  updateNotificationIdentSuccess,
  updatePersonalDetailsFailed,
  updatePersonalDetailsSuccess,
  updateProfilePictureFailed,
  updateProfilePictureSuccess,
  updateUserInfoFailed,
  updateUserInfoSuccess,
  verifyTwoFactorAuthFailed,
  verifyTwoFactorAuthSuccess,
} from "./actions";
import { openToaster } from "App/actions";
import {
  GET_CLIENT_BEGIN,
  CHANGE_PASSWORD_BEGIN,
  RESET_PASSWORD_BEGIN,
  AGREE_TO_TERMS_AND_CONDITIONS,
  AGREE_TO_PRIVACY_POLICY,
  UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN,
  UPDATE_LANGUAGE_BEGIN,
  UPDATE_PROFILE_PICTURE_BEGIN,
  UPDATE_PERSONAL_DETAILS_BEGIN,
  UPDATE_ADDRESS_DETAILS_BEGIN,
  DECLINE_PRIVACY_POLICY,
  REQUEST_ACCOUNT_CLOSURE,
  UPDATE_APPLICATION_VIEW_BEGIN,
  GET_PRIVACY_POLICY,
  GET_TERMS_AND_CONDITIONS,
  ADD_PHONE_BEGIN,
  ADD_EMAIL_BEGIN,
  GET_PENDING_DELIVERY_BALANCE_BEGIN,
  CONFIRM_PASSWORD_CHANGE_BEGIN,
  CHANGE_EMAIL_BEGIN,
  CONFIRM_CHANGE_EMAIL_BEGIN,
  CONFIRM_ADD_EMAIL_BEGIN,
  CHANGE_PHONE_NUMBER_BEGIN,
  CONFIRM_CHANGE_PHONE_NUMBER_BEGIN,
  CONFIRM_ADD_PHONE_BEGIN,
  GET_REFERRAL_CODE_BEGIN,
  GET_USER_REFERRALS_BEGIN,
  CHECK_IF_HAS_SAVED_CARD_BEGIN,
  GENERATE_TWO_FACTOR_AUTH_BEGIN,
  VERIFY_TWO_FACTOR_AUTH_BEGIN,
  DEACTIVATE_TWO_FACTOR_AUTH_BEGIN,
  GET_WALLET_TOTAL_AMOUNT_BEGIN,
  UPDATE_USER_INFO_BEGIN,
  CHANGE_AUTH_SECURITY_BEGIN,
  GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN,
} from "./constants";
import StorageService from "services/storage.service";

import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function* getClientData() {
  try {
    const response = yield call(profileService.getClientData);
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      localStorage.setItem(
        "applicationViewSettings",
        JSON.stringify({
          background_id: response?.data?.data.background_id,
          color: response?.data?.data.color,
        })
      );
      yield put(getClientSuccess(response?.data?.data));
    } else {
      yield put(getClientFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getClientFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* changePassword(action) {
  try {
    const response = yield call(profileService.changePassword, {
      old_password: action.payload.old_password,
      password: action.payload.password,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(changePasswordSuccess(response?.data));
      // yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(changePasswordFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(changePasswordFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* resetPassword(action) {
  try {
    const response = yield call(profileService.resetPassword, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(resetPasswordSuccess(response?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(resetPasswordFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(resetPasswordFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getTermsAndConditions() {
  try {
    const response = yield call(profileService.getTermsAndConditions);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getTermsAndConditionsSuccess(response?.data?.data));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getPrivacyPolicy() {
  try {
    const response = yield call(profileService.getPrivacyPolicy);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getPrivacyPolicySuccess(response?.data?.data));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* agreeToTermsAndCond() {
  try {
    const response = yield call(profileService.agreeToTermsAndConditions);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* agreeToPrivacyPolicy() {
  try {
    const response = yield call(profileService.agreeToPrivacyPolicy);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* declineToPrivacyPolicy() {
  try {
    const response = yield call(profileService.declineToPrivacyPolicy);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* requestAccountClosure() {
  try {
    const response = yield call(profileService.requestAccountClosure);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateNotificationIdent(action) {
  try {
    const response = yield call(
      profileService.updateNotificationIdentification,
      {
        notifications_enabled: action.payload.notifications_enabled,
        face_id_enabled: action.payload.face_id_enabled,
        two_factor_auth_enabled: action.payload.two_factor_auth_enabled,
      }
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateNotificationIdentSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateNotificationIdentFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateNotificationIdentFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateLanguage(action) {
  try {
    const response = yield call(profileService.updateLanguage, {
      language: action.payload,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateLanguageSuccess(response?.data));
      if (!window.location.pathname.includes("/product/promotion"))
        yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateLanguageFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateLanguageFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateProfilePicture(action) {
  try {
    const response = yield call(profileService.updateProfilePicture, {
      image: action.payload.image,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateProfilePictureSuccess(action?.payload?.image));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateProfilePictureFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateProfilePictureFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updatePersonalDetails(action) {
  try {
    const response = yield call(profileService.updatePersonalDetails, {
      salutation: action.payload.salutation,
      firstname: action.payload.firstname,
      lastname: action.payload.lastname,
      date_of_birth: action.payload.date_of_birth,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updatePersonalDetailsSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updatePersonalDetailsFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updatePersonalDetailsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* addPhone(action) {
  try {
    const response = yield call(profileService.addPhone, {
      country_phone: action.payload.country_phone,
      phone: action.payload.phone,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      StorageService.setKeyValue("addPhoneSuccess", true);
      yield put(addPhoneSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(addPhoneFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(addPhoneFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* addEmail(action) {
  try {
    const response = yield call(profileService.addEmail, {
      email: action.payload.email,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      StorageService.setKeyValue("addEmailSuccess", true);
      yield put(addEmailSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(addEmailFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(addEmailFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* confirmAddEmail(action) {
  try {
    const response = yield call(profileService.confirmAddEmail);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(confirmAddEmailSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(confirmAddEmailFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(confirmAddEmailFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateAddressDetails(action) {
  try {
    const response = yield call(
      profileService.updateAddressDetails,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateAddressDetailsSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateAddressDetailsFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateAddressDetailsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateApplicationView(action) {
  let data = {
    color: action.payload.color,
    background_id: action.payload.background_id,
  };
  try {
    const response = yield call(profileService.updateApplicationView, data);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      localStorage.setItem("applicationViewSettings", JSON.stringify(data));
      yield put(updateApplicationViewSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateApplicationViewFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateApplicationViewFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getPendingDeliveryBalance() {
  try {
    const response = yield call(profileService.getPendingDeliveryBalance);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getPendingDeliveryBalanceSuccess(response?.data?.data));
    } else {
      yield put(getPendingDeliveryBalanceFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getPendingDeliveryBalanceFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getWalletTotalAmount() {
  try {
    const response = yield call(profileService.getWalletTotalAmount);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getWalletTotalAmountSuccess(response?.data?.data));
    } else {
      yield put(getWalletTotalAmountFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getWalletTotalAmountFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* confirmPasswordChangeCall() {
  try {
    const response = yield call(profileService.confirmPasswordChange);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(confirmPasswordChangeSuccess(response?.data?.data));
    } else {
      yield put(confirmPasswordChangeFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(confirmPasswordChangeFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* changeEmail(action) {
  try {
    const response = yield call(profileService.changeEmail, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(changeEmailSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(changeEmailFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(changeEmailFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* confirmChangeEmail(action) {
  try {
    const response = yield call(profileService.confirmChangeEmail, {
      token: action.payload.token,
      email: action.payload.email,
    });

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(changeEmailSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(changeEmailFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(changeEmailFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* changePhoneNumber(action) {
  try {
    const response = yield call(
      profileService.changePhoneNumber,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(changePhoneNumberSuccess(response?.data?.data));
    } else {
      yield put(changePhoneNumberFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(changePhoneNumberFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* confirmChangePhoneNumber(action) {
  try {
    const response = yield call(
      profileService.confirmPhoneNumberChange,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(confirmChangePhoneNumberSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(confirmChangePhoneNumberFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(confirmChangePhoneNumberFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* confirmAddPhone(action) {
  try {
    const response = yield call(profileService.confirmAddPhone, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(confirmAddPhoneSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(confirmAddPhoneFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(confirmAddPhoneFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getReferralCode() {
  try {
    const response = yield call(profileService.getReferralCode);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getReferralCodeSuccess(response?.data?.data));
    } else {
      yield put(getReferralCodeFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getReferralCodeFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getUserReferrals(action) {
  try {
    const response = yield call(
      profileService.getUserReferrals,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(getUserReferralsSuccess(response?.data?.data?.referredClients));
    } else {
      yield put(getUserReferralsFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getUserReferralsFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* checkIfUserHasSavedCard() {
  try {
    const response = yield call(profileService.checkIfHasSavedCard);
    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(checkIfHasSavedCardSuccess(response?.data?.data));
    } else {
      yield put(checkIfHasSavedCardFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(checkIfHasSavedCardFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* generateTwoFactorAuth(action) {
  try {
    const response = yield call(profileService.generateTwoFator);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(generateTwoFactorAuthSuccess(response?.data?.data));
    } else {
      yield put(generateTwoFactorAuthFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(generateTwoFactorAuthFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* verifyTwoFactorAuth(action) {
  try {
    const response = yield call(
      profileService.verifyTwoFactorAuth,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(verifyTwoFactorAuthSuccess(response?.data?.data?.qrCode));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(verifyTwoFactorAuthFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(verifyTwoFactorAuthFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* deactivateTwoFactorAuth(action) {
  try {
    const response = yield call(profileService.deactivateTwoFactorAuth);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(deactivateTwoFactorAuthSuccess(response?.data?.data?.qrCode));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(deactivateTwoFactorAuthFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(deactivateTwoFactorAuthFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* updateUserInfo(action) {
  try {
    const response = yield call(profileService.updateUserInfo, action.payload);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(updateUserInfoSuccess(response?.data?.data));
      yield put(openToaster("SUCCESS", response?.data?.message));
    } else {
      yield put(updateUserInfoFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(updateUserInfoFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* changeAuthSecurity(action) {
  try {
    const response = yield call(
      profileService.changeAuthSecurity,
      action.payload
    );

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(changeAuthSecuritySuccess(response?.data));
    } else {
      yield put(changeAuthSecurityFailed(response?.response?.data));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(changeAuthSecurityFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export function* getS3ReferralImageLink() {
  try {
    const response = yield call(profileService.getReferralImageUploadUrl);

    if (
      response?.data &&
      response?.data?.status &&
      response?.data?.status === 200
    ) {
      yield put(
        getS3UploadReferralImageSuccess(response?.data?.data?.uploadUrl)
      );
    } else {
      yield put(getS3UploadReferralImageFailed(response?.data?.message));
      yield put(openToaster("ERROR", response?.data?.message));
    }
  } catch (e) {
    yield put(getS3UploadReferralImageFailed(e?.response?.data?.message));
    yield put(openToaster("ERROR", e?.response?.data?.message));
  }
}

export default function* profileSaga() {
  yield takeLatest(GET_CLIENT_BEGIN, getClientData);
  yield takeLatest(CHANGE_PASSWORD_BEGIN, changePassword);
  yield takeLatest(RESET_PASSWORD_BEGIN, resetPassword);
  yield takeLatest(GET_TERMS_AND_CONDITIONS, getTermsAndConditions);
  yield takeLatest(GET_PRIVACY_POLICY, getPrivacyPolicy);
  yield takeLatest(AGREE_TO_TERMS_AND_CONDITIONS, agreeToTermsAndCond);
  yield takeLatest(AGREE_TO_PRIVACY_POLICY, agreeToPrivacyPolicy);
  yield takeLatest(
    UPDATE_NOTIFICATION_IDENTIFICATION_BEGIN,
    updateNotificationIdent
  );
  yield takeLatest(UPDATE_LANGUAGE_BEGIN, updateLanguage);
  yield takeLatest(UPDATE_PROFILE_PICTURE_BEGIN, updateProfilePicture);
  yield takeLatest(UPDATE_PERSONAL_DETAILS_BEGIN, updatePersonalDetails);
  yield takeLatest(UPDATE_ADDRESS_DETAILS_BEGIN, updateAddressDetails);
  yield takeLatest(DECLINE_PRIVACY_POLICY, declineToPrivacyPolicy);
  yield takeLatest(REQUEST_ACCOUNT_CLOSURE, requestAccountClosure);
  yield takeLatest(UPDATE_APPLICATION_VIEW_BEGIN, updateApplicationView);
  yield takeLatest(ADD_PHONE_BEGIN, addPhone);
  yield takeLatest(ADD_EMAIL_BEGIN, addEmail);
  yield takeLatest(CONFIRM_ADD_EMAIL_BEGIN, confirmAddEmail);
  yield takeLatest(
    GET_PENDING_DELIVERY_BALANCE_BEGIN,
    getPendingDeliveryBalance
  );
  yield takeLatest(GET_WALLET_TOTAL_AMOUNT_BEGIN, getWalletTotalAmount);
  yield takeLatest(CONFIRM_PASSWORD_CHANGE_BEGIN, confirmPasswordChangeCall);
  yield takeLatest(CHANGE_EMAIL_BEGIN, changeEmail);
  yield takeLatest(CONFIRM_CHANGE_EMAIL_BEGIN, confirmChangeEmail);
  yield takeLatest(CHANGE_PHONE_NUMBER_BEGIN, changePhoneNumber);
  yield takeLatest(CONFIRM_CHANGE_PHONE_NUMBER_BEGIN, confirmChangePhoneNumber);
  yield takeLatest(CONFIRM_ADD_PHONE_BEGIN, confirmAddPhone);
  yield takeLatest(GET_REFERRAL_CODE_BEGIN, getReferralCode);
  yield takeLatest(GET_USER_REFERRALS_BEGIN, getUserReferrals);
  yield takeLatest(CHECK_IF_HAS_SAVED_CARD_BEGIN, checkIfUserHasSavedCard);
  yield takeLatest(GENERATE_TWO_FACTOR_AUTH_BEGIN, generateTwoFactorAuth);
  yield takeLatest(VERIFY_TWO_FACTOR_AUTH_BEGIN, verifyTwoFactorAuth);
  yield takeLatest(DEACTIVATE_TWO_FACTOR_AUTH_BEGIN, deactivateTwoFactorAuth);
  yield takeLatest(UPDATE_USER_INFO_BEGIN, updateUserInfo);
  yield takeLatest(CHANGE_AUTH_SECURITY_BEGIN, changeAuthSecurity);
  yield takeLatest(GET_S3_UPLOAD_REFERRAL_IMAGE_BEGIN, getS3ReferralImageLink);
}
