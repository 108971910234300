import React, { useState } from "react";

import FormControl from "@mui/material/FormControl";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

interface IOptions {
  value: string | number;
  label: string;
}
interface Props {
  value?: any;
  label: String;
  name?: string;
  width?: string | number;
  placeholder?: string;
  type: "select" | "textbox" | "date" | "password";
  options?: IOptions[];
  required?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement> | any;
  style?: object;
  disabled?: boolean;
  inputProps?: object;
  InputLabelProps?: object;
}

export default function InputMui({
  label,
  width,
  type,
  options,
  ...props
}: Props) {
  const { defaultBackground, defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const [showPassword, setShowPassword] = useState(false);
  const { t: translations } = useTranslation();
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      {/* <OutlinedInput id="outlined-adornment-amount" label={label} {...props} />} */}
      {type === "date" && (
        <FormControl
          fullWidth
          sx={{ m: 1, width: `${width}ch`, margin: "10px 0px" }}
        >
          {/* <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={props?.value}
              //   label={label}
              inputFormat="DD/MM/YYYY"
              maxDate={dayjs().subtract(18, "year").format()}
              disableFuture
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& label": {
                      color: getDefaultColour(defaultColour),
                    },
                    "& label.Mui-focused": {
                      color: getDefaultColour(defaultColour),
                      border: 0,
                    },
                    "& .MuiOutlinedInput-root": {
                      color: getDefaultColour(defaultColour),
                      fontFamily: "Montserrat-Medium",
                      width: "100%",
                      "& fieldset": {
                        borderColor: "transparent",
                        borderTop: 0,
                        borderLeft: 0,
                        borderRight: 0,
                        borderRadius: 0,
                        borderBottom: "0px",
                      },
                      "&:hover fieldset": {
                        borderTopColor: "transparent !important",
                        borderLeftColor: "transparent !important",
                        borderRightColor: "transparent !important",
                      },
                      "&.Mui-error": {
                        borderColor: "#e2e2e2",
                        "&.MuiOutlinedInput-notchedOutline": {
                          borderColor: "#e2e2e2",
                        },
                      },
                      "&.MuiInputAdornment-root": {
                        opacity: 0,
                      },
                      "&.Mui-disabled": {
                        color: getDefaultColour(defaultColour),
                        WebkitTextFillColor: "#000",
                      },
                      "&.MuiOutlinedInput-notchedOutline": {},
                      svg: { color: getDefaultColour(defaultColour) },
                    },
                  }}
                  {...params}
                />
              )}
              PaperProps={{
                sx: {
                  "& .MuiPickersDay-root": {
                    "&.Mui-selected": {
                      backgroundColor: getDefaultColour(defaultColour),
                    },
                  },
                },
              }}
              {...props}
            />
          </LocalizationProvider>
        </FormControl>
      )}
    </>
  );
}
