import {
  MinusRoundedIcon,
  RoundedPlusIcon,
  TrashIcon,
} from "assets/icons/icons";
import styles from "./CartItemRowCard.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { formatPrice, getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import { deleteCartItem, updateQuantity } from "pages/Purchasing/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { ProductDetails } from "types/reducers/purchasing.reducer.type";

interface Props {
  item: ProductDetails;
  isLastItem?: boolean;
  index: number;
}

function CartItemRowCard(props: Props) {
  const {
    id,
    product_name,
    price,
    product_image,
    productProducer,
    quantity,
    wine_name,
    region,
    vintage,
    bottle_size,
  } = props.item;
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );

  const { cartItems } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const totalPrice = Number(Number(price) * quantity).toFixed(2);

  return isMobile ? (
    <div
      className={styles.card}
      style={{
        width: cartItems?.length > 1 ? "calc(100dvw - 80px)" : "100%",
      }}
    >
      <div className={styles.productMainInfos}>
        <div className={styles.image}>
          <img src={product_image} alt={product_name} />
        </div>
        <div className={styles.labels}>
          <div>
            <span>{productProducer}</span>
          </div>
          <div className={styles.wineNameLabel}>
            <span>{wine_name}</span>
          </div>
          <div className={styles.vintageLabels}>
            <span>{vintage} | </span>
            <span>{bottle_size}</span>
          </div>
          {/* <div className={styles.twoBoxes}>
                <div>{vintage}</div>
                <div>{bottle_size}</div>
              </div> */}
          <div className={styles.titleLabels}>
            <span>
              {translations("label.bottle_price")} |{" "}
              {translations("label.total_price")}
            </span>
          </div>
          <div className={styles.twoBoxes}>
            <div>
              <div className={styles.bottlePriceLabel}>
                <span>CHF {formatPrice(price.toString())}</span>
              </div>
            </div>
            <div>
              <div className={styles.blackBoldTitle}>
                <span>CHF {formatPrice(totalPrice.toString())}</span>
              </div>
            </div>
          </div>

          <div className={styles.bottomPart}>
            <div className={styles.quantityActions}>
              <div onClick={() => dispatch(updateQuantity("decrement", id))}>
                <MinusRoundedIcon color={"#000"} />
              </div>
              <span>{quantity}</span>
              <div onClick={() => dispatch(updateQuantity("increment", id))}>
                <RoundedPlusIcon color={"#000"} />
              </div>
            </div>
            <div
              className={styles.deleteItemIcon}
              onClick={() => dispatch(deleteCartItem(id))}
            >
              <TrashIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={styles.card}
      style={{
        borderTopLeftRadius: isMobile && props.index === 0 ? 10 : 0,
        borderTopRightRadius: isMobile && props.index === 0 ? 10 : 0,
        borderBottomLeftRadius: isMobile && props.isLastItem ? 10 : 0,
        borderBottomRightRadius: isMobile && props.isLastItem ? 10 : 0,
      }}
    >
      <div>
        <img src={product_image} alt={product_name} />
      </div>
      <div className={styles.leftPart}>
        <div className={styles.highlighted}>
          <span>
            {isMobile && product_name?.length > 30
              ? product_name?.substring(0, 30) + "..."
              : product_name}
          </span>
        </div>
        <div>
          <span>{productProducer}</span>
        </div>
      </div>
      <div className={styles.rightPart}>
        <>
          <div>
            <div className={styles.coloredLabel}>
              <span>{translations("label.unit_price")}:</span>
            </div>
            <div>
              <span>
                CHF {price}
                {/* {unit_price} */}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.coloredLabel}>
              <span>{translations("label.quantity")}</span>
            </div>
            <div className={styles.quantityActions}>
              <div onClick={() => dispatch(updateQuantity("decrement", id))}>
                <MinusRoundedIcon color={getDefaultColour(defaultColour)} />
              </div>
              <span>{quantity}</span>
              <div onClick={() => dispatch(updateQuantity("increment", id))}>
                <RoundedPlusIcon color={getDefaultColour(defaultColour)} />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.coloredLabel}>
              <span>{translations("label.total_price")}</span>
            </div>
            <div>
              <span> CHF {formatPrice(totalPrice.toString())}</span>
            </div>
          </div>
        </>
        <div></div>
        <div
          className={styles.deleteItemIcon}
          onClick={() => dispatch(deleteCartItem(id))}
        >
          <TrashIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
    </div>
  );
}

export default CartItemRowCard;
