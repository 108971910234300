import { CopyIcon, LockerIcon, RoundedSuccessIcon } from "assets/icons/icons";
import styles from "./TwoFactorAuth.module.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect, useState } from "react";
import { GlobalIState } from "types/RootState";
import {
  deactivateTwoFactorAuthBegin,
  generateTwoFactorAuthBegin,
  verifyTwoFactorAuthBegin,
} from "pages/Profile/actions";
import { getDefaultColour, handleOnlyNumbersKeydown } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { deactivateTwoFactorAuth } from "pages/Profile/saga";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { openToaster } from "App/actions";

function TwoFactorAuth() {
  const {
    defaultColour,
    client,
    twoFactorQrCode,
    manualEntryAuthenticationCode,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [twoFactorValue, setTwoFactorValue] = useState("");
  const isMobile = useCheckMobileScreen();

  const handleSubmit = () => {
    dispatch(verifyTwoFactorAuthBegin(twoFactorValue));
  };

  const handleDeactivateTwoFactor = () => {
    dispatch(deactivateTwoFactorAuthBegin());
  };

  const handleCopyCode = () => {
    navigator.clipboard.writeText(manualEntryAuthenticationCode);
    dispatch(openToaster("SUCCESS", translations("label.copied")));
  };

  useEffect(() => {
    if (!twoFactorQrCode) {
      dispatch(generateTwoFactorAuthBegin());
    }
  }, [twoFactorQrCode]);

  return (
    <div
      className={styles.main}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <LockerIcon />
        <h2>{translations("label.two_factor_authentication")}</h2>
        <span>
          {translations(
            client?.two_factor_auth_enabled
              ? "label.two_factor_active"
              : isMobile
              ? "label.manual_two_factor_auth_subtitle"
              : "label.two_factor_auth_subtitle"
          )}
        </span>
      </div>
      {!client?.two_factor_auth_enabled ? (
        <div className={styles.content}>
          {isMobile ? (
            <div className={styles.manualEntryCode}>
              <div className={styles.title}>
                <h5>
                  1. {translations("label.enter_code_in_authentication_app")}
                </h5>
              </div>
              <div className={styles.code}>
                <span>{manualEntryAuthenticationCode}</span>
                <div
                  className={styles.copyIcon}
                  onClick={() => handleCopyCode()}
                >
                  <CopyIcon color="#000" />
                </div>
              </div>
            </div>
          ) : (
            <>
              <h5>1. {translations("label.scan_qr_code")}</h5>
              <img src={twoFactorQrCode} alt="QR Code" />
            </>
          )}

          <h5 className={styles.partTwo}>
            2. {translations("label.enter_verification_code")}
          </h5>
          <div className={styles.twoFactorInput}>
            <input
              type="text"
              value={twoFactorValue}
              onKeyDown={handleOnlyNumbersKeydown}
              placeholder={translations("label.enter_your_code_here")}
              onChange={(e) => setTwoFactorValue(e.target.value)}
            />
          </div>

          <div className={styles.button}>
            <ButtonComponent
              label={translations("button.submit")}
              class={"btn btn-primary"}
              handleClick={() => handleSubmit()}
            />
          </div>
        </div>
      ) : null}

      {client?.two_factor_auth_enabled ? (
        <div className={styles.deactivateTwoFactorContent}>
          <RoundedSuccessIcon />
          <div>
            <span>{translations("label.wish_to_deactivate_two_factor")}</span>
            <span
              className="pointer"
              style={{ color: getDefaultColour(defaultColour) }}
              onClick={() => handleDeactivateTwoFactor()}
            >
              {" "}
              {translations("label.click_here")}.
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default TwoFactorAuth;
