import { useTranslation } from "react-i18next";
import styles from "./SetPasscode.module.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { GlobalIState } from "types/RootState";

import PasscodeInput from "components/PasscodeInput/PasscodeInput.component";
import { useEffect, useState } from "react";
import {
  HidePasswordIcon,
  PreviousIcon,
  ShowPasswordIcon,
} from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import ButtonComponent from "components/Button/Button.component";
import { clearAction, openToaster, setMiddlepartItem } from "App/actions";
import { logInBegin, registerBegin } from "pages/Authentication/actions";
import StorageService from "services/storage.service";
import { LOG_IN_SUCCESS } from "pages/Authentication/constants";
import { CHANGE_AUTH_SECURITY_SUCCESS } from "../constants";
import { changeAuthSecurityBegin, getClientBegin } from "../actions";

export default function SetPasscode() {
  const {
    action: { actionName, isLoading, error },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t: translations } = useTranslation();
  let isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const [passcode, setPasscode] = useState("");
  const [showPasscode, setShowPasscode] = useState(true);

  const handleSave = () => {
    if (passcode?.length !== 4) {
      dispatch(
        openToaster("ERROR", translations("error.please_fill_passcode_field"))
      );
      return;
    }
    dispatch(changeAuthSecurityBegin(passcode));
  };

  useEffect(() => {
    if (actionName === CHANGE_AUTH_SECURITY_SUCCESS) {
      dispatch(
        openToaster("SUCCESS", translations("success.moved_to_passcode"))
      );
      dispatch(getClientBegin());
      if (isMobile) navigate("/profile");
      else dispatch(setMiddlepartItem(null));
    }
    return () => {
      dispatch(clearAction());
    };
  }, [actionName]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>{translations("label.passcode")}</h1>

        <div className={styles.passcodeContainer}>
          <h3>{translations("label.create_4_digit_passcode")}</h3>
          <div
            className={styles.showPasscodeLabel}
            onClick={() => setShowPasscode(!showPasscode)}
          >
            <span>
              {translations(
                showPasscode ? "label.hide_passcode" : "label.show_passcode"
              )}
            </span>
            <div>
              {showPasscode ? (
                <ShowPasswordIcon color={"#000"} />
              ) : (
                <HidePasswordIcon color={"#000"} />
              )}
            </div>
          </div>
          <PasscodeInput
            length={4}
            showPasscode={showPasscode}
            onChange={setPasscode}
          />
          <div className={styles.hint}>
            <span>{translations("label.passcode_info")}</span>
          </div>
        </div>

        <div className={styles.saveButton}>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
