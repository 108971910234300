import { ITastings } from "types/reducers/personal-ledger.reducer.type";
import axiosInstance from "./api";

export const getUserOrderedWines = (searchValue: string) => {
  return axiosInstance.get(
    searchValue?.length > 0
      ? `/products/me?search=${searchValue}`
      : "/products/me"
  );
};

export const rateWine = (data: {
  product_id: string;
  rating: number;
  isBlind: boolean;
}) => {
  return axiosInstance.post(`/products/rate/${data.product_id}`, {
    rating: data.rating,
    isBlind: data.isBlind,
  });
};

export const rateAddedWine = (data: { product_id: string; rating: number }) => {
  return axiosInstance.post(`/added-wines/rate/${data.product_id}`, {
    rating: data.rating,
  });
};

export const setProductTaste = (data: {
  product_id: number;
  tastings: ITastings[];
  isBlind: boolean;
}) => {
  return axiosInstance.post(`/tastings/client/${data.product_id}`, {
    tastings: data.tastings,
    isBlind: data.isBlind,
  });
};

export const setAddedWineTaste = (data: {
  product_id: number;
  tastings: ITastings[];
}) => {
  return axiosInstance.post(`/added-wines/taste/${data.product_id}`, {
    tastings: data.tastings,
  });
};

export const getProductTransactions = (product_id: string) => {
  return axiosInstance.get(`/products/transaction-history/${product_id}`);
};

export const getUserBlindTastings = (searchValue: string) => {
  return axiosInstance.get(
    searchValue?.length > 0
      ? `/tastings/blind?search=${searchValue}`
      : "/tastings/blind"
  );
};

export const addWine = (data: {
  wine_name: string;
  producer: string;
  bottle_size: string;
  type: string;
  country: string;
  region: string;
  location: string;
  inventory: number;
  custom_field: string;
}) => {
  return axiosInstance.post("/added-wines", {
    ...data,
    inventory: data.inventory === 0 ? null : data.inventory,
  });
};

export const getAddedWines = (searchValue: string) => {
  return axiosInstance.get(
    searchValue?.length > 0
      ? `/added-wines?search=${searchValue}`
      : "/added-wines"
  );
};

export const requestToSupplyWine = (wine_id: number) => {
  return axiosInstance.post("/wine-supply-requests", {
    added_wine_id: wine_id,
  });
};

export const getSearchedWines = (searchValue: string) => {
  return axiosInstance.get(
    searchValue?.length > 0
      ? `/journal/search?search=${searchValue}`
      : "/journal/search"
  );
};

export const deleteAddedWine = (product_id: string) => {
  return axiosInstance.delete(`/added-wines/${product_id}`);
};
