import { useTranslation } from "react-i18next";
import styles from "./BankTransfer.module.scss";
import {
  CloseFirstTypeIcon,
  CopyIcon,
  RoundedClockIcon,
  ShareSecondIcon,
} from "assets/icons/icons";
import { getDefaultColour } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate } from "react-router-dom";
import properties from "properties";
import { useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import { openToaster } from "App/actions";

export default function BankTransfer() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [activeStep, setActiveStep] = useState("showInfo");

  const handleClose = () => {
    navigate("/investments");
  };

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);
    dispatch(openToaster("SUCCESS", translations("label.copied")));
  };

  const handleNext = () => {
    if (activeStep === "showInfo") {
      setActiveStep("closeInfo");
    } else navigate("/investments");
  };

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {activeStep === "closeInfo" ? (
        <div className={styles.afterClosedContent}>
          <h1 className={styles.title}>
            {translations("label.bank_transfer_after_closed.title")}
          </h1>

          <div className={styles.icon}>
            <RoundedClockIcon color={getDefaultColour(defaultColour)} />
          </div>

          <div
            className={styles.label}
            style={{ color: getDefaultColour(defaultColour) }}
          >
            <span>{translations("label.benefit_from_fire_and_forget")}</span>
          </div>

          <span className={styles.hint}>
            {translations("label.bank_transfer_after_closed.subtitle")}
          </span>

          <div className={styles.button}>
            <ButtonComponent
              label={translations("button.close")}
              class={"btn btn-primary"}
              handleClick={() => handleClose()}
            />
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.title}>
            <h1>{translations("label.bank_transfer.main_title")}</h1>
          </div>
          <div className={styles.labelWithCopy}>
            <span>{translations("label.account_holder")}</span>
            <div onClick={() => handleCopy(properties.ACCOUNT_HOLDER)}>
              <CopyIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
          <h1>{properties.ACCOUNT_HOLDER}</h1>

          <div className={styles.labelWithCopy}>
            <span>{translations("label.iban")}</span>
            <div onClick={() => handleCopy(properties.IBAN)}>
              <CopyIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
          <h1>{properties.IBAN}</h1>

          <div className={styles.labelWithCopy}>
            <span>{translations("label.swift_bic")}</span>
            <div onClick={() => handleCopy(properties.SWIFT_BIC)}>
              <CopyIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
          <h1>{properties.SWIFT_BIC}</h1>

          <div className={styles.labelWithCopy}>
            <span>{translations("label.reference")}</span>
            <div
              onClick={() =>
                handleCopy(
                  translations("label.topup") +
                    " / " +
                    properties.TOPUP_REFERENCE
                )
              }
            >
              <CopyIcon color={getDefaultColour(defaultColour)} />
            </div>
          </div>
          <h1>
            {translations("label.topup") + " / " + properties.TOPUP_REFERENCE}
          </h1>

          <div className={styles.actionButtons}>
            <div>
              <span>{translations("button.download_details")}</span>
            </div>
            <ButtonComponent
              label={translations("button.next")}
              class={"btn btn-primary"}
              handleClick={() => handleNext()}
            />
          </div>
        </div>
      )}
    </div>
  );
}
