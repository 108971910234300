import { useTranslation } from "react-i18next";
import styles from "./ResetPassword.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import PasswordInput from "components/PasswordInput/PasswordInput.component";
import { useEffect, useState } from "react";
import ButtonComponent from "components/Button/Button.component";
import {
  isInvalidPassword,
  getDefaultColour,
  hasEightCharacters,
  hasLowercaseLetter,
  hasNumbers,
  hasSymbols,
  hasUppercaseLetter,
} from "utils/utils";
import { GlobalIState } from "types/RootState";
import { resetPasswordBegin } from "pages/Profile/actions";
import { useSearchParams } from "react-router-dom";
import { RESET_PASSWORD_SUCCESS } from "pages/Profile/constants";
import ResetPasswordSuccess from "./ResetPasswordSuccess/ResetPasswordSuccess.page";

export default function ResetPassword() {
  const {
    action: { actionName, isLoading },
    defaultColour,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [state, setState] = useState({
    newPassword: "",
    repeatPassword: "",
  });

  const disabled = isInvalidPassword(state.newPassword, state.repeatPassword);

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = () => {
    let data = {
      token: token,
      password: state.newPassword,
    };

    dispatch(resetPasswordBegin(data));
  };

  useEffect(() => {
    if (actionName === RESET_PASSWORD_SUCCESS) {
      searchParams.delete("token");
      searchParams.delete("email");
      setSearchParams(searchParams);
    }
  }, [actionName]);

  return actionName === RESET_PASSWORD_SUCCESS ? (
    <ResetPasswordSuccess />
  ) : (
    <div className={styles.pageWrapper}>
      <h1>{translations("label.reset_password")}</h1>
      <div className={styles.fields}>
        <h2>{translations("label.new_password")}</h2>
        <PasswordInput
          name="newPassword"
          className="secondInput"
          placeholder={translations("label.new_password")}
          value={state.newPassword}
          onChange={(e) => handleChange(e)}
        />
        <PasswordInput
          name="repeatPassword"
          className="secondInput"
          placeholder={translations("label.repeat_password")}
          value={state.repeatPassword}
          onChange={(e) => handleChange(e)}
        />

        <div className={styles.hints}>
          <span style={{ color: getDefaultColour(defaultColour) }}>
            {translations("label.change_password.hint1")}
          </span>
          <span
            style={{
              color: !hasUppercaseLetter(state.repeatPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint2")}
          </span>
          <span
            style={{
              color: !hasLowercaseLetter(state.repeatPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint3")}
          </span>
          <span
            style={{
              color: !hasNumbers(state.repeatPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint4")}
          </span>
          <span
            style={{
              color: !hasSymbols(state.repeatPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint5")}
          </span>
          <span
            style={{
              color: !hasEightCharacters(state.repeatPassword)
                ? getDefaultColour(defaultColour)
                : "#06A423",
            }}
          >
            {translations("label.change_password.hint6")}
          </span>
        </div>

        <div className={styles.buttonContainer}>
          <ButtonComponent
            isLoading={isLoading}
            label={translations("button.save")}
            class={"btn btn-primary"}
            disabled={disabled}
            handleClick={() => handleSave()}
          />
        </div>
      </div>
    </div>
  );
}
