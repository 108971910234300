import { useTranslation } from "react-i18next";

import styles from "./AddEmail.module.scss";

import { useAppDispatch, useAppSelector } from "hooks";

import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useEffect, useState } from "react";
import { GlobalIState } from "types/RootState";
import ButtonComponent from "components/Button/Button.component";
import CustomInputComponent from "components/CustomInput/CustomInput.component";

import { addEmailBegin, addPhoneBegin } from "pages/Profile/actions";
import { setLeftPartItem, setRightPartItem } from "App/actions";
import StorageService from "services/storage.service";
import PersonalDetails from "../PersonalDetails.page";
import EmailConfirm from "./EmailConfirm/EmailConfirm.page";

interface Props {}

export default function AddEmail(props: Props) {
  const {
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const [email, setEmail] = useState("");
  const addEmailSuccessCookie = StorageService.getDataByKey("addEmailSuccess");

  const handleSave = () => {
    dispatch(addEmailBegin({ email: email }));
  };

  useEffect(() => {
    if (addEmailSuccessCookie) {
      if (isMobile) {
        dispatch(setLeftPartItem(<EmailConfirm />));
      } else {
        dispatch(setRightPartItem(<EmailConfirm />));
      }

      StorageService.deleteCookie("addEmailSuccess");
    }
  }, [addEmailSuccessCookie]);

  return (
    <div className={styles.addEmailWrapper}>
      <div>
        <h1>{translations("label.email")}</h1>
        <div className={styles.fields}>
          <CustomInputComponent
            type="email"
            name="email"
            className="secondInput"
            placeholder={translations("label.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <ButtonComponent
          label={translations("button.save")}
          class={"btn btn-primary"}
          isLoading={isLoading}
          handleClick={() => handleSave()}
        />
      </div>
    </div>
  );
}
