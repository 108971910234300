import { useTranslation } from "react-i18next";
import styles from "./DeleteAccountByToken.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import ButtonComponent from "components/Button/Button.component";
import { RoundedSuccessIcon } from "assets/icons/icons";

import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { useEffect, useState } from "react";
import { cancelSignupByEmailToken, logOut } from "../actions";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { clearCartItems } from "pages/Purchasing/actions";

export default function DeleteAccountByToken() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    isLoggedIn,
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const emailTokenParam = searchParams.get("token");

  const handleClose = () => {
    navigate(isLoggedIn ? "/" : "login");
  };

  useEffect(() => {
    if (emailTokenParam) {
      dispatch(cancelSignupByEmailToken(emailTokenParam));
      setTimeout(() => {
        searchParams.delete("token");
        setSearchParams(searchParams);
        dispatch(clearCartItems());
        dispatch(logOut());
        navigate("/");
      }, 200);
    }
  }, [emailTokenParam]);

  return (
    <div className={styles.pageWrapper}>
      <LoaderWrapper isLoading={isLoading}>
        <div className={styles.successStep}>
          <div>
            <h1>{translations("label.success")}</h1>
            <h2>{translations("label.account_deleted_successfully")}</h2>
          </div>

          <div className={styles.icon}>
            <RoundedSuccessIcon color={getDefaultColour(defaultColour)} />
          </div>
          <div className={styles.button}>
            <ButtonComponent
              label={translations("button.close")}
              class={"btn btn-primary"}
              handleClick={() => handleClose()}
            />
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
}
