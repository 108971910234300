import { RegisterFriendsOnEventReqInterface } from "types/reducers/global.reducer.type";
import axiosInstance from "./api";

export const getEvents = () => {
  return axiosInstance.get(`/events`);
};

export const getEventById = (id: number) => {
  return axiosInstance.get(`/events/details/${id}`);
};

export const registerOnEvent = (event_id: number) => {
  return axiosInstance.put(`/events/register-event`, { event_id: event_id });
};

export const cancelEventRegistration = (event_id: number) => {
  return axiosInstance.put(`/events/remove-event`, { event_id: event_id });
};

export const registerFriendsOnEvent = (
  payload: RegisterFriendsOnEventReqInterface
) => {
  return axiosInstance.put(`/events/friends/register-event`, payload);
};

export const removeFriendFromEvent = (payload: {
  event_id: number;
  email: string;
}) => {
  return axiosInstance.put(`/events/remove-client-event`, payload);
};

export const updateInvitedFriend = (payload: {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
}) => {
  return axiosInstance.put(`/events/friends/update-event`, payload);
};

export const getTakeoverTransactionsById = (id: number) => {
  return axiosInstance.get(`/transactions/takeover/details/${id}`);
};
