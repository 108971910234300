import React, { useEffect, useRef, useState } from "react";
import styles from "./GeographicAllocationChart.module.scss";

import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { RefreshIcon } from "assets/icons/icons";
import { fetchGeographicAllocationBegin } from "../actions";
import { useTranslation } from "react-i18next";

function GeographicAllocationChart() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { geographicAllocation } = useAppSelector(
    (state: GlobalIState) => state.InvestmentsReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState({
    series: [],
  });
  const [chartKey, setChartKey] = useState(0);
  const chartRef = useRef(null);

  const options: ApexOptions = {
    chart: {
      width: "100%",
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    fill: {
      colors: [
        `${getDefaultColour(defaultColour)}`,
        `${getDefaultColour(defaultColour)}80`,
        `${getDefaultColour(defaultColour)}60`,
        `${getDefaultColour(defaultColour)}50`,
        `${getDefaultColour(defaultColour)}40`,
      ],
      type: "pattern",
      opacity: 1,
      pattern: {
        style: Array(state.series.length).fill("horizontalLines"),
        width: 2,
        height: 2,
      },
    },
    series: [],
    labels: geographicAllocation
      ? geographicAllocation?.map((item) => item.regionName)
      : [],
    colors: ["#34054C", "#FF6333", "#34FF71", "#BC9CE2", "#00CBFF"],
    yaxis: {
      labels: {
        show: true,
        formatter: (value: any) => {
          return `${Number(value).toFixed(2)}%`;
        },
      },
    },
    stroke: {
      width: 0,
    },
  };

  const resetAnimation = () => {
    // setChartKey((prevKey) => prevKey + 1);
    dispatch(fetchGeographicAllocationBegin());
  };

  useEffect(() => {
    dispatch(fetchGeographicAllocationBegin());
  }, []);

  useEffect(() => {
    if (geographicAllocation) {
      const newCategories = geographicAllocation.map(
        (item) => `${item.regionName}`
      );
      const newSeriesData = geographicAllocation.map(
        (item) => item?.percentage
      );
      const newLabels = geographicAllocation.map(
        (item) => `${item.regionName}`
      );

      chartRef.current.chart.updateOptions({
        xaxis: {
          categories: newCategories,
        },
      });

      chartRef.current.chart.updateSeries([
        {
          name: "",
          data: newSeriesData,
        },
      ]);

      setState({
        series: newSeriesData,
      });

      // Update labels in options directly
      chartRef.current.chart.updateOptions({
        labels: newLabels,
      });
      setChartKey((prevKey) => prevKey + 1);
    } else {
      setState({
        series: [100, 80, 70, 60, 50],
      });
    }
  }, [geographicAllocation, chartRef]);

  return (
    <div className={styles.chartWrapper}>
      <div className={styles.header}>
        <div>
          <h2>{translations("label.geographic_allocation")}</h2>
        </div>
        <div className={styles.refreshIcon} onClick={resetAnimation}>
          <RefreshIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
      <ReactApexChart
        key={chartKey}
        ref={chartRef}
        options={options}
        series={state.series}
        type="donut"
        width={"100%"}
        height={"95%"}
      />
      {/* <div className={styles.refreshIcon} onClick={resetAnimation}>
        <RefreshIcon color={getDefaultColour(defaultColour)} />
      </div> */}
    </div>
  );
}

export default GeographicAllocationChart;
