import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Journal.module.scss";
import { useEffect, useRef, useState } from "react";
import { getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import Orders from "./MyOrders/Orders.page";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import StorageService from "services/storage.service";
import {
  getAddedWinesBegin,
  getSearchedJournalWinesBegin,
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
} from "./actions";
import { ClearIcon, SearchIcon } from "assets/icons/icons";
import { useLocation, useSearchParams } from "react-router-dom";

function Journal() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("vinfinity-wines");
  const [searchParams, setSearchParams] = useSearchParams();
  let ledgerActiveTabParam = searchParams.get("tab");
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const isLoggedIn = () => StorageService.getDataByKey("token");
  let showSearchParam = searchParams.get("search");
  const searchInputRef = useRef(null);

  const handleActiveTabChange = (tab: string) => {
    searchParams.set("tab", tab);
    setSearchParams(searchParams);
    setActiveTab(tab);
  };

  const handleClearSearch = () => {
    searchParams.delete("search");
    setSearchParams(searchParams);
    setSearchValue("");
    setIsSearching(false);
  };

  useEffect(() => {
    const ledgerDelayDebounceFn = setTimeout(() => {
      if (isLoggedIn()) {
        if (isMobile && searchValue?.length > 0) {
          dispatch(getSearchedJournalWinesBegin(searchValue));
        } else {
          if (ledgerActiveTabParam === "vinfinity-wines") {
            dispatch(getUserOrdersBegin(searchValue));
            dispatch(getUserBlindTastingsBegin(searchValue));
          } else if (ledgerActiveTabParam === "added-wines") {
            dispatch(getAddedWinesBegin(searchValue));
          }
        }
      }
    }, 100);
    // eslint-disable-next-line
    return () => clearTimeout(ledgerDelayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    if (!ledgerActiveTabParam) {
      searchParams.set("tab", "vinfinity-wines");
      setSearchParams(searchParams);
      setActiveTab("vinfinity-wines");
    } else {
      setActiveTab(ledgerActiveTabParam);
    }
  }, [ledgerActiveTabParam]);

  useEffect(() => {
    if (showSearchParam === "true") {
      setIsSearching(true);
    }
  }, [showSearchParam]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("search") === "true") {
      searchInputRef.current.focus();
    }
  }, [location]);

  return (
    <div
      className={styles.contentWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {isMobile ? (
        <div className={styles.headerTabs}>
          {isMobile && !isSearching ? (
            <>
              <div
                className={styles.tab}
                style={{
                  backgroundColor:
                    activeTab === "vinfinity-wines" ? "#000" : "transparent",
                  color: activeTab === "vinfinity-wines" ? "#fff" : "#000",
                }}
                onClick={() => handleActiveTabChange("vinfinity-wines")}
              >
                <span>
                  {translations("label.vinfinity_wines")?.length >= 14
                    ? translations("label.vinfinity_wines")?.slice(0, 14) +
                      "..."
                    : translations("label.vinfinity_wines")}
                </span>
              </div>

              <div
                className={styles.tab}
                style={{
                  backgroundColor:
                    activeTab === "added-wines" ? "#000" : "transparent",
                  color: activeTab === "added-wines" ? "#fff" : "#000",
                  opacity: searchValue?.length > 0 ? 0.7 : 1,
                  pointerEvents: searchValue?.length > 0 ? "none" : "all",
                  cursor: searchValue?.length > 0 ? "default" : "pointer",
                }}
                onClick={() => handleActiveTabChange("added-wines")}
              >
                <span>
                  {translations("label.added_wines")?.length >= 14
                    ? translations("label.added_wines")?.slice(0, 14) + "..."
                    : translations("label.added_wines")}
                </span>
              </div>
            </>
          ) : null}

          <div
            className={styles.searchBox}
            style={{
              height: isSearching ? "auto" : 0,
              width: !isSearching && "0",
              overflow: "hidden",
              transition: "height 0.3s ease",
            }}
          >
            <div className={styles.searchInput}>
              <SearchIcon color="#000" />
              <input
                type="text"
                ref={searchInputRef}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
            <div onClick={() => handleClearSearch()}>
              <ClearIcon />
            </div>
          </div>
        </div>
      ) : null}

      <Orders />
    </div>
  );
}

export default Journal;
