import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./Orders.module.scss";
import { useEffect, useState } from "react";

import { getClientTastingLabel, getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import {
  deleteAddedWineBegin,
  getAddedWinesBegin,
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
  requestToSupplyWineBegin,
} from "../actions";
import {
  DeleteIcon,
  ExpandIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  ShoppingCartAddIcon,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import dayjs from "dayjs";
import { openToaster, scrollToTop } from "App/actions";
import ProductDetails from "pages/Purchasing/ProductDetails/ProductDetails.page";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import RateAndTaste from "./RateAndTastePage/RateAndTastePage.page";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";
import PurchasedQuantity from "./PurchasedQuantity/PurchasedQuantity.page";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import MobileMyWineOrders from "./MobileMyOrders/MobileMyOrders.page";
import WineBluredImage from "assets/images/wine.png";
import { DELETE_ADDED_WINE_SUCCESS } from "../constants";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";

function Orders() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const [searchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    orders,
    userBlindTastings,
    addedWines,
    searchedWines,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const [openedCard, setOpenedCard] = useState({
    isExpanded: false,
    item: null,
    category: "",
  });
  const [showProductDetails, setShowProductDetails] = useState({
    id: null,
    show: false,
  });
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const [data, setData] = useState([]);
  let activeTab = searchParams.get("tab");

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const getSelectedRating = (item) => {
    if (item?.product?.ratings?.length !== 0) {
      switch (item?.product?.ratings[0].rating) {
        case 1:
          return <FirstRateWineEmoji />;
        case 2:
          return <SecondRateWineEmoji />;
        case 3:
          return <ThirdRateWineEmoji />;
        case 4:
          return <FourthRateWineEmoji />;
        case 5:
          return <FifthRateWineEmoji />;
        default:
          break;
      }
    }
  };

  const handleProposeToSupply = (id) => {
    dispatch(requestToSupplyWineBegin(id));

    setTimeout(() => {
      if (activeTab === "vinfinity-wines") {
        dispatch(getUserOrdersBegin());
        dispatch(getUserBlindTastingsBegin());
      }

      if (activeTab === "added-wines") {
        dispatch(getAddedWinesBegin());
      }
    }, 300);
  };

  const rowItem = (item, index) => {
    let itemUpdated = data?.filter(
      (el) => el?.product?.id === item?.product?.id
    )[0];
    const tastingLabels = getClientTastingLabel(
      itemUpdated?.product?.clientTastings
    )?.map((label) => translations(label));

    const showRateAndTasteBtn =
      itemUpdated?.product?.ratings?.length === 0 &&
      itemUpdated?.product?.clientTastings?.length === 0;

    return (
      <div key={index} className={styles.row}>
        <div className={styles.firstCol}>
          <div className={styles.imagePart}>
            <img
              src={
                itemUpdated?.product?.product_image
                  ? itemUpdated?.product?.product_image
                  : WineBluredImage
              }
              style={
                !itemUpdated?.product?.product_image ? { opacity: 0.5 } : {}
              }
            />
          </div>
          <div className={styles.rightPart}>
            <div>
              <div className={styles.title}>
                <span>
                  {itemUpdated?.product?.wine_name?.length > 25
                    ? itemUpdated?.product?.wine_name?.substring(0, 25) + "..."
                    : itemUpdated?.product?.wine_name}
                </span>
              </div>
              <div>{item?.product?.vintage}</div>
            </div>
            <div>
              <div className={styles.title}>
                {itemUpdated?.product?.bottle_size ? (
                  <span>{translations("label.bottle_size")}</span>
                ) : null}
              </div>
              <div className={styles.bottleSizeLabel}>
                <span>{itemUpdated?.product?.bottle_size}</span>
              </div>
            </div>
          </div>

          {!item?.product?.inputted_data ? (
            <div
              className={styles.expandIcon}
              onClick={() => handleCardExpand(itemUpdated, "product-details")}
            >
              <ExpandIcon />
            </div>
          ) : null}
        </div>
        <div className={styles.secondCol}>
          {showRateAndTasteBtn ? (
            <div
              className={styles.noRatingsBox}
              onClick={() => handleCardExpand(itemUpdated, "rate/taste")}
            >
              <span>
                {translations("label.rate") +
                  " & " +
                  translations("label.taste")}
              </span>
            </div>
          ) : (
            <div className={styles.productFeedback}>
              {tastingLabels?.length > 0 ? (
                <div className={styles.headerLabel}>
                  <span>{translations("label.my_tasting")}</span>
                  <div className={styles.tastingLabel}>
                    <span>{tastingLabels?.join(", ")}</span>
                  </div>
                </div>
              ) : null}
              <div className={styles.ratings}>
                {getSelectedRating(itemUpdated) ? (
                  <div className={styles.headerLabel}>
                    <span>{translations("label.my_rating")}</span>
                  </div>
                ) : null}
                <div className={styles.rateEmojis}>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 1
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FirstRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 2
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <SecondRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 3
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <ThirdRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 4
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FourthRateWineEmoji />
                  </div>
                  <div
                    className={
                      itemUpdated?.product?.ratings[0]?.rating === 5
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FifthRateWineEmoji />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={styles.expandIcon}
            onClick={() => handleCardExpand(itemUpdated, "rate/taste")}
          >
            <ExpandIcon />
          </div>
        </div>
        <div className={styles.thirdCol}>
          <div>
            <div className={styles.title}>
              {!item?.flaskSentOn && !item?.product?.inputted_data ? (
                <span>{translations("label.purchased_quantity")}</span>
              ) : null}
              {item?.product?.inputted_data ? (
                <span>
                  {translations("label.bottle_location_and_inventory")}
                </span>
              ) : null}
            </div>
            <div>
              <span>
                {itemUpdated?.product?.inputted_data
                  ? `${itemUpdated?.product?.inputted_data?.location} ${
                      itemUpdated?.product?.inputted_data?.inventory?.toString()
                        ?.length > 0
                        ? (itemUpdated?.product?.inputted_data?.location
                            ?.length > 0
                            ? " | "
                            : "") +
                          itemUpdated?.product?.inputted_data?.inventory
                        : ""
                    }`
                  : itemUpdated?.totalQuantity}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.title}>
              <span>
                {translations(
                  item?.flaskSentOn
                    ? "label.flask_sent_on"
                    : item?.product?.inputted_data
                    ? "label.bottle_added_on"
                    : "label.last_order"
                )}
              </span>
            </div>
            <div>
              <span>
                {dayjs(
                  item?.flaSskSentOn
                    ? itemUpdated?.flaskSentOn
                    : item?.product?.inputted_data
                    ? item?.product?.createdAt
                    : itemUpdated?.lastOrderDate
                ).format("DD.MM.YYYY")}
              </span>
            </div>
          </div>
          {!item?.product?.inputted_data ? (
            <div
              className={styles.expandIcon}
              onClick={() => handleCardExpand(itemUpdated, "pqty")}
            >
              <ExpandIcon />
            </div>
          ) : null}
        </div>
        <div className={styles.lastColumn}>
          <div>
            <div className={styles.title}>
              <span>{translations("label.bottle_price")}</span>
            </div>

            <div className={styles.price}>
              {itemUpdated?.product?.old_price ? (
                <>
                  <span className={styles.discount}>
                    CHF {itemUpdated?.product?.old_price}
                  </span>
                  <br />
                </>
              ) : null}
              {itemUpdated?.product?.price ? (
                <span>CHF {itemUpdated?.product?.price}</span>
              ) : (
                <span>{translations("label.not_known")}</span>
              )}
            </div>
          </div>
          <div className={styles.actionsContainer}>
            {itemUpdated?.product?.price ? (
              <div
                className={styles.mainBtn}
                onClick={() => handleAddToCart(itemUpdated?.product?.id)}
                style={
                  !itemUpdated?.product?.price ||
                  itemUpdated?.product?.inventory_count === 0
                    ? { pointerEvents: "none", opacity: 0.6 }
                    : {}
                }
              >
                <ShoppingCartAddIcon />
              </div>
            ) : item?.product?.supplyRequest ? (
              <div className={styles.requestedToSupplyLabel}>
                <span>{translations("label.request_received_to_supply")}</span>
              </div>
            ) : (
              <div
                className={styles.mainBtn}
                style={{
                  opacity: item?.product?.supplyRequest ? 0.5 : 1,
                  cursor: item?.product?.supplyRequest ? "default" : "pointer",
                }}
                onClick={() => handleProposeToSupply(itemUpdated?.product?.id)}
              >
                <span>{translations("label.propose_to_supply")}</span>
              </div>
            )}
            {item?.product?.inputted_data ? (
              <div
                className={styles.deleteWineIcon}
                onClick={() => handleDeleteAddedWine(itemUpdated?.product?.id)}
              >
                <DeleteIcon color="#000" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguage()));
  };

  const handleCardExpand = (item, card) => {
    if (activeTab === "added-wines" && card !== "rate/taste") {
      return;
    }
    if (card === "product-details") {
      setShowProductDetails({ id: item.product.id, show: true });
    }

    setOpenedCard({ isExpanded: true, item, category: card });
    dispatch(scrollToTop());
  };

  const handleDeleteAddedWine = (id: number) => {
    dispatch(deleteAddedWineBegin(id));
    setTimeout(() => {
      dispatch(getAddedWinesBegin());
    }, 200);
  };

  useEffect(() => {
    if (activeTab === "vinfinity-wines") {
      dispatch(getUserOrdersBegin());
      dispatch(getUserBlindTastingsBegin());
    }

    if (activeTab === "added-wines") {
      dispatch(getAddedWinesBegin());
    }

    setOpenedCard({ isExpanded: false, item: null, category: "null" });
  }, [activeTab]);

  useEffect(() => {
    if (showProductDetails.id) {
      dispatch(fetchProductDetailsBegin(showProductDetails.id, getLanguage()));
    }

    if (!showProductDetails.show) {
      setOpenedCard({ isExpanded: false, item: null, category: "" });
    }
  }, [showProductDetails]);

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    if (searchedWines?.length !== 0) {
      setData([...(searchedWines ?? [])]);
    } else if (!searchedWines && !orders && !userBlindTastings && !addedWines) {
      setData(null);
    }

    if (activeTab === "vinfinity-wines" && orders && userBlindTastings) {
      setData([...orders, ...userBlindTastings]);
    }
    if (activeTab === "added-wines" && addedWines) {
      setData([...addedWines]);
    }
  }, [activeTab, orders, userBlindTastings, addedWines, searchedWines]);

  return isMobile ? (
    <div>
      <MobileMyWineOrders />
    </div>
  ) : (
    <div
      className={styles.wineOrdersWrapper}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      {openedCard?.isExpanded ? (
        <div className={styles.expandedItem}>
          {rowItem(openedCard.item, 1)}
          <div className={styles.biggerContainer}>
            {openedCard.category === "product-details" ? (
              <ProductDetails setShowProductDetails={setShowProductDetails} />
            ) : openedCard.category === "rate/taste" ? (
              <RateAndTaste
                setOpenedCard={setOpenedCard}
                product_id={openedCard.item?.product?.id}
              />
            ) : openedCard.category === "pqty" ? (
              <PurchasedQuantity
                setOpenedCard={setOpenedCard}
                product_id={openedCard.item?.product?.id}
              />
            ) : (
              openedCard?.category
            )}
          </div>
        </div>
      ) : null}
      <LoaderWrapper isLoading={isLoading}>
        {data?.length === 0 && !isLoading ? (
          <div className={styles.noOrdersText}>
            <span>
              {translations(
                activeTab === "added-wines"
                  ? "label.no_wines_added_found"
                  : "label.no_orders_found"
              )}
            </span>
          </div>
        ) : (
          data?.map((item, index) => {
            return rowItem(item, index);
          })
        )}
      </LoaderWrapper>
    </div>
  );
}

export default Orders;
