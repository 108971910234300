import {
  BackButtonIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  SelectedOptionArrow,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import styles from "./RateWine.module.scss";
import { useTranslation } from "react-i18next";
import ButtonComponent from "components/Button/Button.component";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAddedWinesBegin,
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
  rateAddedWineBegin,
  rateWineBegin,
} from "pages/PersonalLedger/actions";
import { GlobalIState } from "types/RootState";
import {
  RATE_ADDED_WINE_SUCCESS,
  RATE_WINE_SUCCESS,
} from "pages/PersonalLedger/constants";
import { openToaster, scrollToTop } from "App/actions";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useSearchParams } from "react-router-dom";

interface Props {
  setActiveTab?: any;
  product_id: string;
}

function RateWine(props: Props) {
  const {
    action: { actionName, isLoading },
    orders,
    userBlindTastings,
    addedWines,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const dispatch = useAppDispatch();
  const isMobile = useCheckMobileScreen();
  const { t: translations } = useTranslation();
  const { setActiveTab, product_id } = props;
  const [searchParams] = useSearchParams();
  let ledgerActiveTabParam = searchParams.get("tab");
  const [selectedOption, setSelectedOption] = useState("");
  const [data, setData] = useState([]);
  const order = data?.filter((item) => item.product?.id === Number(product_id));

  const rateOptions = [
    { id: "1", icon: <FirstRateWineEmoji /> },
    { id: "2", icon: <SecondRateWineEmoji /> },
    { id: "3", icon: <ThirdRateWineEmoji /> },
    { id: "4", icon: <FourthRateWineEmoji /> },
    { id: "5", icon: <FifthRateWineEmoji /> },
  ];

  const handleChange = (id: string) => {
    setSelectedOption(id);
  };

  const handleSubmit = () => {
    if (selectedOption !== "") {
      if (ledgerActiveTabParam === "added-wines") {
        dispatch(
          rateAddedWineBegin({
            product_id: product_id,
            rating: Number(selectedOption),
          })
        );
      } else {
        dispatch(
          rateWineBegin({
            product_id: product_id,
            rating: Number(selectedOption),
          })
        );
      }
    } else
      dispatch(openToaster(translations("label.please_select_one_option")));
  };

  useEffect(() => {
    if ([RATE_WINE_SUCCESS, RATE_ADDED_WINE_SUCCESS].includes(actionName)) {
      setActiveTab("tasting");
      if (ledgerActiveTabParam === "vinfinity-wines") {
        dispatch(getUserOrdersBegin());
        dispatch(getUserBlindTastingsBegin());
      } else if (ledgerActiveTabParam === "added-wines") {
        dispatch(getAddedWinesBegin());
      }

      dispatch(scrollToTop());
    }
  }, [actionName]);

  useEffect(() => {
    if (data && order?.length !== 0) {
      setSelectedOption(order[0]?.product?.ratings[0]?.rating?.toString());
    }
  }, [data, product_id]);

  useEffect(() => {
    if (
      ledgerActiveTabParam === "vinfinity-wines" &&
      orders &&
      userBlindTastings
    ) {
      setData([...orders, ...userBlindTastings]);
    }
    if (ledgerActiveTabParam === "added-wines" && addedWines) {
      setData([...addedWines]);
    }
  }, [ledgerActiveTabParam, orders, userBlindTastings, addedWines]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.rateContent}>
        <div className={styles.title}>
          <h1>{translations("label.rate_the_wine")}</h1>
        </div>
        <div className={styles.options}>
          {rateOptions?.map((item) => (
            <div
              key={item.id}
              className={`${styles.option} ${
                selectedOption === item.id ? styles.activeOption : ""
              }`}
              onClick={() => handleChange(item.id)}
            >
              {selectedOption === item.id ? (
                <div className={styles.icon}>
                  <SelectedOptionArrow />
                </div>
              ) : null}
              {item?.icon}
            </div>
          ))}
        </div>
        <div>
          <ButtonComponent
            label={translations("button.submit")}
            class={"btn btn-primary"}
            isLoading={isLoading}
            disabled={!selectedOption}
            handleClick={() => handleSubmit()}
          />
        </div>
      </div>
    </div>
  );
}

export default RateWine;
