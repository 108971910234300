import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";
import { useAppDispatch, useAppSelector } from "hooks";
import { getPrivacyPolicyBegin } from "pages/Profile/actions";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobalIState } from "types/RootState";
import styles from "./PrivacyPolicy.module.scss";

function PrivacyPolicy() {
  const {
    action: { isLoading },
    privacyPolicy,
  } = useAppSelector((state: GlobalIState) => state.ProfilePageReducer);
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();

  useEffect(() => {
    dispatch(getPrivacyPolicyBegin());
  }, []);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div className={styles.wrapper}>
        <h1>{translations("label.privacy_policy")}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: privacyPolicy?.text,
          }}
        ></div>
      </div>
    </LoaderWrapper>
  );
}

export default PrivacyPolicy;
