import React, { useState, useRef } from "react";
import styles from "./PasscodeInput.module.scss";

interface PasscodeInputProps {
  length: number;
  onChange: (passcode: string) => void;
  showPasscode?: boolean;
}

const PasscodeInput: React.FC<PasscodeInputProps> = ({
  length,
  onChange,
  showPasscode,
}) => {
  const [values, setValues] = useState<string[]>(Array(length).fill(""));
  const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;

    if (value.length > 1) return;

    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    onChange(newValues.join(""));

    if (value !== "" && index < length - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !values[index] && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <div className={styles.passcodeContainer}>
      {values.map((value, index) => (
        <input
          key={index}
          ref={(el) => (inputsRef.current[index] = el)}
          type="tel"
          maxLength={1}
          value={showPasscode ? value : value ? "*" : ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className={styles.passcodeInput}
        />
      ))}
    </div>
  );
};

export default PasscodeInput;
