import { produce } from "immer";

import { ModalIState } from "types/reducers/modal.reducer.type";
import * as constants from "./constants";

const initialState: ModalIState = {
  isOpen: false,
  closeModal: false,
  modalContent: null,
  modalStyle: null,
  hideModalHeader: false,
  type: null,
};

const modalReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case constants.OPEN_MODAL:
        draft.isOpen = null;
        draft.modalContent = null;
        draft.isOpen = true;
        draft.modalContent = action.payload.content;
        draft.modalStyle = action.payload.modalStyle;
        draft.type = action.payload.type;
        break;
      case constants.CLOSE_MODAL:
        draft.isOpen = null;
        draft.isOpen = false;
        draft.hideModalHeader = false;
        break;
      case constants.HIDE_MODAL_HEADER:
        draft.hideModalHeader = true;
        break;
      default:
        break;
    }
  });

export default modalReducer;
