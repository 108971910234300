import { useTranslation } from "react-i18next";
import styles from "./Bookmarks.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useEffect, useState } from "react";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { useNavigate } from "react-router-dom";
import { openToaster, scrollToTop } from "App/actions";
import {
  BookmarksIcon,
  RatingIcon,
  ShoppingCartAddIcon,
  ViewDetailsArrowRight,
} from "assets/icons/icons";
import {
  formatPrice,
  getDefaultColour,
  isProductBookmarked,
} from "utils/utils";
import {
  fetchProductDetailsBegin,
  toggleBookmarkProductBegin,
  setCartItems,
  fetchProductBookmarksBegin,
  setProductBookmarksCookie,
  populateProductBookmarks,
} from "../actions";
import {
  FETCH_PRODUCT_DETAILS_SUCCESS,
  TOGGLE_BOOKMARK_PRODUCT_SUCCESS,
} from "../constants";
import BottleShadow from "assets/images/bottle-shadow.png";
import ProductDetails from "../ProductDetails/ProductDetails.page";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";

function Bookmarks() {
  const { isLoggedIn } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const { defaultColour, client } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading, actionName },
    products,
    productBookmarks,
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const [showProductDetails, setShowProductDetails] = useState({
    id: null,
    show: false,
  });
  const [shouldAddToCart, setShouldAddToCart] = useState(false);

  const productBookmarksCookie = () => {
    return JSON.parse(localStorage.getItem("product_bookmarks"));
  };

  const updatedProductsByLang = products?.filter(
    (item) =>
      productBookmarksCookie()
        ?.map((item) => item?.id)
        ?.includes(item?.id)
  );

  const getLanguageToken = () => {
    return localStorage.getItem("lang");
  };

  const checkIfIsBookmarked = (id: number) => {
    return isProductBookmarked(productBookmarks, id);
  };

  const handleProductClick = (id: number) => {
    if (isMobile) {
      navigate(`/purchasing/product?id=${id}`);
    } else {
      if (id === showProductDetails?.id) {
        setShowProductDetails({ id: null, show: false });
      } else {
        setShowProductDetails({ id: id, show: true });
      }
      dispatch(scrollToTop());
    }
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguageToken()));
  };

  const handleAddToBookmarks = (product) => {
    if (isLoggedIn) {
      dispatch(toggleBookmarkProductBegin(product?.id?.toString()));
    } else {
      if (!productBookmarksCookie()) {
        localStorage.setItem("product_bookmarks", JSON.stringify([]));
      }
      setTimeout(() => {
        dispatch(setProductBookmarksCookie(product));
      }, 0);
    }
  };

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }

    if (actionName === TOGGLE_BOOKMARK_PRODUCT_SUCCESS) {
      dispatch(fetchProductBookmarksBegin(getLanguageToken()));
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    if (showProductDetails.id) {
      dispatch(
        fetchProductDetailsBegin(showProductDetails.id, getLanguageToken())
      );
    }
    if (isLoggedIn) {
      dispatch(fetchProductBookmarksBegin(getLanguageToken()));
    }
  }, [showProductDetails.id, isLoggedIn]);

  useEffect(() => {
    if (!productBookmarksCookie) {
      localStorage.setItem("product_bookmarks", JSON.stringify([]));
    }
    if (updatedProductsByLang && !isLoggedIn) {
      dispatch(populateProductBookmarks(updatedProductsByLang));
    }
  }, [products]);

  useEffect(() => {
    dispatch(scrollToTop());
  }, []);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.container}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div
          className={styles.productsWrapper}
          style={{
            width: showProductDetails.show ? "32%" : "100%",
            justifyContent: showProductDetails.show && "space-evenly",
            marginRight: showProductDetails.show && "5px",
          }}
        >
          {isMobile ? (
            <div
              className={styles.mobileProduct}
              style={{ display: "none" }}
            ></div>
          ) : null}

          {productBookmarks?.length === 0 ? (
            <div className={styles.noProductsLabel}>
              <span>{translations("error.no_products_bookmarked")}</span>
            </div>
          ) : isMobile ? (
            productBookmarks?.map((item, index) => {
              return (
                <div
                  key={item?.id}
                  className={styles.mobileProduct}
                  onClick={() => handleProductClick(item?.id)}
                >
                  <div className={styles.leftContent}>
                    <div className={styles.header}>
                      <div className={styles.region}>
                        <span>{item?.region}</span>
                      </div>
                      <div className={styles.productRating}>
                        <RatingIcon color={getDefaultColour(defaultColour)} />
                        <span>96 / 100</span>
                      </div>
                    </div>
                    <div className={styles.category}>
                      <span>{item?.producer}</span>
                    </div>
                    <div>
                      <div className={styles.bottleInfo}>
                        <span>
                          {item?.vintage} | {item?.bottle_size}
                        </span>
                      </div>
                      <div className={styles.price}>
                        <span>CHF {formatPrice(item?.price?.toString())}</span>
                      </div>
                      <div className={styles.name}>
                        <span>
                          {item?.wine_name?.length > 25
                            ? item?.wine_name?.substring(0, 25) + "..."
                            : item?.wine_name}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContent}>
                    <img
                      className={styles.productImage}
                      src={item?.product_image}
                      alt={item?.producer}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            productBookmarks?.map((item) => {
              return (
                <div
                  key={item?.id}
                  className={`${styles.product} ${
                    showProductDetails.show && styles.productWithDetailsOpen
                  }`}
                >
                  <div className={styles.infoParts}>
                    <div
                      className={styles.productRating}
                      onClick={() => handleProductClick(item?.id)}
                    >
                      <RatingIcon />
                      <span>96 / 100</span>
                    </div>

                    <div>
                      <div
                        className={styles.name}
                        onClick={() => handleProductClick(item?.id)}
                      >
                        <div>
                          <span>
                            {showProductDetails.show &&
                            item?.wine_name?.length > 15
                              ? item?.wine_name?.substring(0, 15) + "..."
                              : item?.wine_name}
                          </span>
                        </div>
                        <br />
                        <div
                          className={styles.vintageLabel}
                          onClick={() => handleProductClick(item?.id)}
                        >
                          <span>{item?.vintage}</span>
                        </div>
                      </div>

                      <div
                        className={styles.bottleSizeLabel}
                        onClick={() => handleProductClick(item?.id)}
                      >
                        <span>{item?.bottle_size}</span>
                      </div>
                      <div
                        className={styles.header}
                        onClick={() => handleProductClick(item?.id)}
                      >
                        <span>{item?.region}</span>
                      </div>
                    </div>

                    <div className={styles.bottoms}>
                      <div
                        className={styles.price}
                        onClick={() => handleProductClick(item?.id)}
                      >
                        <span>CHF {item?.price}</span>
                      </div>

                      <div className={styles.productActions}>
                        <div
                          className={styles.addToCartButton}
                          onClick={() => handleAddToCart(item.id)}
                        >
                          <ShoppingCartAddIcon />
                        </div>
                        <div onClick={() => handleAddToBookmarks(item)}>
                          <BookmarksIcon
                            color={"#000"}
                            fill={checkIfIsBookmarked(item?.id) ? "#000" : null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={styles.imagePart}
                    onClick={() => handleProductClick(item?.id)}
                  >
                    <img
                      className={styles.productImage}
                      style={{
                        height: 260,
                      }}
                      src={item?.product_image}
                      alt={item?.producer}
                    />
                    <img
                      src={BottleShadow}
                      className={styles.bottleShadow}
                      alt="Shadow"
                    />
                  </div>

                  <div className={styles.productInfo}>
                    <div className={styles.boldText}>
                      <span>{item?.hover_text}</span>
                    </div>
                    <div>
                      <span>{item?.pairing}</span>
                    </div>
                    <div
                      className={`pointer ${styles.boldText}`}
                      onClick={() => handleProductClick(item?.id)}
                    >
                      <ViewDetailsArrowRight
                        color={getDefaultColour(defaultColour)}
                      />
                      <span>{translations("label.details")}</span>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>

        {showProductDetails.show ? (
          <div className={styles.productDetailsWrapper}>
            <ProductDetails setShowProductDetails={setShowProductDetails} />
          </div>
        ) : null}
      </div>
    </LoaderWrapper>
  );
}

export default Bookmarks;
