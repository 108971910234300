import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import styles from "./MobileRateAndTaste.module.scss";
import {
  BackButtonIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getClientTastingLabel, getDefaultColour } from "utils/utils";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";

import RateWine from "../RateWine/RateWine.page";
import TasteWine from "../TasteWine/TasteWine.page";
import {
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
} from "pages/PersonalLedger/actions";

function MobileRateAndTaste() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const {
    action: { isLoading },
    orders,
    userBlindTastings,
    addedWines,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const productIdParam = searchParams.get("id");
  const ledgerActiveTabParam = searchParams.get("tab");
  const [tastingLabels, setTastingLabels] = useState([""]);
  const [activeTab, setActiveTab] = useState("rating");
  const [data, setData] = useState([]);
  const productOrder = data?.find(
    (item) => item?.product?.id === Number(productIdParam)
  );

  const getSelectedRating = (item) => {
    if (item?.product?.ratings?.length !== 0) {
      switch (item?.product?.ratings[0]?.rating) {
        case 1:
          return <FirstRateWineEmoji />;
        case 2:
          return <SecondRateWineEmoji />;
        case 3:
          return <ThirdRateWineEmoji />;
        case 4:
          return <FourthRateWineEmoji />;
        case 5:
          return <FifthRateWineEmoji />;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    setTastingLabels(
      data?.length > 0
        ? getClientTastingLabel(productOrder?.product?.clientTastings)?.map(
            (label) => translations(label)
          )
        : [""]
    );
  }, [data]);

  useEffect(() => {
    if (ledgerActiveTabParam === "vinfinity-wines") {
      dispatch(getUserOrdersBegin());
      dispatch(getUserBlindTastingsBegin());
    }
  }, [ledgerActiveTabParam]);

  useEffect(() => {
    if (
      ledgerActiveTabParam === "vinfinity-wines" &&
      orders &&
      userBlindTastings
    ) {
      setData([...orders, ...userBlindTastings]);
    }
    if (ledgerActiveTabParam === "added-wines" && addedWines) {
      setData([...addedWines]);
    }
  }, [ledgerActiveTabParam, orders, userBlindTastings, addedWines]);

  return (
    <LoaderWrapper isLoading={isLoading}>
      <div
        className={styles.wrapper}
        style={
          {
            "--color": getDefaultColour(defaultColour),
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <div className={styles.tabs}>
            <div
              className={styles.tab}
              style={{
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                borderRight: "none",
                backgroundColor: activeTab === "rating" ? "#000" : "#fff",
                color: activeTab === "rating" ? "#fff" : "#000",
              }}
              onClick={() => setActiveTab("rating")}
            >
              {translations("tab.rating")}
            </div>
            <div
              className={styles.tab}
              style={{
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                backgroundColor: activeTab === "tasting" ? "#000" : "#fff",
                color: activeTab === "tasting" ? "#fff" : "#000",
              }}
              onClick={() => setActiveTab("tasting")}
            >
              {translations("tab.tasting")}
            </div>
          </div>
        </div>

        <div className={styles.content}>
          {productOrder?.product?.ratings?.length === 0 &&
          productOrder?.product?.clientTastings?.length === 0 ? null : (
            <div className={styles.card}>
              {tastingLabels?.length > 0 ? (
                <div className={styles.headerLabel}>
                  <span>{translations("label.my_tasting")}</span>
                  <div className={styles.tastingLabel}>
                    <span>{tastingLabels?.join(", ")}</span>
                  </div>
                </div>
              ) : null}
              <div className={styles.ratings}>
                {getSelectedRating(productOrder) ? (
                  <div className={styles.headerLabel}>
                    <span>{translations("label.my_rating")}</span>
                  </div>
                ) : null}
                <div>{getSelectedRating(productOrder)}</div>
              </div>
            </div>
          )}

          {activeTab === "rating" ? (
            <RateWine setActiveTab={setActiveTab} product_id={productIdParam} />
          ) : (
            <TasteWine
              setActiveTab={setActiveTab}
              product_id={productIdParam}
            />
          )}
        </div>
      </div>
    </LoaderWrapper>
  );
}

export default MobileRateAndTaste;
