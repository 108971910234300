import { useTranslation } from "react-i18next";
import styles from "./Signup.module.scss";
import { PreviousIcon } from "assets/icons/icons";
import { useEffect, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput.component";
import ButtonComponent from "components/Button/Button.component";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";

import { openModal, openToaster } from "App/actions";
import { modalTypes } from "types/reducers/modal.reducer.type";
import StorageService from "services/storage.service";
import { checkIfHasPasscodeBegin } from "../actions";
import { CHECK_IF_HAS_PASSCODE_SUCCESS } from "../constants";
import properties from "properties";
import ChangeLanguageModal from "components/ChangeLanguage/ChangeLanguage.component";

export default function Signup() {
  const { defaultColour, termsAndConditions, privacyPolicy } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { actionName, isLoading },
  } = useAppSelector((state: GlobalIState) => state.authenticationReducer);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t: translations } = useTranslation();
  const [state, setState] = useState({
    email: "",
  });
  const referralCodeParam = searchParams.get("referral_code");
  const firstNameParam = searchParams.get("firstname");
  const lastNameParam = searchParams.get("lastname");
  const langStored = () => localStorage.getItem("lang")?.toUpperCase();

  const handleChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePreviousButton = () => {
    navigate("/login");
  };
  const getStoredLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleTermsAndConditionsPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className={styles.shownText}
            dangerouslySetInnerHTML={{
              __html: termsAndConditions?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handlePrivacyPolicyPopup = () => {
    dispatch(
      openModal({
        content: (
          <div
            className="shownText"
            dangerouslySetInnerHTML={{
              __html: privacyPolicy?.text,
            }}
          ></div>
        ),
        type: modalTypes.smInfo,
      })
    );
  };

  const handleLanguageChangeModal = () => {
    dispatch(
      openModal({
        content: <ChangeLanguageModal />,
        type: modalTypes.mdNoBg,
      })
    );
  };

  const handleSubmit = () => {
    if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(state.email) ||
      /(\.\.)/.test(state.email) ||
      (properties.ENV !== "dev" && state.email.includes("+"))
    ) {
      dispatch(
        openToaster(
          "ERROR",
          translations("error.special_characters_not_allowed")
        )
      );
      return;
    }

    dispatch(
      checkIfHasPasscodeBegin({
        email: state.email,
        sign_up: true,
        lang: langStored().toLowerCase(),
      })
    );
  };

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  useEffect(() => {
    if (referralCodeParam) {
      StorageService.setKeyValue("referral_code", referralCodeParam);
    }
  }, [referralCodeParam]);

  useEffect(() => {
    if (actionName === CHECK_IF_HAS_PASSCODE_SUCCESS) {
      navigate(`/signup/passcode?email=${state.email}`);
    }
  }, [actionName]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div
              className={styles.previousIcon}
              onClick={() => {
                handlePreviousButton();
              }}
            >
              <PreviousIcon color={getDefaultColour(defaultColour)} />
            </div>
            <div
              className={styles.additionalItem}
              onClick={handleLanguageChangeModal}
            >
              <span>{langStored()}</span>
            </div>
          </div>
          <div className={styles.link} onClick={() => navigate("/login")}>
            <span>{translations("label.already_a_client")}</span>
            <a>{translations("button.login")}</a>
          </div>
        </div>
        <div className={styles.authContent}>
          <h1>{translations("label.signup")}</h1>

          {referralCodeParam ? (
            <span className={styles.invitedByLabel}>
              {translations(`label.invited_by`) +
                firstNameParam +
                " " +
                lastNameParam +
                translations("label.to_join_vinfinity")}
            </span>
          ) : null}

          <div className={styles.emailField}>
            <CustomInput
              type="email"
              name="email"
              label={translations("label.your_email_address")}
              value={state.email}
              onKeyDown={(e) => onKeyDown(e)}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className={styles.submitButton}>
            <ButtonComponent
              label={translations("button.sign_up")}
              class={"btn btn-primary"}
              isLoading={isLoading}
              handleClick={() => handleSubmit()}
            />
          </div>

          <div className={styles.colored}>
            <span>{translations("label.only_above_18")}</span>
          </div>

          {!referralCodeParam ? (
            <div
              className={styles.signupMethods}
              onClick={() => navigate("/auth/alternative?type=signup")}
            >
              <span>{translations("label.alternative_signup_methods")}</span>
            </div>
          ) : null}

          <div className={styles.agreementHint}>
            <span>{translations("label.by_signing,you_agree_to_the")}</span>
            <span
              className={styles.link}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              onClick={() => handleTermsAndConditionsPopup()}
            >
              {translations("label.terms_of_use")}
            </span>
            <span>{translations("label.and")}</span>
            <span
              className={styles.link}
              style={
                {
                  "--color": getDefaultColour(defaultColour),
                } as React.CSSProperties
              }
              onClick={() => handlePrivacyPolicyPopup()}
            >
              {translations("label.privacy_policy")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
