import {
  CloseIconWhite,
  ExpandPhotoIcon,
  PreviousIcon,
  ReuploadIcon,
} from "assets/icons/icons";
import styles from "./AddWine.module.scss";
import { getDefaultColour, getValueByIndex } from "utils/utils";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

import ButtonComponent from "components/Button/Button.component";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { closeModal, openModal, openToaster } from "App/actions";
import { addWineBegin } from "pages/PersonalLedger/actions";
import { ADD_WINE_SUCCESS } from "pages/PersonalLedger/constants";
import dayjs from "dayjs";
import { modalTypes } from "types/reducers/modal.reducer.type";
import AddCustomFieldModal from "./AddCustomFieldModal/AddCustomFieldModal.page";
import { getPurchasingFiltersBegin } from "pages/Purchasing/actions";
import { fetchCountriesBegin } from "pages/Authentication/actions";
import { TextField } from "@mui/material";

function AddWine() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { countries } = useAppSelector(
    (state: GlobalIState) => state.authenticationReducer
  );
  const {
    action: { actionName },
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const { filterOptions } = useAppSelector(
    (state: GlobalIState) => state.PurchasingReducer
  );

  const { t: translations } = useTranslation();
  const [state, setState] = useState({
    wine_name: "",
    producer: "",
    bottle_size: "",
    type: "",
    country: "",
    region: "",
    location: "",
    inventory: null,
  });
  const isMobile = useCheckMobileScreen();
  const [previewUrl, setPreviewUrl] = useState(null);
  const inputFile = useRef(null);
  const [customFields, setCustomFields] = useState({});
  const [file, setFile] = useState("");
  const typeFilterOptions: any = filterOptions?.types;
  const bottleSizeOptions = [
    { id: 1, label: "20cl" },
    { id: 2, label: "37.5cl" },
    { id: 3, label: "50cl" },
    { id: 4, label: "75cl" },
    { id: 5, label: "150cl" },
    { id: 6, label: "300cl" },
    { id: 7, label: "600cl" },
    { id: 8, label: "1200cl" },
    { id: 9, label: translations("label.other") },
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleStateChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const updateNthItem = (index, newValue) => {
    const entries = Object.entries(customFields);
    if (index < 0 || index >= entries.length) {
      console.error("Index out of range");
      return;
    }

    entries[index][1] = newValue;

    const updatedState: any = Object.fromEntries(entries);
    setCustomFields(updatedState);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleAddImageClick = () => {
    inputFile?.current?.click();
  };

  const handleImageExpand = () => {
    dispatch(
      openModal({
        content: (
          <div className={styles.expandedImage}>
            <div className={styles.header}>
              <div onClick={() => dispatch(closeModal())}>
                <CloseIconWhite />
              </div>
            </div>
            <div className={styles.imageBox}>
              <img src={previewUrl} alt="Selected" />
            </div>
          </div>
        ),
        type: modalTypes.sm,
      })
    );
  };

  const handleClearData = () => {
    setState({
      wine_name: "",
      producer: "",
      bottle_size: null,
      type: "",
      country: "",
      region: "",
      location: "",
      inventory: null,
    });

    setCustomFields({});
    setPreviewUrl("");
  };

  const handleAddCustomField = () => {
    dispatch(
      openModal({
        content: (
          <AddCustomFieldModal
            handleAddCustomValueSubmit={handleAddCustomValueSubmit}
          />
        ),
        type: modalTypes.mdNoBg,
      })
    );
  };

  const handleSave = () => {
    if (state.wine_name === "") {
      dispatch(openToaster("ERROR", translations("error.wine_name_mandatory")));
      return;
    }

    setIsDisabled(true);

    let data = {
      ...state,
      inventory: Number(state.inventory),
      custom_field: JSON.stringify(customFields),
      image: previewUrl ? true : false,
      image_file: file,
    };

    dispatch(addWineBegin(data));
  };

  const handleAddCustomValueSubmit = (data) => {
    setCustomFields((prev) => ({
      ...prev,
      [data.label]: data.value,
    }));

    dispatch(closeModal());
  };

  useEffect(() => {
    if (actionName === ADD_WINE_SUCCESS) {
      setIsLoading(true);
      setTimeout(() => {
        navigate("/journal?tab=added-wines");
      }, 3000);
    }
  }, [actionName]);

  useEffect(() => {
    if (!filterOptions) dispatch(getPurchasingFiltersBegin(getLanguage()));

    if (!countries) dispatch(fetchCountriesBegin("withAuth"));
  }, [filterOptions, countries]);

  return (
    <div className={styles.container}>
      {!isMobile ? (
        <div className={styles.header}>
          <div onClick={() => navigate("/journal")}>
            <PreviousIcon color={getDefaultColour(defaultColour)} />
          </div>
          <div
            className={styles.clearDataBtn}
            onClick={() => handleClearData()}
          >
            {translations("label.clear_data")}
          </div>
        </div>
      ) : null}

      <h1>{translations("label.enter_wine_data_below")}</h1>
      <div className={styles.inputs}>
        <TextField
          name="wine_name"
          label={translations("label.wine_name")}
          variant="filled"
          value={state.wine_name}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            style: {
              color: "#000",
              fontFamily: "Montserrat-Regular",
              fontSize: 14,
              marginTop: state?.wine_name?.length >= 1 ? 5 : 13,
              transition: ".1s ease-in-out",
            },
          }}
          sx={{
            outline: "none",
          }}
          onChange={(e) => handleStateChange(e)}
        />

        <TextField
          name="producer"
          label={translations("label.producer")}
          variant="filled"
          value={state.producer}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            style: {
              color: "#000",
              fontFamily: "Montserrat-Regular",
              fontSize: 14,
              marginTop: state?.producer?.length >= 1 ? 5 : 13,
              transition: ".1s ease-in-out",
            },
          }}
          sx={{
            outline: "none",
            fontSize: 16,
            fontFamily: "Montserrat-Medium",
          }}
          onChange={(e) => handleStateChange(e)}
        />

        <div className={styles.selectBox}>
          <label
            htmlFor="bottle_size"
            className={
              state?.bottle_size?.length === 0
                ? styles.nullValueLabel
                : styles.activeLabel
            }
          >
            {translations("label.bottle_size")}
          </label>
          <select
            name={"bottle_size"}
            value={state.bottle_size ? state.bottle_size : 0}
            className={styles.selectInput}
            onChange={(e) => handleStateChange(e)}
          >
            <option value="0" disabled>
              {/* {translations("label.bottle_size")} */}
            </option>

            {bottleSizeOptions?.map((item, index) => {
              return (
                <option key={index} value={item.label ? item.label : null}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>

        <div className={styles.selectBox}>
          <label
            htmlFor="type"
            className={
              state?.type?.length === 0
                ? styles.nullValueLabel
                : styles.activeLabel
            }
          >
            {translations("label.type")}
          </label>
          <select
            name={"type"}
            value={state.type ? state.type : 0}
            className={styles.selectInput}
            onChange={(e) => handleStateChange(e)}
          >
            <option value="0" disabled>
              {/* {translations("label.type")} */}
            </option>

            {typeFilterOptions?.map((item, index) => {
              return (
                <option key={index} value={item.value ? item.value : null}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className={styles.selectBox}>
          <label
            htmlFor="country"
            className={
              state?.country?.length === 0
                ? styles.nullValueLabel
                : styles.activeLabel
            }
          >
            {translations("label.country")}
          </label>
          <select
            name={"country"}
            value={state.country ? state.country : 0}
            className={styles.selectInput}
            onChange={(e) => handleStateChange(e)}
          >
            <option value="0" disabled>
              {/* {translations("label.country")} */}
            </option>

            {countries?.map((item, index) => {
              return (
                <option key={index} value={item.value ? item.value : null}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>

        <TextField
          name="region"
          label={translations("label.region")}
          variant="filled"
          value={state.region}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            style: {
              color: "#000",
              fontFamily: "Montserrat-Regular",
              // fontSize: 14,
              marginTop: state?.region?.length >= 1 ? 5 : 13,
              transition: ".1s ease-in-out",
            },
          }}
          sx={{
            outline: "none",
            fontSize: 16,
            fontFamily: "Montserrat-Medium",
          }}
          onChange={(e) => handleStateChange(e)}
        />

        {Object.keys(customFields)?.length >= 1 ? (
          <TextField
            name={Object.keys(customFields)[0]}
            label={Object.keys(customFields)[0]}
            variant="filled"
            value={getValueByIndex(0, customFields)?.toString() ?? ""}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              style: {
                color: "#000",
                fontFamily: "Montserrat-Regular",
                // fontSize: 14,
                marginTop:
                  getValueByIndex(0, customFields)?.toString()?.length >= 1
                    ? 5
                    : 13,
                transition: ".1s ease-in-out",
              },
            }}
            sx={{
              outline: "none",
              fontSize: 16,
              fontFamily: "Montserrat-Medium",
            }}
            onChange={(e) => updateNthItem(0, e.target.value)}
          />
        ) : null}

        {Object.keys(customFields)?.length >= 2 ? (
          <TextField
            name={Object.keys(customFields)[1]}
            label={Object.keys(customFields)[1]}
            variant="filled"
            value={getValueByIndex(1, customFields)?.toString() ?? ""}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              style: {
                color: "#000",
                fontFamily: "Montserrat-Regular",
                // fontSize: 14,
                marginTop:
                  getValueByIndex(2, customFields)?.toString()?.length >= 1
                    ? 5
                    : 13,
                transition: ".1s ease-in-out",
              },
            }}
            sx={{
              outline: "none",
              fontSize: 16,
              fontFamily: "Montserrat-Medium",
            }}
            onChange={(e) => updateNthItem(1, e.target.value)}
          />
        ) : null}

        {Object.keys(customFields)?.length >= 3 ? (
          <TextField
            name={Object.keys(customFields)[2]}
            label={Object.keys(customFields)[2]}
            variant="filled"
            value={getValueByIndex(2, customFields)?.toString() ?? ""}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              style: {
                color: "#000",
                fontFamily: "Montserrat-Regular",
                // fontSize: 14,
                marginTop:
                  getValueByIndex(2, customFields)?.toString()?.length >= 1
                    ? 5
                    : 13,
                transition: ".1s ease-in-out",
              },
            }}
            sx={{
              outline: "none",
              fontSize: 16,
              fontFamily: "Montserrat-Medium",
            }}
            onChange={(e) => updateNthItem(2, e.target.value)}
          />
        ) : null}

        {previewUrl && (
          <div className={styles.selectedImageBox}>
            <div className={styles.image}>
              <img src={previewUrl} alt="Selected" />
            </div>
            <div className={styles.rightPart}>
              <div className={styles.label}>
                <span>
                  {translations("label.image_added_on")}{" "}
                  {dayjs().format("MMM DD,  YYYY")}
                </span>
              </div>
              <div className={styles.bottomButtons}>
                <div onClick={() => handleImageExpand()}>
                  <ExpandPhotoIcon />
                  <span> {translations("label.expand_photo")}</span>
                </div>
                <div onClick={handleAddImageClick}>
                  <ReuploadIcon />
                  <span> {translations("label.reupload")}</span>
                </div>
              </div>
            </div>
          </div>
        )}

        <input
          type="file"
          id="imageUploadInput"
          ref={inputFile}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImageChange}
        />

        <div className={styles.actionButtons}>
          {!previewUrl ? (
            <div className={styles.addButton} onClick={handleAddImageClick}>
              <span>
                +{" "}
                {translations("label.add_bottle_image")?.length >= 25
                  ? translations("label.add_bottle_image")?.slice(0, 25) + "..."
                  : translations("label.add_bottle_image")}
              </span>
            </div>
          ) : null}
          <div
            className={styles.addButton}
            style={{
              width: previewUrl ? "100%" : "50%",
              opacity: Object.keys(customFields)?.length >= 3 ? 0.5 : 1,
              pointerEvents:
                Object.keys(customFields)?.length >= 3 ? "none" : "all",
              cursor:
                Object.keys(customFields)?.length >= 3 ? "default" : "pointer",
            }}
            onClick={() => handleAddCustomField()}
          >
            <span>
              +{" "}
              {translations("label.add_custom_field")?.length >= 25
                ? translations("label.add_custom_field")?.slice(0, 25) + "..."
                : translations("label.add_custom_field")}
            </span>
          </div>
        </div>
      </div>

      <h1 className={styles.secondDataTitle}>
        {translations("label.bottle_location_and_inventory")}
      </h1>
      <div className={styles.inputs}>
        <TextField
          name="location"
          label={translations("label.location")}
          variant="filled"
          value={state.location}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            style: {
              color: "#000",
              fontFamily: "Montserrat-Regular",
              // fontSize: 14,
              marginTop: state?.location?.length >= 1 ? 5 : 13,
              transition: ".1s ease-in-out",
            },
          }}
          sx={{
            outline: "none",
            fontSize: 16,
            fontFamily: "Montserrat-Medium",
          }}
          onChange={(e) => handleStateChange(e)}
        />

        <TextField
          type="number"
          name="inventory"
          label={translations("label.inventory")}
          variant="filled"
          value={state.inventory}
          InputProps={{
            disableUnderline: true,
            inputProps: { min: 0 },
          }}
          InputLabelProps={{
            style: {
              color: "#000",
              fontFamily: "Montserrat-Regular",
              // fontSize: 14,
              marginTop: state?.inventory?.length >= 1 ? 5 : 13,
              transition: ".1s ease-in-out",
            },
          }}
          sx={{
            outline: "none",
            fontSize: 16,
            fontFamily: "Montserrat-Medium",
          }}
          onChange={(e) => handleStateChange(e)}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
        />
      </div>

      <div className={styles.button}>
        <ButtonComponent
          label={translations("button.save")}
          isLoading={isLoading}
          class={"btn btn-primary"}
          disabled={isDisabled}
          handleClick={() => handleSave()}
        />
      </div>
    </div>
  );
}

export default AddWine;
