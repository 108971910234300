import React, { useEffect, useRef, useState } from "react";
import styles from "./ReturnsOverTimeChart.module.scss";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useAppDispatch, useAppSelector } from "hooks";
import { GlobalIState } from "types/RootState";
import { getDefaultColour } from "utils/utils";
import { RefreshIcon } from "assets/icons/icons";
import { useTranslation } from "react-i18next";
import {
  getWeeklyPerformanceClientBegin,
  getWeeklyPerformanceGuestBegin,
} from "../actions";
import dayjs from "dayjs";
import StorageService from "services/storage.service";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(weekOfYear);

function ReturnsOverTimeChart() {
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { weeklyPerformance } = useAppSelector(
    (state: GlobalIState) => state.InvestmentsReducer
  );
  const dispatch = useAppDispatch();
  const { t: translations } = useTranslation();
  const chartRef = useRef<any>(null);
  const [chartKey, setChartKey] = useState(0);
  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 300,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        tools: {
          download: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: Array(10).fill(getDefaultColour(defaultColour)),
    },
    grid: {
      show: false,
    },
    colors: ["#34FF71"],
    xaxis: {
      categories: [],
      labels: {
        show: true,
        style: {
          colors: Array(10).fill(getDefaultColour(defaultColour)),
          fontSize: "12px",
        },
        offsetX: 2,
      },
    },
    yaxis: {
      labels: {
        show: false,
        formatter: (value: any) => {
          return `${value} %`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
  });

  const [state, setState] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
  });

  const isLoggedIn = () => {
    return StorageService.getDataByKey("token");
  };

  const handleRefresh = () => {
    setChartKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (isLoggedIn()) dispatch(getWeeklyPerformanceClientBegin());
    // else dispatch(getWeeklyPerformanceGuestBegin());
  }, [dispatch]);

  useEffect(() => {
    if (weeklyPerformance?.result?.length >= 1) {
      const categories = weeklyPerformance?.result?.map((item) =>
        item?.month_group
          ? `${translations("label.month")} ${item?.month_group}`
          : `${translations("label.week")} ${dayjs(
              item.week_start_date
            ).week()}`
      );
      const data = weeklyPerformance?.result?.map((item) => item.value);

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories,
        },
      }));

      setState({
        series: [
          {
            name: "",
            data: data,
          },
        ],
      });
    } else {
      const categories = [
        `${translations("label.year")} 1`,
        `${translations("label.year")} 2`,
        `${translations("label.year")} 3`,
        `${translations("label.year")} 4`,
        `${translations("label.year")} 5`,
        `${translations("label.year")} 6`,
        `${translations("label.year")} 7`,
        `${translations("label.year")} 8`,
        `${translations("label.year")} 9`,
        `${translations("label.year")} 10`,
      ];
      const data = [1.6, 4.9, 10.7, 8.4, 4.1, 8.2, 7.6, 19.2, 18.3, 28.5];

      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories,
        },
      }));

      setState({
        series: [
          {
            name: "",
            data,
          },
        ],
      });
    }
    setChartKey((prevKey) => prevKey + 1);
  }, [weeklyPerformance]);

  return (
    <div className={styles.lineChartWrapper}>
      <div className={styles.header}>
        <div>
          <h2>
            {translations(
              isLoggedIn() && weeklyPerformance?.result?.length >= 1
                ? "label.returns_since_start"
                : "label.exemplary_returns"
            )}
          </h2>
        </div>
        <div className={styles.refreshIcon} onClick={handleRefresh}>
          <RefreshIcon color={getDefaultColour(defaultColour)} />
        </div>
      </div>
      {isLoggedIn() && weeklyPerformance?.result?.length >= 1 ? (
        <div
          className={styles.valueLabels}
          style={{
            borderBottom: `1px solid ${getDefaultColour(defaultColour)}30`,
          }}
        >
          <div>{translations("label.net_wine_returns")}</div>
          <div>
            {weeklyPerformance?.cumulativeSum
              ? weeklyPerformance?.cumulativeSum
              : 0.0}{" "}
            %
          </div>
        </div>
      ) : null}
      <ReactApexChart
        key={chartKey}
        ref={chartRef}
        options={options}
        series={state.series}
        type="line"
        height={"80%"}
      />
    </div>
  );
}

export default ReturnsOverTimeChart;
