import styles from "./MobileMyOrders.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalIState } from "types/RootState";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  DeleteIcon,
  ExpandIcon,
  FifthRateWineEmoji,
  FirstRateWineEmoji,
  FourthRateWineEmoji,
  SecondRateWineEmoji,
  ShoppingCartAddIcon,
  ThirdRateWineEmoji,
} from "assets/icons/icons";
import dayjs from "dayjs";
import { getClientTastingLabel, getDefaultColour } from "utils/utils";
import { useEffect, useRef, useState } from "react";
import {
  fetchProductDetailsBegin,
  setCartItems,
} from "pages/Purchasing/actions";
import { openToaster } from "App/actions";
import { FETCH_PRODUCT_DETAILS_SUCCESS } from "pages/Purchasing/constants";
import {
  deleteAddedWineBegin,
  getAddedWinesBegin,
  getUserBlindTastingsBegin,
  getUserOrdersBegin,
  requestToSupplyWineBegin,
} from "pages/PersonalLedger/actions";
import WineBluredImage from "assets/images/wine.png";
import LoaderWrapper from "components/Loader/LoaderWrapper/LoaderWrapper";

function MobileMyOrders() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t: translations } = useTranslation();
  const { defaultColour } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const {
    action: { isLoading },
    orders,
    userBlindTastings,
    addedWines,
    searchedWines,
  } = useAppSelector((state: GlobalIState) => state.PersonalLedgerReducer);
  const {
    action: { actionName },
    productDetails,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const [shouldAddToCart, setShouldAddToCart] = useState(false);
  const [activeDotIndex, setActiveDotIndex] = useState(0);
  const [previousSlide, setPreviousSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();
  let activeTab = searchParams.get("tab");

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMode: true,
    centerPadding: "5%",
    afterChange: (current: number) => scrollToDot(current),
  };

  const getSelectedRating = (item) => {
    if (item?.product?.ratings?.length !== 0) {
      switch (item?.product?.ratings[0].rating) {
        case 1:
          return <FirstRateWineEmoji />;
          break;
        case 2:
          return <SecondRateWineEmoji />;
          break;
        case 3:
          return <ThirdRateWineEmoji />;
          break;
        case 4:
          return <FourthRateWineEmoji />;
          break;
        case 5:
          return <FifthRateWineEmoji />;
          break;
        default:
          break;
      }
    }
  };

  const handleProposeToSupply = (id) => {
    dispatch(requestToSupplyWineBegin(id));

    setTimeout(() => {
      if (activeTab === "vinfinity-wines") {
        dispatch(getUserOrdersBegin());
        dispatch(getUserBlindTastingsBegin());
      }

      if (activeTab === "added-wines") {
        dispatch(getAddedWinesBegin());
      }
    }, 300);
  };

  const rowItem = (item, index, tastingLabels) => {
    const showRateAndTasteBtn =
      item?.product?.ratings?.length === 0 &&
      item?.product?.clientTastings?.length === 0;

    return (
      <div key={index} className={styles.row}>
        <div className={styles.firstCol}>
          <div className={styles.imagePart}>
            <img
              src={
                item?.product?.product_image
                  ? item?.product?.product_image
                  : WineBluredImage
              }
              style={!item?.product?.product_image ? { opacity: 0.5 } : {}}
            />
          </div>
          <div className={styles.rightPart}>
            <div>
              <div className={styles.title}>
                <span>
                  {item?.product?.wine_name?.length > 25
                    ? item?.product?.wine_name?.substring(0, 25) + "..."
                    : item?.product?.wine_name}
                </span>
              </div>
              <div>{item?.product?.vintage}</div>
            </div>
            <div>
              <div className={styles.title}>
                {item?.product?.bottle_size ? (
                  <span>{translations("label.bottle_size")}</span>
                ) : null}
              </div>
              <div className={styles.bottleSizeLabel}>
                <span>{item?.product?.bottle_size}</span>
              </div>
            </div>
          </div>

          {!item?.product?.inputted_data ? (
            <div
              className={styles.expandIcon}
              onClick={() => handleCardExpand(item, "product-details")}
            >
              <ExpandIcon />
              <span>{translations("label.details")}</span>
            </div>
          ) : null}
        </div>
        <div className={styles.secondCol}>
          {showRateAndTasteBtn ? (
            <div
              className={styles.noRatingsBox}
              onClick={() => handleCardExpand(item, "rate/taste")}
            >
              <span>
                {translations("label.rate") +
                  " & " +
                  translations("label.taste")}
              </span>
            </div>
          ) : (
            <div className={styles.productFeedback}>
              {tastingLabels?.length > 0 ? (
                <div className={styles.headerLabel}>
                  <span>{translations("label.my_tasting")}</span>
                  <div className={styles.tastingLabel}>
                    <span>{tastingLabels?.join(", ")}</span>
                  </div>
                </div>
              ) : null}
              <div className={styles.ratings}>
                {getSelectedRating(item) ? (
                  <div className={styles.headerLabel}>
                    <span>{translations("label.my_rating")}</span>
                  </div>
                ) : null}
                <div className={styles.rateEmojis}>
                  <div
                    className={
                      item?.product?.ratings[0]?.rating === 1
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FirstRateWineEmoji />
                  </div>
                  <div
                    className={
                      item?.product?.ratings[0]?.rating === 2
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <SecondRateWineEmoji />
                  </div>
                  <div
                    className={
                      item?.product?.ratings[0]?.rating === 3
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <ThirdRateWineEmoji />
                  </div>
                  <div
                    className={
                      item?.product?.ratings[0]?.rating === 4
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FourthRateWineEmoji />
                  </div>
                  <div
                    className={
                      item?.product?.ratings[0]?.rating === 5
                        ? styles.activeIcon
                        : styles.disabled
                    }
                  >
                    <FifthRateWineEmoji />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className={styles.expandIcon}
            onClick={() => handleCardExpand(item, "rate/taste")}
          >
            <ExpandIcon />
            <span>{translations("label.details")}</span>
          </div>
        </div>
        <div className={styles.thirdCol}>
          <div>
            <div className={styles.title}>
              {!item?.flaskSentOn && !item?.product?.inputted_data ? (
                <span>{translations("label.purchased_quantity")}</span>
              ) : null}
              {item?.product?.inputted_data &&
              (item?.product?.inputted_data?.location !== "" ||
                item?.product?.inputted_data?.country !== "") ? (
                <span>
                  {translations("label.bottle_location_and_inventory")}
                </span>
              ) : null}
            </div>
            <div>
              <span>
                {item?.product?.inputted_data
                  ? `${item?.product?.inputted_data?.location} ${
                      item?.product?.inputted_data?.inventory?.toString()
                        ?.length > 0
                        ? (item?.product?.inputted_data?.location?.length > 0
                            ? " | "
                            : "") + item?.product?.inputted_data?.inventory
                        : ""
                    }`
                  : item?.totalQuantity}
              </span>
            </div>
          </div>
          <div>
            <div className={styles.title}>
              <span>
                {translations(
                  item.flaskSentOn
                    ? "label.flask_sent_on"
                    : item?.product?.inputted_data
                    ? "label.bottle_added_on"
                    : "label.last_order"
                )}
              </span>
            </div>
            <div>
              <span>
                {dayjs(
                  item.flaskSentOn
                    ? item?.flaskSentOn
                    : item?.product?.inputted_data
                    ? item?.product?.createdAt
                    : item?.lastOrderDate
                ).format("DD.MM.YYYY")}
              </span>
            </div>
          </div>
          {!item?.product?.inputted_data ? (
            <div
              className={styles.expandIcon}
              onClick={() => handleCardExpand(item, "pqty")}
            >
              <ExpandIcon />
              <span>{translations("label.details")}</span>
            </div>
          ) : null}
        </div>
        <div className={styles.lastColumn}>
          <div className={styles.price}>
            {item?.product?.old_price ? (
              <>
                <span className={styles.discount}>
                  CHF {item?.product?.old_price}
                </span>
                <br />
              </>
            ) : null}
            {item?.product?.price ? (
              <span>CHF {item?.product?.price}</span>
            ) : // <span>{translations("label.not_known")}</span>
            null}
          </div>
          <div className={styles.bottomItems}>
            {item?.product?.price ? (
              <div
                className={styles.mainBtn}
                onClick={() => handleAddToCart(item?.product?.id)}
                style={
                  !item?.product?.price || item?.product?.inventory_count === 0
                    ? { pointerEvents: "none", opacity: 0.6 }
                    : {}
                }
              >
                <ShoppingCartAddIcon />
              </div>
            ) : item?.product?.supplyRequest ? (
              <div className={styles.requestedToSupplyLabel}>
                <span>{translations("label.request_received_to_supply")}</span>
              </div>
            ) : (
              <div
                className={styles.mainBtn}
                style={{
                  opacity: item?.product?.supplyRequest ? 0.5 : 1,
                  cursor: item?.product?.supplyRequest ? "default" : "pointer",
                }}
                onClick={() => handleProposeToSupply(item?.product?.id)}
              >
                <span>{translations("label.propose_to_supply")}</span>
              </div>
            )}
          </div>
        </div>
        {item?.product?.inputted_data ? (
          <div
            className={styles.deleteWineIcon}
            onClick={() => handleDeleteAddedWine(item?.product?.id)}
          >
            <DeleteIcon color="#000" />
          </div>
        ) : null}
      </div>
    );
  };

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleCardExpand = (item, card) => {
    if (activeTab === "added-wines" && card !== "rate/taste") {
      return;
    }
    switch (card) {
      case "product-details":
        navigate(
          `/journal/product-details?id=${item?.product?.id}&tab=${activeTab}`
        );
        break;
      case "rate/taste":
        navigate(
          `/journal/rate-and-taste?id=${item?.product?.id}&tab=${activeTab}`
        );
        break;
      case "pqty":
        navigate(
          `/journal/transactions?id=${item?.product?.id}&tab=${activeTab}`
        );
        break;
      default:
        break;
    }
  };

  const handleAddToCart = (id: number) => {
    setShouldAddToCart(true);
    dispatch(fetchProductDetailsBegin(id, getLanguage()));
  };

  const scrollToDot = (current: number) => {
    setCurrentSlide(current);
    setPreviousSlide(current);

    if (activeDotIndex === 0 && previousSlide > current) {
      setActiveDotIndex(7);
    } else if (previousSlide > current) {
      setActiveDotIndex(activeDotIndex - 1);
    } else if (current === 0) {
      setActiveDotIndex(1);
    } else if (current > 7) {
      if (current > 7 && activeDotIndex < 7)
        setActiveDotIndex(activeDotIndex + 1);
      else setActiveDotIndex(0);
    } else setActiveDotIndex(current);
  };

  const handleDeleteAddedWine = (id: number) => {
    dispatch(deleteAddedWineBegin(id));
    setTimeout(() => {
      dispatch(getAddedWinesBegin());
    }, 200);
  };

  useEffect(() => {
    if (shouldAddToCart && actionName === FETCH_PRODUCT_DETAILS_SUCCESS) {
      dispatch(setCartItems(productDetails));
      dispatch(
        openToaster("SUCCESS", translations("label.item_added_to_cart"))
      );
      setShouldAddToCart(false);
    }
  }, [shouldAddToCart, actionName]);

  useEffect(() => {
    setActiveDotIndex(0);
    setPreviousSlide(0);
    setCurrentSlide(0);
  }, [data]);

  useEffect(() => {
    if (activeTab === "vinfinity-wines") {
      dispatch(getUserOrdersBegin());
      dispatch(getUserBlindTastingsBegin());
    } else if (activeTab === "added-wines") {
      dispatch(getAddedWinesBegin());
    }
  }, [activeTab]);

  useEffect(() => {
    if (searchedWines?.length !== 0) {
      setData([...(searchedWines ?? [])]);
    } else if (!searchedWines && !orders && !userBlindTastings && !addedWines) {
      setData(null);
    }

    if (activeTab === "vinfinity-wines" && orders && userBlindTastings) {
      setData([...orders, ...userBlindTastings]);
    }
    if (activeTab === "added-wines" && addedWines) {
      setData([...addedWines]);
    }
  }, [activeTab, orders, userBlindTastings, addedWines, searchedWines]);

  return (
    <>
      <LoaderWrapper isLoading={isLoading}>
        {data?.length > 0 ? (
          <div className={styles.header}>
            <div className={styles.customDots}>
              {/* {Array(data?.length >= 8 ? 8 : data?.length)
              .fill("")
              .map((_, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor:
                      activeDotIndex === index
                        ? getDefaultColour(defaultColour)
                        : "#b7bdc0",
                  }}
                ></div>
              ))} */}
              {Array(data?.length >= 5 ? 5 : data?.length)
                .fill("")
                .map((_, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor:
                        Math.floor(
                          Array(data?.length >= 5 ? 5 : data?.length)?.length /
                            2
                        ) === index
                          ? getDefaultColour(defaultColour)
                          : "#b7bdc0",
                      width:
                        Math.floor(
                          Array(data?.length >= 5 ? 5 : data?.length)?.length /
                            2
                        ) === index
                          ? 10
                          : 7,
                      height:
                        Math.floor(
                          Array(data?.length >= 5 ? 5 : data?.length)?.length /
                            2
                        ) === index
                          ? 10
                          : 7,
                      marginTop:
                        Math.floor(
                          Array(data?.length >= 5 ? 5 : data?.length)?.length /
                            2
                        ) === index
                          ? 0
                          : 2,
                    }}
                  ></div>
                ))}
            </div>
            <div className={styles.rightPart}>
              <span style={{ color: getDefaultColour(defaultColour) }}>
                {currentSlide + 1}/{data?.length}
              </span>
            </div>
          </div>
        ) : null}
        {data?.length === 0 ? (
          <div className={styles.noOrdersText}>
            <span>
              {translations(
                activeTab === "added-wines"
                  ? "label.no_wines_added_found"
                  : "label.no_orders_found"
              )}
            </span>
          </div>
        ) : (
          <div
            className={`${styles.sliderContainer} slider-container`}
            style={data?.length === 1 ? { marginTop: 20 } : {}}
          >
            {data?.length === 1 ? (
              data?.map((item, index) => {
                const tastingLabels = getClientTastingLabel(
                  item?.product?.clientTastings
                )?.map((label) => translations(label));
                return rowItem(item, index, tastingLabels);
              })
            ) : (
              <Slider ref={sliderRef} {...settings}>
                {data?.map((item, index) => {
                  const tastingLabels = getClientTastingLabel(
                    item?.product?.clientTastings
                  )?.map((label) => translations(label));
                  return rowItem(item, index, tastingLabels);
                })}
              </Slider>
            )}
          </div>
        )}
      </LoaderWrapper>
    </>
  );
}

export default MobileMyOrders;
